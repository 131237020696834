import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatCardModule } from '@angular/material/card'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { CoreModule } from 'app/core/core.module'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MaterialModule } from 'app/layouts/material.module'
import { PipesModule } from 'app/core/pipes/pipes.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ArticlesComponent } from './components/articles/articles.component'
import { ArticlesRoutingModule } from './articles-routing.module';
import { ArticleComponent } from './components/article/article.component';
import { ArticleCardComponent } from './components/article-card/article-card.component'

@NgModule({
    declarations: [ArticlesComponent, ArticleComponent, ArticleCardComponent],
    exports: [
        ArticleCardComponent
    ],
    imports: [
        CoreModule,
        CommonModule,
        ArticlesRoutingModule,
        MaterialModule,
        MatCardModule,
        MatButtonToggleModule,
        TranslateModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        PipesModule,
        FlexLayoutModule,
        FormsModule,
    ],
})
export class ArticlesModule {}
