<div
    class="search-panel cursor-pointer"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    [class.search-panner--visible]="visibleSearch"
    (click)="visibleSearch = !visibleSearch;"
>
    <span *ngIf="searchValuesInit && searchValuesFirstInit; else initSearch" class="search-panel_text"
        ><strong
            >{{"ActivityType." + (searchValues?.activityTypes.toString() ? searchValues?.activityTypes.toString() :
            'All') | translate}}</strong
        ><ng-container
            *ngIf="searchValues && searchValues?.distanceRanges | distanceRanges: ('Unit.KM' | translate) as distanceRanges"
        >
            <span class="d-lg-none"> · </span><span class="d-lg-inline-block d-md-none">&nbsp;|&nbsp;</span>
            <strong>{{distanceRanges | translate}}</strong></ng-container
        ><br class="d-lg-none" /><span class="d-lg-inline-block d-md-none">&nbsp;|&nbsp;</span>{{searchValues?.dateFrom
        | localizeDate:'shortDate'}} - {{searchValues?.dateTo | localizeDate:'shortDate'}}</span
    >
    <ng-template #initSearch
        ><span class="search-panel_text"><strong>{{"searchInitText" | translate}}</strong></span></ng-template
    >
    <button mat-icon-button class="srh-btn">
        <mat-icon>search</mat-icon>
    </button>
</div>
<div class="search-panel cursor-pointer opacity-0" *ngIf="visibleSearch" (click)="visibleSearch = false">&nbsp;</div>
<div class="search-form" #searchForm>
    <app-organized-activities-search
        [hidden]="!visibleSearch"
        (searchParams)="actualSearchParams($event)"
        (searchCallback)="visibleSearch = false"
        (offClick)="closeSearch()"
        [offClickEnabled]="!!visibleSearch"
    ></app-organized-activities-search>
</div>
