<!-- TODO: Add condition if picture then add class "activity-header--with-picture" to next <mat-card-header>. If not picture avaible then nothing -->
<mat-card-header
    class="cursor-pointer activity-header activity-header--with-picture pt-4 ps-3"
    [routerLink]="['/events/activities',organizedActivity.id]"
    fxLayout="column"
>
    <!--
        <div mat-card-avatar>
            <img src="assets/images/users/1.jpg" class="img-fluid img-circle" />
        </div>
    -->
    <mat-card-title>
        <!--{{organizedActivity.organizedEvent?.name | localize}} • -->
        {{organizedActivity.name | localize}}
    </mat-card-title>
    <h3 class="mb-0">
        {{ "ActivityType." + organizedActivity.activity.type | translate}} {{organizedActivity.activity.distance}}
        {{'Unit.KM' | translate}} • {{organizedActivity.place | localize}} • {{organizedActivity.startTime |
        localizeDate: "shortDate"}}
    </h3>
</mat-card-header>
<ng-container *ngIf="($any(organizedActivity?.organizedEvent) | eventPictureUrl) as pictures">
    <ng-container *ngIf="pictures && pictures.length">
        <div class="activity-picture-holder" [style]="isInThePast ? 'filter: saturate(0)' : ''">
            <app-carousel
                *ngIf="showCarousel; else activityPicture"
                [pictures]="pictures"
                [type]="carouselTypes.gallery"
                [fullscreen]="false"
                [autoPlay]="false"
            ></app-carousel>
            <ng-template #activityPicture>
                <a
                    *ngIf="fixedAspectRatio"
                    [routerLink]="['/events/activities',organizedActivity.id]"
                    class="activity-picture-container cursor-pointer"
                    [style.background-image]="'url(' + favoriteOf(pictures, organizedActivity.favoriteMediaRef).thumbRef + ')'"
                    [attr.title]="favoriteOf(pictures, organizedActivity.favoriteMediaRef).title"
                >
                </a>
                <a *ngIf="!fixedAspectRatio" [routerLink]="['/events/activities',organizedActivity.id]">
                    <img [src]="favoriteOf(pictures, organizedActivity.favoriteMediaRef).thumbRef" [attr.alt]="favoriteOf(pictures, organizedActivity.favoriteMediaRef).title" class="activity-picture" />
                </a>
            </ng-template>
        </div>
    </ng-container>
</ng-container>
<mat-card-content
    [class]="organizedActivity?.organizedEvent?.media?.length ? 'activity-content activity-content--with-picture' : ''"
>
    <p *ngIf="activityLabels$ | async as activityLabels">
        <mat-chip-list class="activity-labels">
            <mat-chip
                *ngFor="let label of organizedActivity.labels"
                class="activity-labels__chip"
                [class.mat-chip-selected]="selectedActivityLabels | inArray: label"
            >
                {{label | localizeActivityLabel : activityLabels : (selectedLanguage$ | async).code}}
            </mat-chip>
        </mat-chip-list>
    </p>
    <p [innerHTML]="organizedActivity.description | localize | marked"></p>
    <mat-card-actions *ngIf="!(isLoggedIn$|async) && organizedActivity.currentPrice != null">
        <app-register-button [organizedActivity]="organizedActivity"></app-register-button>
    </mat-card-actions>
</mat-card-content>
<!-- footer -->
<div fxLayout="row wrap" class="mat-card-footer-border text-center bg-light rounded_bottom">
    <div fxFlex.gt-xs="50" fxFlex="50" class="p-t-10 p-b-10">
        <a
            class="link"
            href="javascript:void(0)"
            [routerLink]="['/events/activities', organizedActivity.id]"
        >
            <i class="fa fa-eye m-r-10 m-t-0 m-b-5"></i>
            {{organizedActivity.followCount}}<!-- {{'followers' | translate | lowercase}}-->
        </a>
        <a
            class="link"
            href="javascript:void(0)"
            *ngIf="!(isFollowing$ | async) || !(isLoggedIn$ | async)" (click)="follow()"
        >
            ({{'follow' | translate | lowercase}})
        </a>
        <a
            class="link"
            href="javascript:void(0)"
            *ngIf="isFollowing$ | async" (click)="unfollow()"
        >
            ({{'unfollow' | translate | lowercase}})
        </a>
    </div>
<!--    <div fxFlex.gt-xs="33.33" fxFlex="33.33" class="p-t-10 p-b-10">-->
<!--        <a-->
<!--            *ngIf="organizedActivity.registrationLimit"-->
<!--            class="link"-->
<!--            href="javascript:void(0)"-->
<!--            [routerLink]="['/events/activities', organizedActivity.id]"-->
<!--        >-->
<!--            <i class="fa fa-check m-r-10 m-t-0 m-b-5"></i>-->
<!--            {{organizedActivity.registrationCount}} / {{organizedActivity.registrationLimit}}-->
<!--        </a>-->
<!--    </div>-->
    <div fxFlex.gt-xs="50" fxFlex="50" class="p-t-10 p-b-10">
        <a class="link" href="javascript:void(0)" [routerLink]="['/events/activities', organizedActivity.id]">
            <i class="fa fa-comment m-r-10 m-t-0 m-b-5"></i>
            {{organizedActivity.messageCount}} {{'posts' | translate | lowercase}}
        </a>
    </div>
</div>
