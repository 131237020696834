import { LocalizedTexts, Nullish } from 'app/core/core.models'
import * as R from 'ramda'
import { Globals } from 'app/app.consts'
import { LanguageCode } from '../constants/languages'

const getLocalizedTextWithoutFallbacks = (localizedTexts: LocalizedTexts, language: LanguageCode) =>
    R.pipe(R.find(R.propEq('language', language)), R.prop('text'))(localizedTexts)

export const getLocalizedText = (
    localizedTexts: LocalizedTexts = [],
    languageCode: LanguageCode,
    withFallback: boolean,
): Nullish<string> => {
    const localizedText = getLocalizedTextWithoutFallbacks(localizedTexts, languageCode)
    if (localizedText) {
        return localizedText
    }

    if (withFallback) {
        const defaultFallbackText = getLocalizedTextWithoutFallbacks(localizedTexts, Globals.defaultFallbackLanguage)
        if (defaultFallbackText) {
            return defaultFallbackText
        }

        return R.pipe(
            R.find(R.propSatisfies((prop) => prop === 'xxx', 'text')),
            R.prop('text'),
            R.defaultTo(''),
        )(localizedTexts)
    }

    return null
}
