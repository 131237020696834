import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { OrganizerHomeComponent } from './components/organizer-home/organizer-home.component'
import { OrganizerBillingComponent } from './components/organizer-billing/organizer-billing.component'
import { OrganizerRegistrationsComponent } from './components/organizer-registrations/organizer-registrations.component'
import { OrganizerTermsComponent } from 'app/organizers/components/organizer-terms/organizer-terms.component'
import { OrganizerSettingsComponent } from 'app/organizers/components/organizer-settings/organizer-settings.component'
import { OrganizerCreateComponent } from './components/organizer-create/organizer-create.component'
import { OrganizerMembersComponent } from './components/organizer-members/organizer-members.component'
import {
    OrganizerActivityResultsComponent
} from './components/organizer-activity-results/organizer-activity-results.component'
import { OrganizerProfileComponent } from './components/organizer-profile/organizer-profile.component'

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: OrganizerHomeComponent,
                data: {
                    title: 'dashboard',
                    urls: [{ title: 'organizer', url: '/organizers' }, { title: 'dashboard' }],
                },
            },
            {
                path: 'create',
                component: OrganizerCreateComponent,
                data: {
                    title: 'create_organizer',
                    urls: [],
                },
            },
            {
                path: 'terms',
                component: OrganizerTermsComponent,
                data: {
                    title: 'terms',
                    urls: [{ title: 'organizer', url: '/organizers' }, { title: 'terms' }],
                },
            },
            {
                path: 'settings',
                component: OrganizerSettingsComponent,
                data: {
                    title: 'organizer_settings',
                    urls: [{ title: 'organizer', url: '/organizers' }, { title: 'settings' }],
                },
            },
            {
                path: 'members',
                component: OrganizerMembersComponent,
                data: {
                    title: 'organizer_members',
                    urls: [{ title: 'organizer', url: '/organizers' }, { title: 'members' }],
                },
            },
            {
                path: 'registrations',
                component: OrganizerRegistrationsComponent,
                data: {
                    title: 'registrations',
                    urls: [{ title: 'organizer', url: '/organizers' }, { title: 'registrations' }],
                },
            },
            {
                path: 'results',
                component: OrganizerActivityResultsComponent,
                data: {
                    title: 'results',
                    urls: [{ title: 'organizer', url: '/organizers' }, { title: 'results' }],
                },
            },
            {
                path: 'billing',
                component: OrganizerBillingComponent,
                data: {
                    title: 'billing',
                    urls: [{ title: 'organizer', url: '/organizers' }, { title: 'billing' }],
                },
            },
            {
                path: ':id',
                component: OrganizerProfileComponent,
                data: {
                    title: 'profile',
                    urls: [{ title: 'organizer', url: '/organizers' }, { title: 'profile' }],
                },
            },
            {
                path: '**',
                redirectTo: '',
            },
        ],
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OrganizersRoutingModule {}
