import { Component, Input, OnInit } from '@angular/core'
import {
    ActivityResult,
    OrganizedActivity,
    RegisteredPerson,
    RegistrationState,
    WeatherForecast,
} from 'app/events/events.models'
import { CarouselTypes } from 'app/core/components/carousel/carousel.model'
import { Message, Person } from 'app/core/core.models'
import { environment } from 'environments/environment'
import { MessageService } from 'app/core/services/message.service'
import { BehaviorSubject, map, Observable } from 'rxjs'
import { LoginService } from 'app/users/services/login.service'
import { RegistrationService } from 'app/events/services/registration.service'
import { OrganizedActivityService } from 'app/events/services/organized-activity.service'
import { ActivityResultService } from 'app/events/services/activity-result.service'
import { WindowService } from 'app/core/services/window.service'
import { OrganizerService } from 'app/organizers/services/organizer.service'
import { Organizer } from 'app/organizers/organizers.models'
import { server } from 'app/core/core.server'
import { EnumService } from 'app/core/services/enum.service'
import { LanguageService } from 'app/core/services/language.service'
import { ConnectionType } from 'app/users/users.models'
import { TranslateService } from '@ngx-translate/core'
import { UserService } from 'app/users/services/user.service'
import { UserFeedbackComponent } from 'app/users/components/user-feedback/user-feedback.component'
import { MatDialog } from '@angular/material/dialog'
import { Sort } from '@angular/material/sort'
import { compare } from 'app/core/core.functions'

@Component({
    selector: 'organized-activity',
    templateUrl: './organized-activity.component.html',
})
export class OrganizedActivityComponent implements OnInit {
    @Input() organizedActivity: OrganizedActivity
    @Input() darkMode: boolean

    now = new Date()
    nowISO = this.now.toISOString()
    time = server.time
    carpooling = environment.features['carpooling']
    organizedActivityWeatherForecast: WeatherForecast
    organizedActivityFollowerPersons: Person[] = []
    organizedActivityAllFollowerPersons: Person[] = []
    organizedActivityRegisteredPersons: Person[] = []

    CarouselTypes = CarouselTypes
    priceListColumns = ['amount', 'validTo'/*, 'validUntilRegistrationCount'*/]
    merchandisePriceListColumns = ['description', 'amount', 'validTo']

    appUrl: string
    apiUrl: string
    config = server
    environment = environment
    messages: Observable<Message[]>
    activityLabels$ = this.enumService.activityLabels$
    selectedLanguage$ = this.languageService.selectedLanguage$

    constructor(
        private readonly organizedActivityService: OrganizedActivityService,
        private readonly activityResultService: ActivityResultService,
        private readonly organizerService: OrganizerService,
        private readonly messageService: MessageService,
        public readonly loginService: LoginService,
        private readonly languageService: LanguageService,
        private readonly translateService: TranslateService,
        private readonly registrationService: RegistrationService,
        private readonly userService: UserService,
        private readonly enumService: EnumService,
        public readonly windowService: WindowService,
        private readonly dialog: MatDialog,
    ) {}

    registeredPersons$ = new BehaviorSubject<RegisteredPerson[]>([])
    activityResults$: Observable<ActivityResult[]>
    organizer$: Observable<Organizer>
    userOrganizerRole = null
    startListColumns = [/*'num', */'person', 'startNumber', 'teamName', 'created']
    activityResultColumns = ['person', 'startNumber', 'time', 'category', 'categoryPosition']
    activityResultCount$: Observable<number>
    startingPersonCount$: Observable<number>


    ngOnInit(): void {
        this.loginService.currentUser$.subscribe((user) => {
            this.organizedActivityService
                .getOrganizedActivityFollowerPersons$(user.id, this.organizedActivity.id)
                .subscribe((it) => {
                    if (it)
                        this.organizedActivityFollowerPersons = it
                })
            this.organizedActivityService
                .getOrganizedActivityFollowerPersons$(this.organizedActivity.id, null)
                .subscribe((it) => {
                    if (it)
                        this.organizedActivityAllFollowerPersons = it
                })
            this.organizedActivityService
                .getOrganizedActivityRegisteredPersons$(user.id, this.organizedActivity.id)
                .subscribe((it) => {
                    if (it)
                        this.organizedActivityRegisteredPersons = it
                })
            this.organizedActivityService
                .getOrganizedActivityWeatherForecast$(this.organizedActivity.id, this.languageService.selectedLanguageCode)
                .subscribe((it) => {
                    this.organizedActivityWeatherForecast = it
                })
            this.organizerService.getUserOrganizerRole$(user.id, this.organizedActivity.organizedEvent.organizerId).subscribe((organizerRole) => {
                this.userOrganizerRole = organizerRole
            })
        })
        this.registrationService.getRegisteredPersons$({
            organizedActivityId: this.organizedActivity.id,
            registrationState: RegistrationState.Confirmed
        }).subscribe(this.registeredPersons$)
        this.activityResults$ = this.activityResultService.getActivityResults$({
            organizedActivityId: this.organizedActivity.id,
            skip: 0,
            limit: 50,
            ascending: true,
        })
        this.organizer$ = this.organizerService.getOrganizer$(this.organizedActivity.organizedEvent.organizerId)
        this.appUrl = `${server.appUrl}/events/activities/${this.organizedActivity.id}`
        this.apiUrl = `${server.apiUrl}/events/activities/${this.organizedActivity.id}`
        this.activityResultCount$ = this.activityResults$.pipe(
            map((activityResults) => activityResults.length)
        )
        this.startingPersonCount$ = this.registeredPersons$.pipe(
            map((registeredPersons) =>
                registeredPersons.filter((registeredPerson) =>
                    true//registeredPerson.state == RegistrationState.Confirmed
                ).length
            )
        )
    }

    priceListRegistrationCount(i: number) {
        return this.organizedActivity.priceList.slice(0, i).reduce((a, b) => +a + +b.validUntilRegistrationCount, 0)
    }

    get hasGarminConnection() {
        let currentUser = this.loginService.currentUserOrNull
        return this.windowService.isNative && currentUser ?
            currentUser.connections.filter((connection) => connection.type == ConnectionType.Garmin).length > 0 : false
    }

    exportToGarmin() {
        if (confirm(this.translateService.instant('confirm_garminExport') + '?')) {
            this.organizedActivityService.exportOrganizedActivityToConnection$(ConnectionType.Garmin, this.organizedActivity.id).subscribe((id) => {
                console.log('Export organized activity to connection', id)
                this.windowService.open(`https://connect.garmin.com/modern/course/${id}`)
            })
        }
    }

    feedback() {
        const dialogRef = this.dialog.open(UserFeedbackComponent, {
            width: '300px',
            data: {
                id: this.organizedActivity.id,
                text: ''
            }
        })
        dialogRef.afterClosed().subscribe((data) => {
            if (data.text) {
                this.userService.createFeedback$(this.organizedActivity.id, 'OrganizedActivity', data.text)
                    .subscribe((result) => {
                        console.log('User feedback created', result)
                    })
            }
        })
    }

    sortRegisteredPersons(sort: Sort) {
        const isAsc = sort.direction === 'asc'
        this.registeredPersons$.next(this.registeredPersons$.value.slice().sort((a, b) => {
            switch (sort.active) {
                case 'person':
                    return compare(a.person.surname + a.person.name, b.person.surname + b.person.name, isAsc)
                case 'startNumber':
                    return compare(a.startNumber, b.startNumber, isAsc)
                case 'created':
                    return compare(a.created, b.created, isAsc)
                default:
                    return 0
            }
        }))
    }
}
