import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { ActivityResult } from '../../events.models'
import { ActivityResultService } from '../../services/activity-result.service'

@Component({
  selector: 'app-activity-results-table',
  templateUrl: './activity-results-table.component.html',
})
export class ActivityResultsTableComponent implements OnInit {
    activityResults$: Observable<ActivityResult[]>
    columns = ['organizedActivity', 'startTime', 'startNumber', 'time', 'category', 'categoryPosition']

    @Input()
    userId: string

    @Input()
    limit = 50

    constructor(
        private readonly activityResultService: ActivityResultService,
    ) {}

    ngOnInit(): void {
        this.activityResults$ = this.activityResultService.getActivityResults$({
            userId: this.userId,
            skip: 0,
            limit: this.limit,
            ascending: false,
        })
    }
}
