import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Router } from '@angular/router'
import { OrganizedActivitiesStateService } from 'app/events/components/organized-activities/organized-activities-state.service'
import { OrganizedActivity } from 'app/events/events.models'
import { LanguageService } from 'app/core/services/language.service'

@Component({
    selector: 'app-organized-activities-list',
    templateUrl: './organized-activities-list.component.html',
    styleUrls: ['organized-activities-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedActivitiesListComponent {
    readonly columns = ['name', 'place', 'distance', 'startTime']

    constructor(
        public readonly organizedActivitiesStateService: OrganizedActivitiesStateService,
        private readonly router: Router,
        public readonly languageService: LanguageService,
    ) {}

    public navigateToDetail({ id }: OrganizedActivity): void {
        this.router.navigate(['/', 'events', 'activities', id])
    }
}
