import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { RegisterModalData } from 'app/events/events.models'
import { WindowService } from 'app/core/services/window.service'
import { server } from 'app/core/core.server'
import { Amount, Merchandise, MerchandisePrice } from '../../core.models'
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, startWith, switchMap } from 'rxjs'
import { TeamService } from '../../../users/services/team.service'
import { FormControl, FormGroup } from '@angular/forms'
import * as R_ from 'ramda-extension'
import { Team } from '../../../users/users.models'
import { filter } from 'rxjs/operators'
import { Globals } from '../../../app.consts'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { LoginService } from '../../../users/services/login.service'

@UntilDestroy()
@Component({
    selector: 'app-prompt',
    templateUrl: './register-modal.component.html',
})
export class RegisterModalComponent implements OnInit {

    totalAmount$ = new BehaviorSubject<Amount>(this.data.organizedActivity.currentPrice.amount)
    merchandise: Merchandise[] = []
    agreed: boolean = false
    form = new FormGroup({
        team: new FormControl(null),
    })
    teamOptions$: Observable<Team[]>

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: RegisterModalData,
        private windowService: WindowService,
        private loginService: LoginService,
        private teamService: TeamService,
    ) {
    }

    ngOnInit() {
        this.teamOptions$ = this.form.controls['team'].valueChanges.pipe(
            startWith(''),
            filter(R_.isNotEmpty),
            debounceTime(Globals.valueChangeDebounce),
            distinctUntilChanged(),
            switchMap((value) => {
                return this.teamService.getTeams$(typeof value === 'string' ? value : value?.name, 20)
            }),
            untilDestroyed(this),
        )
        this.loginService.currentUser$.subscribe((user) => {
            this.form.controls['team'].setValue(user.profile.teamName)
        })
    }

    displayTeam(value: any) {
        return typeof value === 'string' ? value : value?.name ?? ''
    }

    get teamName() {
        return this.displayTeam(this.form.controls['team'].value)
    }

    openTerms() {
        this.windowService.open(server.appUrl + '/terms', '_blank')
    }

    updateMerchandise(merchandisePrice: MerchandisePrice, checked: boolean) {
        if (checked) {
            this.merchandise.push({ sku: merchandisePrice.sku, quantity: 1 })
            this.totalAmount$.next({ value: this.totalAmount$.value.value + merchandisePrice.amount.value, currency: this.totalAmount$.value.currency })
        } else {
            this.merchandise = this.merchandise.filter((it) => it.sku != merchandisePrice.sku)
            this.totalAmount$.next({ value: this.totalAmount$.value.value - merchandisePrice.amount.value, currency: this.totalAmount$.value.currency })
        }
        console.log('Updating registration data', this.totalAmount$.value, this.merchandise)
    }
}
