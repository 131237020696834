<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <h2>Page not found</h2>
                <p>{{path}}</p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
