<ng-container *ngIf="asCard">
    <!-- share button -->
    <button
        *ngIf="windowService.isNative"
        class="d-inline-block"
        mat-button
        (click)="windowService.share(this.appUrl, person.name)"
        style="position: absolute; right: 0; top: 0; margin: 5pt"
    >
        <mat-icon>share</mat-icon>
        {{'share' | translate}}
    </button>

    <!-- profile data -->
    <a [routerLink]="'/' + (person.nickname ? person.nickname : person.id)">
        <img [src]="person.id ? apiUrl + '/users/' + person.id + '.pic?time=' + time : '/assets/images/logo-actigo.png'"
             class="img-fluid img-circle"
             style="width: 120px; height: 120px"/>
    </a>
    <h3 class="card-title mt-4 mb-0 h3">{{person.name}}</h3>
    <ng-container *ngIf="person.nickname">
        <p>@{{person.nickname}}</p>
    </ng-container>
    <p class="text-muted" *ngIf="subtitle">{{subtitle}}</p>
    <p class="text-muted" *ngIf="!subtitle">
        {{label}}
    </p>
    <p *ngIf="withDescription" [innerHTML]="person.description | marked"></p>
    <p *ngIf="withTeams && person.teams?.length">
        {{'teams' | translate}}:
        <ng-container *ngFor="let team of person.teams; last as isLast">
            {{team.name}}{{isLast ? '' : ', '}}
        </ng-container>
    </p>
    <div class="mt-4">
        <button mat-raised-button color="accent" *ngIf="followStatus == 'NotFollowing'" (click)="followPerson()">
            {{'follow' | translate}}
        </button>
        <button mat-raised-button color="accent" *ngIf="followStatus == 'Pending'" [disabled]="true">
            {{'pending' | translate}}
        </button>
        <button mat-raised-button color="accent" *ngIf="followStatus == 'Following'" (click)="unfollowPerson()">
            {{'unfollow' | translate}}
        </button>
    </div>
    <!--
    <div class="m-t-20">
      <span>
        {{ contact.contactadd }}
          <br />
        <br />
        <i class="fa fa-phone m-r-10"></i>{{ contact.contactno }}</span
      >
    </div>
    -->
</ng-container>

<button
    *ngIf="remove.observed && showRemoveButton"
    mat-icon-button
    color="warn"
    (click)="removePerson()"
    style="float: right"
>
    <mat-icon>delete</mat-icon>
</button>
<a
    class="d-flex person-component person-component_simple p-3"
    *ngIf="!asCard"
    [routerLink]="person.id ? '/' + (person.nickname ? person.nickname : person.id) : ''"
><!-- NOTE: pls fix to make both block a + delete button work :-) -->
    <div mat-card-avatar>
        <img [src]="person.id ? apiUrl + '/users/' + person.id + '.pic?time=' + time : '/assets/images/logo-actigo.png'"
             class="img-fluid img-circle"
             style="width: 40px; height: 40px"
        />
    </div>
    <div class="ms-2">
        <h3 class="h6 mb-0">{{person.name}}</h3>
        <div class="text-muted" *ngIf="subtitle">{{subtitle}}</div>
        <div class="text-muted" *ngIf="!subtitle && person.nickname">@{{person.nickname}}</div>
    </div>
</a>

