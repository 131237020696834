import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatCardModule } from '@angular/material/card'
import { MatTabsModule } from '@angular/material/tabs'
import { MatChipsModule } from '@angular/material/chips'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { TranslateModule } from '@ngx-translate/core'
import { CoreModule } from 'app/core/core.module'
import { EventsRoutingModule } from './events-routing.module'
import { OrganizedActivityContainer } from 'app/events/components/organized-activity/organized-activity.container'
import { MapModule } from 'app/core/components/map/map.module'
import { OrganizedActivitiesComponent } from 'app/events/components/organized-activities/organized-activities.component'
import { OrganizedActivityCardComponent } from 'app/events/components/organized-activity-card/organized-activity-card.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
// import { MatDatepickerModule } from '@angular/material/datepicker'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { MaterialModule } from 'app/layouts/material.module'
import { RegistrationsComponent } from './components/registrations/registrations.component'
import { ActivityResultsComponent } from './components/activity-results/activity-results.component'
import { ReviewsComponent } from './components/reviews/reviews.component'
import { OrganizedEventsComponent } from './components/organized-events/organized-events.component'
// import { OrganizedActivitiesSearchComponent } from 'app/events/components/organized-activities-search/organized-activities-search.component'
import { PipesModule } from 'app/core/pipes/pipes.module'
import { ChipsRadioInputModule } from 'app/core/components/forms/chips-radio-input/chips-radio-input.module'
import { CarouselModule } from 'app/core/components/carousel/carousel.module'
import { TrackFromActivitiesPipe } from 'app/core/pipes/tracks-from-activities/tracks-from-activities.pipe'
import { LoaderModule } from 'app/core/components/loader/loader.module'
import { OrganizedEventComponent } from './components/organized-event/organized-event.component'
import { ActionButtonModule } from 'app/core/components/action-button/action-button.module'
import { RegistrationComponent } from './components/registration/registration.component'
import { OrganizedActivitiesCardsComponent } from 'app/events/components/organized-activities-cards/organized-activities-cards.component'
import { OrganizedActivitiesListComponent } from 'app/events/components/organized-activities-list/organized-activities-list.component'
import { OrganizedActivitiesMapComponent } from 'app/events/components/organized-activities-map/organized-activities-map.component'
import { OrganizedEventSettingsComponent } from 'app/events/components/organized-event-settings/organized-event-settings.component'
import { OrganizedEventMediaComponent } from 'app/events/components/organized-event-media/organized-event-media.component'
import { FileUploaderModule } from 'app/core/components/file-uploader/file-uploader.module'
import { OrganizedEventActivityComponent } from 'app/events/components/organized-event-activity/organized-event-activity.component'
import { OrganizedEventActivitiesComponent } from 'app/events/components/organized-event-activities/organized-event-activities.component'
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker'
import { TrackProfileModule } from 'app/core/components/track-profile/track-profile.module'
import { OrganizedActivityCarpoolComponent } from './components/organized-activity-carpool/organized-activity-carpool.component'
import { RegisterButtonComponent } from './components/register-button/register-button.component'
import { OrganizedEventActivityPriceListComponent } from 'app/events/components/organized-event-activity-price-list/organized-event-activity-price-list.component'
import { OrganizedActivityComponent } from 'app/events/components/organized-activity/organized-activity.component'
import { RegistrationsTableComponent } from './components/registrations-table/registrations-table.component'
import { EventsTotalsComponent } from './components/events-totals/events-totals.component'
import { FollowButtonComponent } from './components/follow-button/follow-button.component'
import { OrganizedActivitiesMineComponent } from './components/organized-activities-mine/organized-activities-mine.component'
import { OrganizedActivitiesSearchModule } from 'app/events/components/organized-activities-search/organized-activities-search.module';
import { OrganizedActivityCopyComponent } from './components/organized-activity-copy/organized-activity-copy.component';
import { ActivityResultsTableComponent } from './components/activity-results-table/activity-results-table.component';
import { OrganizedEventActivityMerchandisePriceListComponent } from './components/organized-event-activity-merchandise-price-list/organized-event-activity-merchandise-price-list.component';
import { OrganizedActivityOpenModalComponent } from './components/organized-activity-open-modal/organized-activity-open-modal.component';
import { OrganizedEventActivitySyncComponent } from './components/organized-event-activity-sync/organized-event-activity-sync.component'

@NgModule({
    declarations: [
        OrganizedActivityContainer,
        OrganizedActivitiesComponent,
        OrganizedActivityCardComponent,
        RegistrationComponent,
        RegistrationsComponent,
        ActivityResultsComponent,
        ReviewsComponent,
        OrganizedEventsComponent,
        // OrganizedActivitiesSearchComponent,
        TrackFromActivitiesPipe,
        OrganizedEventComponent,
        OrganizedActivitiesCardsComponent,
        OrganizedActivitiesListComponent,
        OrganizedActivitiesMapComponent,
        OrganizedEventSettingsComponent,
        OrganizedEventMediaComponent,
        OrganizedEventActivityComponent,
        OrganizedEventActivitiesComponent,
        OrganizedEventActivityPriceListComponent,
        OrganizedActivityCarpoolComponent,
        RegisterButtonComponent,
        FollowButtonComponent,
        OrganizedActivityComponent,
        RegistrationsTableComponent,
        EventsTotalsComponent,
        OrganizedActivitiesMineComponent,
        OrganizedActivityCopyComponent,
        ActivityResultsTableComponent,
        OrganizedEventActivityMerchandisePriceListComponent,
        OrganizedActivityOpenModalComponent,
        OrganizedEventActivitySyncComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        EventsRoutingModule,
        FlexLayoutModule,
        TranslateModule,
        MatCardModule,
        MatTabsModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        MatButtonToggleModule,
        MapModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        MaterialModule,
        PipesModule,
        CarouselModule,
        PipesModule,
        ChipsRadioInputModule,
        LoaderModule,
        ActionButtonModule,
        FileUploaderModule,
        TrackProfileModule,
        NgxMatTimepickerModule,
        OrganizedActivitiesSearchModule,
    ],
    providers: [],
    exports: [
        RegistrationsComponent,
        RegistrationsTableComponent,
        EventsTotalsComponent,
        OrganizedActivityCardComponent,
        ActivityResultsTableComponent,
    ],
})
export class EventsModule {}
