import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
    selector: 'app-not-found',
    templateUrl: './email-not-verified.component.html',
})
export class EmailNotVerifiedComponent implements OnInit {
    constructor(private router: Router) {}

    path: String

    ngOnInit(): void {
        this.path = this.router.url
    }
}
