import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core'
import { MenuItem, MenuItems, MenuItemType } from 'app/layouts/layouts.models'
import { LoginService } from 'app/users/services/login.service'
import { server } from 'app/core/core.server'

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['user-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
    @Input()
    menuItems: MenuItems<() => any>

    @Output()
    profileMenuClick = new EventEmitter<MenuItem<() => any>>()

    manuItemType = MenuItemType
    picture$ = this.loginService.picture$
    user$ = this.loginService.me$

    constructor(
        private loginService: LoginService
    ) {
    }
}
