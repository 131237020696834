import { NgModule } from '@angular/core'
import { DomChangedDirective } from 'app/core/directives/dom-changed/dom-changed.directive'
import { AutofocusDirective } from 'app/core/directives/auto-focus.directive'
import { OffClickDirective } from 'app/core/directives/off-click/off-click.directive'

@NgModule({
    declarations: [AutofocusDirective, DomChangedDirective, OffClickDirective],
    exports: [AutofocusDirective, DomChangedDirective, OffClickDirective],
    providers: [AutofocusDirective, DomChangedDirective, OffClickDirective],
})
export class DirectivesModule {}
