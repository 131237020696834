export enum LanguageCode {
    CS = 'cs',
    EN = 'en',
}

export const defaultLanguage: Language = {
    language: 'Česky',
    code: LanguageCode.CS,
    type: 'CZ',
    icon: 'cz',
    locale: 'cs-CZ',
}

export const languages: Language[] = [
    {
        language: 'Česky',
        code: LanguageCode.CS,
        type: 'CZ',
        icon: 'cz',
        locale: 'cs-CZ',
    },
    {
        language: 'English',
        code: LanguageCode.EN,
        type: 'US',
        icon: 'us',
        locale: 'en-EN',
    },
]

export interface Language {
    language: string
    code: LanguageCode
    type?: string
    icon: string
    locale: string
}
