import { Injectable } from '@angular/core'
import {
    BehaviorSubject,
    EMPTY,
    first,
    Observable,
    of,
    pipe,
    share,
    Subject,
    switchMap,
    throwError,
    UnaryFunction,
} from 'rxjs'
import { UserService } from 'app/users/services/user.service'
import { AccessRight, AppState, User, UserProfile } from 'app/users/users.models'
import { catchError, filter, map } from 'rxjs/operators'
import { Router } from '@angular/router'
import * as R_ from 'ramda-extension'
import { Auth0SpaService } from './auth0-spa.service'
import { log } from 'app/core/utils'
import { MentionConfig } from 'angular-mentions'
import { Person } from 'app/core/core.models'
import { LanguageService } from 'app/core/services/language.service'
import { WindowService } from 'app/core/services/window.service'
import { Capacitor } from '@capacitor/core'
import { uniqueId } from 'app/core/core.functions'
import { server } from 'app/core/core.server'
//import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    public isAccessKeyEnabled = Capacitor.isNativePlatform()
    private device$ = this.windowService.device$
    public picture$ = new BehaviorSubject('')

    constructor(
        private userService: UserService,
        private authService: Auth0SpaService,
        private windowService: WindowService,
        private languageService: LanguageService,
        private router: Router,
    ) {
        this.user$.subscribe((user) => {
            if (user)
                this.picture$.next(`${server.apiUrl}/users/${user.id}.pic?time=${server.time}`)
        })
    }

    get deviceSecret() {
        return this.isAccessKeyEnabled ? localStorage.getItem('deviceSecret') : null
    }

    set deviceSecret(value: string) {
        localStorage.setItem('deviceSecret', value)
    }

    get isAuthenticated$() {
        return this.deviceSecret ? new BehaviorSubject(true) : this.authService.isAuthenticated$
    }

    get authorizationHeader$(): Observable<string> {
        return this.deviceSecret
            ? this.device$.pipe(switchMap((device) => of('Basic ' + btoa(`${device.uuid}:${this.deviceSecret}`))))
            : this.authService.getAccessToken$().pipe(switchMap((token) => of('Bearer ' + token)))
    }

    authenticate(url: string = null): Subject<User> {
        const login = (this.deviceSecret ? of(true) : this.authService.initAuthentication(url)).pipe(
            this.loginPipe,
            share(),
        ) as Subject<User>

        login.subscribe((user: User) => {
            // if (this.windowService.isNative) {
            //     FirebaseAnalytics.setUserId({
            //         userId: user.id
            //     }).then(() => {
            //         console.log('Firebase Analytics userId = ' + user.id)
            //     })
            // }
            localStorage.setItem('user', JSON.stringify(user))
            this.user$.next(user)
            this.languageService.changeLanguageByCode(user.profile.language)
            if (this.isAccessKeyEnabled && this.deviceSecret == null) {
                this.device$.subscribe((device) => {
                    let deviceSecret = uniqueId(32)
                    console.log('Setting device user access key', deviceSecret)
                    this.userService
                        .setUserAccessKey$({
                            name: device.uuid,
                            info: `${device.platform}:${device.name}`,
                            secret: deviceSecret,
                            rights: [AccessRight.Write],
                        })
                        .subscribe((accessKey) => {
                            this.deviceSecret = deviceSecret
                            console.log('Device user access key secret set', deviceSecret)
                            this.authStateRoute()
                        })
                })
            } else {
                this.authStateRoute()
            }
        })

        return login
    }

    private authStateRoute() {
        if (this.authService.state) {
            this.router.navigateByUrl(this.authService.state.url)
        }
    }

    private loginPipe: UnaryFunction<Observable<boolean>, Observable<User>> = pipe(
        log('LOGIN-SERVICE: init login: '),
        filter((it) => it),
        switchMap(() => this.userService.me$()),
        log('LOGIN-SERVICE: lookup user: '),
        switchMap((user: User) => (user == undefined ? this.signUp() : of(user))),
        log('LOGIN-SERVICE: logged in: '),
        catchError((err, caught) => {
            return throwError(() => new Error('LOGIN-SERVICE: login failed ' + err))
        }),
    )

    private readonly signUp: () => Observable<User> = () =>
        this.authService.getUserInfo$().pipe(
            log('LOGIN-SERVICE: unknown user - init sign up'),
            switchMap((info) => {
                if (info.email_verified) {
                    return this.userService.signUp$()
                } else {
                    this.router.navigate(['/', 'verifyEmail'])
                    return EMPTY
                }
            }),
        )

    private userJson = this.isAccessKeyEnabled ? localStorage.getItem('user') : null
    private user$ = new BehaviorSubject<User | null>(this.userJson ? JSON.parse(this.userJson) : null)

    /**
     * Emits when user log in and then every time user object is updated.
     */
    currentUser$ = this.user$.pipe(
        filter(R_.isNotNil),
        switchMap(() => this.userService.getCurrentUser$()),
    )

    me$ = this.user$.pipe(
        filter(R_.isNotNil),
        switchMap(() => this.userService.me$()),
    )

    /**
     * Emits only ONCE when user log in, then complete the observable
     */
    userLoggedIn$ = this.currentUser$.pipe(first())

    /**
     * Sync method to get user.
     */
    get currentUser(): User {
        const user = this.user$.value
        if (user == null) throw 'User not logged in.'
        return user
    }

    set currentUser(user: User) {
        this.user$.next(user)
        console.log('current user set', user)
    }

    get currentUserOrNull(): User {
        return this.user$.value
    }

    isLoggedIn$ = this.user$.pipe(map((it) => it != null))

    loggedIn$ = this.isLoggedIn$.pipe(filter((isLoggedIn) => isLoggedIn))

    mentionConfig$ = this.user$.pipe(
        filter(R_.isNotNil),
        switchMap((user: User) => this.userService.getUserFollowingPerson$(user.id)),
        map((persons: Person[]) => {
            return {
                mentions: [
                    {
                        items: persons,
                        triggerChar: '@',
                        labelKey: 'name',
                        mentionSelect: (item, triggerChar) => {
                            return `[${item.name}](${server.appUrl}/${item.ref})` //triggerChar + item + '!'
                        },
                    },
                    // {
                    //     items: [ "Red", "Yellow", "Green" ],
                    //     triggerChar: '#'
                    // }
                ],
            } as MentionConfig
        }),
    )

    get isLoggedIn(): boolean {
        return this.user$.value != null
    }

    userInfo$ = new BehaviorSubject<any | null>(null)

    signup() {
        this.authService.signup(this.stateSnapshot())
    }

    private stateSnapshot(url: string = ''): AppState {
        // remove any query string on root url
        return {
            url: url
                ? url
                : this.router.url.startsWith('?') || this.router.url.startsWith('/?')
                ? '/'
                : this.router.url,
        }
    }

    login(url: string = '') {
        this.authService.login(this.stateSnapshot(url))
    }

    logout() {
        localStorage.removeItem('user')
        localStorage.removeItem('deviceSecret')
        this.authService.logout()
    }
}
