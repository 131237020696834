<div fxLayout="row wrap" *ngIf="organizer">
    <!-- Card column -->
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{organizer.name}}</mat-card-title>
<!--                <mat-card-subtitle>-->
<!--                    ID: {{organizer.id}} • {{'isActive' | translate}}: {{(organizer.isActive ? 'yes' : 'no') | translate}}-->
<!--                </mat-card-subtitle>-->
            </mat-card-header>
            <mat-card-content>
                <p [innerHTML]="organizer.description | marked"></p>
                <div class="d-block clear mb-4">
                    <button
                        *ngIf="organizer.email"
                        mat-button
                        [routerLink]=""
                        (click)="windowService.open('mailto:' + organizer.email)"
                    >
                        <mat-icon>email</mat-icon>
                        {{organizer.email}}
                    </button>
                    <button
                        *ngIf="organizer.phoneNumber && organizer.phoneNumber != '+0'"
                        mat-button
                        [routerLink]=""
                        (click)="windowService.open('tel:' + organizer.phoneNumber)"
                    >
                        <mat-icon>phone</mat-icon>
                        {{organizer.phoneNumber}}
                    </button>
                    <button
                        *ngFor="let link of organizer.links"
                        mat-button
                        [routerLink]=""
                        (click)="windowService.open(link.ref)"
                    >
                        <mat-icon>open_in_browser</mat-icon>
                        {{link.type.toLowerCase() | translate | titlecase}}
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="h3 text-shadow text-center cursor-pointer">&nbsp;{{'upcoming_events' | translate}}</div>
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <div
            fxLayout="row wrap"
            fxFlexAlign="center"
            class="row activities-overview-list"
            *ngIf="(organizedActivities$ | async) as organizedActivities"
        >
            <div
                *ngFor="let organizedActivity of organizedActivities"
                fxFlex="100"
                fxFlex.gt-xs="50"
                fxFlex.gt-md="33"
                class="activities-overview-list__item"
            >
                <mat-card>
                    <app-organized-activity-card [organizedActivity]="organizedActivity"></app-organized-activity-card>
                </mat-card>
            </div>
            <div
                class="h4 text-shadow text-center cursor-pointer"
            >
                {{'total' | translate}} {{organizedActivities.length}}
            </div>
        </div>
    </div>
</div>
