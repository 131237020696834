<div fxLayout="row wrap">
    <!-- Card column -->
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <ng-container *ngIf="state">
                    <p class="alert alert-warning">{{'terms_disclaimer' | translate}}</p>
                    <mat-card-actions class="text-right">
                        <button mat-flat-button color="warn" (click)="acceptTerms()">
                            {{"acceptTerms" | translate }}
                        </button>
                    </mat-card-actions>
                </ng-container>
                <p [innerHTML]="text | marked: false"></p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
