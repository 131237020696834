<mat-chip-list class="mat-chip-list" multiple [formControl]="control">
    <mat-chip
        *ngFor="let option of options"
        class="mat-chip-list--mat-chip"
        [selectable]="true"
        [value]="option.value"
        (click)="toggleOption(option.value)"
    >
        {{option.label}}
    </mat-chip>
</mat-chip-list>
<mat-error class="mat-chip-list-error" *ngIf="control.invalid && control.touched"
    >{{errorMessage | translate}}</mat-error
>
