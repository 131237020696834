import { Directive } from '@angular/core'
import { FormArray, FormControl, FormGroup } from '@angular/forms'
import * as R from 'ramda'
import { isObject, omitTypeName } from 'app/core/utils'
import { WindowService } from 'app/core/services/window.service'
import { localizedTextValidator } from 'app/core/validators/localized-text-validator'
import { Language, LanguageCode } from '../constants/languages'
import { LanguageService } from '../services/language.service'
import { DateAdapter } from '@angular/material/core'

@Directive()
export abstract class AbstractFormComponent {
    private readonly localizedTextUUID = 'isLocalizeText@@_@'
    abstract form: FormGroup
    abstract onSubmitValidForm(values: any, appendData: any)
    readonly selectedLanguage$ = this.languageService.selectedLanguage$

    protected constructor(
        public readonly windowService: WindowService,
        private languageService: LanguageService,
        private _adapter: DateAdapter<any>,
    ) {
        this.languageService.selectedLanguage$.subscribe((language: Language) => {
            this._adapter.setLocale(language.locale)
        })
    }

    removeLocalizedTextAfterSubmit(values: any): any {
        const localizeKeys: string[] = R.pipe(
            R.keys,
            R.filter((key) => {
                const isLocalizedValue = values?.[key]?.[0]?.[this.localizedTextUUID] === true
                const abstractForm = values?.[key]
                const isFromFormArray = Array.isArray(abstractForm)
                if (isFromFormArray) {
                    abstractForm?.forEach((formGroupWithLocalizeValue) => {
                        delete formGroupWithLocalizeValue?.[this.localizedTextUUID]
                    })
                }
                const isFormGroup = isObject(abstractForm)
                if (isFormGroup) {
                    this.removeLocalizedTextAfterSubmit(abstractForm)
                }
                return isLocalizedValue
            }),
        )(values)

        const filterValuesWithoutSetLocalize = R.filter((values) => {
            return !!values?.text
        })

        return R.pipe(
            R.map((key) => {
                return {
                    [key]: filterValuesWithoutSetLocalize(values[key]),
                }
            }),
            R.mergeAll,
            R.mergeRight(values),
        )(localizeKeys)
    }

    createLocalizeFormGroup(languageCode: LanguageCode): FormGroup {
        return new FormGroup({
            language: new FormControl(languageCode),
            text: new FormControl(null),
            [this.localizedTextUUID]: new FormControl(true),
        })
    }

    createLocalizeFormArray(required = true): FormArray {
        return new FormArray(
            [this.createLocalizeFormGroup(LanguageCode.CS), this.createLocalizeFormGroup(LanguageCode.EN)],
            localizedTextValidator(this.languageService.selectedLanguage$, required),
        )
    }

    submitForm(appendData: any = undefined): void {
        if (this.form.valid) {
            const values = this.removeLocalizedTextAfterSubmit(this.form.getRawValue())
            this.onSubmitValidForm(values, appendData)
        } else {
            this.form.markAllAsTouched()
        }
    }

    filterIgnoreKeys = (ignoreKeys: string[], form) => (data) => {
        return !!form.get(data) && !ignoreKeys.includes(data)
    }

    prefillFormByKeys(values: any, currentLanguage: LanguageCode, ignoreKeys: string[] = [], customForm = null): void {
        const form = customForm ?? this.form
        R.pipe(
            R.keys,
            R.filter(this.filterIgnoreKeys(ignoreKeys, form)),
            R.forEach((key) => {
                const isLocalizeText = values?.[key]?.[0]?.__typename === 'LocalizedText'
                if (isLocalizeText) {
                    const result = omitTypeName(values[key])
                    form.get(key).patchValue(result)
                } else {
                    form.get(key).setValue(values[key])
                }
            }),
        )(values)
    }
}
