import { Pipe, PipeTransform } from '@angular/core'
import { LocalizedTexts } from 'app/core/core.models'
import { getLocalizedText } from 'app/core/utils/get-localized-text'
import { LanguageService } from '../../services/language.service'
import { LanguageCode } from '../../constants/languages'

@Pipe({
    name: 'localize',
    pure: false,
})
export class LocalizePipe implements PipeTransform {
    private result: string = ''
    private latestTexts: LocalizedTexts
    private latestCode: LanguageCode
    private latestFallback: boolean

    constructor(private languageService: LanguageService) {}

    transform(localizedTexts: LocalizedTexts = [], languageCode: LanguageCode = null, withFallback = true): String {
        if (languageCode === null) {
            languageCode = this.languageService.selectedLanguageCode
        }
        if (
            this.latestTexts === localizedTexts &&
            this.latestCode === languageCode &&
            this.latestFallback === withFallback
        ) {
            return this.result
        }

        this.latestTexts = localizedTexts
        this.latestCode = languageCode
        this.latestFallback = withFallback
        this.result =
            localizedTexts && localizedTexts.length ? getLocalizedText(localizedTexts, languageCode, withFallback) : null

        return this.result
    }
}
