<div class="track-profile">
    <canvas
        baseChart
        [height]="height"
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="legend"
        [chartType]="'line'"
        (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)"
    >
    </canvas>
</div>
