import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { IFooterItems } from 'app/layouts/full/footer/footer.model'
import { Router } from '@angular/router'

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
    constructor(private router: Router) {}

    @Input()
    footerItems: IFooterItems

    @Input()
    copyright: string

    devtoolsClicks = 0

    openDevTools() {
        ++this.devtoolsClicks

        if (this.devtoolsClicks > 2) {
            this.router.navigateByUrl('/developers')
        }
    }
}
