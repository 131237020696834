import { Component, OnInit } from '@angular/core'
import { Person } from 'app/core/core.models'
import { UserService } from 'app/users/services/user.service'
import { TranslateService } from '@ngx-translate/core'
import * as R_ from 'ramda-extension'
import { filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { LoginService } from 'app/users/services/login.service'
import { FormControl } from '@angular/forms'
import { debounceTime, Observable, startWith, switchMap } from 'rxjs'
import { Router } from '@angular/router'

@UntilDestroy()
@Component({
    selector: 'app-people',
    templateUrl: './people.component.html',
})
export class PeopleComponent implements OnInit {

    personControl = new FormControl('')
    filteredPersons$: Observable<Person[]>
    followerPersons: Person[] = []
    followingPersons: Person[] = []

    constructor(
        private router: Router,
        private userService: UserService,
        private loginService: LoginService,
        private translateService: TranslateService,
    ) {
        this.personControl.valueChanges.subscribe((person) => {
            if (typeof person == 'object')
                this.router.navigateByUrl(person.id)
        })
    }

    ngOnInit(): void {
        this.loginService.currentUser$
            .subscribe((currentUser) => {
                this.userService
                    .getUserFollowerPerson$(currentUser.id)
                    .pipe(filter(R_.isNotNil), untilDestroyed(this))
                    .subscribe((persons) => {
                        this.followerPersons = persons
                    })
                this.userService
                    .getUserFollowingPerson$(currentUser.id)
                    .pipe(filter(R_.isNotNil), untilDestroyed(this))
                    .subscribe((persons) => {
                        this.followingPersons = persons
                    })
            })
        this.filteredPersons$ = this.personControl.valueChanges.pipe(
            filter((value) => typeof value == 'string'),
            startWith(''),
            debounceTime(500),
            switchMap((value) => {
                return this.userService.getPersons$(value)
            }),
        )
    }

    stringify(person: Person): string {
        return person && person.name ? (person.name + (person.nickname ? ' (' + person.nickname + ')' : '')) : ''
    }

    // follow(person: Person) {
    //     this.userService.followUser$(person.id).subscribe((status) => {
    //         console.log(`follow user ${person.id} status`, status)
    //         this.followStatus = status
    //     })
    // }

    unfollow(person: Person) {
        if (confirm(this.translateService.instant('confirm_unfollow') + ` ${person.givenName} ${person.surname}?`)) {
            this.userService.unfollowUser$(person.id).subscribe((result) => {
                console.log(`unfollow user ${person.id} result`, result)
                if (result) {
                    //if (person.id == this.person.id) this.followStatus = UserFollowStatus.NotFollowing
                    this.followingPersons = this.followingPersons.filter((p) => p.id != person.id)
                }
            })
        }
    }

    deleteFollower(person: Person) {
        if (confirm(this.translateService.instant('confirm_delete') + ` ${person.givenName} ${person.surname}?`)) {
            this.userService.deleteUserFollow$(person.id).subscribe((result) => {
                console.log(`delete follower user ${person.id} result`, result)
                if (result) {
                    this.followerPersons = this.followerPersons.filter((p) => p.id != person.id)
                }
            })
        }
    }

}
