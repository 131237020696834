import { OrganizedActivity } from 'app/events/events.models';

export interface MapTrack {
    strokeColor: string;
    points: google.maps.LatLngLiteral[];
    name: string;
}

export enum MarkerType {
    default = 'default',
    start = 'start',
    finish = 'finish',
}

export interface MapMarker {
    icon: string;
    type: MarkerType;
    point: google.maps.LatLngLiteral;
}

export interface MapActivity {
    organizedActivity?: OrganizedActivity;
    markers: MapMarker[];
    tracks?: MapTrack[];
}
