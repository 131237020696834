// transform to one const

import { LanguageCode } from './core/constants/languages'

export const Globals = {
    valueChangeDebounce: 750,
    durationSnackBarMessage: 4000,
    durationSnackBarSuccessMessage: 2000,
    delayOfLoadingAnimation: 1000,
    defaultFallbackLanguage: LanguageCode.CS,
    ignoreLoadingOperationNames: [
        'me',
        'signUp',
        'syncProfile',
        'removeOrganizedEventMedia',
        'importUserActivities',
        'importUserFriends',
        'createMessage',
        'messages',
        'createPost',
        'createLike',
        'deleteLike',
        'toggleLike',
        'touchUser',
        'purgeUser',
        'followUser',
        'unfollowUser',
        'updateUserActivity',
        'followOrganizedActivity',
        'unfollowOrganizedActivity',
        'setUserNotificationToken',
        'setUserAccessKey',
        'removeUserAccessKey',
        'createRegistration',
        'receivePost',
        'removePost',
        'payRegistration',
        'checkPayment'
    ],
}

export const TermsSummary = {
    'cs': `
**Registrace případně další služby a zboží jsou prodávány jménem Pořadatele** za ceny uvedené Pořadatelem v ceníku. Za uskutečnění pořádané akce resp. dodání objednaných služeb či zboží je plně zodpovědný Pořadatel.
Společnost **Racebook s.r.o. není prodejcem**, ale pouze zprostředkovatelem a provozovatelem aplikace Actigo. Čtete pozorně [Obchodní podmínky](https://actigo.app/terms).
Racebook s.r.o. si za své služby **účtuje provizi ve výši 0 %**.
Finanční prostředky budou poukázány na výše uvedený bankovní účet Pořadatele **do 14 dní od uskutečnění akce**.

Máte zájem o individuální obchodní podmínky? Kontaktujte [obchod@actigo.app](mailto:obchod@actigo.app).
    `
}

export const Terms = {
    'cs': `
# Obchodní podmínky - prodej registrací

## ČÁST 1 – ÚVOD A ZÁKLADNÍ POJMY

### **1.1. Kdo se řídí těmito obchodními podmínkami?**

**1.1.1. Racebook s.r.o.**, se sídlem Rybná 716/24, 110 00 Praha 1, IČO: 14368587, vedená v OR u Městského soudu v Praze pod spisovou značkou C 364627 (dále jen jako “**Racebook**”), jako zprostředkovatel prodeje registrací a poukazů.

**1.1.2.** Každý, kdo s Racebookem uzavře smlouvu o zprostředkování prodeje registrací nebo poukazů třetím osobám, a to na akce/aktivity jím pořádané (dále jen jako “**Organizátor**”). Každý Organizátor je identifikován v popisu akce/aktivity včetně kontaktů.

**1.1.3.** Každý, kdo hodlá využít možnosti nákupu registrací nebo poukazů prostřednictvím Racebooku na akci/aktivitu pořádanou Organizátorem, za tím účelem mu je zřízen účet a  současně vyjádří svůj souhlas s těmito obchodními podmínkami (dále jen jako “**Kupující**”).

### **1.2. Co upravují tyto obchodní podmínky?**

**1.2.1.** Tyto obchodní podmínky upravují práva a povinnosti Racebooku, Organizátora a Kupujícího vznikající v souvislosti se zprostředkováním prodeje registrací a poukazů ze strany Racebooku Kupujícímu, a to na sportovní nebo jiné akce/aktivity pořádané Organizátorem (dále jen jako “**Akce**”).

**1.2.2.** Racebook nabízí registrace a poukazy na Akce Organizátorů na svých webových stránkách **actigo.app** a zajišťuje jejich výrobu, prodej a doručení Kupujícím, a to jménem Organizátora (Racebook je pouze zprostředkovatelem prodeje) a na jeho účet na základě pověření uděleného v souladu se smlouvou uzavřenou mezi Racebookem a Organizátorem.

**1.2.3.** Kupující bere nákupem registrace nebo poukazu na vědomí, že pořadatelem Akce je Organizátor, nikoli Racebook. Za konání Akce či její případné změny, včetně změny podmínek využití poukazu, je odpovědný Organizátor, stejně tak je odpovědný za oznámení o případném nekonání Akce nebo o změnách Akce Kupujícímu. Organizátor si vyhrazuje právo na změny týkající se Akce, jejího programu, termínu, místa konání nebo podmínek Akce. Toto právo Organizátora bere Kupující též na vědomí. Racebook žádným způsobem neodpovídá za aktuálnost údajů o Akci pořádané Organizátorem na svých webových stránkách v případě, že Organizátor případné nekonání Akce nebo její změny Racebooku neoznámí.

**1.2.4.** Racebook při zprostředkování prodeje registrací a poukazů neposkytuje Organizátorovi platební služby.

**1.2.5.** Úhrady Kupujících za zakoupené registrace a poukazy na Akci Organizátora jsou připisovány na bankovní účet Racebooku. Vypořádání přijatých plnění mezi Racebookem a Organizátorem je provedeno následně v souladu s dohodou stran (smlouvou o zprostředkování registrací) a těmito obchodními podmínkami.

**1.2.6.** Tyto obchodní podmínky tvoří nedílnou součást smlouvy uzavřené mezi Organizátorem a Kupujícím jakožto osobou nakupující registrace a poukazy na Akci prostřednictvím Racebooku resp. jím provozované aplikace Actigo.app. Tyto obchodní podmínky tvoří též nedílnou součást smlouvy o zprostředkování prodeje registrací a poukazů uzavřené mezi Organizátorem a Racebookem.

### **1.3. Základní pojmy**

**1.3.1. “Registrací”** se rozumí elektronická nebo tištěná forma dokladu vydaného Racebookem na Akci Organizátora, který opravňuje jeho držitele ke (zpravidla jednorázovému) vstupu na konkrétní Akci pořádanou ve stanovený čas a na stanoveném místě.

**1.3.2.**  **“Poukazem”** se rozumí elektronická nebo tištěná forma dokladu vydaného Racebookem na Akci Organizátora, který opravňuje jeho držitele k využití vybraných služeb nabízených Organizátorem v určitém časově ohraničeném období v množství nebo délce zvolené při nákupu Poukazu a v místě určeném Organizátorem. Konkrétní podmínky využití Poukazu jsou vždy součástí popisu Akce a jsou uvedeny v zakoupeném Poukazu.

**1.3.3. “Účtem uživatele”** se rozumí uživatelský účet vytvořený ze strany Racebook v aplikaci Actigo.app na základě požadavku Kupujícího, do něhož má na základě zvoleného uživatelského jména a hesla (popřípadě přihlášením prostřednictvím Google účtu, Facebook účtu, apod.) přístup pouze Kupující a v němž jsou Kupujícímu k dispozici zejména informace vztahující se k jím provedeným nákupům Registrací a Poukazů. Dostupné funkce Účtu uživatele se mohou v čase měnit a současně se mohou lišit v závislosti na lokaci Kupujícího.

**1.3.4. “Účtem Organizátora”** se rozumí vyšší stupeň oprávnění v rámci Účtu uživatele umožňující přístup do sekce pro Organizátora, a to na základě registrace uživatele jako Organizátora. Po registraci je Organizátorovi aktivováno oprávnění k tzv. Dashboardu, v němž jsou Organizátorovi k dispozici zejména informace vztahující se k nákupu Registrací a Poukazů Kupujícími na Akce tohoto Organizátora. Současně slouží jako nástroj pro vytváření Akcí a nastavení prodeje Registrací/Poukazů na tyto Akce samotným Organizátorem. Tyto funkce podléhají schválení a další identifikaci Organizátora. Rozsah a dostupnost funkcí spojených s Účtem Organizátora se mohou v čase měnit a současně se mohou lišit v závislosti na typu Akce pořádané Organizátorem.

**1.3.5.** **“Obchodními podmínkami”** se rozumí tyto obchodní podmínky, které upravují vzájemná práva a povinnosti Racebooku, Kupujícího a Organizátora a které jsou v aktuálním znění zveřejněny na stránkách actigo.app nebo v mobilní aplikaci Actigo.

## ČÁST 2 - PRÁVA A POVINNOSTI KUPUJÍCÍHO

### **2.1. Jak probíhá nákup Registrace a Poukazu?**

**2.1.1.** Kupující vyhledá na příslušných webových stránkách actigo.app nebo v mobilní aplikaci Actigo Akci, na kterou chce zakoupit Registraci(e) nebo Poukaz(y), přesune se do části s detailním popisem Akce, konkrétními podmínkami (termín konání, místo konání a další) a ceníkem. Objednávku provede kliknutím na tlačítko “Registrovat za XX Kč” (XX je nahrazeno aktuálně platnou cenou) a tímto krokem potvrdí svůj zájem o nákup předmětné Registrace za uvedenou cenu.

**2.1.2.** Potvrzením tlačítka “Registrovat za XX Kč” Kupující potvrzuje, že se seznámil se všemi zveřejněnými podmínkami Akce a těmito Obchodními podmínkami, stejně jako se zásadami ochrany osobních údajů, a souhlasí s nimi.

**2.1.3.** V případě, že jsou vyplněny všechny nezbytné identifikační údaje je Kupující na další stránce vyzván k výběru platební metody a provedení platby. Ragistrace nebo Poukaz se považují za zaplacený, pokud je platba následně v pořádku připsána na bankovní účet Racebooku, Organizátora nebo poskytovatele platební brány.

**2.1.4.** Kupujícímu je po provedené úhradě Registrace nebo Poukazu odesláno (e-mailovou zprávou, případně i formou SMS) potvrzení o nákupu a jedinečný identifikátor zakoupené Registrace nebo Poukazu. Identifikátor Registrace či Poukazu se stává aktivním provedenou úhradou dle čl. 2.1.3. těchto Obchodních podmínek a následně slouží jako oprávnění ke vstupu na Akci nebo k využití Poukazu.

**2.1.5.** Kupní smlouva zprostředkovaná Racebookem je mezi Organizátorem a Kupujícím uzavřena okamžikem zaplacení ceny Registrace nebo Poukazu, za předpokladu, že je Kupujícímu odesláno potvrzení o nákupu Registrace či Poukazu ze strany Racebook dle čl. 2.1.4. těchto Obchodních podmínek (e-mailovou zprávou, případně SMS) na jím uvedený kontakt. Potvrzení o nákupu je rovněž přístupné na stránklách actigo.app nebo v aplikaci Actigo pod Účtem uživatele.

**2.1.6.** Nákupem Registrace či Poukazu, tj. uzavřením smlouvy, se Kupující zavazuje dodržovat veškerá pravidla a pokyny Organizátora vztahující se k příslušné Akci. Organizátor má právo zkontrolovat splnění podmínek na straně Kupujícího, např. věk či to, zda je Kupující držitel průkazu ZTP/ISIC apod. Organizátor může před konáním Akce aktualizovat, upřesnit či změnit podmínky konání Akce, případně zrušit Akci. O tom je Kupující informován prostřednictvím zaslané e-mailové zprávy. Racebook (jakožto zprostředkovatel prodeje Registrace a Poukazů) žádným způsobem neodpovídá za majetkovou nebo nemajetkovou újmu, která by případně vznikla Kupujícímu v souvislosti s účastí nebo neúčastí na Akci nebo i v souvislosti s nekonáním Akce.

### **2.2. Účet uživatele**

**2.2.1.** Každý Kupující má možnost vytvořit si na internetových stránkách actigo.app nebo v mobilní aplikaci Actigo svůj uživatelský profil, tj. Účet uživatele. Podmínkou pro zakoupení Registrací nebo Poukazů na Akce je vázáno na vytvoření uvedeného uživatelského účtu tak, jak je popsáno v čl. 2.1. těchto Obchodních podmínek.

**2.2.2.** Kupující má po přihlášení možnost zejména nakupovat Registrace nebo Poukazy na Akce, procházet historii zakoupených Registrací a Poukazů, možnost uložit údaje ke své platební kartě a případně další možnosti.

**2.2.3.** Racebook je oprávněn smazat Účet uživatele v případě, že použité identifikační údaje zjevně nepatří osobě, která účet vytvořila, profilové obrázky a fotografie nebo obsah Účtu uživatele odporuje právním předpisům, obsahuje chráněná či registrovaná díla nebo označení nebo je jeho obsah zjevně nevhodný. Pokud je to možné a vhodné, upozorní Racebook e-mailovou zprávou Kupujícího na možnost smazání Účtu uživatele, pokud nedojde k odstranění nebo úpravě zakázaného nebo nevhodného obsahu.

**2.2.4.** Kupující může kdykoliv měnit nastavení svého Účtu nebo zadané údaje, stejně tak může svůj Účet kdykoliv zrušit.

**2.2.5.** Údaje poskytnuté Kupujícím zpracovává Racebook v souladu s čl. 4.1. těchto Obchodních podmínek.

### **2.3. Odstoupení od smlouvy a reklamace**

#### **Odstoupení od smlouvy**

**2.3.1.** Kupující, který je spotřebitelem, je oprávněn odstoupit od smlouvy o koupi uzavřené na dálku (prostřednictvím internetu) bez udání důvodu za podmínek dle ust. § 1829 a násl. zák. č. 89/20012 Sb., občanský zákoník, tj. ve lhůtě 14 dnů ode dne převzetí předmětu koupě, to za předpokladu, že odstoupení od smlouvy není v daném případě povahou zakoupené Registrace nebo Poukazu vyloučeno a dále za předpokladu, že zakoupené Registrace či Poukazy nebyly využity. V souladu s předchozí větou je odstoupení od smlouvy platné, pokud je Kupujícím oznámeno elektronicky e-mailem na adresu [podpora@actigo.app](mailto:podpora@actigo.app) nejpozději poslední den lhůty.

**2.3.2.** V souladu s výše uvedeným (čl. 2.3.1.) není Kupující oprávněn odstoupit od smlouvy o koupi Registrace nebo Poukazu, pokud se jedná o Registraci nebo Poukaz na služby, které jsou považovány za využití volného času v určeném termínu (ust. § 1837 písm. j) zák. č. 89/2012 Sb., občanský zákoník), nebo v případě, že se jedná o Poukaz vytvořený individuálně podle přání Kupujícího (ust. § 1837 písm. d) zák. č. 89/2012 Sb., občanský zákoník), nebo se jedná o jiný případ uvedený v ust. § 1837 zák. č. 89/2012 Sb. (občanský zákoník).

#### **Reklamace**

**2.3.3.** Zakoupenou Registraci nebo Poukaz není možné vrátit ani vyměnit a Kupující nemá právo na vrácení zaplacené ceny, pokud se nemůže vybrané Akce zúčastnit, ať už z jakéhokoli důvodu. Toto ustanovení platí, pokud není Organizátorem uvedeno u konkrétní Akce jinak.

**2.3.4.** Racebook nenese žádnou odpovědnost za nedoručení Registrace nebo Poukazu (jedinečného identifikátoru Registrace/Poukazu) na Akci do e-mailové schránky určené Kupujícím z důvodů spočívajících na straně Kupujícího jako je přeplněná e-mailová schránka, spam filtr, nesprávně zadaná e-mailová adresa apod. V případě, že Kupující bez svého zavinění neobdržel uhrazenou Registraci nebo Poukaz (jedinečný identifikátor Registrace/Poukazu) do e-mailové schránky, případně ji nemá k dispozici ani ve svém Účtu uživatele, do 24 hodin od nákupu Registrace nebo Poukazu dle čl. 2.1.4. těchto Obchodních podmínek, kontaktuje Kupující Racebook a postupuje podle pokynů, které se budou lišit v závislosti na konkrétní situaci. Pokud tímto způsobem nedojde k úspěšnému doručení Registrace či Poukazu Kupujícímu, případně v mezidobí již Akce proběhla, vrátí Racebook Kupujícímu uhrazenou cenu Registrace/Poukazu.

**2.3.5.** V případě, že ze strany Organizátora dojde ke změně termínu Akce nebo k úplnému zrušení Akce, má Kupující vůči Organizátorovi právo na vrácení zaplacené ceny Registrace/Poukazu. Toto právo uplatňuje Kupující u Organizátora Akce, případně prostřednictvím Racebooku, a to bez zbytečného odkladu, nejpozději však ve lhůtě 5 dnů ode dne původního termínu konání Akce za předpokladu, že Registrace nebo Poukaz dosud nebyly Kupujícím využity. Nárok Kupujícího na vrácení zaplacené ceny Registrace nebo Poukazu nebo vystavení poukazu ve výši původní ceny dle předchozí věty vypořádá Organizátor, případně Racebook, Racebook však vždy jen do výše finančních prostředků, které má k dispozici od Organizátora, resp. do výše finančních prostředků z prodeje Registrací a Poukazů od Kupujících, které Racebook ještě neproplatil Organizátorovi.

**2.3.6.** Dotazy a nejasnosti týkající se koupě nebo reklamace zakoupených Registrací či Poukazů může Kupující zaslat Racebook na e-mailovou adresu [podpora@actigo.app](mailto:podpora@actigo.app).

## ČÁST 3 - PRÁVA A POVINNOSTI POŘADATELE

### **3.1. Uzavření smlouvy**

**3.1.1.** Tato část Obchodních podmínek upravuje smluvní vztah Organizátora jako pořadatele Akce a Racebook jako zprostředkovatele prodeje Registrací či Poukazů na Akce Organizátora a platí, pokud nebylo mezi Racebookem a Organizátorem výslovně dohodnuto něco jiného. Předmětem dohody mezi Racebookem a Organizátorem je zejména závazek Racebooku zprostředkovat prodej Registrací nebo Poukazů na Akci Organizátora, za což se Organizátor zavazuje Racebooku uhradit smluvenou odměnu. Strany se mohou dohodnout na poskytování dalších služeb souvisejících s prodejem Registrací/Poukazů a podmínkách poskytování takových služeb.

**3.1.2.** Racebook a Organizátor si vždy individuálně dohodnou možnosti naplnění konkrétních požadavků Organizátora týkajících se zprostředkování prodeje Registrací a Poukazů na Akci a propagaci Akce Organizátora, taktéž si dohodnou předmět a rozsah spolupráce - veškeré podmínky Akce jako je termín a místo konání apod., objem Registrací či Poukazů nabízených k prodeji přes Racebook respektive stránky actigo.app a mobilní aplikaci Actigo, termín zahájení a ukončení online prodeje, jednotlivé druhy a ceny Registrací a Poukazů, podmínky zveřejnění Akce na internetových provozovaných stránkách Racebookem k tomu určených a další podmínky. Tato dohoda může být učiněna formou ústní či písemnou anebo vzájemně potvrzenými e-mailovými zprávami, společně s těmito Obchodními podmínkami pak bude taková dohoda představovat smlouvu uzavřenou mezi stranami. Pokud se Racebook s Organizátorem nedohodne ohledně propagace Akce jinak, platí, že Racebook může Akci Organizátora samostatně propagovat a současně může též propagovat svou participaci na této Akci.

**3.1.3.** Racebook je oprávněn odmítnout zprostředkování prodeje Registrací a Poukazů, pokud se týkají Akce, jejíž obsah se neslučuje s obchodními záměry nebo s obecnými postoji a zájmy společnosti. Ze stejného důvodu může být zprostředkování již probíhajícího prodeje Registrací a Poukazů na Akci ukončeno za předpokladu, že neslučitelnost zájmů nebylo objektivně možné zjistit z informací předaných Organizátorem při uzavření smlouvy.

### **3.2. Podmínky zprostředkování prodeje Registrací a Poukazů**

**3.2.1.** Pokud se Racebook s Organizátorem nedohodnou jinak, platí, že se Organizátor zavazuje zajistit:

- aby jím provozovaná internetová stránka, která obsahuje propagaci Akce, obsahovala logo “Actigo” alespoň na stránce odkazující na prodej Registrací/Poukazů na Akci/Akce,
- aby jím provozovaná internetová stránka v rámci nákupu Registrace či Poukazu na Akci, jejíž prodej zprostředkovává Racebook, odkazovala na internetovou stránku actigo.app a umožňovala tak Kupujícímu přímý přístup do prodejního systému Actigo,
- aby v případě, že využívá též jiné prostředky k propagaci své Akce než jen internet, bylo na takových prostředcích vždy uvedeno, že Registrace a Poukazy na Akce je možné zakoupit prostřednictvím actigo.app,
- aby bylo logo (nebo název) “Actigo” uvedeno na všech sociálních sítích a jiných internetových stránkách provozovaných Organizátorem za účelem propagace Akce s přímým odkazem na internetovou stránku actigo.app, který umožní Kupujícímu přímý přístup k nákupu Registrací a Poukazů na Akci/Akce Organizátora,
- pro Racebook alespoň 4 volné registrace na Akci zdarma za účelem propagace.

**3.2.2.** Pokud se Racebook s Organizátorem nedohodnou jinak, platí, že se Organizátor dále zavazuje zajistit, aby podmínky prodeje Registrací a Poukazů prodávaných na Akci jinými prodejci vstupenek, kteří mají za tímto účelem uzavřený smluvní vztah s Organizátorem, byly srovnatelné jako podmínky prodeje Registrací a Poukazů prostřednictvím Racebooku a ceny Registrací a Poukazů nebyly nižší, než ceny Registrací a Poukazů na Akci prodávaných prostřednictvím Racebooku. Za nedodržení uvedeného závazku se Organizátor zavazuje uhradit Racebooku smluvní pokutu ve výši 100 000,- Kč, a to ve lhůtě stanovené v písemné výzvě Racebookem zaslané Organizátorovi na jím uvedenou kontaktní adresu. Zaplacením smluvní pokuty podle tohoto ustanovení nezaniká nárok Racebooku na náhradu případně vzniklé škody, která přesahuje výši smluvní pokuty.

**3.2.3.** Pokud si smluvní strany nedohodnou jinak, je Racebook oprávněn umístit na registraci na Akci Organizátora propagační sdělení/reklamu dle svého obchodního uvážení a potřeb.

**3.2.4.** Pokud si strany nedohodnou jinak, platí, že prodej Registrací a Poukazů je standardně ukončen

- vyprodáním všech Registrací a Poukazů určených k prodeji na Akci prostřednictvím Racebooku, nebo
- v termínu stanoveném předem Organizátorem, nebo
- nejpozději před začátkem Akce v čase stanoveném dohodou mezi Racebookem a Organizátorem.

Racebook se zavazuje bez zbytečného odkladu po ukončení online prodeje dodat Organizátorovi informace o prodaných Registracích a Poukazech na Akci, tj. seznam prodaných Registrací a Poukazů včetně uvedení jedinečných identifikátorů Registrací či Poukazů a identifikace Kupujících (v rozsahu nezbytně nutném pro potřeby Organizátora tak, aby bylo Kupujícímu umožněno uplatnit Registraci či Poukaz i v případě např. technického problému s jedinečným identifikátorem vstupenky).

**3.2.5.** Racebook předává Organizátorovi informace o stavu prodaných Registrací a Poukazů na Akci (Akce) Organizátora

- prostřednictvím Účtu Organizátora a přístupu Organizátora do Dashboardu, v němž jsou informace týkající se prodeje Registrací a Poukazů zobrazovány v reálném čase
- na základě dohody s Organizátorem i jiným způsobem (tj. Organizátor není povinen se registrovat a získat oprávnění k Účtu Organizátora); termíny a forma předání informací Organizátorovi závisí na vzájemné dohodě stran (např. ve formě sdílené tabulky nebo zasíláním aktualizované tabulky emailovou zprávou).

### **3.3. Odměna za zprostředkování prodeje**

**3.3.1.** Pokud není mezi stranami smluveno jinak, zavazuje se Organizátor uhradit Racebooku za zprostředkování prodeje Registrací a Poukazů a propagaci Akce na intenetových stránkách actigo.app resp. mobilní aplikací Actigo odměnu ve výši 0 % navýšenou o příslušnou sazbu DPH z ceny každé prodané Registrace a Poukazu (dále jen jako “**Odměna**”). Odměna Racebooku je splatná úplným zaplacením ceny Registrace nebo Poukazu ze strany Kupujícího. Organizátor tímto výslovně souhlasí s tím, aby si Racebook odečetl a ponechal Odměnu z částky zaplacené Kupujícím za Registraci či Poukaz a přijaté ve prospěch bankovního účtu Racebooku. Vyúčtování Odměny formou řádného daňového dokladu předloží Racebook Organizátorovi bez zbytečného odkladu poté, co bude mezi stranami provedeno vypořádání finančních nároků (po uskutečnění Akce i v případě její změny, nebo po jejím zrušení).

**3.3.2.** Racebook a Organizátor se uzavřením této smlouvy dohodli na tom, že částka odpovídající platbám Kupujících za Registrace a Poukazy přijatá Racebookem, po odečtení Odměny, bude zaslána Organizátorovi do 5 pracovních dnů po uskutečnění Akce nebo po ukončení prodeje Registrací nebo Poukazů na Akci, podle toho, co nastane později. Částka bude Organizátorovi zaslána na bankovní účet, který Organizátor pro tento účel Racebook včas označí a případně určí i variabilní symbol, kterým má být platba označena. V případě, že je Organizátor plátcem DPH a uvede bankovní účet, který není registrován pro účely evidence plátců DPH, je Racebook oprávněn odepřít výplatu finančních prostředků dle předchozí věty až do doby, než Organizátor potvrdí Racebooku číslo bankovního účtu, který je registrovaný pro účely plateb DPH a na tento účet Racebook provede výplatu finančních prostředků určených Organizátorovi. V případě, že nastanou události tzv. vyšší moci, je Racebook oprávněno prodloužit lhůtu uvedenou v první větě tohoto ustanovení až na 30 pracovních dnů. Dohodou obou smluvních stran je možné se od tohoto ustanovení odchýlit.

**3.3.3.** Jestliže se na tom smluvní strany dohodnou, vyplatí Racebook ještě před uskutečněním Akce Organizátorovi z plnění dle ust. 3.3.2. těchto Obchodních podmínek zálohu na plnění (dále jen jako “**Záloha**”) ve výši dle dohody stran. Vyplacení Zálohy Organizátorovi může být ze strany Racebooku podmíněno poskytnutím zajištění. Pokud se Akce, na kterou byla vyplacena Organizátorovi Záloha, neuskuteční nebo dojde ke změně Akce, je Organizátor povinen bez prodlení, maximálně do 3 dnů ode dne změny nebo zrušení Akce, vrátit Racebook celou poskytnutou Zálohu na bankovní účet, ze kterého byla poskytnuta (není-li dohodnuto jinak). Pokud tak Organizátor včas a řádně neučiní, je povinen uhradit Racebooku smluvní pokutu ve výši 0,1 % z dlužné částky Zálohy za každý den prodlení.

**3.3.4.** V případě neuskutečnění Akce (ať už z jakéhokoli důvodu) nebo v případě změny Akce (např. změny termínu Akce) se strany zavazují nejprve vypořádat případně uplatněné nároky Kupujících a teprve v závislosti na jejich vypořádání, nejpozději do 2 měsíců ode dne, kdy se měla Akce původně uskutečnit, bude mezi smluvními stranami učiněno vypořádání plateb za Registrace a Poukazy a Odměny Racebook. Obě smluvní strany strany jsou srozuměny s tím, že důvod tohoto opatření spočívá zejména v právu Organizátora kdykoli změnit podmínky konání Akce či jeho právu zrušit Akci, s čímž jsou následně spojeny nároky Kupujících na vrácení zaplacené ceny Registrace a Poukazu či případné další nároky Kupujících, které mohou být uplatňovány případně i prostřednictvím Racebook.

**3.3.5.** Pokud budou oprávněné nároky Kupujících na vrácení zaplacené ceny Registrací či Poukazů na konkrétní Akci Organizátora dle předcházejícího ustanovení těchto Obchodních podmínek (ust. 3.3.4.) uplatněny Kupujícími vůči Racebooku, je Racebook oprávněn, nikoli však povinnen, vyřídit danou reklamaci Kupujícího sám s tím, že vyřízení konkrétní reklamace Kupujícího oznámí Racebook bez prodlení Organizátorovi. K vyřízení takové reklamace je Racebook oprávněn použít veškeré platby Kupujících přijaté na danou konkrétní Akci Organizátora, po odečtení Odměny, případně, pokud by tyto platby nepostačovaly k vyřízení reklamace, pak je Racebook oprávněn použít i platby přijaté v souvislosti s jinou Akcí téhož Organizátora. Za vyřízení reklamace se ve smyslu tohoto ustanovení považuje vrácení ceny Registrace či Poukazu Kupujícím nebo vystavení poukazu na nákup Registrací nebo Poukazů na jinou událost, na kterou Racebook zprostředkovává prodej Registrací/Poukazů (bez ohledu na to, kdo je pořadatelem události) v odpovídající výši. V takovém případě není Racebook povinnen Organizátorovi platby Kupujících dle ust. 3.3.2. těchto Obchodních podmínek až do výše vrácené ceny Registrace nebo Poukazu (resp. až do výše částky vystavených poukazů na nákup Registrací/Poukazů) zasílat.

**3.3.6**. V případě, že Kupující reklamuje platební transakci - nákup Registrace či Poukazu  na Akci Organizátora provedenou prostřednictvím Racebooku - u své banky, která shledá reklamaci oprávněnou (např. z důvodu odcizení platební karty či jejího zneužití) a platební transakci zruší, v důsledku čehož z bankovního účtu Racebooku odečte částku odpovídající ceně Registrace/Poukazu na Akci Organizátora, je Racebook oprávněn žádat po Organizátorovi náhradu takto odečtené částky včetně případných nákladů spojených se zrušením platební transakce. Pro tyto účely může Racebook použít peněžní prostředky zaplacené Kupujícími za Registrace či Poukazy na Akci/Akce Organizátora, pokud dosud nebyly zaslány Organizátorovi. V opačném případě nebo pokud tyto peněžní prostředky nebudou dostatečné, je Organizátor povinen předmětnou částku uhradit ve prospěch bankovního účtu Racebooku, a to bez zbytečného odkladu od výzvy Racebooku k její úhradě.

**3.3.7.** Pokud se Akce pořádaná Organizátorem neuskuteční, nebo dojde ke změně Akce, není tím nikterak dotčen nárok Racebooku na smluvenou Odměnu za zprostředkování prodeje ve výši určené dle skutečného počtu Registrací a Poukazů prodaných prostřednictvím Racebooku, vyjma situace, kdy nastanou události, které nebylo možné ovlivnit. V případě, že ke zrušení Akce nebo změně jejího termínu dojde v důsledku událostí, které nemohl Organizátor ovlivnit (tzv. vyšší moc), a v důsledku těchto událostí vznikne Kupujícím nárok na vrácení ceny Registrace nebo Poukazu, není Organizátor povinen ve vztahu k vráceným vstupenkám hradit Racebooku Odměnu za zprostředkování prodeje, namísto toho je však povinen uhradit Racebooku administrativní poplatek ve výši 2 % + příslušná sazba DPH z ceny každé Registrace a Poukazu, u nichž Kupující nárokoval vrácení ceny a toto mu bylo ze strany Racebooku nebo Organizátora vráceno (případně i formou poukazu na nákup Registrace či Poukazu na jinou událost). Administrativní poplatek představuje nezbytné náklady vynaložené na zprostředkování prodeje Registrací či Poukazů na zrušenou nebo změněnou Akci Organizátora, u níž byla Kupujícím vrácena cena Registrace/Poukazu, resp. vystaven poukaz.

**3.3.8.** Smlouva mezi stranami se uzavírá na dobu neurčitou. Každá ze smluvních stran je oprávněna smlouvu kdykoli a bez udání důvodů vypovědět s tím, že dnem doručení výpovědi druhé smluvní straně běží výpovědní lhůta v délce 3 dnů. Výpovědní doba odpovídá času nezbytnému k zajištění ukončení zprostředkování prodeje Registrací a Poukazů na straně Racebooku.

**3.3.9.** Ukončením smluvního vztahu mezi Racebookem a Organizátorem nezanikají závazky smluvních stran, které vznikly do doby ukončení smluvního vztahu. Zejména tedy nezaniká nárok Racebooku na Odměnu, případně nárok na úhradu administrativního poplatku dle ust. 3.3.7. těchto Obchodních podmínek, za Registrace a Poukazy na Akci, jejichž prodej zprostředkoval Racebook do dne ukončení smlouvy.

**3.3.10.** Pokud se strany při ukončení smluvního vztahu nedohodnou jinak, platí, že Registrace a Poukazy dosud prodané prostřednictvím Racebooku zůstávají v platnosti i po ukončení smlouvy. Pro případ, že strany dospějí k tomu, že Registrace a Poukazy prodané do dne ukončení smluvního vztahu prostřednictvím Racebooku budou zneplatněny, jsou strany povinny tuto skutečnost oznámit všem dotčeným Kupujícím e-mailovou zprávou (a případně i SMS zprávou) na e-mailovou adresu určenou Kupujícím při koupi Registrace či Poukazu a současně sdělit, jak a v jakém termínu bude vypořádán jejich nárok na vrácení zaplacené ceny Registrace/Poukazu. Strany jsou povinny se pro tento případ při ukončení smlouvy dohodnout, jakým konkrétním způsobem bude vypořádán nárok Kupujících na vrácení ceny Registrace/Poukazu. Pokud tak neučiní, platí, že vůči Kupujícím je za vyřízení a splnění veškerých nároků plně odpovědný Organizátor.

**3.3.11.** Pro vyloučení pochybností smluvní strany potvrzují, že žádný závazek není fixním závazkem ve smyslu ustanovení § 1980 zák. č. 89/2012 Sb., občanského zákoníku.

**3.3.12.** Organizátor souhlasem s těmito Obchodními podmínkami potvrzuje, že plně odpovídá Kupujícím za veškerou újmu jim případně vzniklou v souvislosti s konáním Akce, ale i v souvislosti s případnou změnou Akce, jejím zrušením nebo zneplatněním Registrací a Poukazů zakoupených Kupujícími.

**3.3.13.** Veškeré informace sdělené mezi Racebookem a Organizátorem a současně veškerá ujednání stran v rámci smluvního vztahu týkajícího se zprostředkování prodeje Registrací a Poukazů jsou smluvními stranami považovány za důvěrné a jsou tak předmětem obchodního tajemství. V případě porušení povinnosti mlčenlivosti ze strany Organizátora má Racebook nárok na zaplacení smluvní pokuty ve výši 100.000,- Kč za každé i jednotlivé porušení uvedené povinnosti.

## ČÁST 4 - DALŠÍ PRÁVA A POVINNOSTI

### **4.1. Zpracování osobních údajů**

**4.1.1.** Racebook a Organizátor společně zpracovávají osobní údaje Kupujících, a to za účelem prodeje Registrací a Poukazů a řádného uskutečnění Akce. Dle nařízení Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen jako “**GDPR**”) jsou Racebook a Organizátor společnými správci a plní svoji povinnost dle čl. 26 GDPR. Racebook je identifikován v čl. 1.1. odst. 1 těchto Obchodních podmínek a identifikace Organizátora je uvedena v popisu Akce, na kterou Racebook zprostředkovává prodej Registrací a Poukazů.

**4.1.2.** Zpracovávanými osobními údaji jsou osobní údaje Kupujících, konkrétně jméno, příjmení, e-mail a telefonní číslo, fotografie, pokud ji Kupující nahraje a případně další údaje vyžadované Organizátorem, které jsou nezbytné pro uskutečnění Akce nebo které je nezbytné ověřit u návštěvníka z pohledu umožnění jeho účasti na Akci (např. věk, jméno a příjmení konkrétní osoby, pokud se jedná o Registraci nebo Poukaz na jméno), IP adresa zařízení Kupujícího a v případě souhlasu Kupujícího se zasíláním obchodních sdělení mohou být zpracovávány další údaje jako jsou osobní preference na základě učiněného nákupu (preference typu Akce, místa konání Akce atd.).

**4.1.3.** Smluvní strany zpracovávají osobní údaje konkrétně z těchto důvodů:

**a)** splnění závazků z uzavřené smlouvy o koupi  - potvrzení o zaplacení Registrace/Poukazu, doručení Registrace/Poukazu, resp. jedinečného identifikátoru Registrace/Poukazu, informace o případných změnách Akce, kontrola Registrace u vstupu na Akci, kontrola Poukazu při jeho uplatnění u Organizátora, vyřízení reklamace,

**b)** zvýšení kvality a rozsahu služeb poskytovaných Kupujícímu, který se registroval a má zřízený Účet uživatele nebo který souhlasil se zasíláním obchodních sdělení, kdy účelem zpracování jsou personalizované nabídky Akcí na základě historie nákupů Kupujícího a jeho dalších preferencí,

**c)** oprávněný zájem Racebooku a Organizátora - vyřízení reklamace Kupujícího, prevence a odhalování možného podvodného jednání, obrana při případném uplatňování nároků Kupujícího souvisejících s prodejem Registrací a Poukazů, vytváření statistik prodejů a jejich následné hodnocení za účelem optimálního nastavení poskytovaných služeb,

**d)** k zasílání obchodních sdělení a newsletterů od Racebooku či Organizátora v případě, že k tomu Kupující udělí výslovný souhlas - tento souhlas může Kupující kdykoliv odvolat postupem sděleným v každém zaslaném obchodním sdělení.

**4.1.4.** Osobní údaje Kupujících, případně jména a příjmení návštěvníků Akce (pokud se liší od Kupujícího), je Racebook oprávněno poskytnout Organizátorovi v rozsahu nezbytném pro účely plnění smlouvy o prodeji Registrace či Poukazu a pro účely zpracování z titulu jeho oprávněného zájmu nebo, se souhlasem Kupujícího, pro účely zasílání novinek a obchodních sdělení. Osobní údaje Kupujících je Racebook dále oprávněno poskytnout subjektům, které jsou na základě zákona oprávněny si tyto údaje vyžádat.

**4.1.5.** Racebook důsledně zajišťuje technické a organizační zabezpečení ochrany všech získaných osobních údajů Kupujících v interních systémech, a to pomocí elektronického zabezpečení, udělení přístupových práv, antivirové ochrany, bezpečnostních záloh a udělení přístupů k údajům pouze autorizovaným osobám.

**4.1.6.** Organizátor se, stejně jako Racebook, zavazují dodržovat všechny standardy technického i organizačního zabezpečení ochrany zpracovávaných osobních údajů, a to  po celou dobu, po kterou jsou oprávněni osobní údaje Kupujících zpracovávat. Konkrétně se Organizátor i Racebook zavazují dodržovat následující pravidla:

**a)** zajišťují elektronické zabezpečení a antivirovou ochranu všech zařízení, prostřednictvím kterých jsou zpracovávány osobní údaje Kupujících;

**b)** osobní údaje Kupujících nebudou jednotlivé autorizované osoby za Racebook ani Organizátora ukládat na svých zařízeních nebo exportovat z interních systémů, pokud to není nezbytně nutné pro realizaci Akce;

**c)** zpracovávané osobní údaje nesmí být nikdy ukládány na nosiče informací, kterou mohou být přístupné třetím osobám;

**d)** zpracovávané osobní údaje nesmí být poskytnuty ani jinak zpřístupněny třetím osobám;

**e)** všechny osoby, které budou přicházet do kontaktu se zpracovávanými osobními údaji, musí být vždy řádně poučeny a zavázány mlčenlivostí (která trvá i po skončení činnosti vykonávané pro Racebook nebo Organizátora);

**f)** přístup k zařízením a úložištím, na kterých dochází ke zpracovávání osobních údajů, musí být chráněn heslem;

**g)** přístupová práva k zařízení a úložišti s osobními údaji Kupujících budou mít vždy pouze pracovníci Racebook nebo Organizátora, jejichž pracovní zařazení to vyžaduje a kteří budou proškoleni ohledně zpracování osobních údajů. Tito pracovníci budou mít přístup pouze k osobním údajům odpovídajícím oprávnění těchto osob;

**h)** Racebook i Organizátor se řídí vlastními vnitřními předpisy upravujícími organizační a technická opatření a postupy a s nimi spojené povinnosti pracovníků Racebook i Organizátora, jejichž účelem je zajištění bezpečnosti zpracovávaných osobních údajů.

**4.1.7.** Organizátor bere na vědomí, že zpracování osobních údajů Kupujících se řídí ustanoveními tohoto oddílu 4.1. Obchodních podmínek a potvrzuje, že se s nimi seznámil. Organizátor se zavazuje dodržovat veškeré povinnosti týkající se ochrany osobních údajů Kupujících vyplývající z těchto Obchodních podmínek a zavazuje se nečinit nic, co by ve vztahu ke Kupujícím mohlo mít za následek porušení ochrany jejich osobních údajů.

**4.1.8.** Organizátor není oprávněn zpracovávat osobní údaje Kupujících k jiným účelům než je prodej Registrací či Poukazů, řádné uskutečnění Akce, případné vyřízení reklamace Kupujícího nebo výkon oprávněných zájmů Organizátora. V případě výslovného souhlasu Kupujícího je Organizátor oprávněn zpracovávat osobní údaje Kupujících za účelem zasílání obchodních sdělení a newsletterů. Pro účely zasílání obchodních nabídek nejsou Racebook a Organizátor společnými správci ve smyslu čl. 26 GDPR a zpracování osobních údajů Kupujících probíhá na vlastní odpovědnost Organizátora.

**4.1.9.** Racebook i Organizátor v plném rozsahu respektují práva Kupujícího vyplývající z GDPR:

- **Právo na přístup k údajům** - v rozsahu garantovaném právními předpisy na ochranu osobních údajů může Kupující požadovat přístup ke svým osobním údajům - informace o tom, zda s nimi Racebook či Organizátor pracuje, s jakými konkrétními údaji a jak s nimi pracuje,
- **Právo na opravu** - Kupující může požadovat opravu nesprávného údaje nebo doplnění neúplného údaje, případně poskytnutí dodatečného souhlasu,
- **Právo na omezení zpracování** - za stanovených zákonných podmínek sice nebudou údaje vymazány, ale Racebook/Organizátor je nebude dále zpracovávat (například v situaci zjišťování oprávněného zájmu na zpracování převažujícího nad požadavkem na výmaz osobních údajů),
- **Právo na výmaz** - vždy pokud jsou pro to splněny zákonné podmínky (například při odvolání souhlasu se zpracováním osobních údajů, zpracování osobních údajů již není třeba, oprávněný zájem na zpracování již pominul apod.),
- **Právo vznést námitku proti zpracování** - právo namítat existenci důvodu pro konkrétní zpracování osobních údajů (námitku je zapotřebí konkretizovat),
- **Právo na přenositelnost údajů** - právo požádat Racebook nebo Organizátora jako správce osobních údajů, aby tyto osobní údaje, pokud je to technicky proveditelné, předal jinému správci osobních údajů.

**4.1.10.** Racebook i Organizátor jsou povinni přijmout taková opatření, aby nemohlo dojít k neoprávněnému nebo nahodilému přístupu k osobním údajům Kupujících, k jejich změně, zničení či ztrátě, neoprávněným přenosům, k jejich jinému neoprávněnému zpracování, jakož i k jinému zneužití osobních údajů. Tato povinnost platí i po ukončení zpracování osobních údajů. Veškeré případy náhodného nebo neoprávněného přístupu k osobním údajům Kupujících se smluvní strany zavazují si vzájemně neprodleně oznámit.

**4.1.11.** Za porušení povinností při zpracování osobních údajů odpovídají dotčeným subjektům údajů Racebook i Organizátor společně a nerozdílně. Odpovědnost Racebooku a Organizátora vůči Úřadu pro ochranu osobních údajů za správní delikty při zpracování osobních údajů se řídí příslušnými ustanoveními GDPR. Organizátor plně odpovídá Racebooku za škodu, která by Racebooku vznikla případným porušením povinností Organizátora týkající se zpracování osobních údajů Kupujících stanovené GDPR.

**4.1.12.** Osobní údaje Kupujících uchovává Racebook a Organizátor pouze do doby uskutečnění Akce, popř. do doby vypořádání nároků Kupujícího vzešlých z účasti na Akci, popř. po delší dobu, pokud to vyžaduje právní předpis nebo je to nutné z titulu oprávněného zájmu Racebooku nebo Organizátora, např. pro účely vyřízení či uplatnění nároků těchto subjektů. Pokud pomine zákonný důvod zpracování, provede Racebook i Organizátor výmaz příslušných osobních údajů. Pokud Kupující udělí souhlas se zpracováním osobních údajů pro jiný účel než plnění smlouvy (např. pro zasílání obchodních sdělení či newsletterů), zpracovává Racebook osobní údaje až do odvolání souhlasu, max. však 3 roky.

**4.1.13.** Organizátor je po ukončení spolupráce s Racebookem povinen ukončit zpracovávání veškerých osobních údajů Kupujících, které převzal od Racebooku v souvislosti se zprostředkováním prodeje Registrací/Poukazů a řádnou realizací Akce a již je pro tento účel nepotřebuje.

**4.1.14.** Kupující může pro uplatnění jakéhokoliv z práv stanovených příslušnými právními předpisy kontaktovat Racebook písemně emailem na [podpora@actigo.app](mailto:podpora@actigo.app) či dopisem zaslaným na adresu sídla Racebook s.r.o., Rybná 716/24, 110 00 Praha 1. Pokud byste se jako Kupující domnívali, že zpracováním Vašich osobních údajů dochází k porušování právních předpisů, máte právo podat stížnost u národního dozorového úřadu. Pro ČR se jedná o Úřad pro ochranu osobních údajů, Pplk. Sochora 27, 170 00 Praha 7, [www.uoou.cz](http://www.uoou.cz/).

**4.1.15.** Právní vztahy smluvních stran týkající se zpracování osobních údajů, které nejsou upraveny v tomto oddíle Obchodních podmínek, se řídí příslušnými ustanoveními GDPR a dalšími příslušnými právními předpisy České republiky.

### **4.2. Spory**

Se spory, které mohou vzniknout mezi Organizátorem a Kupujícím, jakožto spotřebitelem, v souvislosti s nákupem Registrace či Poukazu na Akci pořádanou Organizátorem, anebo v souvislosti s průběhem Akce pořádané Organizátorem, a které se nepodaří vyřešit vzájemnou dohodou stran, může se spotřebitel obrátit v rámci mimosoudního řešení sporu na subjekt určený pro mimosoudní řešení spotřebitelských sporů, kterým je v tomto případě Česká obchodní inspekce, Ústřední inspektorát - oddělení ADR, Štěpánská 567/15, 120 00 Praha 2. Kupující může rovněž využít platformu pro řešení sporů online, která je zřízena Evropskou komisí na internetové stránce <http://ec.europa.eu/consumers/odr/>.

### **4.3. Závěrečná ustanovení**

**4.3.1.** Racebook může kdykoliv, a to i jednostranně, změnit tyto Obchodní podmínky, přičemž informaci o změně a účinnosti změny oznámí Racebook Kupujícím i Organizátorům přímo na internetové stránce actigo.app, případně e-mailovou zprávou. Změna je účinná uplynutím dvou měsíců ode dne oznámení změny, neurčí-li Racebook v oznámení pozdější účinnost. Změna se nevztahuje na již zakoupené Registrace a Poukazy na Akci.

**4.3.2.** Veškeré právní vztahy vzniklé na základě nebo v souvislosti s těmito Obchodními podmínkami se řídí právním řádem České republiky, a to bez ohledu na to, odkud byl nákup Registrací či Poukazů Kupujícím realizován nebo kde má Organizátor své sídlo.

**4.3.3.** Je-li nebo stane-li se některé z ustanovení Obchodních podmínek neplatným nebo neúčinným, namísto těchto neplatných ustanovení nastoupí takové ustanovení, jehož smysl se co nejvíce přibližuje hospodářskému účelu neplatného či neúčinného ustanovení. Neplatností nebo neúčinností některého ustanovení není dotčena platnost a účinnost ostatních ustanovení. Neplatností nebo neúčinností některého ustanovení pouze ve vztahu k určitému subjektu nebo k určité skupině subjektů není dotčena platnost a účinnost těchto ustanovení vůči jiným subjektům.

**4.3.4.** Veškerá oznámení mezi stranami probíhají prostřednictvím e-mailové zprávy, telefonicky (dle kontaktních údajů sdělených druhé smluvní straně) či případně oznámením v Účtu uživatele nebo Účtu Organizátora.

**4.3.5.** Tímto smluvní strany vylučují aplikaci následujících ustanovení zák. č. 89/2012 Sb., občanský zákoník: § 557, § 1799 a § 1800 (pokud se jedná o smluvní vztah Racebook a podnikatele), § 1805 odst. 2 a § 1936 odst. 1.

Tyto Obchodní podmínky vstupují v platnost a jsou účinné od 1. října 2022.

## Cookies

### **(1) Co jsou cookies?**

Soubory cookies jsou malé datové soubory umísťované do paměti vašeho prohlížeče nebo zařízení při prohlížení webové stránky. Ve vašem prohlížeči jsou ukládány webovými stránkami, které navštěvujete. Jsou nezbytné pro některé funkce webových stránek, jako je třeba přihlašování. Díky cookies si stránky nebo aplikace mohou také zapamatovat různá nastavení, jako je jazyk, font a další možnosti, které jste si pro zobrazení stránek sami zvolili. To je důvod, proč cookies umisťujeme na vaše zařízení.

Cookies lze rozdělit **podle jejich trvanlivosti** na dvě kategorie:

**Krátkodobé (session cookies)** zůstávají ve vašem prohlížeči či zařízení do zavření vašeho prohlížeče nebo aplikace, poté se vymažou.

**Dlouhodobé (persistent cookies)** zůstávají uloženy v prohlížeči či zařízení dlouhou dobu (záleží na nastavení vašeho prohlížeče a nastavení samotných cookies) nebo dokud je ručně neodstraníte. Podle těchto cookies lze identifikovat zařízení uživatele při opětovném spuštění webového prohlížeče či aplikace. V žádném případě soubory cookies neovlivní technické používání vašeho zařízení a neobsahují viry.

Podle toho, **kdo cookies vytváří a zpracovává** lze rozdělit cookies na dvě kategorie:

**Cookies první strany (first party cookies)** vytváří přímo webová stránka nebo skripty na stejné doméně. Nejčastěji slouží k zajištění základní funkčnosti webu či aplikace.

**Cookies třetích stran (third party cookies)** jsou nejčastěji cookies reklamních systémů, widgetu sociálních sítí nebo vložených videí.

### **(2) Jaké cookies využíváme?**

**Využíváme především technické cookies (první strany, krátkodobé)**, které jsou nezbytné pro zajištění elementární funkce webových stránek, aplikací, případně dalších služeb, tj. např. pro uložení zvolené jazykové verze.

**Dále využíváme zejména nástroje společnosti Google, jako např. Google Analytics, Google Adwords, Google Tag Manager (první strany, dlouhodobé)** - tyto nástroje používáme pro získávání dat a vytváření statistik o chování uživatelů, abychom vám mohli naše služby lépe přizpůsobit. Data se posuzují v podobě hromadného celku a tím pádem v anonymní podobě, která neumožňuje vaši identifikaci.

Webové stránky můžete používat i v režimu, který neumožňuje sbírání údajů o vašem chování – stačí si tak nastavit váš prohlížeč, nebo je možné vznést proti sběru na základě našeho oprávněného zájmu námitku, a to e-mailem na [podpora@actigo.app](mailto:podpora@actigo.app).

Vaši námitku vyřídíme bez zbytečného odkladu, maximálně však do jednoho měsíce. Ve výjimečných případech, zejména z důvodu složitosti Vašeho požadavku, jsme oprávněni tuto lhůtu prodloužit o další dva měsíce. O takovém případném prodloužení a jeho zdůvodnění bychom Vás informovali.

Vznesete-li námitku proti zpracování technických cookies nezbytných pro fungování webu, nemůžeme vám pak nicméně zaručit plnou funkčnost a kompatibilitu našeho webu.

**Cookies nikdy nepoužíváme k tomu, abychom vás osobně identifikovali, a nikdy do nich neumisťujeme citlivá nebo osobní data.**

### **(3) Proč cookies zpracováváme?**

1. **Plná funkčnost využívání našich webových stránek** - soubory cookies vyžadované pro zajištění plné funkčnosti využívání našich webových stránek (tzv. technické cookies).
1. **Usnadnění prohlížení našich webových stránek** - soubory cookies, které nejsou zcela nezbytné k plnému fungování webových stránek, nicméně jejich používání zlepšuje uživatelský dojem.
1. **Oprávněný zájem** - díky cookies měříme návštěvnost a děláme si statistiky o vašem chování na webu. Můžeme tak hlídat a zdokonalovat funkčnost a bezpečnost našeho webu, aplikace a dalších služeb.
1. **Přizpůsobení reklamních a marketingových sdělení** - přizpůsobíme vám na míru reklamu a její cílení nebo využijeme cookies pro retargeting.

Abychom zabránili neoprávněnému přístupu k našim souborům cookies a podobným technologiím, máme zavedena bezpečnostní opatření všude, kde je to zapotřebí. Jedinečný identifikátor zajišťuje, že přístup k údajům ze souborů cookies máme pouze my a/nebo autorizovaní poskytovatelé služeb.

Veškeré osobní údaje, které pomocí těchto technologií shromažďujeme a uchováváme pro výše uvedené kategorie 2 až 4 závisí na vašem souhlasu, získaném formou jednoznačného sdělení zobrazeného na našich webových stránkách při vaší první návštěvě. Tento souhlas můžete odvolat v nastavení vašeho prohlížeče.

### **(4) Jak lze upravit využívání cookies?**

Všechny cookies, které už na vašem zařízení jsou, můžete vymazat. Většina prohlížečů také nabízí možnost blokace umisťování cookies na vaše zařízení, v takovém případě ale mějte na paměti, že blokace nebo deaktivace souborů cookies za tímto účelem bude mít za následek, že funkce nástroje kontroly již nebude efektivní a může to mít nepříznivý dopad na váš celkový zážitek při prohlížení. Detailní informace o nastavení ukládání souborů cookies ve vašem prohlížeči nebo zařízení najdete na stránkách poskytovatele konkrétního prohlížeče. Informace o tom, jak si nastavit svůj prohlížeč a omezit nebo povolit zpracování cookies, najdete například [zde](https://www.whatismybrowser.com/guides/how-to-enable-cookies/).

### **(5) Jak dlouho budeme údaje zpracovávat?**

Data z cookies pro měření návštěvnosti a fungování webu nebo aplikace jsou uchovány pouze na dobu nezbytně nutnou pro fungování webu a dále pro statistiku. Jednotlivé doby uchovávání a zpracování cookies (včetně marketingových) se řídí obchodními podmínkami konkrétně využité služby (viz bod 6. níže). Souhlas s užitím cookies můžete vzít kdykoliv zpět, stačí, když změníte nastavení svého webového prohlížeče, nebo se nám ozvete na **podpora@actigo.app**.

### **(6) Kdo může mít k údajům z cookies přístup?**

Shromážděné cookies soubory jsou zpracovány dalšími zpracovateli:

- službou Google Analytics, provozovanou společností Google Inc., se sídlem 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
- službou Google Adwords, provozovanou společností Google Inc., se sídlem 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
- Google Tag Manager, provozovanou společností Google Inc., se sídlem 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
- službou Sklik, provozovanou společností Seznam.cz, a.s., IČ: 26168685, se sídlem Praha 5 – Smíchov, Radlická 3294/10, PSČ 150 00
- Facebook, společnost se sídlem 1 Hacker Way, Menlo Park, CA 94025, United States

Cookies soubory jsou zpracovateli zpracovány v souladu s jejich podmínkami nakládání s osobními údaji.

Ty zásady využívání cookies vstupují v platnost a jsou účinné od 1. října 2022.

`,

    'en': ``,
}


export const Support = {
    'cs': `
# Podpora a kontakty

Provozovatelem webových stránek actigo.app a mobilních aplikací Actigo pro iOS a Android je společnost Racebook s.r.o., se sídlem Rybná 716/24, 110 00 Praha 1, IČO: 14368587.

## Podpora pro uživatele aplikace

- email: [podpora@actigo.app](mailto:podpora@actigo.app)
- dokumentace: [docs.actigo.app](https://docs.actigo.app)

Uvedené kontakty jsou určeny pro technickou podporu uživatelů a organizátorů a neslouží k zodpovězení dotazů k jednotlivým akcím. V takovém případě se obracejte přímo na jejich organizátory. Kontakt na organizátora je uveden vždy na detailu konkrétní akce/aktivity.
    `,
    'en': `
# Support & Contacts

Operator of web pages actigo.app a mobile application Actigo for iOS and Android is company Racebook s.r.o. with registered business address Rybná 716/24, 110 00 Praha 1, Company ID: 14368587.

## User support

- email: [support@actigo.app](mailto:support@actigo.app)
- documentation: [docs.actigo.app](https://docs.actigo.app)

Above mentioned contacts are dedicated for technical support of users or event organizers and a and can't be used for requests related to event details. At that case contact event organizer directly. Relevant contacts are part of each event detail page.
    `
}


// export const OrganizerWelcome = {
//     'cs': `Vítejte v Actigo jako pořadatel!`,
//     'en': `Welcome to Actigo as organizer!`,
// }

export const OrganizerWelcome = {
    'cs' : `Vítejte v Actigo jako pořadatel!`,
    'en': `Welcome to Actigo as organizer!`
}


export const OrganizerEvents = {
    'cs' : `Byly tvé závody součástí termínovky na [běhej.com](https://behej.com)? V tom případě nejprve zkus svůj závod vyhledat, protože byl do Actigo automaticky přenesen. Napiš nám na [podpora@actigo.app](mailto:podpora@actigo.app) a my ti závod převedeme do tvé správy. Budeš moci nahrát nové fotky, zkontrolovat termín, přidat ceník, propozice atd.`,
    'en': `Welcome to Actigo as organizer!`
}
