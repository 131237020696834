<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="form-element" [innerHTML]="text | marked: false"></div>
                <form
                    [formGroup]="formGroup"
                    (ngSubmit)="submitForm(formGroup.value)"
                    class="form">
                    <mat-form-field class="form-element">
                        <input matInput formControlName="name" [placeholder]="'name'|translate"/>
                        <mat-error *ngIf="!formGroup.controls['name'].valid && formGroup.controls['name'].touched">
                            {{'missing' | translate}} {{'name' | translate | lowercase}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="form-element">
                        <input matInput formControlName="orgId" [placeholder]="'orgId'|translate"/>
                        <mat-error *ngIf="!formGroup.controls['orgId'].valid && formGroup.controls['orgId'].touched">
                            {{'missing' | translate}} {{'orgId' | translate | lowercase}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="form-element">
                        <input matInput formControlName="vatId" [placeholder]="'vatId'|translate"/>
                        <mat-error *ngIf="!formGroup.controls['vatId'].valid && formGroup.controls['vatId'].touched">
                            {{'missing' | translate}} {{'vatId' | translate | lowercase}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="form-element">
                        <input matInput formControlName="street" [placeholder]="'street' | translate"/>
                        <mat-error *ngIf="!formGroup.controls['street'].valid && formGroup.controls['street'].touched">
                            {{'missing' | translate}} {{'street' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="form-element">
                        <input matInput formControlName="city" [placeholder]="'city' | translate"/>
                        <mat-error *ngIf="!formGroup.controls['city'].valid && formGroup.controls['city'].touched">
                            {{'missing' | translate}} {{'city' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="form-element">
                        <input matInput formControlName="postalCode" [placeholder]="'postalCode' | translate"/>
                        <mat-error *ngIf="!formGroup.controls['postalCode'].valid && formGroup.controls['postalCode'].touched">
                            {{'missing' | translate}} {{'postalCode' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="form-element">
                        <input matInput formControlName="state" [placeholder]="'state' | translate"/>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select formControlName="country" [placeholder]="'country' | translate">
                            <mat-option *ngFor="let country of countries" [value]="country.code">{{country.name | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
<!--                    <h2>{{'terms' | translate}}</h2>-->
<!--                    <div class="form-element" [innerHTML]="terms | keepHtml"></div>-->
                    <div class="form-element text-right">
                        <button
                            mat-raised-button
                            color="primary"
                            type="submit"
                            class="button"
                            [disabled]="!formGroup.valid"
                        >
                            {{'create' | translate}}
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>
