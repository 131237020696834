<mat-card>
    <mat-card-content>
        <mat-card-title>{{'organizer' | translate}}</mat-card-title>
        <form
            [formGroup]="form"
            class="form"
        >
            <mat-form-field class="form-element">
                <input matInput formControlName="name" [placeholder]="'name' | translate"/>
                <mat-error *ngIf="!form.controls['name'].valid && form.controls['name'].touched">
                    {{'missing' | translate}} {{'name' | translate | lowercase}}
                </mat-error>
            </mat-form-field>
            <div class="mb-2">
                <mat-checkbox formControlName="isActive">{{'isActive' | translate | lowercase}}</mat-checkbox>
            </div>
            <mat-form-field class="form-element">
                <textarea matInput formControlName="description" [placeholder]="'description' | translate"></textarea>
                <mat-error *ngIf="!form.controls['description'].valid && form.controls['description'].touched">
                    {{'missing' | translate}} {{'description' | translate | lowercase}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput formControlName="orgId" [placeholder]="'orgId'|translate"/>
                <mat-error *ngIf="!form.controls['orgId'].valid && form.controls['orgId'].touched">
                    {{'missing' | translate}} {{'orgId' | translate | lowercase}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput formControlName="vatId" [placeholder]="'vatId'|translate"/>
                <mat-error *ngIf="!form.controls['vatId'].valid && form.controls['vatId'].touched">
                    {{'missing' | translate}} {{'vatId' | translate | lowercase}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput formControlName="bankAccount" [placeholder]="'bankAccount'|translate"/>
                <mat-error *ngIf="!form.controls['bankAccount'].valid && form.controls['bankAccount'].touched">
                    {{'missing' | translate}} {{'bankAccount' | translate | lowercase}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput formControlName="email" [placeholder]="'email' | translate"/>
                <mat-error *ngIf="!form.controls['email'].valid && form.controls['email'].touched">
                    {{'missing' | translate}} {{'email' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput formControlName="phoneNumber" [placeholder]="('phoneNumber' | translate) + ' (+420XXXXXX)'"/>
                <mat-error *ngIf="!form.controls['phoneNumber'].valid && form.controls['phoneNumber'].touched">
                    {{'missing' | translate}} {{'phoneNumber' | translate}}
                </mat-error>
            </mat-form-field>
            <h3>{{"links" | translate}}</h3>
            <app-link-list
                [formGroup]="form"
            ></app-link-list>
            <div class="form-element text-right">
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="button"
                    (click)="submitForm()"
                    [disabled]="!form.valid"
                >
                    {{'save' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
