import { Pipe, PipeTransform } from '@angular/core'
import { marked } from 'marked'
import { escapeHtmlEntities } from '../../core.functions'

@Pipe({
  name: 'marked'
})
export class MarkedPipe implements PipeTransform {

    transform(value: any, removeParagraph: boolean = true, escapeHtml: boolean = true): any {
        if (value && value.length > 0) {
            let html = marked(escapeHtml ? escapeHtmlEntities(value) : value)
            return removeParagraph ? html.substring(3, html.length - 5) : html // removing <p>..</p>
        }
        return value
    }
}
