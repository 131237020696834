<div fxLayout="row wrap">
    <!-- Card column -->
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap">
                    <div fxFlex.gt-sm="100%" fxFlex="100">
                        <form [formGroup]="form" class="basic-form">
                            <mat-form-field>
                                <input
                                    type="text"
                                    matInput
                                    formControlName="organizedActivityId"
                                    [matAutocomplete]="auto"
                                >
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="stringify">
                                    <mat-option *ngFor="let organizedActivity of filteredOrganizedActivities$ | async" [value]="organizedActivity">
                                        {{stringify(organizedActivity)}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </form>
                    </div>
                    <ng-container>
                        <div fxFlex="100">
                            <app-file-uploader
                                [disabled]="(uploadingFile$ | async) || !exportUrl"
                                [uploader]="uploader"
                            ></app-file-uploader>
                        </div>
                        <div fxFlex="50">
                            <button
                                mat-button
                                [disabled]="!canUpload"
                                (click)="upload()"
                            >
                                <mat-icon>file_upload</mat-icon>
                                CSV
                            </button>
                        </div>
                        <div fxFlex="50" class="text-right" align="right">
                            <button
                                mat-button
                                [disabled]="!exportUrl"
                                (click)="windowService.share(this.exportUrl, '')"
                            >
                                <mat-icon>file_download</mat-icon>
                                CSV
                            </button>
                        </div>
                    </ng-container>
                </div>
            </mat-card-content>
            <div
                class="scrollable-table"
                *ngIf="items$ | async; let items"
            >
                <mat-table #table [dataSource]="items">
                    <ng-container matColumnDef="person">
                        <mat-header-cell *matHeaderCellDef>{{'person' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'person'|translate">
                            <app-person [person]="element.person"></app-person
                            ></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="startNumber">
                        <mat-header-cell *matHeaderCellDef class="justify-content-end"> {{'startNumber' | translate}} </mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'startNumber' | translate" class="justify-content-end"> {{element.startNumber}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="time">
                        <mat-header-cell *matHeaderCellDef class="justify-content-end">{{'time' | translate}} </mat-header-cell>
                        <mat-cell  *matCellDef="let element" [attr.data-label]="'time' | translate" class="justify-content-end"> {{element.time | time}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <mat-header-cell *matHeaderCellDef> {{'category' | translate}} </mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'category' | translate">{{element.category}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="categoryPosition" class="justify-content-end">
                        <mat-header-cell *matHeaderCellDef> {{'categoryPosition' | translate}} </mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'categoryPosition' | translate" class="justify-content-end">
                            <ng-container *ngIf="element.position > 0 && element.position < 100000">{{element.position}} / {{element.positionBySex}} / {{element.positionByCategory}}</ng-container>
                            <ng-container *ngIf="element.position == 0">{{'unofficial' | translate | uppercase}}</ng-container>
                            <ng-container *ngIf="element.position == 100000">DNF</ng-container>
                            <ng-container *ngIf="element.position == 100001">DNS</ng-container>
                            <ng-container *ngIf="element.position == 100002">DSQ</ng-container>

                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: columns"></mat-row>
                    <p *matNoDataRow class="no-data">{{'noData' | translate}}</p>
                </mat-table>
            </div>
        </mat-card>
    </div>
</div>
