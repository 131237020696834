import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PrivacyComponent } from './components/privacy/privacy.component'
import { TermsComponent } from './components/terms/terms.component'
import { HomeComponent } from './components/home/home.component'
import { EventsModule } from './events/events.module'
import { UsersModule } from './users/users.module'
import { OrganizersModule } from './organizers/organizers.module'
import { FullLayoutComponent } from 'app/layouts/full/full-layout.component'
import { DevToolsComponent } from './components/dev-tools/dev-tools.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { PaymentsModule } from './payments/payments.module'
import { SupportComponent } from './components/support/support.component'
import { ProfileComponent } from './components/profile/profile.component'
import { EmailNotVerifiedComponent } from './components/email-not-verified/email-not-verified.component'
import { ToolsComponent } from './components/tools/tools.component'
import { ArticlesModule } from './articles/articles.module'
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component'
import { LandingComponent } from './components/landing/landing.component'
import { PeopleComponent } from './components/people/people.component'

const routes: Routes = [
    {
        path: '',
        // redirectTo: '/events/activities',
        // pathMatch: 'full',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
                data: {
                    title: 'home',
                },
            },
        ],
    },
    {
        path: 'landing',
        component: LandingComponent
    },
    {
        path: 'articles',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => ArticlesModule,
            },
        ],
    },
    {
        path: 'events',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => EventsModule,
            },
        ],
    },
    {
        path: 'payments',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => PaymentsModule,
            },
        ],
    },
    {
        path: 'users',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => UsersModule,
            },
        ],
    },
    {
        path: 'organizers',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => OrganizersModule,
            },
        ],
    },
    {
        path: 'privacy',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: PrivacyComponent,
                data: {
                    title: 'privacy',
                    urls: [{ title: 'home', url: '/' }, { title: 'privacy' }],
                },
            },
        ],
    },
    {
        path: 'people',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: PeopleComponent,
                data: {
                    title: 'people',
                    urls: [{ title: 'home', url: '/' }, { title: 'people' }],
                },
            },
        ],
    },
    {
        path: 'unsubscribe/:id',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: UnsubscribeComponent,
                data: {
                    title: 'unsubscribe',
                    urls: [{ title: 'home', url: '/' }, { title: 'unsubscribe' }],
                },
            },
        ],
    },
    {
        path: 'terms',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: TermsComponent,
                data: {
                    title: 'terms',
                    urls: [{ title: 'home', url: '/' }, { title: 'terms' }],
                },
            },
        ],
    },
    {
        path: 'support',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: SupportComponent,
                data: {
                    title: 'support',
                    urls: [{ title: 'home', url: '/' }, { title: 'support' }],
                },
            },
        ],
    },
    {
        path: 'tools',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: ToolsComponent,
                data: {
                    title: 'tools',
                    urls: [{ title: 'home', url: '/' }, { title: 'tools' }],
                },
            },
        ],
    },
    {
        path: 'developers',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: DevToolsComponent,
                data: {
                    title: 'developers',
                    urls: [{ title: 'home', url: '/' }, { title: 'developers' }],
                },
            },
        ],
    },
    {
        path: 'verifyEmail',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: EmailNotVerifiedComponent,
                data: {
                    title: 'email-not-verified',
                    urls: [{ title: 'home', url: '/' }, { title: 'email-not-verified' }],
                },
            },
        ],
    },
    {
        path: ':ref',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: ProfileComponent,
                data: {
                    title: 'profile',
                    urls: [{ title: 'home', url: '/' }, { title: 'profile' }],
                },
            },
        ],
    },
    {
        path: '**',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: NotFoundComponent,
                data: {
                    title: '404',
                    urls: [{ title: 'home', url: '/' }],
                },
            },
        ],
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
