import { from, lastValueFrom, map, Observable, retry, tap, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { environment } from 'environments/environment'
import { Injectable } from '@angular/core'
import { Geolocation, WatchPositionCallback } from "@capacitor/geolocation";
import { WindowService } from './window.service'
import { Position } from 'app/core/core.models'
import { LoginService } from 'app/users/services/login.service'

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    constructor(private windowService: WindowService) {}

    currentPosition = new Promise<Position | null>((resolve, reject) => {
        lastValueFrom(
            this.windowService.isNative ? this.getCurrentNativePosition$() : this.getCurrentBrowserPosition$(),
        )
            .then((position) => {
                console.log('Position resolved from ' + (this.windowService.isNative ? 'device' : 'browser'), position)
                resolve(position)
            })
            .catch((reason) => {
                fetch('https://api.ipgeolocation.io/ipgeo?apiKey=' + environment.ipGeolocation.apiKey)
                    .then((response) => {
                        if (response.ok) {
                            response
                                .json()
                                .then((data) => {
                                    console.log('Position resolved using IP geolocation', data)
                                    resolve({
                                        latitude: Number(data.latitude),
                                        longitude: Number(data.longitude),
                                        //accuracy: data.accuracy,
                                        //source: 'ip/' + data.ip,
                                    })
                                })
                                .catch((result) => {
                                    resolve(null)
                                })
                        } else {
                            resolve(null)
                        }
                    })
                    .catch((reason) => {
                        resolve(null)
                    })
            })
    })

    getCurrentNativePosition$(): Observable<Position> {
        return from(Geolocation.getCurrentPosition()).pipe(
            map((position) => ({
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
                //accuracy: position.coords.accuracy,
                //source: 'native',
            })),
            tap((result) => {
                console.log('Got current position from device', result)
            }),
            catchError((error) => {
                console.log('Failed to get current position from device')
                return throwError(error)
            }),
        )
    }

    watchNativePosition$(callback: WatchPositionCallback) {
        Geolocation.watchPosition({enableHighAccuracy: true, timeout: 1000}, callback)
    }

    getCurrentBrowserPosition$(): Observable<Position> {
        return this.getCurrentWindowCoordinates$().pipe(
            map((coordinates) => ({
                longitude: coordinates.longitude,
                latitude: coordinates.latitude,
                //accuracy: coordinates.accuracy,
                //source: 'browser',
            })),
        )
    }

    getCurrentWindowCoordinates$(): Observable<GeolocationCoordinates> {
        return new Observable<GeolocationCoordinates>((observer) => {
            window.navigator.geolocation.getCurrentPosition(
                (position) => {
                    observer.next(position.coords)
                    observer.complete()
                },
                (err) => observer.error(err),
            )
        }).pipe(
            retry(1),
            tap((result) => {
                console.log('Got current coordinates from browser', result)
            }),
            catchError((error) => {
                console.log('Failed to get current coordinates from browser')
                return throwError(error)
            }),
        )
    }
}
