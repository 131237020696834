import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { MenuItem, MenuItems, MenuItemType } from 'app/layouts/layouts.models'
import { User } from 'app/users/users.models'
import { Organizer } from 'app/organizers/organizers.models'

@Component({
    selector: 'organizer-menu',
    templateUrl: './organizer-menu.component.html',
    styleUrls: ['organizer-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizerMenuComponent {
    @Input()
    menuItems: MenuItems<any>

    @Input()
    organizers: Organizer[] = []

    @Input()
    currentOrganizer: Organizer

    @Output()
    menuClick = new EventEmitter<MenuItem<() => any>>()

    @Output()
    organizerMenuClick = new EventEmitter<Organizer>()

    manuItemType = MenuItemType
    opened = false
}
