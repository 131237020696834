import {
    ActivityType,
    Amount,
    Link, LocalizedText,
    LocalizedTexts,
    Media,
    MediaType, Merchandise, MerchandisePrice,
    Person,
    Position,
    Price,
    Seekable, Skipable, SkipableQuery,
    Workflow,
    WorkflowState,
} from 'app/core/core.models'
import { User } from 'app/users/users.models'
import { Organizer } from '../organizers/organizers.models'

export enum TransactionType {
    BankAccount = 'BankAccount',
    PaymentCard = 'PaymentCard',
    ApplePay = 'ApplePay',
    GooglePay = 'GooglePay',
}

export interface TrackPoint {
    altitude?: number
    latitude: number
    longitude: number
    time?: Date
}

export interface TrackSegment {
    points: TrackPoint[]
    profile?: TrackProfile
}

export interface Track {
    segments: TrackSegment[]
}

export interface TrackProfilePoint {
    altitude?: number
    distance?: number
    label?: string
    hr?: number,
    cad?: number,
    speed?: number,
    course?: number,
    power?: number
}

export interface TrackProfile {
    points: TrackProfilePoint[]
    totalDistance: number
    elevationGain: number
    elevationLoss: number
    minimumSpeed?: number
    maximumSpeed?: number
}

export interface ActivitySegment {
    type: string
    distance: number
}

export interface Activity {
    type: ActivityType
    name: string
    distance?: number
    segments?: ActivitySegment[]
    track?: Track
}

export enum TransactionState {
    Pending = 'Pending',
    Paid = 'Paid',
    Refunded = 'Refunded',
    Timeouted = 'Timeouted',
    Cancelled = 'Cancelled',
}

export enum SelectionType {
    Personalized = 'Personalized',
    Featured = 'Featured',
    Trending = 'Trending',
    New = 'New',
}

export enum SyncType {
    Registration = 'Registration',
    ActivityResult = 'ActivityResult'
}

export interface EventsTotals {
    organizedEventCount: number
    organizedActivityCount: number
    registrationCount: number
    activityResultCount: number
}

export interface OrganizedEvent {
    id: string
    externalId?: string
    organizerId: string
    name: LocalizedTexts
    description: LocalizedTexts
    activities: OrganizedActivity[]
    labels: string[]
    media: Media[]
    workflow: Workflow
}

export interface GetOrganizedActivity {
    organizedActivityId: string
}

export interface GetOrganizedEvents {
    organizerId: string
}

export interface GetOrganizedEvent {
    organizedEventId: string
}

export interface LocalizedTextInput {
    language: string
    text: string
}

export interface CreateOrganizedEventInput {
    description: LocalizedTextInput[]
    externalId: string
    name: LocalizedTextInput[]
    organizerId: string
    labels: string[]
}

export interface UpdateOrganizedEventInput extends CreateOrganizedEventInput {
    id: string
}

export interface MediaInput {
    ref: string
    subtype?: string
    thumbRef?: string
    title: string
    type: MediaType
}

export interface AddOrganizedEventMedia {
    media: MediaInput
    organizerEventId: string
}

export interface SetOrganizedEventMediaFavorite {
    organizerEventId: string
    mediaRef: string
}

export interface TrackPointInput {
    altitude?: number
    latitude: number
    longitude: number
    time: string
}

export interface TrackSegmentInput {
    points: TrackPointInput[]
}

export interface TrackInput {
    name: string
    segments: TrackSegmentInput[]
    type: string
}

export interface ActivityInput {
    distance?: number
    name: string
    track: TrackInput
    type: ActivityType
}

export interface CreateOrganizedActivityInput {
    activity: ActivityInput
    description: LocalizedTextInput[]
    externalId: string
    labels: string[]
    links: Link[]
    name: LocalizedTextInput[]
    organizedEventId: string
    place: LocalizedTextInput[]
    startTime: string
    timeLimit?: number
    state?: WorkflowState
}

export interface UpdateOrganizedActivityInput {
    activity: ActivityInput
    description: LocalizedTextInput[]
    id: string
    labels: string[]
    links: Link[]
    name: LocalizedTextInput[]
    place: LocalizedTextInput[]
    startTime: string
    timeLimit?: number
}

export interface OrganizedActivity {
    id?: string
    code: string
    externalId?: string
    organizedEvent?: OrganizedEvent
    //workflow?: Workflow
    state?: WorkflowState
    name: LocalizedTexts
    place?: LocalizedTexts
    description: LocalizedTexts
    labels?: string[]
    links?: Link[]
    activity?: Activity
    registrationSyncConfig?: SyncConfig
    activityResultSyncConfig?: SyncConfig
    startTime?: string
    timeLimit?: number
    favoriteMediaRef?: string
    priceList?: Price[]
    merchandisePriceList?: MerchandisePrice[]
    registrationLimit?: number
    registrationCount?: number
    followCount?: number
    messageCount?: number
    isOpen: boolean
    isCompleted: boolean
    isUserRegistered?: boolean
    isUserFollowing?: boolean
    currentPrice?: Price
    position?: Position
}

export interface SyncConfig {
    endpointUrl: string
    dataFileFormat?: string
    runImmediately?: boolean
    dryRun?: boolean
}

export interface WeatherForecast {
    tempC: number
    tempF: number
    maxWindMPH: number
    maxWindKPH: number
    chanceOfRain: number
    chanceOfSnow: number
    condition: WeatherForecastCondition
    uv: number
}

export interface WeatherForecastCondition {
    text: string
    icon: string
    code: number
}

export interface OrganizedActivityFollow {
    id: string
    userId: string
    organizedActivityId: string
}

export interface ActivityResult {
    organizedActivity?: OrganizedActivity
    startNumber: String
    time: number
    category?: string
    position: number
    positionBySex?: number
    positionByCategory?: number
    splitTimes: number[]
}

export interface Transaction {
    id: string
    userId: string
    serviceId: string
    type: TransactionType
    state: TransactionState
    amount: Amount
    updated?: Date
    note: string
}

export enum RegistrationState {
    New = 'New',
    Confirmed = 'Confirmed',
    Cancelled = 'Cancelled',
}

export enum ContractType {
    Default = 'Default',
    Individual = 'Individual',
}
export interface Registration {
    id: string
    code: string
    created: Date
    state: RegistrationState
    user?: User
    transaction: Transaction
    organizedActivity?: OrganizedActivity
    startNumber?: string
    teamName?: string
    activityResult?: ActivityResult
    merchandise: Merchandise[]
    paymentId?: string
}

export interface RegisteredPerson {
    id: string
    created: Date
    code: string
    startNumber: string
    state: RegistrationState
    amount: Amount
    person: Person
}

export interface DistanceRange {
    max: number
    min: number
}

export interface PositionInput {
    latitude: number
    longitude: number
}

export interface GetOrganizedActivities extends Seekable {
    activityTypes: ActivityType[]
    dateFrom: Date
    dateTo: Date
    name?: string
    distanceRanges: DistanceRange[]
    labels: string[]
    maxDistanceFromPosition: number
    myPosition: PositionInput
    organizerIds: string[]
}

export interface GetMyOrganizedActivities {
    futureOnly: boolean
    registeredOnly: boolean
}

export interface GetActivityResults extends Skipable {
    organizedActivityId?: string
    userId?: string
}

export interface GetUserRegistrations {
    userId: string
    futureOnly: boolean
}

export interface GetRegisteredPersons {
    organizedActivityId: string
    registrationState?: string
}

export interface CreateRegistration {
    userId: string
    organizedActivityId: string
    merchandise: Merchandise[]
    teamName: string
}

export interface CreateMediaInput {
    ref: string
    readonly type: MediaType
    readonly title: string
}

export interface RemoveOrganizedEventMedia {
    mediaRef: string
    organizerEventId: string
}

export interface CreateEventForm {
    name: LocalizedTextInput[]
    description: LocalizedTextInput[]
    externalId?: string
    labels?: string[]
    place: LocalizedTextInput[]
    organizedEventId: string
    startDate: string
    startTime: string
    timeLimit: number
    registrationLimit: number
    activity: ActivityInput
}

export interface RegisterModalData {
    organizer: Organizer,
    organizedActivity: OrganizedActivity,
    hasCompletedProfile: boolean,
}

export interface OpenOrganizedActivityData {
    organizedActivity: OrganizedActivity,
    summary: string,
    terms: string
}
