<div fxLayout="row wrap" *ngIf="organizer != null">
    <div
        fxFlex.gt-xs="50%"
        fxFlex.gt-sm="50%"
        fxFlex="100"
    >
        <app-organizer
            [organizer]="organizer"
            (updateOrganizer)="updateOrganizer($event)"
        ></app-organizer>
    </div>
    <div
        fxFlex.gt-xs="50%"
        fxFlex.gt-sm="50%"
        fxFlex="100"
    >
        <app-address
            [title]="'address'|translate"
            [subtitle]="'address_desc'|translate"
            [address]="organizer.address"
            (updateAddress)="updateAddress($event)"
        ></app-address>
    </div>
</div>
