import { Component, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { environment } from 'environments/environment'
import { Terms } from 'app/app.consts'
import { WindowService } from 'app/core/services/window.service'
import { isPlatform } from '@ionic/angular'
import { Browser } from '@capacitor/browser'

@Component({
    templateUrl: './terms.component.html',
})
export class TermsComponent {

    text: string = ''

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private windowService: WindowService,
        private route: ActivatedRoute,
    ) {}

    /**
     * If state is set, component shows accept button (case when terms are shown during login)
     */
    state?: String

    ngOnInit() {
        this.windowService.selectedLanguage$.subscribe((language) => {
            this.text = Terms[language.code] ? Terms[language.code] : Terms['cs']
        })
        this.route.queryParams.subscribe((params) => {
            this.state = params['state']
        })
    }

    acceptTerms() {
        let url = 'https://' + environment.auth0.domain + '/continue?state=' + this.state
        if (this.windowService.isNative && isPlatform('ios')) {
            Browser.open({ url, windowName: '_self' })
        } else {
            document.location.href = url
        }
    }
}
