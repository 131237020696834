import { MediaMatcher } from '@angular/cdk/layout'
import { Router } from '@angular/router'
import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core'
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { footerMenuItems } from 'app/layouts/full/footer/footer.menu-items'
import { UserMenuService } from 'app/core/services/user-menu.service'
import { LoginService } from 'app/users/services/login.service'
import { MatSidenav } from '@angular/material/sidenav/sidenav'
import { ApolloStateService } from 'app/core/services/apollo-state.service'
import { OrganizerService } from 'app/organizers/services/organizer.service'
import { OrganizerMenuService } from 'app/organizers/services/organizer-menu.service'
import { WindowService } from 'app/core/services/window.service'
import { DeviceId, DeviceInfo } from '@capacitor/device'
import { debounceTime, fromEvent, Observable, switchMap } from 'rxjs'
import { RoutingHistoryService } from 'app/core/services/routing-history.service'
import { SafeAreaController } from '@aashu-dubey/capacitor-statusbar-safe-area'
import { OrganizedActivitiesStateService } from 'app/events/components/organized-activities/organized-activities-state.service'
import { getPlatforms, isPlatform } from '@ionic/angular'
import { UserTrackerService } from 'app/users/services/user-tracker.service'
import { MatDrawerToggleResult } from '@angular/material/sidenav/drawer'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { LayoutService } from 'app/layouts/services/layout.service'
import { MenuMainItem } from 'app/layouts/layouts.models'

@UntilDestroy()
@Component({
    selector: 'app-full-layout',
    templateUrl: 'full-layout.component.html',
    styleUrls: [],
    providers: [OrganizedActivitiesStateService],
})
export class FullLayoutComponent implements OnDestroy {
    @ViewChild('snav')
    sideNavigation: MatSidenav
    mobileQuery: MediaQueryList
    dir = 'ltr'
    dark = false
    boxed = false
    horizontal = false
    green = false

    blue = false
    danger = false
    showHide = false
    url = ''
    sidebarOpened = false
    status = false
    platforms = getPlatforms()
    minimizedSidebar: boolean
    showSearch = false
    searchVisible = false
    // displayNotch: number = 0
    config: PerfectScrollbarConfigInterface = {}
    // tslint:disable-next-line - Disables all
    private readonly _mobileQueryListener: () => void
    private device: DeviceId & DeviceInfo
    readonly footerMenuItems = footerMenuItems
    resizeEvent$ = fromEvent(window, 'resize').pipe(debounceTime(200))
    get currentYear(): number | null {
        let currentYear = new Date().getFullYear()
        return currentYear > 2022 ? currentYear : null
    }
    mainMenuItems: Observable<MenuMainItem[]>
    get isIos() {
        return this.platforms.includes('ios')
    }

    constructor(
        public router: Router,
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public userMenuService: UserMenuService,
        public loginService: LoginService,
        public apolloStateService: ApolloStateService,
        public organizerService: OrganizerService,
        public organizerMenuService: OrganizerMenuService,
        public userTrackerService: UserTrackerService,
        public windowService: WindowService,
        public routingHistoryService: RoutingHistoryService,
        public layoutService: LayoutService,
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 992px)')
        this._mobileQueryListener = () => changeDetectorRef.detectChanges()
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener)
        // this is for dark theme
        // const body = document.getElementsByTagName('body')[0];
        // body.classList.toggle('dark');
        this.dark = false
        this.minimizedSidebar = this.userMenuService.minimizedSidebar$.value
        if (this.windowService.isNative) {
            this.windowService.device$
                .pipe(
                    switchMap((device) => {
                        this.device = device
                        return this.windowService.screenOrientation$
                    }),
                )
                .subscribe((orientation) => {
                    //TODO better iPhone/device with notch detection and its proper height
                    // if (!orientation && this.deviceInfo.model == 'iPhone') this.displayNotch = 34
                    // if (this.deviceInfo.model == 'iPad') this.displayNotch = 24

                    if (this.device.platform == 'ios') {
                        const sat = getComputedStyle(document.documentElement).getPropertyValue('--sat')
                        SafeAreaController.injectCSSVariables()
                        console.log('SCREEN ORIENTATION', { sat, orientation, deviceInfo: this.device })
                    }
                })
        }
        this.sidebarOpened = this.mobileQuery.matches
        this.resizeEvent$.pipe(untilDestroyed(this)).subscribe((_) => {
            this.sidebarOpened = this.mobileQuery.matches ? true : this.sideNavigation.opened
        })
        this.loginService.isLoggedIn$.pipe(untilDestroyed(this)).subscribe((loggedIn) => {
            if (this.layoutService.menuLoginState !== loggedIn) {
                this.mainMenuItems = this.layoutService.getMainMenuItems$(loggedIn)
                this.layoutService.menuLoginState = loggedIn
                this.layoutService.menuItems = this.mainMenuItems
            } else {
                this.mainMenuItems = this.layoutService.menuItems
            }
        })
    }

    get showBackButton() {
        return (
            isPlatform('ios') &&
            this.windowService.isNative &&
            this.routingHistoryService.canGoBack &&
            (this.routingHistoryService.currentUrl == '/users/tracker' ||
                (this.routingHistoryService.currentUrl.split('/').length > 3 &&
                    !this.routingHistoryService.currentUrl.startsWith('/events/activities/cards') &&
                    !this.routingHistoryService.currentUrl.startsWith('/events/activities/list') &&
                    !this.routingHistoryService.currentUrl.startsWith('/events/activities/map') &&
                    !this.routingHistoryService.currentUrl.startsWith('/events/activities/calendar') &&
                    !this.routingHistoryService.currentUrl.startsWith('/events/activities/results') &&
                    !this.routingHistoryService.currentUrl.startsWith('/events/activities/mine')))
        )
    }

    goBack() {
        // this.routingHistoryService.goBack()
        window.history.back()
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener)
    }

    searchActive(opened: boolean) {
        this.searchVisible = opened
    }

    clickEvent(): void {
        this.status = !this.status
    }

    darkClick() {
        // const body = document.getElementsByTagName('body')[0];
        // this.dark = this.dark;
        const body = document.getElementsByTagName('body')[0]
        body.classList.toggle('dark')
        // if (this.dark)
        // else
        // 	body.classList.remove('dark');
        // this.dark = this.dark;
        // body.classList.toggle('dark');
        // this.dark = this.dark;
    }

    toggleSideNavigation() {
        if (!this.mobileQuery.matches) {
            this.sideNavigation.toggle().then((opened: MatDrawerToggleResult) => {
                this.sidebarOpened = opened === 'open'
            })
        } else {
            this.minimizedSidebar = !this.userMenuService.minimizedSidebar$.value
            this.userMenuService.minimizedSidebar$.next(this.minimizedSidebar)
            this.sidebarOpened = true
        }
    }
}
