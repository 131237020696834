<div fxLayout='row wrap' *ngIf='userActivity' class='user-activity-card' xmlns='http://www.w3.org/1999/html'>
    <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-card [class]="userActivity.isLive ? 'make-me-magic' : ''">
            <!-- header -->
            <mat-card-header class="pt-4 ps-3">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around start">
                    <div fxFlex="1 1 auto">
                        <mat-card-title>
                            <ng-container
                                *ngIf="!canEdit"
                            >
                                {{userActivity.activity.name}}
                            </ng-container>
                            <ng-container
                                *ngIf="canEdit"
                            >
                                <mat-icon>create</mat-icon>
                                <input
                                    type="text"
                                    matInput
                                    [(ngModel)]="userActivity.activity.name"
                                    (keydown.enter)="updateName($event)"
                                    (blur)="updateName($event)"
                                    style="width: 80%"
                                    />
                            </ng-container>
                            <a
                                *ngIf="userActivity.organizedActivity"
                                [routerLink]="['/events/activities', userActivity.organizedActivity.id]"
                            >
                                • {{userActivity.organizedActivity.name | localize}}
                                • {{'ActivityType.' + userActivity.organizedActivity.activity.type | translate}} {{userActivity.organizedActivity.activity.distance}} {{'Unit.KM' | translate}}
                                • {{userActivity.organizedActivity.place | localize}}
                            </a>
                        </mat-card-title>
                    </div>
                    <!-- actions -->
                    <div fxFlex="0 1 auto">
                        <button
                            class="d-inline-block"
                            mat-button
                            [routerLink]=""
                            [disabled]="!(loginService.isLoggedIn$ | async)"
                            (click)="like()">
                            <mat-icon>thumb_up</mat-icon>&nbsp;{{userActivity.likeCount}} {{'likes' | translate | lowercase}}
                        </button>

                        <button
                            class="d-inline-block"
                            mat-button
                            *ngIf="userActivity.connectionType != 'Actigo'"
                            [routerLink]=""
                            (click)="open(this.connectionUrl[userActivity.connectionType.toLowerCase()] + userActivity.connectionId)"
                        >
                            <mat-icon>open_in_new</mat-icon>
                            {{userActivity.connectionType | translate}}
                        </button>
                        <button
                            class="d-inline-block"
                            mat-button
                            [routerLink]=""
                            (click)="windowService.open(this.apiUrl + '/users/activities/' + userActivity.id + '.gpx')"
                            *ngIf="userActivity.activity?.track?.segments?.length"
                        >
                            <mat-icon>satellite</mat-icon>
                            GPX
                        </button>
                        <button
                            *ngIf="windowService.isNative"
                            class="d-inline-block"
                            mat-button
                            (click)="windowService.share(this.appUrl + '/users/activities/' + userActivity.id, userActivity.activity.name)"
                        >
                            <mat-icon>share</mat-icon>
                            {{'share' | translate}}
                        </button>
                    </div>
                </div>
            </mat-card-header>
            <app-person [person]="userActivity.person"></app-person>
            <div class="d-block clear mb-4">
                <div fxLayout="row wrap">
                    <!-- detail -->
                    <div class="ps-4 pe-lg-0 pe-4" fxFlex.gt-sm="100" fxFlex="100">
                        <div fxLayout="row wrap" class="user-activity">
                            <div fxFlex.gt-sm="50" fxFlex.gt-md="50" fxFlex.gt-lg="25" fxFlex="50" class="pb-2">
                                <div class="user-activity_label pb-1">{{'type' | translate}}</div>
                                <div class="user-activity_value">{{'ActivityType.' + userActivity.activity.type | translate}}</div>
                            </div>
                            <div fxFlex.gt-sm="50" fxFlex.gt-md="50" fxFlex.gt-lg="25" fxFlex="50" class="pb-2">
                                <div class="user-activity_label pb-1">{{'distance' | translate}}</div>
                                <div class="user-activity_value">
                                    {{userActivity.distance | number : '1.0-2' }} {{'Unit.KM' | translate}}
                                </div>
                            </div>
                            <div fxFlex.gt-sm="50" fxFlex.gt-md="50" fxFlex.gt-lg="25" fxFlex="50" class="pb-2">
                                <div class="user-activity_label pb-1">{{'startTime' | translate}}</div>
                                <div class="user-activity_value">
                                    {{userActivity.started | localizeDate :'medium'}}
                                </div>
                            </div>
                            <div fxFlex.gt-sm="50" fxFlex.gt-md="50" fxFlex.gt-lg="25" fxFlex="50" class="pb-2">
                                <div class="user-activity_label pb-1">{{'endTime' | translate}}</div>
                                <div class="user-activity_value">
                                    {{userActivity.finished | localizeDate :'medium'}}
                                </div>
                            </div>
                            <div fxFlex.gt-sm="50" fxFlex.gt-md="50" fxFlex.gt-lg="25" fxFlex="50" class="pb-2">
                                <div class="user-activity_label pb-1">{{'elapsedTime' | translate}}</div>
                                <div class="user-activity_value">
                                    {{userActivity.elapsedTime | time : {thousandths: false} }}
                                </div>
                            </div>
                            <div fxFlex.gt-sm="50" fxFlex.gt-md="50" fxFlex.gt-lg="25" fxFlex="50" class="pb-2">
                                <div class="user-activity_label pb-1">{{'movingTime' | translate}}</div>
                                <div class="user-activity_value">
                                    {{userActivity.movingTime | time : {thousandths: false} }}
                                </div>
                            </div>
                            <div fxFlex.gt-sm="50" fxFlex.gt-md="50" fxFlex.gt-lg="25" fxFlex="50" class="pb-2">
                                <div class="user-activity_label pb-1">{{'pace' | translate}}</div>
                                <div class="user-activity_value">{{userActivity.pace | pace}}/{{'Unit.KM' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="userActivity.activity?.track?.segments?.length">
                        <!-- profile -->
                        <div class="ps-4 pe-4" fxFlex.gt-sm="50.00%" fxFlex="100">
                            <div fxLayout="row wrap" class="user-activity">
                                <ng-container *ngFor="let segment of userActivity.activity.track.segments; let segmentIndex = index">
                                    <div
                                        *ngIf="userActivity.activity.track.segments.length > 1"
                                        fxFlex="100"
                                    >
                                        Segment {{segmentIndex}}
                                    </div>
                                    <div
                                        fxFlex.gt-sm="50"
                                        fxFlex.gt-md="50"
                                        fxFlex.gt-lg="50"
                                        fxFlex="50"
                                        class="pb-2"
                                    >
                                        <div class="user-activity_label pb-1">{{'elevationGain' | translate}}</div>
                                        <div class="user-activity_value">
                                            {{segment.profile.elevationGain | number: '1.0-1'}} m
                                        </div>
                                    </div>
                                    <div
                                        fxFlex.gt-sm="50"
                                        fxFlex.gt-md="50"
                                        fxFlex.gt-lg="50"
                                        fxFlex="50"
                                        class="pb-2"
                                    >
                                        <div class="user-activity_label pb-1">{{'elevationLoss' | translate}}</div>
                                        <div class="user-activity_value">
                                            {{segment.profile.elevationLoss | number: '1.0-1'}} m
                                        </div>
                                    </div>
                                    <div
                                        fxFlex.gt-sm="50"
                                        fxFlex.gt-md="50"
                                        fxFlex.gt-lg="50"
                                        fxFlex="50"
                                        class="pb-2"
                                    >
                                        <div class="user-activity_label pb-1">{{'minimumSpeed' | translate}}</div>
                                        <div class="user-activity_value">
                                            {{segment.profile.minimumSpeed >= 1000 ? '-' : (segment.profile.minimumSpeed | number: '1.0-1')}} {{'Unit.KM' |
                                            translate}}/{{'Unit.HH' | translate}}
                                        </div>
                                    </div>
                                    <div
                                        fxFlex.gt-sm="50"
                                        fxFlex.gt-md="50"
                                        fxFlex.gt-lg="50"
                                        fxFlex="50"
                                        class="pb-2"
                                    >
                                        <div class="user-activity_label pb-1">{{'maximumSpeed' | translate}}</div>
                                        <div class="user-activity_value">
                                            {{segment.profile.maximumSpeed <= 0 ? '-' : (segment.profile.maximumSpeed | number: '1.0-1')}} {{'Unit.KM' |
                                            translate}}/{{'Unit.HH' | translate}}
                                        </div>
                                    </div>
                                    <div
                                        fxFlex.gt-sm="100"
                                        fxFlex.gt-md="100"
                                        fxFlex.gt-lg="100"
                                        fxFlex="100"
                                        class="pb-2"
                                    >
                                        <app-track-profile
                                            left="altitude"
                                            [right]="userActivity.activity.type != 'Walk' ? 'speed' : null"
                                            [height]="100"
                                            [legend]="true"
                                            [profile]="segment.profile"
                                        ></app-track-profile>
                                        <app-track-profile
                                            *ngIf="profilePropertyMaxValue(segment.profile, 'hr') > 0"
                                            left="hr"
                                            [right]="profilePropertyMaxValue(segment.profile, 'power') > 0 ? 'power' : null"
                                            [height]="100"
                                            [legend]="true"
                                            [profile]="segment.profile"
                                        ></app-track-profile>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <!-- map -->
                        <div fxFlex.gt-sm="50.00%" fxFlex="100">
                            <app-map
                                *ngIf="userActivity.activity?.track?.segments?.length && userActivity.activity.track.segments[0].points.length"
                                mapHeight="300pt"
                                [fullscreenControl]="true"
                                [activity]="userActivity.activity"
                                [darkMode]="windowService.darkMode$ | async"
                            ></app-map>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- tabs -->
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>announcement</mat-icon>&nbsp;{{'posts'|translate}}
                    </ng-template>
                    <mat-card-content class="messages">
                        <app-messages [entityType]="'UserActivity'" [entityId]="userActivity.id"></app-messages>
                    </mat-card-content>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>thumb_up</mat-icon>&nbsp;{{userActivity.likeCount}} {{'likes' | translate | lowercase}}
                    </ng-template>
                    <mat-card-content class="pt-2 pe-0 pb-0 ps-0">
                        <div fxLayout="row wrap">
                            <app-person
                                *ngFor="let followerPerson of userActivityLikingPersons$ | async"
                                [person]="followerPerson"
                                [showRemoveButton]="false"
                                fxFlex.gt-sm="50"
                                fxFlex.gt-md="33.3"
                                fxFlex.gt-lg="33.3"
                                fxFlex.lt-sm="100"
                                fxFlex.lt-md="50"
                                fxFlex="33.3"
                                class="pb-3"
                            ></app-person>
                        </div>
                    </mat-card-content>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
</div>
