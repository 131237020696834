<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <h2>{{ 'email-not-verified' | translate}}</h2>
                <p>{{'verify-email' |translate}}</p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
