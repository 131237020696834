<mat-card>
    <ng-container *ngIf='registration$ | async as registration'>
        <img
            [src]="this.apiUrl + '/' + registration.code + '.qr.png?invert=' + (windowService.darkMode$ |async) "
            style='width: 100pt; height: 100pt; float: right'
        />
        <mat-card-header class='pt-4 ps-3'>
            <mat-card-title class='registration-title'>
                <a [routerLink]="['/events','activities', registration.organizedActivity.id]">
                    {{registration.organizedActivity.name |localize}}
                </a>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class='pt-0'>
            <small>{{"startTime" | translate}}</small>
            <p>{{registration.organizedActivity.startTime | localizeDate:'short'}}</p>

            <small>{{"registered" | translate}}</small>
            <p>{{registration.created | localizeDate:'short'}}</p>

            <small>{{"code" | translate}}</small>
            <p>{{registration.code}}</p>

            <small>{{"State" | translate}}</small>
            <p>{{"RegistrationState." + registration.state | translate}}</p>

            <small>{{"startNumber" | translate}}</small>
            <p>{{registration.startNumber ? registration.startNumber : '-'}}</p>

            <small>{{"team" | translate}}</small>
            <p>{{registration.teamName ? registration.teamName : '-'}}</p>

            <ng-container *ngIf="registration.organizedActivity.merchandisePriceList?.length > 0">
                <small>{{"merchandise" | translate}}</small>
                <ul>
                    <li *ngFor="let merchandise of registration.merchandise">
                        {{merchandise.quantity}}x
                        {{findMerchandisePrice(registration.organizedActivity.merchandisePriceList, merchandise.sku).description | localize}}
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf='payment$| async as payment'>
                <h3>{{"payment" | translate}}</h3>
                <small>{{"amount" | translate}}</small>
                <p>{{payment.amount | amount}}</p>
                <small>{{"payments.state" | translate}}</small>
                <div [ngSwitch]='payment.state'>
                    <div *ngSwitchCase="'Paid'">
                        <h4>{{'payments.successful'|translate}}</h4>
                    </div>
                    <div *ngSwitchCase="'Created'">
                        <h3>{{'payments.not_finished' | translate}}</h3>
                        <a mat-raised-button color='accent' [href]='(paymentStatus$ |async).redirectUrl'
                        >{{'payments.finish' | translate}}</a
                        >
                    </div>
                    <div *ngSwitchDefault>
                        <h3>{{'PaymentState.' + payment.state | translate}}</h3>
                    </div>
                </div>
            </ng-container>
            <div class='text-right'>
                <!--<button mat-raised-button color="accent" [disabled]="true">{{'transfer' | translate}}</button>&nbsp;-->
                <button mat-raised-button color='accent' *ngIf='cancelable$|async' (click)='cancel()'>
                    {{'cancel' | translate}}
                </button>
            </div>
        </mat-card-content>
    </ng-container>
</mat-card>
