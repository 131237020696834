<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-card>
            <!-- header -->
            <mat-card-header class="pt-4 ps-3">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around start">
                    <div fxFlex="1 1 auto">
                        <mat-card-title>
                            <!--{{organizedActivity.organizedEvent.name | localize}} • -->
                            {{organizedActivity.name | localize}}
                        </mat-card-title>
                        <h3 class="mb-0">
                            {{ ('ActivityType.' + organizedActivity.activity.type) | translate }} {{
                            organizedActivity.activity.distance }} {{'Unit.KM' | translate}} • {{
                            organizedActivity.place | localize }} • {{ organizedActivity.startTime | localizeDate:
                            "short" }}<ng-container *ngIf="organizedActivity.timeLimit">
                                • {{'limit' | translate}} {{organizedActivity.timeLimit / 60}} {{'Unit.HH' |
                                translate}}</ng-container
                            >
                        </h3>
                    </div>
                    <!-- actions -->
                    <div fxFlex="0 1 auto">
                        <button
                            *ngFor="let link of organizedActivity.links"
                            class="d-inline-block"
                            mat-button
                            [routerLink]=""
                            (click)="windowService.open(link.ref)"
                        >
                            <mat-icon>open_in_browser</mat-icon>
                            {{link.type.toLowerCase() | translate | titlecase}}
                        </button>
                        <button
                            class="d-inline-block"
                            mat-button
                            [routerLink]=""
                            (click)="windowService.open(this.apiUrl + '.ics')"
                        >
                            <mat-icon>event</mat-icon>
                            {{'calendar'|translate}}
                        </button>
                        <button
                            class="d-inline-block"
                            mat-button
                            [routerLink]=""
                            (click)="windowService.open(this.apiUrl + '.gpx')"
                            *ngIf="organizedActivity.activity?.track?.segments?.length"
                        >
                            <mat-icon>satellite</mat-icon>
                            GPX&nbsp;{{'file' | translate}}
                        </button>
                        <button
                            class="d-inline-block"
                            mat-button
                            [routerLink]=""
                            (click)="exportToGarmin()"
                            *ngIf="organizedActivity.activity?.track?.segments?.length && hasGarminConnection"
                        >
                            <mat-icon>watch</mat-icon>
                            Garmin&nbsp;Connect
                        </button>
                        <button
                            *ngIf="windowService.isNative"
                            class="d-inline-block"
                            mat-button
                            (click)="windowService.share(this.appUrl, organizedActivity.name[0].text)"
                        >
                            <mat-icon>share</mat-icon>
                            {{'share' | translate}}
                        </button>
                        <button
                            class="d-inline-block"
                            mat-button
                            (click)="feedback()"
                            [disabled]="!loginService.isLoggedIn"
                        >
                            <mat-icon>report</mat-icon>
                            {{'report' | translate}}
                        </button>
                        <button
                            *ngIf="userOrganizerRole"
                            class="d-inline-block"
                            mat-button
                            [routerLink]="'/events/' + organizedActivity.organizedEvent.id + '/activity'"
                        >
                            <mat-icon>edit</mat-icon>
                            {{'manage' | translate}}
                        </button>
                    </div>
                </div>
            </mat-card-header>

            <div class="d-block clear mb-4">
                <div fxLayout="row wrap">
                    <div class="ps-3 pe-lg-0 pe-3" fxFlex.gt-sm="50.00%" fxFlex="100">

                        <!-- QR code -->
                        <!--                        <a (click)="windowService.open(this.apiUrl + '.qr.png?invert=' + darkMode)" style="cursor: pointer"-->
                        <!--                        ><img-->
                        <!--                            [src]="this.apiUrl + '.qr.png?invert=' + darkMode"-->
                        <!--                            style="width: 80pt; height: 80pt; float: right"-->
                        <!--                        /></a>-->

                        <!-- labels -->
                        <p *ngIf="activityLabels$ | async as activityLabels">
                            <mat-chip-list class="activity-labels">
                                <mat-chip *ngFor="let label of organizedActivity.labels" class="activity-labels__chip">
                                    {{label | localizeActivityLabel : activityLabels : (selectedLanguage$ | async).code}}
                                </mat-chip>
                            </mat-chip-list>
                        </p>
                        <p [innerHTML]="organizedActivity.description | localize | marked"></p>
                        <ng-container *ngIf="organizedActivityWeatherForecast">
                            <h3>{{'weather_forecast' | translate}}</h3>
                            <img [src]="(organizedActivityWeatherForecast.condition.icon.startsWith('//') ? 'https:' : '') + organizedActivityWeatherForecast.condition.icon" align="left">
                            {{organizedActivityWeatherForecast.condition.text}}<br>
                            {{'temperature' | translate}} {{organizedActivityWeatherForecast.tempC}}°C,
                            {{'precipitation_probability' | translate}} {{organizedActivityWeatherForecast.chanceOfSnow > organizedActivityWeatherForecast.chanceOfRain ? organizedActivityWeatherForecast.chanceOfSnow : organizedActivityWeatherForecast.chanceOfRain}}%<br>
                            {{'max_wind' | translate}} {{organizedActivityWeatherForecast.maxWindKPH}}km/h, UV index {{organizedActivityWeatherForecast.uv}}
                            <br clear="left"><br>
                        </ng-container>

                        <!-- organizer -->
                        <div class="d-block clear mb-4" *ngIf="(organizer$ | async) as organizer">
                            <h3>{{'organized_by' | translate}} <a [routerLink]="['/', 'organizers', organizer.id]">{{organizer.name}}</a></h3>
                            <!--<p *ngIf="organizer.description">{{organizer.description}}</p>-->
                            <button
                                *ngIf="organizer.email"
                                mat-button
                                [routerLink]=""
                                (click)="windowService.open('mailto:' + organizer.email)"
                                >
                                <mat-icon>email</mat-icon>
                                {{organizer.email}}
                            </button>
                            <button
                                *ngIf="organizer.phoneNumber && organizer.phoneNumber != '+0'"
                                mat-button
                                [routerLink]=""
                                (click)="windowService.open('tel:' + organizer.phoneNumber)"
                                >
                                <mat-icon>phone</mat-icon>
                                {{organizer.phoneNumber}}
                            </button>
                            <button
                                *ngFor="let link of organizer.links"
                                mat-button
                                [routerLink]=""
                                (click)="windowService.open(link.ref)"
                            >
                                <mat-icon>open_in_browser</mat-icon>
                                {{link.type.toLowerCase() | translate | titlecase}}
                            </button>
                        </div>

                        <!-- followers -->
                        <small>
                            <b>{{ organizedActivity.followCount }}</b> {{'activity_followers'| translate}},
                            <b>{{ organizedActivityFollowerPersons.length }}</b> {{'activity_known' | translate}}
                            <ng-container *ngIf="organizedActivityFollowerPersons.length > 0">
                                (<ng-container *ngFor="let person of organizedActivityFollowerPersons.slice(0, 3); index as i"
                            >{{ person.givenName + ' ' + person.surname }}<ng-container
                                *ngIf="i < 2 && i < organizedActivityFollowerPersons.length - 1">, </ng-container
                            ></ng-container><ng-container *ngIf="organizedActivityFollowerPersons.length > 3">...</ng-container>)
                            </ng-container>
                        </small>
                        <div>
                            <a
                                *ngFor="let person of organizedActivityFollowerPersons"
                                [routerLink]="'/' + (person.nickname ? person.nickname : person.id)"
                                [title]="person.givenName + ' ' + person.surname"
                            >
                                <div mat-card-avatar class="avatar-in-list">
                                    <img
                                        [src]="config.apiUrl + '/users/' + person.id + '.pic?time=' + time"
                                        [alt]="person.givenName + ' ' + person.surname"
                                        style="width: 40px; height: 40px"
                                        class="img-fluid img-circle" />
                                </div>
                            </a>
                        </div>
                        <div class="d-block clear mb-4">
                            <app-follow-button [organizedActivity]="organizedActivity"></app-follow-button>
                        </div>

                        <!-- registrants -->
                        <small
                            *ngIf="organizedActivity.registrationLimit"
                        >
                            <b>{{ organizedActivity.registrationCount }}<!-- / {{ organizedActivity.registrationLimit }}--></b> {{'activity_registered' | translate}},
                            <b>{{ organizedActivityRegisteredPersons.length }}</b> {{'activity_known' | translate}}
                            <ng-container *ngIf="organizedActivityRegisteredPersons.length > 0">
                                (<ng-container *ngFor="let person of organizedActivityRegisteredPersons.slice(0, 3); index as i"
                            >{{ person.givenName + ' ' + person.surname }}<ng-container
                                *ngIf="i < 2 && i < organizedActivityRegisteredPersons.length - 1">, </ng-container
                            ></ng-container><ng-container *ngIf="organizedActivityRegisteredPersons.length > 3">...</ng-container>)
                            </ng-container>
                        </small>
                        <div>
                            <a
                                *ngFor="let person of organizedActivityRegisteredPersons"
                                [routerLink]="'/' + (person.nickname ? person.nickname : person.id)"
                                [title]="person.givenName + ' ' + person.surname"
                            >
                                <div mat-card-avatar class="avatar-in-list">
                                    <img
                                        [src]="config.apiUrl + '/users/' + person.id + '.pic?time=' + time"
                                        [alt]="person.givenName + ' ' + person.surname"
                                        style="width: 40px; height: 40px"
                                        class="img-fluid img-circle"/>
                                </div>
                            </a>
                        </div>
                        <div class="d-block clear mb-4">
                            <app-register-button
                                *ngIf="organizedActivity.isOpen"
                                [organizedActivity]="organizedActivity"
                                [organizer]="organizer$ | async"
                            ></app-register-button>
                        </div>
                    </div>
                    <!-- carousel -->
                    <div fxFlex.gt-sm="50.00%" fxFlex="100">
                        <app-carousel
                            [pictures]="$any(organizedActivity.organizedEvent) | eventPictureUrl"
                            [type]="CarouselTypes.gallery"
                            [fullscreen]="false"
                            [autoPlay]="false"
                        ></app-carousel>
                    </div>
                    <!-- map -->
                    <div fxFlex.gt-sm="50.00%" fxFlex="100">
                        <app-map
                            mapHeight="200pt"
                            class="rounded_bottom"
                            [fullscreenControl]="true"
                            [inputMarker]="organizedActivity.position"
                            [inputMarkerAllowed]="true"
                            [activity]="organizedActivity.activity"
                            [darkMode]="darkMode"
                            [showLegendForTracks]="true"
                            [inputMarkerRemovingAllowed]="false"
                            [inputMarkerMovingAllowed]="false"
                        >
                        </app-map>
                    </div>
                    <!-- profile -->
                    <div fxFlex.gt-sm="50.00%" fxFlex="100">
                        <div fxLayout="row wrap" class="user-activity" *ngIf="organizedActivity.activity.track" style="padding-left: 5pt">
                            <ng-container *ngFor="let segment of organizedActivity.activity.track.segments">
                                <div
                                    fxFlex.gt-sm="100"
                                    fxFlex.gt-md="33.3"
                                    fxFlex.gt-lg="33.3"
                                    fxFlex="33.3"
                                    class="pb-3 pe-2"
                                >
                                    <div class="user-activity_label pb-1">{{'elevationGain' | translate}}</div>
                                    <div class="user-activity_value">
                                        {{segment.profile?.elevationGain | number: '1.0-1'}} M
                                    </div>
                                </div>
                                <div
                                    fxFlex.gt-sm="100"
                                    fxFlex.gt-md="33.3"
                                    fxFlex.gt-lg="33.3"
                                    fxFlex="33.3"
                                    class="pb-3 pe-2"
                                >
                                    <div class="user-activity_label pb-1">{{'elevationLoss' | translate}}</div>
                                    <div class="user-activity_value">
                                        {{segment.profile?.elevationLoss | number: '1.0-1'}} M
                                    </div>
                                </div>
                                <div
                                    fxFlex.gt-sm="100"
                                    fxFlex.gt-md="33.3"
                                    fxFlex.gt-lg="33.3"
                                    fxFlex="33.3"
                                    class="pb-3 pe-2"
                                >
                                    <div class="user-activity_label pb-1">{{'elevation' | translate}}</div>
                                    <div class="user-activity_value">
                                        {{segment.profile?.elevationGain + segment.profile?.elevationLoss | number:
                                        '1.0-1'}} M
                                    </div>
                                </div>
                                <div
                                    fxFlex.gt-sm="100"
                                    fxFlex.gt-md="100"
                                    fxFlex.gt-lg="100"
                                    fxFlex="100"
                                    class="pb-3 pe-2"
                                >
                                    <app-track-profile
                                        [legend]="false"
                                        [height]="80"
                                        [profile]="segment.profile"
                                    ></app-track-profile>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!-- description -->
                    <div fxFlex.gt-sm="100.00%" fxFlex="100" class="form-element ps-3 pt-3"
                         [innerHTML]="organizedActivity.organizedEvent.description | localize | marked"
                    >
                    </div>
                </div>
            </div>
            <!-- tabs -->
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>announcement</mat-icon>&nbsp;{{'posts'|translate}}
                    </ng-template>
                    <mat-card-content class="messages">
                        <app-messages [entityType]="'OrganizedEvent'" [entityId]="organizedActivity.organizedEvent.id"></app-messages>
                    </mat-card-content>
                </mat-tab>
                <mat-tab *ngIf="organizedActivity.priceList.length">
                    <ng-template mat-tab-label>
                        <mat-icon>attach_money</mat-icon>&nbsp;{{'priceList'|translate}}
                    </ng-template>
                    <mat-card-content>
                        <div class="responsive-table">
                            <h3>{{'registration' | translate}}</h3>
                            <mat-table #table [dataSource]="organizedActivity.priceList">
                                <ng-container matColumnDef="amount">
                                    <mat-header-cell *matHeaderCellDef>{{"amount"|translate}}</mat-header-cell>
                                    <mat-cell *matCellDef="let element"
                                              [attr.data-label]="'amount'|translate"
                                              [style]="'opacity:' + (element.validTo > nowISO && element.validUntilRegistrationCount > organizedActivity.registrationCount ? '1.0' : '0.5')">
                                        <ng-container *ngIf="element.amount.value > 0">
                                            {{element.amount |amount}}
                                        </ng-container>
                                        <ng-container *ngIf="element.amount.value == 0">
                                            {{ 'for_free'| translate }}
                                        </ng-container>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="validTo">
                                    <mat-header-cell *matHeaderCellDef>{{"validTo"|translate}}</mat-header-cell>
                                    <mat-cell *matCellDef="let element"
                                              [attr.data-label]="'validTo'|translate"
                                              [style]="'opacity:' + (element.validTo > nowISO && element.validUntilRegistrationCount > organizedActivity.registrationCount ? '1.0' : '0.5')"
                                    >{{element.validTo | localizeDate:'mediumDate'}}<!-- short including time temporarily removed --></mat-cell
                                    >
                                </ng-container>
                                <ng-container matColumnDef="validUntilRegistrationCount">
                                    <mat-header-cell *matHeaderCellDef class="justify-content-end"
                                    >{{"validFor"|translate}}</mat-header-cell
                                    >
                                    <mat-cell
                                        *matCellDef="let element; let i = index"
                                        [attr.data-label]="'validFor'|translate"
                                        [style]="'opacity:' + (element.validTo > nowISO && element.validUntilRegistrationCount > organizedActivity.registrationCount ? '1.0' : '0.5')"
                                        class="justify-content-end"
                                    >{{((i > 0 ? organizedActivity.priceList[i - 1].validUntilRegistrationCount : 0) + 1) +
                                    (i > 0 && organizedActivity.priceList[i - 1].validUntilRegistrationCount > organizedActivity.registrationCount ?
                                            '(' + (organizedActivity.registrationCount + 1) + ')' : ''
                                    )
                                        }}. - {{element.validUntilRegistrationCount}}.
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="priceListColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: priceListColumns"></mat-row>
                            </mat-table>
                        </div>

                        <div class="responsive-table" *ngIf="organizedActivity.merchandisePriceList.length">
                            <br/>
                            <h3>{{'merchandise' | translate}}</h3>
                            <mat-table #table [dataSource]="organizedActivity.merchandisePriceList">
                                <ng-container matColumnDef="description">
                                    <mat-header-cell *matHeaderCellDef>{{"description"|translate}}</mat-header-cell>
                                    <mat-cell *matCellDef="let element"
                                              [attr.data-label]="'description'|translate"
                                              [style]="'opacity:' + (element.validTo > nowISO ? '1.0' : '0.5')">
                                        {{element.description | localize}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="amount">
                                    <mat-header-cell *matHeaderCellDef>{{"amount"|translate}}</mat-header-cell>
                                    <mat-cell *matCellDef="let element"
                                              [attr.data-label]="'amount'|translate"
                                              [style]="'opacity:' + (element.validTo > nowISO ? '1.0' : '0.5')">
                                        <ng-container *ngIf="element.amount.value > 0">
                                            {{element.amount |amount}}
                                        </ng-container>
                                        <ng-container *ngIf="element.amount.value == 0">
                                            {{ 'for_free'| translate }}
                                        </ng-container>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="validTo">
                                    <mat-header-cell *matHeaderCellDef>{{"validTo"|translate}}</mat-header-cell>
                                    <mat-cell *matCellDef="let element"
                                              [attr.data-label]="'validTo'|translate"
                                              [style]="'opacity:' + (element.validTo > nowISO ? '1.0' : '0.5')"
                                    >{{element.validTo | localizeDate:'mediumDate'}}<!-- short including time temporarily removed --></mat-cell
                                    >
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="merchandisePriceListColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: merchandisePriceListColumns"></mat-row>
                            </mat-table>
                        </div>
                    </mat-card-content>
                </mat-tab>
                <mat-tab *ngIf="organizedActivity.isCompleted">
                    <ng-template mat-tab-label>
                        <mat-icon>timer</mat-icon>&nbsp;{{'results' | translate}} ({{(activityResultCount$ | async) || '-'}})
                    </ng-template>
                    <div class="scrollable-table">
                        <mat-table #table [dataSource]="activityResults$ | async">
                            <ng-container matColumnDef="person">
                                <mat-header-cell *matHeaderCellDef>{{'person' | translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element" [attr.data-label]="'person'|translate">
                                    <app-person [person]="element.person"></app-person
                                    ></mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="startNumber">
                                <mat-header-cell *matHeaderCellDef class="justify-content-end"> {{'startNumber' | translate}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" [attr.data-label]="'startNumber' | translate" class="justify-content-end"> {{element.startNumber}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="time">
                                <mat-header-cell *matHeaderCellDef class="justify-content-end">{{'time' | translate}} </mat-header-cell>
                                <mat-cell  *matCellDef="let element" [attr.data-label]="'time' | translate" class="justify-content-end"> {{element.time | time}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="category">
                                <mat-header-cell *matHeaderCellDef> {{'category' | translate}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" [attr.data-label]="'category' | translate">{{element.category}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="categoryPosition" class="justify-content-end">
                                <mat-header-cell *matHeaderCellDef> {{'categoryPosition' | translate}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" [attr.data-label]="'categoryPosition' | translate" class="justify-content-end">
                                    <ng-container *ngIf="element.position > 0 && element.position < 100000">{{element.position}} / {{element.positionBySex}} / {{element.positionByCategory}}</ng-container>
                                    <ng-container *ngIf="element.position == 0">{{'unofficial' | translate | uppercase}}</ng-container>
                                    <ng-container *ngIf="element.position == 100000">DNF</ng-container>
                                    <ng-container *ngIf="element.position == 100001">DNS</ng-container>
                                    <ng-container *ngIf="element.position == 100002">DSQ</ng-container>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="activityResultColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: activityResultColumns"></mat-row>
                            <p *matNoDataRow class="no-data">{{'noData' | translate}}</p>
                        </mat-table>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>done_all</mat-icon>&nbsp;{{'startList' | translate}} ({{(startingPersonCount$ | async) || '-'}})
                    </ng-template>
                    <div class="scrollable-table">
                        <mat-table #table matSort (matSortChange)="sortRegisteredPersons($event)" [dataSource]="registeredPersons$ | async">
<!--                            <ng-container matColumnDef="num">-->
<!--                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'num' | translate}}</mat-header-cell>-->
<!--                                <mat-cell *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>-->
<!--                            </ng-container>-->
                            <ng-container matColumnDef="person">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{'person' | translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element" [attr.data-label]="'person' | translate">
                                    <app-person [person]="element.person"></app-person>
                                </mat-cell>
                            </ng-container>
<!--                            <ng-container matColumnDef="state">-->
<!--                                <mat-header-cell *matHeaderCellDef>{{"registration_state" |translate}}</mat-header-cell>-->
<!--                                <mat-cell *matCellDef="let element" [attr.data-label]="'registration_state' | translate">-->
<!--                                    {{"RegistrationState." + element.state | translate}}-->
<!--                                </mat-cell>-->
<!--                            </ng-container>-->
                            <ng-container matColumnDef="startNumber">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{"startNumber" |translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element" [attr.data-label]="'startNumber'| translate">
                                    {{element.startNumber ? element.startNumber : '-'}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="teamName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{"team" |translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element" [attr.data-label]="'team'| translate">
                                    {{element.teamName ? element.teamName : '-'}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="created">
                                <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{"registered" |translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let element" [attr.data-label]="'registered'| translate">
                                    {{element.created | localizeDate: 'short'}}
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="startListColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: startListColumns"></mat-row>
                            <p *matNoDataRow class="no-data">{{'noData'|translate}}</p>
                        </mat-table>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>people</mat-icon>&nbsp;{{'followers' | translate}} ({{organizedActivityAllFollowerPersons.length || '-'}})
                    </ng-template>
                    <mat-card-content class="pt-2 pe-0 pb-0 ps-0">
                        <div fxLayout="row wrap">
                            <app-person
                                *ngFor="let followerPerson of organizedActivityAllFollowerPersons"
                                [person]="followerPerson"
                                [showRemoveButton]="false"
                                fxFlex.gt-sm="50"
                                fxFlex.gt-md="33.3"
                                fxFlex.gt-lg="33.3"
                                fxFlex.lt-sm="100"
                                fxFlex.lt-md="50"
                                fxFlex="33.3"
                                class="pb-3"
                            ></app-person>
                        </div>
                    </mat-card-content>
                </mat-tab>
                <mat-tab
                    *ngIf="carpooling && organizedActivity.isOpen"
                >
                    <ng-template mat-tab-label>
                        <mat-icon>directions_car</mat-icon>&nbsp;{{'carpool' | translate}}
                    </ng-template>
                    <mat-card-content>
                        <app-organized-activity-carpool
                            [organizedActivity]="organizedActivity"
                        ></app-organized-activity-carpool>
                    </mat-card-content>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
</div>
