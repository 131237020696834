import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'
import { OrganizedActivity, Track } from 'app/events/events.models'

@Pipe({
    name: 'trackFromActivities',
})
export class TrackFromActivitiesPipe implements PipeTransform {
    transform(organizedActivities: OrganizedActivity[]): Track {
        const segments = R.pipe(R.map(R.path(['activity', 'track', 'segments'])), R.flatten)(organizedActivities)
        const track = organizedActivities[0].activity.track
        track.segments = segments
        return track
    }
}
