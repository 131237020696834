import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Address } from 'app/core/core.models'
import { countries } from 'app/core/constants/countries'

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit {
    formGroup: FormGroup
    countries = countries

    constructor(public fb: FormBuilder) {
        this.formGroup = this.fb.group({
            label: '',
            street: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: '',
            country: ['', [Validators.required]],
            postalCode: ['', [Validators.required]],
        })
    }

    ngOnInit(): void {
        this.formGroup.patchValue(this.address)
    }

    @Input()
    title: string

    @Input()
    subtitle: string

    @Input()
    address: Address

    @Output()
    updateAddress = new EventEmitter<Address>()

    submitForm(address: Address): void {
        this.updateAddress.emit(address)
    }
}
