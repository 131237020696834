import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ChipsRadioInputComponent } from './chips-radio-input.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatChipsModule } from '@angular/material/chips'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
    declarations: [ChipsRadioInputComponent],
    imports: [CommonModule, MatFormFieldModule, MatChipsModule, ReactiveFormsModule, TranslateModule],
    providers: [],
    exports: [ChipsRadioInputComponent],
})
export class ChipsRadioInputModule {}
