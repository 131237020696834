<ng-container *ngIf="isSetup">
    <b>Prosím zkontroluj a případně doplň údaje o sobě. Pokud se budeš registrovat na závody, tak je musíme předat pořadateli. Zároveň slouží ke spárování tvých výsledků z dřívějších závodů.</b>
    <br/><br/>
</ng-container>
<ng-container *ngIf="!isSetup">
    <mat-card-title>{{'profile'|translate}}</mat-card-title>
    <mat-card-subtitle>{{'profile_desc'|translate}}</mat-card-subtitle>
</ng-container>
<form [formGroup]="formGroup" (ngSubmit)="submitForm(formGroup.value)" class="form">
    <mat-form-field class="form-element">
        <input matInput [placeholder]="'givenName' | translate" formControlName="givenName" />
        <mat-error *ngIf="!formGroup.controls['givenName'].valid && formGroup.controls['givenName'].touched">
            {{ 'missing' | translate }} {{ 'givenName' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="form-element">
        <input matInput [placeholder]="'surname' | translate" formControlName="surname" />
        <mat-error *ngIf="!formGroup.controls['surname'].valid && formGroup.controls['surname'].touched">
            {{ 'missing' | translate }} {{ 'surname' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-select [placeholder]="'sex' | translate" formControlName="sex">
            <mat-option value="None">{{ 'none' | translate }}</mat-option>
            <mat-option value="Male">{{ 'Male' | translate }}</mat-option>
            <mat-option value="Female">{{ 'Female' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <input
            matInput
            [matDatepicker]="picker"
            [placeholder]="'birthDate' | translate"
            formControlName="birthDate"
        />
        <mat-error *ngIf="!formGroup.controls['birthDate'].valid && formGroup.controls['birthDate'].touched">
            {{ 'not_in_past' | translate }}
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-select
            [placeholder]="'language' | translate"
            (selectionChange)="changeLanguage($event)"
            formControlName="language"
        >
            <mat-option value="cs">{{ 'cs' | translate }}</mat-option>
            <mat-option value="en">{{ 'en' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <textarea matInput formControlName="description" [placeholder]="'description' | translate"></textarea>
    </mat-form-field>
    <mat-form-field>
        <input
            type="text"
            [placeholder]="'team' | translate"
            formControlName="teamName"
            matInput
            [matAutocomplete]="auto"
        />
        <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayTeam"
        >
            <mat-option *ngFor="let option of teamOptions$ | async" [value]="option">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <ng-container *ngIf="isSetup">
        <b>Nejsme seznamka, ale parťáka z okolí na společný trénink nebo cestu na vzdálenější závody tady najít můžeš.</b>
        <p>Je tvůj profil na Actigo veřejný? Osobní údaje jako telefon, email, adresa či datum narození samozřejmě nikomu nezpřístupníme. Tyto údaje uchováváme pouze pro potřeby registrace na závody. Ale pokud je tvůj profil veřejný, tak tě mohou ostatní uživatelé Actigo dohledat, uvidí tvé aktivity, sledování, výsledky atd.</p>
    </ng-container>

    <div class="mb-2">
        <mat-checkbox formControlName="isPublic">{{'profile'|translate}} {{'isPublic' | translate | lowercase}}</mat-checkbox>
    </div>
    <div class="mb-2">
        <mat-checkbox formControlName="shareActivitiesWithFollowers">{{'shareActivitiesWithFollowers' | translate}}</mat-checkbox>
    </div>
    <div class="mb-2">
        <mat-checkbox formControlName="allowMatchmaking">{{'allowMatchmaking' | translate}}</mat-checkbox>
    </div>
    <div class="mb-2">
        <mat-checkbox formControlName="sameSexOnly">{{'sameSexOnly' | translate}}</mat-checkbox>
    </div>
    <mat-form-field>
        <mat-select [placeholder]="'maxDistance' | translate" formControlName="maxDistance">
            <!--TODO options should be moved to same constant to be shared with organized-activities component -->
            <mat-option>-</mat-option>
            <mat-option [value]="5">&lt; 5 {{'Unit.KM' | translate}}</mat-option>
            <mat-option [value]="10">&lt; 10 {{'Unit.KM' | translate}}</mat-option>
            <mat-option [value]="20">&lt; 20 {{'Unit.KM' | translate}}</mat-option>
            <mat-option [value]="30">&lt; 30 {{'Unit.KM' | translate}}</mat-option>
            <mat-option [value]="50">&lt; 50 {{'Unit.KM' | translate}}</mat-option>
            <mat-option [value]="100">&lt; 100 {{'Unit.KM' | translate}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'runningMonthly'|translate}}</mat-label>
        <input formControlName="runningMonthly" matInput />
        <span matSuffix>{{'Unit.KM' | translate}}</span>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'runningShoeCount'|translate}}</mat-label>
        <input formControlName="runningShoeCount" matInput />
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'runningPB'|translate}}</mat-label>
        <div fxFlex="70">
            <input [formControl]="$any(formGroup.get('runningPB').get('time'))" placeholder="HH:MM:SS" matInput />
        </div>
        <div fxFlex="30">
            <mat-select [formControl]="$any(formGroup.get('runningPB').get('distance'))">
                <mat-option [value]="10.0">{{'DistanceRange.10K'|translate}}</mat-option>
                <mat-option [value]="21.0975">{{'DistanceRange.HalfMarathon'|translate}}</mat-option>
                <mat-option [value]="42.195">{{'DistanceRange.Marathon'|translate}}</mat-option>
            </mat-select>
        </div>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'registrationPriceLimit'|translate}}</mat-label>
        <div fxLayout="row wrap">
            <div fxFlex="70">
                <input matInput [formControl]="$any(formGroup.get('registrationPriceLimit').get('value'))" />
            </div>
            <div fxFlex="30">
                <mat-select [formControl]="$any(formGroup.get('registrationPriceLimit').get('currency'))">
                    <mat-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</mat-option>
                </mat-select>
            </div>
        </div>
    </mat-form-field>
    <small>{{'preferred' | translate}} {{'activityTypes' | translate | lowercase}}</small>
    <p>
        <app-chips-radio-input
            [control]="$any(formGroup.get('activityTypes'))"
            [options]="activityTypes$ | async | arrayOfValuesToOptions | optionsTranslate: null: 'ActivityType.': false"
        >
        </app-chips-radio-input>
    </p>
    <small>{{'preferred' | translate}} {{'activityLabels' | translate | lowercase}}</small>
    <p>
        <app-chips-radio-input
            [control]="$any(formGroup.get('activityLabels'))"
            [options]="activityLabels$ | async | localizedEnumOptions : (selectedLanguage$ | async).code"
        >
        </app-chips-radio-input>
    </p>


    <!--            <mat-form-field class="form-element">-->
    <!--                <input matInput [placeholder]="'homeLocation' | translate" formControlName="homeLocation" />-->
    <!--                <mat-error-->
    <!--                    *ngIf="!formGroup.controls['homeLocation'].valid && formGroup.controls['homeLocation'].touched"-->
    <!--                >-->
    <!--                    {{ 'missing' | translate }} {{ 'homeLocation' | translate }}-->
    <!--                </mat-error>-->
    <!--            </mat-form-field>-->
<!--            <app-map-->
<!--                mapHeight="150pt"-->
<!--                [inputMarkerAllowed]="true"-->
<!--                (inputMarkerAction)="updatePosition($event)"-->
<!--                [inputMarker]="formGroup.controls['homeLocation'].value"-->
<!--                [darkMode]="windowService.darkMode$ | async"-->
<!--                class="mb-3 d-block"-->
<!--            ></app-map>-->
    <div class="form-element text-right">
        <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!formGroup.valid">
            {{ 'save' | translate }}
        </button>
    </div>
</form>

