<mat-card>
    <mat-card-content>
        <mat-card-title>{{'postRules' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'postRules_desc' | translate}}</mat-card-subtitle>
        <form class="form" [formGroup]="formGroup">
            <ng-container formArrayName="items">
                <div *ngFor="let item of items.controls; let i = index">
                    <div fxLayout="row wrap" [formGroupName]="i">
                        <div fxFlex="50">
                            <ng-container *ngIf="item.value['type']">{{'Post.' + item.value['type'] | translate}}</ng-container>
                            <mat-form-field *ngIf="!item.value['type']" class="form-element">
                                <mat-select
                                    formControlName="type"
                                    placeholder="{{'Post.type' | translate}}"
                                >
                                    <mat-option *ngFor="let postType of postTypes$ | async" [value]="postType">
                                        {{'Post.' + postType | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="10">
                            <mat-checkbox formControlName="isEnabled"></mat-checkbox>
                        </div>
                        <div fxFlex="30">
                            <mat-form-field class="form-element">
                                <mat-select
                                    formControlName="notificationPriority"
                                    placeholder="{{'Priority.notification' | translate}}"
                                    [disabled]="!item.value['isEnabled']"
                                >
                                    <mat-option *ngFor="let priority of priorities$ | async" [value]="priority">
                                        {{'Priority.' + priority | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="10">
                            <button mat-icon-button color="warn" (click)="delete(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div fxLayout="row wrap">
                <div fxFlex="50" class="form-element">
                    <button mat-mini-fab (click)="add()">
                        <mat-icon class="add-course-btn">add</mat-icon>
                    </button>
                </div>

                <div fxFlex="50" class="form-element text-right">
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        class="button"
                        (click)="save()"
                    >
                        {{ 'save' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>
