import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { sec2pace, sec2time, str2sec } from '../../core.functions'

interface PaceCalculation {
    distance?: number
    time?: string
    pace?: string
    speed?: string
}

interface DistanceOption {
    distance: number
    label: string
}

@Component({
    selector: 'app-pace-calculator',
    templateUrl: './pace-calculator.component.html',
})
export class PaceCalculatorComponent implements OnInit {
    formGroup: FormGroup
    distanceOptions: DistanceOption[] = [
        { distance: 5, label: '5K' },
        { distance: 10, label: '10K' },
        { distance: 15, label: '15K' },
        { distance: 20, label: '20K' },
        { distance: 21.0975, label: '1/2 Marathon' },
        { distance: 42.195, label: 'Marathon' },
        { distance: 50, label: '50K' },
        { distance: 100, label: '100K' },
        { distance: 150, label: '150K' },
        { distance: 200, label: '200K' },
        { distance: 250, label: '250K' },
    ]
    calculation: PaceCalculation = {
        distance: 10.0,
        time: '1:00:00',
        pace: '6:00',
        speed: '10.0',
    }

    constructor(public fb: FormBuilder) {
        this.formGroup = this.fb.group({
            distance: [null, [Validators.required]],
            time: [null, [Validators.required]],
            pace: [null, [Validators.required]],
            speed: [null, [Validators.required]],
        })
        this.formGroup.get('distance').valueChanges.subscribe((distance) => {
            this.calculate({ distance: distance })
        })
        this.formGroup.get('time').valueChanges.subscribe((time) => {
            this.calculate({ time: time })
        })
        this.formGroup.get('pace').valueChanges.subscribe((pace) => {
            this.calculate({ pace: pace })
        })
        this.formGroup.get('speed').valueChanges.subscribe((speed) => {
            this.calculate({ speed: speed })
        })
    }

    ngOnInit(): void {
        this.formGroup.patchValue(this.calculation)
    }

    calculate(calculation: PaceCalculation) {
        if (calculation.distance) {
            this.calculation.distance = Number(calculation.distance)
            let timeInSec = str2sec(this.calculation.time)
            this.calculation.pace = sec2pace(timeInSec / 60 / this.calculation.distance)
            this.calculation.speed = (this.calculation.distance / (timeInSec / 3600)).toFixed(1)
            this.formGroup.get('pace').setValue(this.calculation.pace, { emitEvent: false })
            this.formGroup.get('speed').setValue(this.calculation.speed, { emitEvent: false })
        }
        if (calculation.time) {
            let timeInSec = str2sec(calculation.time)
            this.calculation.pace = sec2pace(timeInSec / 60 / this.calculation.distance)
            this.calculation.speed = (this.calculation.distance / (timeInSec / 3600)).toFixed(1)
            this.formGroup.get('pace').setValue(this.calculation.pace, { emitEvent: false })
            this.formGroup.get('speed').setValue(this.calculation.speed, { emitEvent: false })
        }
        if (calculation.pace) {
            let paceInSec = str2sec(calculation.pace)
            let timeInSec = paceInSec * this.calculation.distance
            this.calculation.time = sec2time(timeInSec)
            this.calculation.speed = (this.calculation.distance / (timeInSec / 3600)).toFixed(1)
            this.formGroup.get('time').setValue(this.calculation.time, { emitEvent: false })
            this.formGroup.get('speed').setValue(this.calculation.speed, { emitEvent: false })
        }
        if (calculation.speed) {
            let timeInSec = (this.calculation.distance / Number(calculation.speed)) * 3600
            this.calculation.time = sec2time(timeInSec)
            this.calculation.pace = sec2pace(timeInSec / 60 / this.calculation.distance)
            this.formGroup.get('time').setValue(this.calculation.time, { emitEvent: false })
            this.formGroup.get('pace').setValue(this.calculation.pace, { emitEvent: false })
        }
    }
}
