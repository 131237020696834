import { environment } from 'environments/environment'

class Server {

    domain = 'actigo.app'
    time = Date.now()

    get env() {
        return localStorage.getItem('server.env')
    }

    set env(value: string) {
        localStorage.setItem('server.env', value)
    }

    get appUrl() {
        return (this.env) ? `https://${this.env}.${this.domain}` : environment.appUrl
    }

    get apiUrl() {
        return (this.env) ? `${this.appUrl}/api` : environment.apiUrl
    }

    get graphqlUrl() {
        return (this.env) ? `${this.appUrl}/graphql` : environment.graphqlUrl
    }
}

export let server = new Server()
