import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pace',
})
export class PacePipe implements PipeTransform {
    transform(value: number): string {
        return (isNaN(value) || value == Infinity) ? '-' : (Math.floor(value) + ':' + (value % 1 * 60).toFixed().toString().padStart(2, '0'))
    }
}
