import { Component, OnInit } from '@angular/core'
import { Support } from 'app/app.consts'
import { WindowService } from 'app/core/services/window.service'

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
})
export class SupportComponent implements OnInit {

    text = ''

    constructor(
        private windowService: WindowService,
    ) {}

    ngOnInit(): void {
        this.windowService.selectedLanguage$.subscribe((language) => {
            this.text = Support[language.code] ? Support[language.code] : Support['cs']
        })
    }
}
