<button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <i class="flag-icon flag-icon-{{ selectedLanguage.icon }}"> </i>
</button>
<mat-menu #flags="matMenu" class="mymegamenu">
    <button
        mat-menu-item
        *ngFor="let lang of languages"
        (click)="changeLanguage(lang)"
    >
        <i class="flag-icon flag-icon-{{ lang.icon }} m-r-10"></i>
        <span>{{ lang.language }}</span>
    </button>
</mat-menu>

<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->
<!--<button *ngIf="(postCount$ |async) > 0" mat-icon-button [routerLink]="['/']" class="me-3 mat-icon_shadow">-->
<!--    <mat-icon [matBadge]="postCount$ |async" matBadgeColor="warn">notifications</mat-icon>-->
<!--</button>-->
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <img src="assets/images/users/1.jpg" alt="user" class="profile-pic" />
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item><mat-icon>settings</mat-icon> Settings</button>
    <button mat-menu-item><mat-icon>account_box</mat-icon> Profile</button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications
    </button>
    <button mat-menu-item><mat-icon>exit_to_app</mat-icon> Sign Out</button>
</mat-menu>
