import { Injectable } from '@angular/core'
import { ApolloService } from './apollo.service'
import { ActivityLabel, ActivityType, WorkflowState } from 'app/core/core.models'
import { ACTIVITY_TYPES, POST_TYPES, PRIORITIES } from '../core.queries'
import { ACTIVITY_LABELS } from '../../events/events.queries'
import { filter, map } from 'rxjs/operators'
import { BehaviorSubject, of } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class EnumService {

    constructor(private apolloService: ApolloService) {}

    readonly workflowStates$ = new BehaviorSubject([WorkflowState.Draft, WorkflowState.Published, WorkflowState.Archived])

    get activityTypes$() {
        return this.apolloService.query<ActivityType[]>({
            query: ACTIVITY_TYPES,
        })
    }

    get activityLabels$() {
        return this.apolloService.query<ActivityLabel[]>({
            query: ACTIVITY_LABELS,
        })
    }

    get postTypes$() {
        return this.apolloService.query<string[]>({
            query: POST_TYPES,
        })
    }

    get priorities$() {
        return this.apolloService.query<string[]>({
            query: PRIORITIES,
        })
    }

    activityLabelsByType(type: ActivityType) {
        return this.activityLabels$.pipe(
            map((labels) => labels.filter((label) => label.types.includes(type)))
        )
    }

    activityLabelByName$(name: string) {
        return this.activityLabels$.pipe(
            map((labels) => labels.find((label) => label.name == name))
        )
    }
}
