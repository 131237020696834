import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UserAccessKey } from 'app/users/users.models'

@Component({
    selector: 'app-user-access-key',
    templateUrl: './user-access-key.component.html',
})
export class UserAccessKeyComponent {
    constructor(
        public dialogRef: MatDialogRef<UserAccessKeyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserAccessKey,
    ) {}

    onNoClick(): void {
        this.dialogRef.close()
    }
}
