import { Component, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { UserAccessKey, AccessRight } from 'app/users/users.models'
import { MatDialog } from '@angular/material/dialog'
import { UserService } from 'app/users/services/user.service'
import { UserAccessKeyComponent } from 'app/users/components/user-access-key/user-access-key.component'
import { TranslateService } from '@ngx-translate/core'
import { uniqueId } from 'app/core/core.functions'
import { server } from 'app/core/core.server'

@Component({
    selector: 'app-user-access-keys',
    templateUrl: './user-access-keys.component.html',
    styleUrls: ['./user-access-keys.component.scss']
})
export class UserAccessKeysComponent implements OnInit {
    apiUrl = server.apiUrl
    columns = ['name', 'info', 'action']
    accessKeys$ = new BehaviorSubject<UserAccessKey[]>([])

    constructor(
        private userService: UserService,
        private translateService: TranslateService,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.userService.getCurrentUser$().subscribe((result) => {
            this.accessKeys$.next(result.accessKeys)
            console.log('ACCESS KEYS', result.accessKeys)
        })
    }

    addAccessKey() {
        const dialogRef = this.dialog.open(UserAccessKeyComponent, {
            width: '300px',
            data: {
                name: uniqueId(8),
                info: '',
                secret: uniqueId(32),
                rights: [AccessRight.Read],
            },
        })
        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed', result)
            if (typeof result == 'object') {
                this.userService.setUserAccessKey$(result).subscribe((accessKey) => {
                    console.log('User access key set', accessKey)
                    this.accessKeys$.next(this.accessKeys$.value.concat(accessKey))
                })
            }
        })
    }

    removeAccessKey(accessKey: UserAccessKey) {
        if (confirm(this.translateService.instant('confirm_delete') + ` ${accessKey.name}?`)) {
            console.log('Deleting access key', accessKey)
            this.userService.removeUserAccessKey$(accessKey).subscribe((result) => {
                console.log('User access key deleted', result)
                this.accessKeys$.next(this.accessKeys$.value.filter((item) => item.name != accessKey.name))
            })
        }
    }
}
