<mat-card>
    <mat-card-content>
        <mat-card-title>{{'connections'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'connections_desc'|translate}}</mat-card-subtitle>
        <div *ngFor="let connection of connections$|async">
            <img [src]="'assets/images/logo-' + connection.type.toLowerCase() + '.png'" style="width: 25pt; height: 25pt;"/>
            &nbsp;{{connection.type}}
            <button mat-icon-button color="warn" (click)="removeUserConnection(connection)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <mat-card-actions class="form-element text-right">
            <button
                mat-raised-button
                color="primary"
                (click)="connect('strava')"
                [disabled]="isStravaConnected"
            >
                <mat-icon>link</mat-icon>
                Strava
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="connect('fitbit')"
                [disabled]="isFitbitConnected"
            >
                <mat-icon>link</mat-icon>
                Fitbit
            </button>
            <button
                *ngIf="isGarminEnabled"
                mat-raised-button
                color="primary"
                (click)="connect('garmin')"
                [disabled]="isGarminConnected"
            >
                <mat-icon>link</mat-icon>
                Garmin
            </button>
<!--            <button mat-raised-button color="primary" (click)="connect('facebook')" [disabled]="isFacebookConnected">-->
<!--                <mat-icon>link</mat-icon>-->
<!--                Facebook-->
<!--            </button>-->
        </mat-card-actions>
    </mat-card-content>
</mat-card>
