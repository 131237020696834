import { MonoTypeOperatorFunction, tap } from 'rxjs'
import { logger } from './logger'
import { stringify } from '@angular/compiler/src/util'

export function log<T>(msg: string): MonoTypeOperatorFunction<T> {
    return tap((it: T) => {
        logger.log(msg + ' ' + JSON.stringify(it))
        console.log(msg, it)
    })
}
