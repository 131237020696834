<ng-template mat-tab-label>
    <mat-icon>directions_run</mat-icon>&nbsp;{{'activities'|translate}}
</ng-template>
<mat-card-content>
    <mat-checkbox [formControl]="onlyLastControl">{{'only_last'|translate}}</mat-checkbox>
</mat-card-content>
<mat-tab-group (selectedTabChange)="selectedTabChange($event)" #tabGroup>
    <mat-tab
        *ngFor="let organizedActivity of organizedEventServiceState.filteredOrganizedActivities$ | async; let i = index; trackBy: trackByKey"
    >
        <ng-template mat-tab-label>
            <mat-icon>directions_run</mat-icon>&nbsp;{{ organizedActivity.startTime | localizeDate: "short" }} • {{
            ('ActivityType.' + organizedActivity.activity.type) | translate }} {{ organizedActivity.activity.distance }}
            {{'Unit.KM' | translate}}
        </ng-template>
        <ng-template matTabContent>
            <!--            <mat-card-content *ngIf="i === activeIndex">-->
            <app-organized-event-activity [organizedActivity]="organizedActivity"></app-organized-event-activity>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="(organizedEventServiceState.organizedEvent$ | async) as organizedEvent">
        <ng-template mat-tab-label> <mat-icon>add_circle</mat-icon>&nbsp;{{'create'|translate}} </ng-template>
        <ng-template matTabContent>
            <!--                <app-organized-activity-form *ngIf="isCreateTab"></app-organized-activity-form>-->
            <app-organized-event-activity
                (finishAction)="changeToLastFilledTab()"
                [organizedEvent]="organizedEvent"
            ></app-organized-event-activity>
        </ng-template>
    </mat-tab>
</mat-tab-group>
