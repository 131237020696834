<mat-card>
    <mat-tab-group #tabs [selectedIndex]="activeTabIndex" (selectedTabChange)="selectedTabChange($event)">
        <mat-tab>
            <ng-template mat-tab-label> <mat-icon>settings</mat-icon>&nbsp;{{'event'|translate}} </ng-template>
            <mat-card-content *ngIf="OrganizedEventModelTab.settings === activeTabIndex">
                <app-organized-event-settings></app-organized-event-settings>
            </mat-card-content>
        </mat-tab>
        <mat-tab [disabled]="isNewEvent">
            <ng-template mat-tab-label> <mat-icon>photo</mat-icon>&nbsp;{{'media'|translate}} </ng-template>
            <mat-card-content *ngIf="OrganizedEventModelTab.media === activeTabIndex">
                <app-organized-event-media></app-organized-event-media>
            </mat-card-content>
        </mat-tab>
        <mat-tab [disabled]="isNewEvent">
            <ng-template mat-tab-label>
                <mat-icon>directions_run</mat-icon>&nbsp;{{'activities'|translate}}
            </ng-template>
            <app-organized-event-activities
                *ngIf="OrganizedEventModelTab.activity === activeTabIndex"
            ></app-organized-event-activities>
        </mat-tab>
    </mat-tab-group>
</mat-card>
