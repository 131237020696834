import { Component, OnInit } from '@angular/core'
import { ConnectionType, UserActivity } from 'app/users/users.models'
import { UserService } from 'app/users/services/user.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { LoginService } from 'app/users/services/login.service'
import { switchMap } from 'rxjs'
import * as R_ from 'ramda-extension'
import { filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { WindowService } from 'app/core/services/window.service'
import { Router } from '@angular/router'

@UntilDestroy()
@Component({
    selector: 'app-user-activities',
    templateUrl: './user-activities.component.html',
    styleUrls: ['./user-activities.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class UserActivitiesComponent implements OnInit {
    columns = ['activity', 'connectionType', 'started', 'elapsedTime', 'movingTime', 'distance', 'pace']
    userActivities: UserActivity[]
    listCount = 0
    expandedRow: UserActivity | null = null

    constructor(
        private userService: UserService,
        private loginService: LoginService,
        public windowService: WindowService,
        private translateService: TranslateService,
        private snackBar: MatSnackBar,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.load()
    }

    load() {
        this.loginService.loggedIn$
            .pipe(
                switchMap(() => {
                    console.log('LOADING FOR', this.loginService.currentUser.id)
                    return this.userService.getUserActivities$(
                        this.loginService.currentUser.id,
                        null,
                        this.windowService.listLimit,
                    )
                }),
                untilDestroyed(this),
            )
            .subscribe((userActivities: UserActivity[]) => {
                this.listCount = userActivities.length
                this.userActivities = userActivities
            })
    }

    loadMore() {
        if (this.userActivities.length > 0) {
            this.userService
                .getUserActivities$(
                    this.loginService.currentUser.id,
                    this.userActivities[this.userActivities.length - 1].id,
                    this.windowService.listLimit,
                )
                .pipe(untilDestroyed(this))
                .subscribe((userActivities) => {
                    this.listCount = userActivities.length
                    this.userActivities = [...this.userActivities, ...userActivities]
                })
        }
    }

    importFromStrava() {
        this.importUserActivities(ConnectionType.Strava)
    }

    importFromFitbit() {
        this.importUserActivities(ConnectionType.Fitbit)
    }

    private importUserActivities(type: ConnectionType) {
        this.userService.importUserActivities$(type).subscribe((result) => {
            console.log(`Import user activities (${type})`, result)
            let activityImportResult = result
            let count = activityImportResult.counters.reduce((n, { value }) => n + value, 0)
            this.snackBar.open(
                `${count}/${activityImportResult.totalCount} ` + this.translateService.instant('imported'),
                '',
                { duration: 2000 },
            )
            this.load()
        })
    }
}
