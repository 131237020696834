import { Injectable } from '@angular/core'
import { Apollo, gql } from 'apollo-angular'
import { environment } from 'environments/environment'
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { from, Observable, of, switchMap, zip } from 'rxjs'
import { Device } from '@capacitor/device'

const VERSION = gql`
    query {
        version
    }
`

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    apiVersion: any
    version$: Observable<string>

    constructor(private apollo: Apollo) {
        this.apollo
            .query<{ version: string }>({
                query: VERSION,
            })
            .subscribe((result) => {
                this.apiVersion = result.data.version
            })
        this.version$ = Capacitor.isNativePlatform() ?
            zip(from(Device.getInfo()), App.getInfo()).pipe(
                switchMap((info) =>
                    of(`${info[0].platform} ${info[1].version} (${info[1].build})`)
                )
            ) :
            from(Device.getInfo()).pipe(
                switchMap((info) =>
                    of(`${info.platform} ${environment.version.replace('COMMIT_REF', 'latest')}`)
                )
            )
    }
}
