import { Directive, HostBinding, Inject, Input, OnInit, OnDestroy } from '@angular/core'

import { AccordionDirective } from './accordion.directive'

@Directive({
    selector: '[appAccordionLink]',
})
export class AccordionLinkDirective implements OnInit, OnDestroy {
    @Input()
    group: any

    @HostBinding('class.selected')
    @Input()
    get selected(): boolean {
        return this._selected
    }

    set selected(value: boolean) {
        this._selected = value
        if (value) {
            this.nav.closeOtherLinks(this)
        }
    }

    // tslint:disable-next-line - Disables all
    protected _selected = false
    protected nav: AccordionDirective

    constructor(@Inject(AccordionDirective) nav: AccordionDirective) {
        this.nav = nav
    }

    ngOnInit(): any {
        this.nav.addLink(this)
    }

    ngOnDestroy(): any {
        this.nav.removeGroup(this)
    }

    toggle(): any {
        this.selected = !this.selected
    }
}
