<div
    *ngIf="{
       language: organizedEventServiceState.language$ | async,
       activityLabels: organizedEventServiceState.activityLabels$ | async
    } as data"
    fxLayout="row wrap"
>
    <div *ngIf="data.language && data.activityLabels" fxFlex="100">
        <form [formGroup]="form" class="basic-form">
            <ng-container *ngIf="data.activityLabels">
                <mat-form-field>
                    <mat-label>{{'organizer' | translate}} ({{(organizers$ | async)?.length}})</mat-label>
                    <mat-select formControlName="organizerId">
                        <mat-option *ngFor="let organizer of organizers$ | async" [value]="organizer.id">
                            {{organizer.name}} ({{organizer.eventCount ? organizer.eventCount : 0}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'name'|translate}}</mat-label>
                    <input
                        required
                        matInput
                        [placeholder]="formControlNamePlaceholder"
                        [formControl]="formControlName"
                    />
                    <mat-error *ngIf="!formControlName.valid && formControlName.touched">
                        {{ 'isRequired' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'description'|translate}}</mat-label>
                    <textarea rows="6" [formControl]="formControlDescription" matInput></textarea>
<!--                    <mat-error *ngIf="!formControlDescription.valid && formControlDescription.touched">-->
<!--                        {{ 'isRequired' | translate }}-->
<!--                    </mat-error>-->
                </mat-form-field>
                <p class="m-t-10">
                    <app-chips-radio-input
                        [control]="$any(form.get('labels'))"
                        [options]="data?.activityLabels | localizedEnumOptions : (selectedLanguage$ | async).code"
                    >
                    </app-chips-radio-input>
                </p>
<!--                <mat-form-field>-->
<!--                    <mat-label>{{'externalId'|translate}}</mat-label>-->
<!--                    <input matInput formControlName="externalId" />-->
<!--                </mat-form-field>-->
                <div class="form-element text-right">
                    <button
                        [disabled]="form.disabled"
                        (click)="submitForm()"
                        mat-raised-button
                        color="primary"
                        type="submit"
                        class="button"
                    >
                        {{'save'|translate}}
                    </button>
                </div>
            </ng-container>
        </form>
    </div>
</div>
