<div *ngIf="(apiLoaded$ | async) || true" class="map-container relative" [style.height]="mapHeight">
    <google-map
        height="100%"
        width="100%"
        #map
        [options]="{
            styles: styles,
            disableDefaultUI: true,
            fullscreenControl: fullscreenControl,
            keyboardShortcuts: false,
            gestureHandling: gestureHandling,
            zoomControl: true
        }"
        (mapMousemove)="move($event)"
        (mapClick)="!inputMarkerAllowed && moveMap($event);mapClick($event);inputMarkerAllowed && inputMarkerMovingAllowed && addMarker($event)"
        (mapDblclick)="inputMarkerAllowed && keepMarkerOnSpot()"
    >
        <ng-container *ngFor="let mapActivity of mapActivities">
            <ng-container *ngFor="let track of mapActivity.tracks">
                <map-polyline [path]="track.points" [options]="{strokeColor: track.strokeColor}"></map-polyline>
            </ng-container>
            <map-marker
                *ngIf="inputMarkerAllowed && markerInputPosition"
                [position]="markerInputPosition"
                (mapDragend)="getDraggedInputMarker($event)"
                (mapClick)="inputMarkerClick()"
                [options]="{
                    draggable: inputMarkerMovingAllowed,
                    icon: markerIcons[markerType.default]
                }"
            ></map-marker>
            <map-marker
                #markerRef="mapMarker"
                *ngFor="let marker of mapActivity.markers; let markerIndex = index"
                [position]="marker.point"
                [options]="{
                    draggable: false,
                    icon: marker.icon
                }"
                (mapMouseover)="marker.type === markerType.default && !markerDisabled && openInfoWindow(markerRef, mapActivity?.organizedActivity, true)"
                (mapClick)="marker.type === markerType.default && openInfoWindow(markerRef, mapActivity?.organizedActivity)"
            >
            </map-marker>
            <map-info-window
                [options]="{
                        disableAutoPan: true
                    }"
            >
                <div class="info-window">
                    <strong *ngIf="selectedOrganizedActivity?.name">
                        {{selectedOrganizedActivity?.name | localize }}</strong
                    ><br />
                    <ng-container *ngIf="selectedOrganizedActivity?.description">
                        {{selectedOrganizedActivity?.description | localize }}
                    </ng-container>
                </div>
            </map-info-window>
        </ng-container>
    </google-map>
</div>
<div class="legend-wrapper">
    <div #legend *ngIf="showLegendForTracks && activity" class="legend">
        <strong>{{'legend' | translate}}</strong><br />
        <ng-container *ngIf="activity.segments.length; else singleTrack">
            <ng-container *ngFor="let segment of activity.segments; let i = index">
                <span class="legend-line" [style.color]="trackColors[i]"
                    >{{segment.type | translate}} {{segment.distance | number}} {{'Unit.KM' | translate}}<br
                /></span>
            </ng-container>
        </ng-container>
        <ng-template #singleTrack>
            <span class="legend-line" [style.color]="trackColors[0]"
                >{{'ActivityType.' + activity.type | translate}} {{activity.distance | number}} {{'Unit.KM' |
                translate}}</span
            >
        </ng-template>
        <br />
        <img src="assets/images/marker/green-racebook.svg" /> {{'mapStart'| translate}}<br />
        <img src="assets/images/marker/red-racebook.svg" /> {{'mapFinish'| translate}}
    </div>
</div>
<ng-content></ng-content>
<!--<div>Latitude: {{display?.lat}}</div>-->
<!--<div>Longitude: {{display?.lng}}</div>-->
