import { Injectable } from '@angular/core'
import { BehaviorSubject, switchMap, tap } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter, map } from 'rxjs/operators'
import * as R from 'ramda'
import { View } from './organized-activities.model'
import { EnumService } from 'app/core/services/enum.service'
import { defaultFormValue } from 'app/events/components/organized-activities-search/organized-activities-search.config'
import { GetOrganizedActivities, OrganizedActivity } from 'app/events/events.models'
import { Nullish } from 'app/core/core.models'
import { OrganizedActivityService } from 'app/events/services/organized-activity.service'
import dayjs from 'dayjs'

@UntilDestroy()
@Injectable()
export class OrganizedActivitiesStateService {
    organizedActivitiesFormState$ = new BehaviorSubject<GetOrganizedActivities>(defaultFormValue)

    activities$ = new BehaviorSubject<OrganizedActivity[]>([])
    view$ = new BehaviorSubject(View.CARDS)
    noActivities$ = new BehaviorSubject(false)
    activityLabels$ = this.enumService.activityLabels$
    activityTypes$ = this.enumService.activityTypes$

    constructor(
        private readonly activityService: OrganizedActivityService,
        private readonly enumService: EnumService,
    ) {
        this.organizedActivitiesFormState$
            .pipe(
                filter(
                    (getOrganizedActivities: GetOrganizedActivities) =>
                        !R.propEq('myPosition', defaultFormValue.myPosition)(getOrganizedActivities),
                ),
                map((it) => {
                    const dates = {
                        dateFrom: dayjs(it.dateFrom).startOf('day').toDate(),
                        dateTo: dayjs(it.dateTo).endOf('day').toDate(),
                    }

                    return R.mergeAll([it, dates])
                }),
                switchMap((getOrganizedActivities: GetOrganizedActivities) => {
                    this.noActivities$.next(false)
                    return this.activityService.getOrganizedActivities$(getOrganizedActivities, (length, isFinished) => {
                        if (isFinished && length == 0)
                            this.noActivities$.next(true)
                    })
                }),
                untilDestroyed(this),
            )
            .subscribe((data: OrganizedActivity[]) => {
                this.activities$.next(data)
            })
    }
}
