import { gql } from 'apollo-angular'
import {
    ACTIVITY_FRAGMENT,
    ADDRESS_FRAGMENT,
    PERSON_FRAGMENT,
    VERIFIED_CONTACT_FRAGMENT,
} from 'app/core/core.fragments'
import { ORGANIZED_ACTIVITY_FRAGMENT } from '../events/events.fragments'

export const PROFILE_FRAGMENT = gql`
    fragment ProfileFields on UserProfile {
        givenName
        surname
        middleName
        birthDate
        sex
        language
        homeLocation {
            longitude
            latitude
        }
        maxDistance
        allowMatchmaking
        shareActivitiesWithFollowers
        sameSexOnly
        isPublic
        description
        teamName
        runningMonthly
        runningShoeCount
        runningPB {
            distance
            distanceUnit
            time
        }
        registrationPriceLimit {
            currency
            value
        }
        activityTypes
        activityLabels
        updated
    }
`

export const WALLPOST_FRAGMENT = gql`
    fragment WallPostFields on WallPost {
        id
        ref
        name
        text
        icon
        type
        received
        created
        entityType
        entityId
        userId
        groupId
    }
`

export const USER_FRAGMENT = gql`
    ${ADDRESS_FRAGMENT}
    ${PROFILE_FRAGMENT}
    ${PERSON_FRAGMENT}
    ${VERIFIED_CONTACT_FRAGMENT}
    fragment UserFields on User {
        id
        email
        picture
        nickname
        address {
            ...AddressFields
        }
        profile {
            ...ProfileFields
        }
        contactEmail {
            ...VerifiedContactFields
        }
        phoneNumber {
            ...VerifiedContactFields
        }
        connections {
            type
        }
        accessKeys {
            name
            info
            secret
            rights
        }
        postRules {
            type
            isEnabled
            notificationPriority
            entityId
        }
        created
        accessed
    }
`

export const USER_ACTIVITY_FRAGMENT = gql`
    ${PERSON_FRAGMENT}
    ${ACTIVITY_FRAGMENT}
    ${ORGANIZED_ACTIVITY_FRAGMENT}
    fragment UserActivityFields on UserActivity {
        id
        person {
            ...PersonFields
        }
        activity {
            ...ActivityFields
        }
        organizedActivity {
            ...OrganizedActivityFields
        }
        externalId
        connectionId
        connectionType
        started
        finished
        distance
        pace
        elapsedTime
        movingTime
        isLive
        likeCount
        messageCount
    }
`
