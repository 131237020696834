<h1 mat-dialog-title>{{data.organizedActivity.name | localize}}</h1>
<div mat-dialog-content>
    <div *ngIf="!data.hasCompletedProfile">
        <b>{{'incompleteProfile.title' | translate}}</b>
        <p>{{'incompleteProfile.text' | translate}}</p>
    </div>
    <div>
        <h3>{{'organized_by' | translate}} <b>{{data.organizer.name}}</b></h3>
        <p>
            {{data.organizer.address.street}}, {{data.organizer.address.postalCode}} {{data.organizer.address.city}}, {{data.organizer.address.country}}<br/>
            <!--{{data.organizer.address.state}} {{data.organizer.address.country}}-->
            <ng-container *ngIf="data.organizer.orgId">{{'orgId' | translate}}: {{data.organizer.orgId}}&nbsp;</ng-container>
            <ng-container *ngIf="data.organizer.vatId">{{'vatId' | translate}}: {{data.organizer.vatId}}</ng-container>
        </p>
        <p class="alert alert-warning">{{'registration_disclaimer' | translate}}</p>
        <ng-container *ngFor="let merchandisePrice of data.organizedActivity.merchandisePriceList">
            <mat-checkbox
                (change)="updateMerchandise(merchandisePrice, $event.checked)"
                >
                {{merchandisePrice.name | localize}}
                <ng-container *ngIf="merchandisePrice.description.length && merchandisePrice.description[0].text">
                    - {{merchandisePrice.description | localize}}
                </ng-container>
            </mat-checkbox> {{'for' | translate}} {{merchandisePrice.amount | amount}}<br/>
        </ng-container>
        <form [formGroup]="form" class="basic-form">
            <div class="me-lg-4 me-sm-0 mb-4">
                <mat-form-field>
                    <input
                        type="text"
                        [placeholder]="'team' | translate"
                        formControlName="team"
                        matInput
                        [matAutocomplete]="auto"
                    />
                    <mat-autocomplete
                        #auto="matAutocomplete"
                        [displayWith]="displayTeam"
                    >
                        <mat-option *ngFor="let option of teamOptions$ | async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </form>
        <mat-checkbox [(ngModel)]="agreed">{{'agree_with' | translate}}</mat-checkbox>
        &nbsp;<a href="javascript:void(0)" (click)="openTerms()">{{'terms2' | translate | lowercase}}</a>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="cancel">{{'cancel' | translate}}</button>
    <button
        mat-button
        [color]="'primary'"
        [mat-dialog-close]="{ result: 'completeProfile' }"
        cdkFocusInitial
        *ngIf="!data.hasCompletedProfile"
    >
        {{'incompleteProfile.completeNow' | translate}}
    </button>
    <button
        mat-raised-button
        color="warn"
        [mat-dialog-close]="{ result: 'register',  merchandise: merchandise, teamName: teamName }"
        [disabled]="!agreed || !data.hasCompletedProfile"
    >
        <ng-container *ngIf="(totalAmount$ | async).value > 0">
            {{ 'register_for'| translate }} {{ totalAmount$ | async | amount}}
        </ng-container>
        <ng-container *ngIf="(totalAmount$ | async).value == 0">
            {{ 'register_for_free'| translate }}
        </ng-container>
    </button>
</mat-dialog-actions>
