import { Address, Person, Link } from 'app/core/core.models'
import { ContractType } from '../events/events.models'

export enum OrganizerRole {
    Owner = 'Owner',
    Editor = 'Editor',
    Reporter = 'Reporter',
}

export interface Organizer {
    id: string
    name: string
    description?: string
    links?: Link[]
    email?: string
    phoneNumber?: string
    address?: Address
    vatId?: string
    orgId?: string
    bankAccount?: string
    eventCount?: string
    isActive?: boolean
    contractType?: ContractType
}

export interface OrganizerMember {
    person: Person
    role: OrganizerRole
    type?: string
}

export interface CreateOrganizer {
    name: string
    street: string
    city: string
    country: string
    state: string
    postalCode: string
    orgId?: string
    vatId?: string
}
