import { ConsumerMenuItems } from 'app/layouts/constants/consumer/model'
import { MenuItemType } from 'app/layouts/layouts.models'

export const loggedInConsumerMenuItems: ConsumerMenuItems = [
    {
        state: 'profile',
        name: 'profile',
        type: MenuItemType.link,
        icon: 'account_box',
        action: (router, loginService, menuItemsService) => {
            loginService.userLoggedIn$.subscribe((user) => router.navigate([user.id]))
        },
    },
    {
        state: 'settings',
        name: 'settings',
        type: MenuItemType.link,
        icon: 'settings',
        action: (router, loginService, menuItemsService) => {
            router.navigate(['users', 'settings'])
        },
    },
    {
        state: 'switchMode',
        name: 'switchMode',
        type: MenuItemType.link,
        icon: 'brightness_4',
        action: (router, loginService, menuItemsService) => {
            menuItemsService.toggleDarkMode()
        },
    },
    {
        state: 'tools',
        name: 'tools',
        type: MenuItemType.link,
        icon: 'straighten',
        action: (router, loginService, menuItemsService) => {
            router.navigate(['tools'])
        },
    },
    // {
    //     state: 'developers',
    //     name: 'developers',
    //     type: MenuItemType.link,
    //     icon: 'code',
    //     action: (router, loginService, menuItemsService) => {
    //         router.navigate(['developers'])
    //     },
    // },
    {
        type: MenuItemType.separator,
    },
    {
        state: 'support',
        name: 'support',
        type: MenuItemType.link,
        icon: 'help',
        action: (router, loginService, menuItemsService) => {
            router.navigate(['support'])
        },
    },
    {
        state: 'terms',
        name: 'terms',
        type: MenuItemType.link,
        icon: 'short_text',
        action: (router, loginService, menuItemsService) => {
            router.navigate(['terms'])
        },
    },
    // {
    //     state: 'about',
    //     name: 'about',
    //     type: MenuItemType.link,
    //     icon: 'info',
    //     action: (router, loginService, menuItemsService) => {
    //         router.navigate(['about'])
    //     },
    // },
    // {
    //     state: 'privacy',
    //     name: 'privacy',
    //     type: MenuItemType.link,
    //     icon: 'lock',
    //     action: (router, loginService, menuItemsService) => {
    //         router.navigate(['privacy'])
    //     },
    // },
    {
        type: MenuItemType.separator,
    },
    {
        state: 'logout',
        name: 'logout',
        type: MenuItemType.link,
        icon: 'logout',
        action: (router, loginService, menuItemsService) => {
            loginService.logout()
        },
    },
    // {
    //     state: 'delete',
    //     name: 'delete_account',
    //     type: MenuItemType.link,
    //     icon: 'delete',
    //     action: (router, loginService, menuItemsService) => {
    //         router.navigate(['users', 'delete'])
    //     },
    // },
]

export const notLoggedInConsumerMenuItems: ConsumerMenuItems = [
    {
        state: 'login',
        name: 'login',
        type: MenuItemType.link,
        icon: 'login',
        action: (router, loginService, menuItemsService) => {
            loginService.login()
        },
    },
    {
        state: 'switchMode',
        name: 'switchMode',
        type: MenuItemType.link,
        icon: 'brightness_4',
        action: (router, loginService, menuItemsService) => {
            menuItemsService.toggleDarkMode()
        },
    },
    {
        type: MenuItemType.separator,
    },
    {
        state: 'support',
        name: 'support',
        type: MenuItemType.link,
        icon: 'help',
        action: (router, loginService, menuItemsService) => {
            router.navigate(['support'])
        },
    },
    // {
    //     state: 'developers',
    //     name: 'developers',
    //     type: MenuItemType.link,
    //     icon: 'code',
    //     action: (router, loginService, menuItemsService) => {
    //         router.navigate(['developers'])
    //     },
    // },
    {
        type: MenuItemType.separator,
    },
    {
        state: 'terms',
        name: 'terms',
        type: MenuItemType.link,
        icon: 'short_text',
        action: (router, loginService, menuItemsService) => {
            router.navigate(['terms'])
        },
    },
    {
        state: 'privacy',
        name: 'privacy',
        type: MenuItemType.link,
        icon: 'lock',
        action: (router, loginService, menuItemsService) => {
            router.navigate(['privacy'])
        },
    },
]
