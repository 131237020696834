import { Component, Input, OnInit } from '@angular/core'
import { v4 as uuidv4 } from 'uuid'
import { Gallery, ImageSize } from 'ng-gallery'
import { Lightbox } from 'ng-gallery/lightbox'
import { CarouselTypes } from 'app/core/components/carousel/carousel.model'
import { Media, MediaType } from 'app/core/core.models'
import { GalleryState } from 'ng-gallery/lib/models/gallery.model'

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
    @Input()
    type: CarouselTypes = CarouselTypes.lightbox

    @Input()
    pictures: Media[] = []

    @Input()
    fullscreen = false

    @Input()
    autoPlay = false

    protected generateUuid = () => uuidv4()
    uuid: string = `id-${this.generateUuid()}`
    carouselTypes = CarouselTypes
    galleryImage: Media
    currIndex = 0

    constructor(public gallery: Gallery, private lightbox: Lightbox) {}

    ngOnInit(): void {
        const galleryRef = this.gallery.ref(this.uuid)

        galleryRef.setConfig({
            imageSize: ImageSize.Contain,
            autoPlay: this.autoPlay,
            dots: this.pictures.length > 1,
            loadingStrategy: 'lazy',
        })

        this.pictures.map((item: Media) => {
            switch (item.type) {
                case MediaType.image:
                    galleryRef.addImage({
                        src: item.ref,
                        thumb: item.thumbRef || item.ref,
                        ...(!!item.title && { title: item.title }),
                    })
                    this.galleryImage = !this.galleryImage ? item : this.galleryImage
                    break
                case MediaType.video:
                    galleryRef.addVideo({
                        src: item.ref,
                        ...(!!item.thumbRef && { thumb: item.thumbRef }),
                        ...(!!item.thumbRef && { poster: item.thumbRef }),
                    })
                    break
                case MediaType.youtube:
                    galleryRef.addYoutube({
                        src: item.ref,
                    })
                    break
                case MediaType.iframe:
                    galleryRef.addIframe({
                        src: item.ref,
                        ...(!!item.thumbRef && { thumb: item.thumbRef }),
                    })
                    break
            }
        })
    }

    indexChange(state: GalleryState) {
        this.currIndex = state.currIndex
    }

    openLightbox() {
        this.lightbox.open(this.currIndex, this.uuid, {
            panelClass: this.fullscreen ? 'fullscreen' : null,
        })
    }
}
