import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Router } from '@angular/router'
import { OrganizerService } from 'app/organizers/services/organizer.service'
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs'
import { OrganizedEvent } from 'app/events/events.models'
import { OrganizedEventModelTab } from 'app/events/components/organized-event/organized-event.model'
import { organizedEventConfig } from 'app/events/components/organized-event/organized-event.config'
import { WindowService } from 'app/core/services/window.service'
import { OrganizedEventService } from 'app/events/services/organized-event.service'
import { LoginService } from 'app/users/services/login.service'
import * as R_ from 'ramda-extension'
import { filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { OrganizerEvents, Terms } from '../../../app.consts'

@UntilDestroy()
@Component({
    selector: 'organized-events',
    templateUrl: './organized-events.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedEventsComponent {
    events$ = new Subject<OrganizedEvent[]>()
    readonly columns = ['name', 'labels', /*'workflow.state',*/ 'workflow.updated' /*, 'externalId'*/]
    readonly maxLabelsToShowInTable = 3

    constructor(
        private readonly router: Router,
        public readonly organizerService: OrganizerService,
        public readonly organizedEventService: OrganizedEventService,
        public readonly windowService: WindowService,
        public readonly loginService: LoginService,
    ) {
        this.organizerService.currentOrganizer$.pipe(untilDestroyed(this)).subscribe((organizer) => {
            this.organizedEventService
                .getOrganizedEvents$({
                    organizerId: organizer.id,
                })
                .pipe(untilDestroyed(this))
                .subscribe((events) => {
                    this.events$.next(events)
                })
        })
    }

    plusButtonAction() {
        this.router.navigate([
            '/',
            'events',
            `${organizedEventConfig.paramForCreate}`,
            OrganizedEventModelTab[OrganizedEventModelTab.settings],
        ])
    }

    navigateToDetail({ id }: OrganizedEvent) {
        this.router.navigate(['/', 'events', id, OrganizedEventModelTab[OrganizedEventModelTab.settings]])
    }
}
