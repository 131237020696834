import { gql } from 'apollo-angular'

export const ADDRESS_FRAGMENT = gql`
    fragment AddressFields on Address {
        label
        street
        city
        country
        postalCode
        state
    }
`

export const VERIFIED_CONTACT_FRAGMENT = gql`
    fragment VerifiedContactFields on VerifiedContact {
        value
        newValue
        created
    }
`

export const ACTIVITY_FRAGMENT = gql`
    fragment ActivityFields on Activity {
        type
        name
        distance
        segments {
            type
            distance
        }
        track {
            distance
            type
            name
            description
            segments {
                points {
                    latitude
                    longitude
                    altitude
                    time
                }
                profile {
                    totalDistance
                    elevationGain
                    elevationLoss
                    minimumSpeed
                    maximumSpeed
                    points {
                        altitude
                        distance
                        label
                        hr
                        cad
                        speed
                        course
                        power
                    }
                }
            }
        }
    }
`

export const PRICE_FRAGMENT = gql`
    fragment PriceFields on RegistrationPrice {
        amount {
            currency
            value
        }
        validTo
        validUntilRegistrationCount
    }
`

export const MERCHANDISE_PRICE_FRAGMENT = gql`
    fragment MerchandisePriceFields on MerchandisePrice {
        sku
        name {
            ...LocalizedTextFields
        }
        description {
            ...LocalizedTextFields
        }
        amount {
            currency
            value
        }
        validTo
    }
`

export const LOCALIZED_TEXT_FRAGMENT = gql`
    fragment LocalizedTextFields on LocalizedText {
        language
        text
    }
`

export const LINK_FRAGMENT = gql`
    fragment LinkFields on Link {
        type
        ref
    }
`

export const MEDIA_FRAGMENT = gql`
    fragment MediaFields on Media {
        ref
        type
        subtype
        thumbRef
        title
        isFavorite
    }
`

export const WORKFLOW_FRAGMENT = gql`
    fragment WorkflowFields on Workflow {
        state
        updated
        userId
    }
`

export const GROUP_FRAGMENT = gql`
    fragment GroupFields on Team {
        id
        name
    }
`

export const PERSON_FRAGMENT = gql`
    ${GROUP_FRAGMENT}
    fragment PersonFields on Person {
        id
        isProfilePublic
        givenName
        surname
        middleName
        name
        sex
        ref
        birthDate
        nickname
        country
        description
        teams {
            ...GroupFields
        }
    }
`

export const MESSAGE_FRAGMENT = gql`
    ${PERSON_FRAGMENT}
    fragment MessageFields on Message {
        id
        type
        sender {
            ...PersonFields
        }
        text
        rating
        entityType
        entityId
        parentId
        created
        isLiked
        likeCount
        childCount
    }
`

export const LIKE_FRAGMENT = gql`
    fragment LikeFields on Like {
        id
        entityType
        entityId
    }
`

export const ACTIVITY_LABEL_FRAGMENT = gql`
    fragment ActivityLabelFields on ActivityLabelV2 {
        name
        text {
            ...LocalizedTextFields
        }
        types
        isEnabled
    }
    ${LOCALIZED_TEXT_FRAGMENT}
`

