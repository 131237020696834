import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FlexModule } from '@angular/flex-layout'
import { LoaderComponent } from 'app/core/components/loader/loader.component'
import { MatProgressBarModule } from '@angular/material/progress-bar'

@NgModule({
    declarations: [LoaderComponent],
    imports: [CommonModule, FlexModule, MatProgressBarModule],
    providers: [],
    exports: [LoaderComponent],
})
export class LoaderModule {}
