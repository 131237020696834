import { Injectable } from '@angular/core'
import { EnumService } from 'app/core/services/enum.service'
import { WindowService } from 'app/core/services/window.service'
import * as R from 'ramda'
import * as R_ from 'ramda-extension'
import { BehaviorSubject, combineLatest, map, Observable, share, tap } from 'rxjs'
import { Nullish } from 'app/core/core.models'
import { OrganizedActivity, OrganizedEvent } from 'app/events/events.models'
import { ApolloStateService } from 'app/core/services/apollo-state.service'
import { OrganizedEventService } from 'app/events/services/organized-event.service'
import { LanguageCode } from 'app/core/constants/languages'
import { filter } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class OrganizedEventServiceState {
    readonly language$ = this.windowService.selectedLanguage$.pipe<LanguageCode>(map(R.prop('code')))
    readonly loading$ = this.apolloStateService.loading$
    readonly onlyLastActivities$ = new BehaviorSubject(true)
    readonly organizedEvent$ = new BehaviorSubject<Nullish<OrganizedEvent>>(null)
    readonly organizedActivities$: Observable<readonly OrganizedActivity[]> = this.organizedEvent$.pipe(
        tap((organizedEvent) => {
            // its need for show view in detail activity
            if (organizedEvent) {
                organizedEvent.activities = organizedEvent?.activities?.map((activity) => {
                    activity.organizedEvent = organizedEvent
                    return activity
                })
            }
        }),
        map(R.prop('activities')),
        share<readonly OrganizedActivity[]>(),
    )
    readonly filteredOrganizedActivities$ = combineLatest(
        this.onlyLastActivities$,
        this.organizedActivities$.pipe(filter(R_.isNotNil))
    ).pipe(
        map(([onlyLastActivities, organizedActivities]) => {
            if (onlyLastActivities) {
                let map = new Map()
                organizedActivities.forEach((organizedActivity) => {
                    let key = organizedActivity.activity.type + organizedActivity.activity.distance
                    if (!map.has(key) || map.get(key).startTime < organizedActivity.startTime)
                        map.set(key, organizedActivity)
                })
                let filteredOrganizedActivities = Array.from(map.values())
                console.log('Filtered organized activities', organizedActivities, filteredOrganizedActivities)
                return filteredOrganizedActivities.sort((a, b) => b.startTime.localeCompare(a.startTime))
            } else {
                console.log('Unfiltered organized activities', organizedActivities)
                return Array.from(organizedActivities).sort((a, b) => b.startTime.localeCompare(a.startTime))
            }
        })
    )
    readonly activityLabels$ = this.enumService.activityLabels$

    constructor(
        private readonly enumService: EnumService,
        private readonly apolloStateService: ApolloStateService,
        private readonly organizedEventService: OrganizedEventService,
        private readonly windowService: WindowService,
    ) {}

    loadOrganizedEvent(organizedEventId: string): void {
        this.organizedEventService
            .getOrganizedEvent$({ organizedEventId })
            .subscribe((organizedEvent: OrganizedEvent) => {
                this.organizedEvent$.next(organizedEvent)
            })
    }

    resetOrganizedEvent(): void {
        this.organizedEvent$.next(null)
    }

    get organizedEventId(): Nullish<string> {
        return this.organizedEvent$?.value?.id
    }
}
