import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { DirectivesModule } from 'app/core/directives/directives.module'
import { TrackProfileComponent } from 'app/core/components/track-profile/track-profile.component'
import { ChartsModule } from 'ng2-charts'

@NgModule({
    declarations: [TrackProfileComponent],
    imports: [CommonModule, TranslateModule, DirectivesModule, ChartsModule],
    providers: [],
    exports: [TrackProfileComponent],
})
export class TrackProfileModule {}
