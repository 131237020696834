import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { generateUUID } from 'app/core/utils/generate-uuid'
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators'
import dayjs from 'dayjs'
import { findIndexOfId } from 'app/core/utils'
import { Currency } from 'app/core/core.models'

@Component({
    selector: 'app-organized-event-activity-price-list',
    templateUrl: './organized-event-activity-price-list.component.html',
    styleUrls: ['./organized-event-activity-price-list.component.scss'],
})
export class OrganizedEventActivityPriceListComponent implements OnInit {
    @Input()
    itemForm: FormGroup
    itemName = 'priceList'

    dataSource: MatTableDataSource<any>
    readonly columns = ['amount', 'currency', 'validTo' /*, 'validUntilRegistrationCount'*/, 'action']
    currencies = ['CZK', 'EUR']

    ngOnInit(): void {
        <FormArray>this.itemForm.get(this.itemName) //.push(this.createPriceItem())
        this.setDataSource()
    }

    create(amount = null, validTo = null, validUntilRegistrationCount = null): FormGroup {
        return new FormGroup({
            id: new FormControl(generateUUID()),
            amount: new FormGroup({
                value: new FormControl(amount ? (amount.value != null ? amount.value / 100 : null) : null, [
                    Validators.required,
                    RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                ]),
                currency: new FormControl(amount ? amount.currency : Currency.CZK),
            }),
            validTo: new FormControl(dayjs(validTo).toDate(), [
                Validators.required,
                // RxwebValidators.minDate({ value: dayjs().toDate() }),
            ]),
            validUntilRegistrationCount: new FormControl(validUntilRegistrationCount, [
                RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber }),
                RxwebValidators.minNumber({ value: 1 }),
            ]),
        })
    }

    pushToArray(): void {
        const items = <FormArray>this.itemForm.get(this.itemName)
        items.push(this.create(null, this.itemForm.controls['startDate'].value, 100000))
        this.setDataSource()
    }

    resetForm() {
        ;(<FormArray>this.itemForm.get(this.itemName)).clear()
        this.ngOnInit()
    }

    remove(element): void {
        const items = <FormArray>this.itemForm.get(this.itemName)
        const item = items.value
        if (item.length !== 0) {
            const indexToRemove = findIndexOfId(element, item)
            items.removeAt(indexToRemove)
            this.setDataSource()
        }
    }

    trackById(index, price): void {
        return price.id
    }

    setDataSource() {
        this.dataSource = new MatTableDataSource((this.itemForm.get(this.itemName) as FormArray).controls)
    }
}
