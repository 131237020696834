export interface MenuBadge {
    type: string
    value: string
}
export interface MenuSeparator {
    name: string
    type?: string
}

export enum MenuItemType {
    'link' = 'link',
    'separator' = 'separator',
    'sub' = 'sub',
    'extTabLink' = 'extTabLink',
    'extLink' = 'extLink',
}

export interface MenuItem<ActionType> {
    state?: string
    name?: string
    type: MenuItemType
    icon?: string
    action?: ActionType
    children?: MenuItems<ActionType>
}

export type MenuItems<ActionType> = Array<MenuItem<ActionType>>

export interface MenuChildItem {
    state: string
    name: string
    type?: string
    children?: MenuChildItem[]
}

export interface MenuMainItem {
    state?: string[]
    excludedStates?: string[][]
    name: string
    type: MenuItemType
    icon: string
    badge?: MenuBadge[]
    separator?: MenuSeparator[]
    children?: MenuChildItem[]
    disabled?: boolean
    visible?: boolean
}
