import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ArticlesComponent } from './components/articles/articles.component'
import { ArticleComponent } from './components/article/article.component'

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: ':feed',
                component: ArticlesComponent,
                data: {
                    title: 'articles',
                },
            },
            {
                path: ':feed/:id',
                component: ArticleComponent,
                data: {
                    title: 'article',
                },
            },
        ],
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ArticlesRoutingModule {}
