<mat-card *ngIf="userInfo$ | async as info">
    <mat-card-content>
        <mat-card-title>
            <h4 class="m-0">{{'identity' | translate}}</h4>
        </mat-card-title>
        <mat-card-subtitle>{{'identity_desc' | translate}}</mat-card-subtitle>
        <small>{{'identification' | translate}} {{'email' | translate | lowercase}}</small>
        <p>{{info.email}}</p>
        <small>{{'authentication' | translate}} ID</small>
        <p>{{info.sub}}</p>
        <small>{{'app' | translate}} ID</small>
        <p>{{user.id}}</p>
        <div class="text-right">
            <button
                mat-raised-button
                color="primary"
                type="submit"
                class="button"
                (click)="syncWithAuthProvider()"
            >
                {{ 'synchronize' | translate }}
            </button>
        </div>
    </mat-card-content>
</mat-card>
