class Logger {
    items: string[] = []

    start = Date.now()

    log(msg: string) {
        const diff = (Date.now() - this.start) / 1000

        this.items.push('[' + diff + '] ' + msg)
    }
}

export const logger = new Logger()
