import { NgModule } from '@angular/core'
import { PacePipe } from 'app/core/pipes/pace/pace.pipe'
import { TimePipe } from 'app/core/pipes/time/time.pipe'
import { LocalizePipe } from './localize/localize.pipe'
import { EventPictureUrlPipe } from 'app/core/pipes/picture-url/picture-url.pipe'
import { ArrayOfValuesToOptionsPipe } from './array-of-values-to-options/array-of-values-to-options.pipe'
import { OptionsTranslatePipe } from './options-translate/options-translate.pipe'
import { InArrayPipe } from './in-array/in-array.pipe'
import { ActiveUrlPipe } from 'app/core/pipes/active-url/active-url.pipe'
import { AmountPipe } from './amount/amount.pipe'
import { LocalizeArrayFormPipe } from 'app/core/pipes/localize-array-form/localize-array-form.pipe'
import { SafePipe } from 'app/core/pipes/safe/safe.pipe'
import { KeepHtmlPipe } from 'app/core/pipes/keep-html/keep-html.pipe'
import { LocalizeDatePipe } from 'app/core/pipes/localizeDate/localize-date.pipe'
import { DistanceRangesPipe } from 'app/core/pipes/distance-ranges/distance-ranges.pipe';
import { MarkedPipe } from './marked/marked.pipe';
import { ShortenedPipe } from './shortened/shortened.pipe'
import { LocalizedEnumOptionsPipe } from './localized-enum-options/localized-enum-options.pipe'
import { LocalizeActivityLabelPipe } from './localize-activity-label/localize-activity-label.pipe'

@NgModule({
    declarations: [
        ActiveUrlPipe,
        TimePipe,
        PacePipe,
        LocalizePipe,
        LocalizeArrayFormPipe,
        AmountPipe,
        KeepHtmlPipe,
        EventPictureUrlPipe,
        ArrayOfValuesToOptionsPipe,
        LocalizedEnumOptionsPipe,
        LocalizeActivityLabelPipe,
        OptionsTranslatePipe,
        InArrayPipe,
        SafePipe,
        LocalizeDatePipe,
        DistanceRangesPipe,
        MarkedPipe,
        ShortenedPipe,
    ],
    exports: [
        ActiveUrlPipe,
        TimePipe,
        PacePipe,
        LocalizePipe,
        LocalizeArrayFormPipe,
        AmountPipe,
        EventPictureUrlPipe,
        ArrayOfValuesToOptionsPipe,
        LocalizedEnumOptionsPipe,
        LocalizeActivityLabelPipe,
        OptionsTranslatePipe,
        InArrayPipe,
        SafePipe,
        KeepHtmlPipe,
        LocalizeDatePipe,
        DistanceRangesPipe,
        MarkedPipe,
        ShortenedPipe
    ],
    providers: [
        ActiveUrlPipe,
        TimePipe,
        PacePipe,
        LocalizePipe,
        LocalizeArrayFormPipe,
        AmountPipe,
        EventPictureUrlPipe,
        ArrayOfValuesToOptionsPipe,
        LocalizedEnumOptionsPipe,
        LocalizeActivityLabelPipe,
        OptionsTranslatePipe,
        InArrayPipe,
        SafePipe,
        LocalizeDatePipe,
        DistanceRangesPipe,
    ],
})
export class PipesModule {}
