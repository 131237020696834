import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { findIndexOfId } from '../../utils'

@Component({
  selector: 'app-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss']
})
export class LinkListComponent implements OnInit {

    @Input()
    formGroup: FormGroup

    @Input()
    name = 'links'

    dataSource: MatTableDataSource<any>
    readonly columns = ['type', 'ref', 'action']

    get formArray(): FormArray {
        return <FormArray>this.formGroup.get(this.name)
    }

    ngOnInit(): void {
        this.formArray
        this.setDataSource()
    }

    createItem(type = 'Web', ref = 'https://'): FormGroup {
        return new FormGroup({
            type: new FormControl(type, [
                Validators.required,
            ]),
            ref: new FormControl(ref, [
                Validators.required,
            ]),
        })
    }

    pushToArray(): void {
        this.formArray.push(this.createItem())
        this.setDataSource()
    }

    resetForm() {
        this.formArray.clear()
        this.ngOnInit()
    }

    remove(element): void {
        const array = this.formArray.value
        if (array.length !== 0) {
            const indexToRemove = array.findIndex((e) => e.type == element.type && e.ref == element.ref)
            this.formArray.removeAt(indexToRemove)
            this.setDataSource()
        }
    }

    trackById(index, price): void {
        return price.id
    }

    setDataSource() {
        this.dataSource = new MatTableDataSource(this.formArray.controls)
    }
}
