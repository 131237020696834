import { setContext } from '@apollo/client/link/context'
import { AccessTokenProvider } from './access-token-provider'

export const authLink = (tokenProvider: AccessTokenProvider) =>
    setContext(
        () =>
            new Promise((resolve) => {
                tokenProvider.getAccessTokenOrNull$().subscribe((token) => {
                    if (token != null) {
                        resolve({
                            headers: {
                                Authorization: 'Bearer ' + token,
                            },
                        })
                    } else {
                        resolve({})
                    }
                })
            }),
    )
