import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { UserActivitiesComponent } from './components/user-activities/user-activities.component'
import { UserSettingsComponent } from './components/user-settings/user-settings.component'
import { UserTrackerComponent } from './components/user-tracker/user-tracker.component'
import { UserActivityComponent } from './components/user-activity/user-activity.component'
import { UserDeleteComponent } from "./components/user-delete/user-delete.component";
import { UserSetupModalComponent } from './components/user-setup-modal/user-setup-modal.component'

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'settings/:property/:type',
                component: UserSettingsComponent,
                data: {
                    title: 'settings',
                    urls: [{ title: 'home', url: '/' }, { title: 'settings' }, { title: 'connections' }],
                },
            },
            {
                path: 'settings',
                component: UserSettingsComponent,
                data: {
                    title: 'user_settings',
                    urls: [{ title: 'home', url: '/' }, { title: 'settings' }],
                },
            },
            {
                path: 'setup',
                component: UserSetupModalComponent,
                data: {
                    title: 'user_setup',
                    urls: [{ title: 'home', url: '/' }, { title: 'setup' }],
                },
            },
            {
                path: 'tracker',
                component: UserTrackerComponent,
                data: {
                    title: 'tracker',
                    urls: [{ title: 'home', url: '/' }, { title: 'tracker' }],
                },
            },
            {
                path: 'delete',
                component: UserDeleteComponent,
                data: {
                    title: 'delete_account',
                    urls: [{ title: 'home', url: '/' }, { title: 'delete_account' }],
                },
            },
            {
                path: 'activities/:id',
                component: UserActivityComponent,
                data: {
                    title: 'activity',
                    urls: [
                        { title: 'home', url: '/' },
                        { title: 'activities', url: '/users/activities' },
                        { title: 'activity' },
                    ],
                },
            },
            {
                path: 'activities',
                component: UserActivitiesComponent,
                data: {
                    title: 'activities',
                    urls: [{ title: 'home', url: '/' }, { title: 'activities' }],
                },
            },
            {
                path: '**',
                redirectTo: '',
            },
        ],
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UsersRoutingModule {}
