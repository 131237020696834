import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { OpenOrganizedActivityData } from '../../events.models'
import { server } from '../../../core/core.server'
import { WindowService } from '../../../core/services/window.service'
import { Organizer } from '../../../organizers/organizers.models'
import { OrganizerService } from '../../../organizers/services/organizer.service'

@Component({
  selector: 'app-organized-activity-open-modal',
  templateUrl: './organized-activity-open-modal.component.html',
  styleUrls: ['./organized-activity-open-modal.component.scss']
})
export class OrganizedActivityOpenModalComponent implements OnInit {

    agreed: boolean = false
    organizer: Organizer

    constructor(
        public dialogRef: MatDialogRef<OrganizedActivityOpenModalComponent>,
        private organizerService: OrganizerService,
        private windowService: WindowService,
        @Inject(MAT_DIALOG_DATA) public data: OpenOrganizedActivityData,
    ) {}

    ngOnInit() {
        this.organizerService.getOrganizer$(this.data.organizedActivity.organizedEvent.organizerId).subscribe((organizer) => {
            this.organizer = organizer
        })
    }

    openTerms() {
        this.windowService.open(server.appUrl + '/terms', '_blank')
    }

    cancel(): void {
        this.dialogRef.close()
    }
}
