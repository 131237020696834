import { ChangeDetectionStrategy, Component } from '@angular/core'
import { OrganizedActivitiesStateService } from 'app/events/components/organized-activities/organized-activities-state.service'
import { LanguageService } from 'app/core/services/language.service'

@Component({
    selector: 'app-organized-activities-cards',
    templateUrl: './organized-activities-cards.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedActivitiesCardsComponent {
    now = new Date()
    constructor(
        public readonly organizedActivitiesStateService: OrganizedActivitiesStateService,
        public readonly languageService: LanguageService,
    ) {}
}
