import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { ActivityResult } from 'app/events/events.models'
import { server } from 'app/core/core.server'
import * as R_ from 'ramda-extension'
import { AbstractSelectableOrganizedActivityComponent } from '../abstract-selectable-organized-activity.component'
import { filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@Component({
    selector: 'app-organizer-activity-results',
    templateUrl: './organizer-activity-results.component.html',
})
@UntilDestroy()
export class OrganizerActivityResultsComponent extends AbstractSelectableOrganizedActivityComponent implements OnInit {
    items$: Observable<ActivityResult[]>
    columns = ['person', 'startNumber', 'time', 'category', 'categoryPosition']

    override ngOnInit() {
        super.ngOnInit()
        this.organizedActivityId$
            .pipe(filter(R_.isNotNil), untilDestroyed(this))
            .subscribe((organizedActivityId) => {
                this.exportUrl = `${server.apiUrl}/events/activities/${organizedActivityId}/results.csv`
                this.items$ = this.activityResultService.getActivityResults$({
                    organizedActivityId: organizedActivityId,
                    skip: 0,
                    limit: 50,
                    ascending: true,
                })
            })
    }
}
