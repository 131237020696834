import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { MatPaginator } from '@angular/material/paginator'
import { Observable } from 'rxjs'
import { MatTableDataSource } from '@angular/material/table'
import { UserActivity, userActivityConnectionUrl } from 'app/users/users.models'
import { WindowService } from 'app/core/services/window.service'

@Component({
    selector: 'app-user-activity-card',
    templateUrl: './user-activity-card.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class UserActivityCardComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    userActivity: UserActivity | UserActivity[] = []

    @Input()
    showMap = true

    @Output()
    currentPage: EventEmitter<number> = new EventEmitter<number>()

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator
    data$: Observable<UserActivity[]>
    dataSource: MatTableDataSource<UserActivity>
    _userActivity: UserActivity[]
    connectionUrl = userActivityConnectionUrl

    constructor(private changeDetectorRef: ChangeDetectorRef, public windowService: WindowService) {}

    ngOnInit() {
        this.changeDetectorRef.detectChanges()
        this.paginator._intl.previousPageLabel = ''
        this.paginator._intl.nextPageLabel = ''
        this._userActivity = Array.isArray(this.userActivity) ? this.userActivity : [this.userActivity]
        this.dataSource = new MatTableDataSource<UserActivity>(this._userActivity)
        this.dataSource.paginator = this.paginator
        this.data$ = this.dataSource.connect()
        this.currentPage.emit(0)
    }

    ngOnDestroy() {
        if (this.dataSource) {
            this.dataSource.disconnect()
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.userActivity) {
            this.ngOnInit()
        }
    }

    pageChanged({ pageIndex }) {
        this.currentPage.emit(pageIndex)
    }
}
