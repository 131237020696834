import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'shortened'
})
export class ShortenedPipe implements PipeTransform {

    transform(value: any, maxCharacters: number = 160): any {
        if (value.length > maxCharacters) {
            let pos = value.substring(0, maxCharacters).lastIndexOf('.')
            if (pos > 0) {
                return value.substring(0, pos + 1)
            } else {
                let pos = value.indexOf(' ', maxCharacters)
                return value.substring(0, pos > maxCharacters ? pos : maxCharacters) + '...'
            }
        } else {
            return value
        }
    }
}
