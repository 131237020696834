import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { EmptyObject, WatchQueryOptions } from 'apollo-angular/src/types'
import { QueryOptions } from '@apollo/client/core'
import { BehaviorSubject, finalize, Observable, tap, throwError } from 'rxjs'
import { catchError, filter, map } from 'rxjs/operators'
import { OperationDefinitionNode } from 'graphql/language/ast'
import { DocumentNode } from 'graphql'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { ApolloStateService } from 'app/core/services/apollo-state.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MutationOptions } from 'apollo-angular/build/types'
import { Globals } from 'app/app.consts'
import { TranslateService } from '@ngx-translate/core'
import * as R from 'ramda'
import * as R_ from 'ramda-extension'
import { SeekableQuery, SkipableQuery } from '../core.models'

@Injectable({
    providedIn: 'root',
})
export class ApolloService {
    constructor(
        private readonly apollo: Apollo,
        private readonly httpStateService: ApolloStateService,
        private readonly snackBar: MatSnackBar,
        private readonly translateService: TranslateService,
    ) {}

    watchQuery<TData, TVariables = EmptyObject>(options: WatchQueryOptions<TVariables, TData>): Observable<TData> {
        options.useInitialLoading = true
        const operationName = this.getOperationName(options.query)
        return this.apollo.watchQuery(options).valueChanges.pipe(
            catchError(this.catchError(false, operationName)),
            tap((data) => {
                if (!data?.loading) {
                    this.httpStateService.removeLoadingOperation(operationName)
                } else {
                    this.httpStateService.addLoadingOperation(operationName)
                }
            }),
            map((it) => it?.data && it?.data[operationName]),
            filter(R_.isNotNil),
        )
    }

    seekQuery<I, V extends SeekableQuery>(
        options: QueryOptions<V, I[]>,
        callback: (length: number, isFinished: boolean) => void = () => {},
        observable$: BehaviorSubject<I[]> = new BehaviorSubject<I[]>([]),
    ): Observable<I[]> {
        this.query(options).subscribe((result) => {
            if (result.length > 0) {
                observable$.next(observable$.value.concat(result))
                if (options.variables.query.limit == result.length) {
                    options.variables.query.seekId = result[result.length - 1]['id']
                    console.log('Seek query continues', observable$.value.length, options.variables.query.seekId)
                    callback(observable$.value.length, false)
                    this.seekQuery(options, callback, observable$)
                } else {
                    console.log('Seek query finished', observable$.value.length, result.length)
                    callback(observable$.value.length, true)
                }
            } else {
                console.log('Seek query finished', observable$.value.length)
                callback(observable$.value.length, true)
            }
        })
        return observable$
    }

    skipQuery<I, V extends SkipableQuery>(
        options: QueryOptions<V, I[]>,
        callback: (length: number, isFinished: boolean) => void = () => {},
        observable$: BehaviorSubject<I[]> = new BehaviorSubject<I[]>([]),
    ): Observable<I[]> {
        this.query(options).subscribe((result) => {
            if (result.length > 0) {
                observable$.next(observable$.value.concat(result))
                options.variables.query.skip = observable$.value.length
                console.log('Skip query continues', observable$.value.length, options.variables.query.skip)
                callback(observable$.value.length, false)
                this.skipQuery(options, callback, observable$)
            } else {
                console.log('Skip query finished', observable$.value.length)
                callback(observable$.value.length, true)
            }
        })
        return observable$
    }

    query<T, V = EmptyObject>(options: QueryOptions<V, T>): Observable<T> {
        const operationName = this.getOperationName(options.query)
        this.httpStateService.addLoadingOperation(operationName)
        return this.apollo.query(options).pipe(
            catchError(this.catchError(false, operationName)),
            map((data) => {
                return data?.data && data?.data[operationName]
            }),
            finalize(() => {
                this.httpStateService.removeLoadingOperation(operationName)
            }),
        )
    }

    mutate<T, V = EmptyObject>(options: MutationOptions<T, V>, successSnackBar: boolean = true): Observable<T> {
        const operationName = this.getOperationName(options.mutation)
        this.httpStateService.addLoadingOperation(operationName)
        return this.apollo.mutate(options).pipe(
            catchError(this.catchError(true, operationName)),
            map((data) => {
                return data?.data && data?.data[operationName]
            }),
            finalize(() => {
                const hasOperationError = this.httpStateService.getErrorOperation(operationName)
                if (!hasOperationError && successSnackBar && !R.find(R.equals(operationName), Globals.ignoreLoadingOperationNames)) {
                    this.snackBar.open(this.translateService.instant('successResult'), null, {
                        duration: Globals.durationSnackBarSuccessMessage,
                        panelClass: 'snack-bar-success',
                    })
                }
                this.httpStateService.removeErrorOperation(operationName)
                this.httpStateService.removeLoadingOperation(operationName)
            }),
        )
    }

    private getOperationName = (query: DocumentNode | TypedDocumentNode): string => {
        const definition = query.definitions.find((it) => it.kind == 'OperationDefinition')
        return (definition as OperationDefinitionNode).name.value
    }

    private catchError =
        (isMutation: boolean, operationName: string = null) =>
        ({ ...rest }) => {
            if (rest.graphQLErrors.length > 0) {
                this.snackBar.open(rest.message, null, {
                    duration: Globals.durationSnackBarMessage,
                    panelClass: 'snack-bar-error',
                })
                console.error(`GraphQL server returned error. Operation: ${operationName} Error: ${rest.message}`, rest)
            } else {
                this.snackBar.open(rest.message, null, {
                    duration: Globals.durationSnackBarMessage,
                    panelClass: 'snack-bar-error',
                })
                console.error(`GraphQL request failed. Operation: ${operationName} Error: ${rest.message}`, rest)
            }

            if (isMutation) {
                this.httpStateService.addErrorOperation(operationName)
            }

            return throwError(() => new GraphQLError(rest.message))
        }
}

class GraphQLError extends Error {
    constructor(message) {
        super(message)
        this.name = 'GraphQLError'
    }
}
