<mat-card>
    <mat-card-content>
        <mat-card-title>{{title}}</mat-card-title>
        <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
        <form
            [formGroup]="formGroup"
            (ngSubmit)="submitForm(formGroup.value)"
            class="form"
        >
            <mat-form-field class="form-element">
                <input
                    matInput
                    [placeholder]="placeholder"
                    formControlName="value"
                />
                <mat-error
                    *ngIf="!formGroup.controls['value'].valid && formGroup.controls['value'].touched"
                >
                    Error in verified contact value
                </mat-error>
            </mat-form-field>
            <div class="form-element text-right">
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="button"
                    [disabled]="!formGroup.valid"
                >
                    {{ (verifiedContact.newValue ? 'verify' : 'update') | translate }}
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
