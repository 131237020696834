<mat-card>
    <mat-card-content>
        <mat-card-title>{{title}}</mat-card-title>
        <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
        <form [formGroup]="formGroup" class="form">
            <mat-form-field class="form-element">
                <input matInput [placeholder]="'label' | translate" formControlName="label" />
                <mat-error *ngIf="!formGroup.controls['label'].valid && formGroup.controls['label'].touched">
                    {{'missing' | translate}} {{'label' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput [placeholder]="'street' | translate" formControlName="street" />
                <mat-error *ngIf="!formGroup.controls['street'].valid && formGroup.controls['street'].touched">
                    {{'missing' | translate}} {{'street' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput [placeholder]="'city' | translate" formControlName="city" />
                <mat-error *ngIf="!formGroup.controls['city'].valid && formGroup.controls['city'].touched">
                    {{'missing' | translate}} {{'city' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput [placeholder]="'postalCode' | translate" formControlName="postalCode" />
                <mat-error *ngIf="!formGroup.controls['postalCode'].valid && formGroup.controls['postalCode'].touched">
                    {{'missing' | translate}} {{'postalCode' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-element">
                <input matInput [placeholder]="'state' | translate" formControlName="state" />
            </mat-form-field>
            <mat-form-field>
                <mat-select [placeholder]="'country' | translate" formControlName="country">
                    <mat-option *ngFor="let country of countries" [value]="country.code"
                        >{{country.name | translate}}</mat-option
                    >
                </mat-select>
            </mat-form-field>
            <div class="form-element text-right">
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="button"
                    [disabled]="!formGroup.valid"
                    (click)="submitForm(formGroup.value)"
                >
                    {{'save' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
