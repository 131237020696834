import { Component, Input, OnInit } from '@angular/core'
import { RegistrationService } from '../../services/registration.service'
import { Observable } from 'rxjs'
import { Registration } from '../../events.models'

@Component({
    selector: 'app-registrations-table',
    templateUrl: './registrations-table.component.html',
})
export class RegistrationsTableComponent implements OnInit {

    @Input()
    userId?: string = null
    @Input()
    openOrganizedActivity = false
    @Input()
    futureOnly = false
    registrations$: Observable<Registration[]>

    constructor(private registrationService: RegistrationService) {
    }

    ngOnInit() {
        this.registrations$ =
            this.userId ?
                this.registrationService.getUserRegistrations$({ userId: this.userId, futureOnly: this.futureOnly }) :
                this.registrationService.userRegistrations$
    }

    columns = ['activity', 'startTime', 'created', 'state', 'startNumber']
}
