<div class="responsive-table">
    <mat-table #table [dataSource]="activityResults$|async">

        <ng-container matColumnDef="organizedActivity">
            <mat-header-cell *matHeaderCellDef> {{'event' | translate}} </mat-header-cell>
            <mat-cell  *matCellDef="let element" [attr.data-label]="'event' | translate">
                <a [routerLink]="'/events/activities/' + element.organizedActivity.id">
                    {{element.organizedActivity.name | localize}}
                </a>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startTime">
            <mat-header-cell *matHeaderCellDef class="justify-content-end"> {{"startTime" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" [attr.data-label]="'startTime'|translate" class="justify-content-end">
                {{element.organizedActivity.startTime | localizeDate: "short"}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startNumber">
            <mat-header-cell *matHeaderCellDef class="justify-content-end"> {{'startNumber' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" [attr.data-label]="'startNumber' | translate" class="justify-content-end"> {{element.startNumber}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef class="justify-content-end">{{'time' | translate}} </mat-header-cell>
            <mat-cell  *matCellDef="let element" [attr.data-label]="'time' | translate" class="justify-content-end"> {{element.time | time}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef> {{'category' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" [attr.data-label]="'category' | translate">{{element.category}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="categoryPosition" class="justify-content-end">
            <mat-header-cell *matHeaderCellDef> {{'categoryPosition' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" [attr.data-label]="'categoryPosition' | translate" class="justify-content-end">
                <ng-container *ngIf="element.position > 0">{{element.position}} / {{element.positionBySex}} / {{element.positionByCategory}}</ng-container>
                <ng-container *ngIf="element.position == 0">{{'unofficial' | translate | uppercase}}</ng-container>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns"></mat-row>
        <p *matNoDataRow class="no-data">{{'noData' | translate}}</p>
    </mat-table>
</div>
