<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-content *ngIf="(checkingPayment$|async) == true">
                <h2>{{'payments.verifying' | translate}}</h2>
                <app-loader *ngIf="checkingPayment$|async"> </app-loader>
            </mat-card-content>
            <mat-card-content *ngIf="(checkingPayment$|async) == false">
                <h2>{{'payments.state'|translate}}</h2>
                <div *ngIf="payment$ | async as payment">
                    <div [ngSwitch]="payment.paymentState">
                        <div *ngSwitchCase="'Paid'">
                            <h3>{{'payments.successful'|translate}}</h3>
                            <button mat-raised-button color="primary" routerLink="/events/registrations">
                                {{'payments.continue_to_registrations'|translate}}
                            </button>
                        </div>
                        <div *ngSwitchCase="'Created'">
                            <h3>{{'payments.not_finished' | translate}}</h3>
                            <a mat-raised-button color="primary" [href]="payment.redirectUrl"
                                >{{'payments.finish' | translate}}</a
                            >
                        </div>
                        <div *ngSwitchDefault>
                            <h3>{{'PaymentState.' + payment.paymentState | translate}}</h3>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
