<div fxLayout="row wrap" *ngIf="organizerService.currentOrganizer$ | async as currentOrganizer">
    <!-- Card column -->
    <div fxFlex.gt-sm="100%" fxFlex="100" *ngIf="(organizers$ | async)?.length > 1">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{currentOrganizer.name}}</mat-card-title>
                <mat-card-subtitle>
                    ID: {{currentOrganizer.id}} • {{'isActive' | translate}}: {{(currentOrganizer.isActive ? 'yes' : 'no') | translate}}
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="row wrap">
                <div fxFlex.gt-sm="100%" fxFlex="100">
                    <mat-form-field>
                        <mat-label>{{'name' | translate}} ({{(organizers$ | async)?.length}})</mat-label>
                        <mat-select
                            *ngIf="(organizers$ | async)?.length <= 20"
                            [formControl]="organizerControl"
                            >
                            <mat-option *ngFor="let organizer of organizers$ | async" [value]="organizer">
                                {{organizer.name}}
                            </mat-option>
                        </mat-select>
                        <ng-container
                            *ngIf="(organizers$ | async)?.length > 20"
                            >
                            <input
                                type="text"
                                matInput
                                [formControl]="organizerControl"
                                [matAutocomplete]="auto"
                            />
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="stringify">
                                <mat-option *ngFor="let organizer of filteredOrganizers$ | async" [value]="organizer">
                                    {{organizer.name}} ({{organizer.eventCount ? organizer.eventCount : 0}})
                                </mat-option>
                            </mat-autocomplete>
                        </ng-container>
                    </mat-form-field>
                </div>
                <div fxFlex.gt-sm="50%" fxFlex="50">
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        class="button"
                        (click)="switchOrganizer()"
                        [disabled]="!organizerControl.value?.id"
                    >
                        {{'switch_organizer' | translate}}
                    </button>
                </div>
                <div fxFlex.gt-sm="50%" fxFlex="50" class="form-element text-right">
                    <button
                        mat-raised-button
                        color="warn"
                        type="submit"
                        class="button"
                        (click)="createOrganizer()"
                    >
                        {{'create_organizer' | translate}}
                    </button>
                </div>


<!--                <ul *ngIf="organizers?.length > 1">-->
<!--                    <li *ngFor="let organizer of organizers">-->
<!--                        {{organizer.name}} &gt;-->
<!--                        <a href="javascript:void(0)" (click)="organizerService.switchOrganizer(organizer)">{{'switch_organizer' | translate}}</a> &gt;-->
<!--                        <a href="javascript:void(0)" (click)="goToEvents(organizer)">{{'events' | translate}}</a>-->
<!--                    </li>-->
<!--                </ul>-->
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div fxFlex.gt-sm="100%" fxFlex="100" *ngIf="(events$ | async) as events">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap">
                    <div fxFlex.gt-sm="50%" fxFlex="50">
                        <h3>{{'events' | translate}} ({{events.length}})</h3>
                    </div>
                    <div fxFlex.gt-sm="50%" fxFlex="50" class="form-element text-right">
                        <button
                            mat-raised-button
                            color="warn"
                            type="submit"
                            class="button"
                            (click)="createOrganizedEvent()"
                        >
                            {{'create' | translate}} {{'event' | translate}}
                        </button>
                    </div>
                </div>
                <p *ngIf="text" class="alert alert-warning" [innerHTML]="text | marked: false"></p>
                <div class="responsive-table">
                    <mat-table #table [dataSource]="events">
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef> {{'name' | translate}} </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.name | localize}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="labels">
                            <mat-header-cell *matHeaderCellDef> {{'labels' | translate}} </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <ng-container
                                    *ngFor="let label of element.labels.slice(0, maxLabelsToShowInTable); let last = last;"
                                >
                                <span>{{label | localizeActivityLabel : (activityLabels$ | async) : (selectedLanguage$ | async).code}}</span
                                ><span *ngIf="!last">,&nbsp;</span>
                                </ng-container>
                                <span *ngIf="element?.labels?.length > maxLabelsToShowInTable">, ...</span>
                            </mat-cell>
                        </ng-container>
                        <!--                        <ng-container matColumnDef="workflow.state">-->
                        <!--                            <mat-header-cell *matHeaderCellDef> {{'State' | translate}} </mat-header-cell>-->
                        <!--                            <mat-cell *matCellDef="let element">-->
                        <!--                                {{"WorkflowState." + element?.workflow?.state | translate}}-->
                        <!--                            </mat-cell>-->
                        <!--                        </ng-container>-->
                        <ng-container matColumnDef="workflow.updated">
                            <mat-header-cell *matHeaderCellDef> {{'updated' | translate}} </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element?.workflow?.updated | localizeDate : 'short'}}
                            </mat-cell>
                        </ng-container>
                        <!--                        <ng-container matColumnDef="externalId">-->
                        <!--                            <mat-header-cell *matHeaderCellDef> {{'externalId' | translate}} </mat-header-cell>-->
                        <!--                            <mat-cell *matCellDef="let element"> {{element?.externalId}} </mat-cell>-->
                        <!--                        </ng-container>-->

                        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                        <mat-row
                            class="cursor-pointer"
                            (click)="navigateToDetail(row)"
                            *matRowDef="let row; columns: columns"
                        ></mat-row>
                        <p *matNoDataRow class="no-data">{{'noData'|translate}}</p>
                    </mat-table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
