<div class="carousel-container">
    <ng-container *ngIf="type === carouselTypes.gallery">
        <gallery
            *ngIf="pictures?.length"
            [id]="uuid"
            [counter]="false"
            [thumb]="true"
            (indexChange)="indexChange($event)"
            [imageSize]="'cover'"
            [loadingStrategy]="'default'"
            [dots]="pictures?.length > 1"
            [autoPlay]="autoPlay"
            (itemClick)="openLightbox()"
        ></gallery>
    </ng-container>
    <ng-container *ngIf="type === carouselTypes.lightbox">
        <img
            mat-card-image
            class="cursor-pointer"
            (click)="openLightbox()"
            [src]="galleryImage.ref"
            [attr.alt]="galleryImage.title"
        />
    </ng-container>
</div>
