import { Component, OnInit } from '@angular/core'
import { Organizer } from '../../organizers.models'
import { OrganizerService } from '../../services/organizer.service'
import { Observable, switchMap } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ActivatedRoute } from '@angular/router'
import { WindowService } from '../../../core/services/window.service'
import { OrganizedActivity } from '../../../events/events.models'
import { OrganizedActivityService } from '../../../events/services/organized-activity.service'

@UntilDestroy()
@Component({
  selector: 'app-organizer-profile',
  templateUrl: './organizer-profile.component.html'
})
export class OrganizerProfileComponent implements OnInit {

    public organizer: Organizer
    public organizedActivities$: Observable<OrganizedActivity[]>

    constructor(
        private route: ActivatedRoute,
        private organizerService: OrganizerService,
        private organizedActivityService: OrganizedActivityService,
        public windowService: WindowService
    ) {

    }

    ngOnInit(): void {
        this.route.params
            .pipe(
                switchMap((params, index) => {
                    return this.organizerService.getOrganizer$(params['id'])
                }),
                untilDestroyed(this),
            )
            .subscribe((organizer) => {
                this.organizer = organizer
                this.organizedActivities$ = this.organizedActivityService.getOrganizedActivities$({
                    activityTypes: [],
                    ascending: true,
                    dateFrom: new Date(),
                    dateTo: undefined,
                    distanceRanges: [],
                    labels: [],
                    limit: 50,
                    maxDistanceFromPosition: 10000,
                    myPosition: undefined,
                    organizerIds: [organizer.id]

                }, (length, isFinished) => {
                    console.log('organized activities length =', length, 'isFinished =', isFinished)
                })
            })
    }

}
