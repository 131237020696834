import { CapacitorConfig } from '@capacitor/cli'

// https://capacitorjs.com/docs/config
const config: CapacitorConfig = {
    appId: 'app.actigo',
    appName: 'Actigo',
    webDir: 'dist/actigo',
    bundledWebRuntime: false,
    loggingBehavior: 'debug', // 'none' | 'production'
    plugins: {
        PushNotifications: {
            presentationOptions: ['badge', 'sound', 'alert'],
        },
        SplashScreen: {
            launchAutoHide: false
        }
    },
}

export default config
