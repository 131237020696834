import { gql } from 'apollo-angular'
import { WALLPOST_FRAGMENT } from '../users/users.fragments'
import { MESSAGE_FRAGMENT } from './core.fragments'

export const CREATE_POST = gql`
    ${WALLPOST_FRAGMENT}
    mutation createPost($command: CreateWallPost!) {
        createPost(command: $command) {
            ...WallPostFields
        }
    }
`

export const UNSUBSCRIBE_POST = gql`
    mutation unsubscribePost($command: UnsubscribeWallPost!) {
        unsubscribePost(command: $command)
    }
`

export const RECEIVE_POST = gql`
    mutation receivePost($command: ReceiveWallPost!) {
        receivePost(command: $command)
    }
`
export const REMOVE_POST = gql`
    mutation removePost($command: RemoveWallPost!) {
        removePost(command: $command)
    }
`
export const CREATE_LIKE = gql`
    mutation createLike($command: CreateLike!) {
        createLike(command: $command)
    }
`

export const DELETE_LIKE = gql`
    mutation deleteLike($command: DeleteLike!) {
        deleteLike(command: $command)
    }
`

export const TOGGLE_LIKE = gql`
    mutation toggleLike($command: ToggleLike!) {
        toggleLike(command: $command)
    }
`
export const CREATE_MESSAGE = gql`
    ${MESSAGE_FRAGMENT}
    mutation createMessage($command: CreateMessage!) {
        createMessage(command: $command) {
            ...MessageFields
        }
    }
`
