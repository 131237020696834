import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { ActionButtonComponent } from 'app/core/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [ActionButtonComponent],
    imports: [CommonModule, FlexModule, MatButtonModule, MatIconModule],
    providers: [],
    exports: [ActionButtonComponent],
})
export class ActionButtonModule {}
