import { Component, Input } from '@angular/core'
import { Article } from '../../articles.models'
import { WindowService } from 'app/core/services/window.service'

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
})
export class ArticleCardComponent {

    @Input() article: Article

    constructor(
        public windowService: WindowService
    ) {
    }

}
