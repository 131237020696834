import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserProfileComponent } from './components/user-profile/user-profile.component'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTableModule } from '@angular/material/table'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CoreModule } from 'app/core/core.module'
import { UsersRoutingModule } from './users-routing.module'
import { UserActivitiesComponent } from './components/user-activities/user-activities.component'
import { TranslateModule } from '@ngx-translate/core'
import { PipesModule } from 'app/core/pipes/pipes.module'
import { UserSettingsComponent } from './components/user-settings/user-settings.component'
import { MaterialModule } from 'app/layouts/material.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { UserConnectionsComponent } from './components/user-connections/user-connections.component'
import { UserAccessKeysComponent } from './components/user-access-keys/user-access-keys.component'
import { MapModule } from 'app/core/components/map/map.module'
import { UserAccessKeyComponent } from './components/user-access-key/user-access-key.component'
import { UserTrackerComponent } from './components/user-tracker/user-tracker.component'
import { UserNicknameComponent } from './components/user-nickname/user-nickname.component'
import { UserActivityCardComponent } from 'app/users/components/user-activity-card/user-activity-card.component'
import { MatPaginatorModule } from '@angular/material/paginator'
import { UserActivityComponent } from './components/user-activity/user-activity.component'
import { TrackProfileModule } from 'app/core/components/track-profile/track-profile.module'
import { ActionButtonModule } from '../core/components/action-button/action-button.module'
import { UserDeleteComponent } from './components/user-delete/user-delete.component'
import { UserPictureComponent } from './components/user-picture/user-picture.component'
import { UserSetupModalComponent } from './components/user-setup-modal/user-setup-modal.component'
import { FileUploaderModule } from '../core/components/file-uploader/file-uploader.module'
import { UserFeedbackComponent } from './components/user-feedback/user-feedback.component'
import { UserPostRulesComponent } from './components/user-post-rules/user-post-rules.component'
import { ChipsRadioInputModule } from '../core/components/forms/chips-radio-input/chips-radio-input.module'

@NgModule({
    declarations: [
        UserProfileComponent,
        UserActivitiesComponent,
        UserSettingsComponent,
        UserConnectionsComponent,
        UserAccessKeysComponent,
        UserAccessKeyComponent,
        UserFeedbackComponent,
        UserTrackerComponent,
        UserNicknameComponent,
        UserActivityCardComponent,
        UserActivityComponent,
        UserDeleteComponent,
        UserPictureComponent,
        UserSetupModalComponent,
        UserFeedbackComponent,
        UserPostRulesComponent,
    ],
    exports: [UserProfileComponent, UserActivityCardComponent, UserAccessKeysComponent],
  imports: [
    CoreModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    UsersRoutingModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    MapModule,
    MatPaginatorModule,
    TrackProfileModule,
    ActionButtonModule,
    FileUploaderModule,
    ChipsRadioInputModule,
  ],
})
export class UsersModule {}
