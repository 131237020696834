<h1 mat-dialog-title>{{'accessKeys'|translate}}</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>{{'name'|translate}}</mat-label>
        <input matInput tabindex="1" [(ngModel)]="data.name" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'secret'|translate}}</mat-label>
        <input matInput tabindex="2" [(ngModel)]="data.secret" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'description'|translate}}</mat-label>
        <input matInput tabindex="3" [(ngModel)]="data.info" />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data" tabindex="4">{{'create'|translate}}</button>
    <button mat-button (click)="onNoClick()" tabindex="-1">{{'cancel'|translate}}</button>
</div>
