import { NgModule } from '@angular/core'

import { LayoutService } from 'app/layouts/services/layout.service'
import { HorizontalMenuItems } from './menu-items/horizontal-menu-items'

import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion'

@NgModule({
    declarations: [AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective],
    exports: [AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective],
    providers: [LayoutService, HorizontalMenuItems],
})
export class SharedModule {}
