// it has to be in order like tab!!!
export enum OrganizedEventModelTab {
    settings,
    media,
    activity,
}

export interface OrganizedEventParams {
    activeTab: string
    id: string
}
