import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { getPlatforms } from '@ionic/angular'

@Component({
  template: '',
})
export class LandingComponent {

    constructor(private router: Router) {
        let platforms = getPlatforms()
        if (platforms.includes('android') && !platforms.includes('hybrid'))
            window.open('https://actigo.app/google', '_self')
        else if (platforms.includes('ios') && !platforms.includes('hybrid'))
            window.open('https://actigo.app/apple', '_self')
        else
            router.navigateByUrl('/')
    }
}
