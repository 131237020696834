import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Team, UserProfile } from 'app/users/users.models'
import { ActivityType, Currency, Position } from 'app/core/core.models'
import * as R from 'ramda'
import * as R_ from 'ramda-extension'
import { WindowService } from 'app/core/services/window.service'
import { DateValidators } from 'app/core/validators/DateValidators'
import { LanguageService } from 'app/core/services/language.service'
import dayjs from 'dayjs'
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators'
import { sec2time, str2sec } from 'app/core/core.functions'
import { EnumService } from 'app/core/services/enum.service'
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, startWith, switchMap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { Globals } from 'app/app.consts'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TeamService } from '../../services/team.service'

@UntilDestroy()
@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit, OnChanges {
    formGroup: FormGroup
    currencies = ['CZK', 'EUR']
    activityTypes$ = new BehaviorSubject([
        ActivityType.Run,
        ActivityType.Walk,
        ActivityType.Triathlon,
        ActivityType.NordicSki,
        ActivityType.OCR
    ])//this.enumService.activityTypes$
    activityLabels$ = this.enumService.activityLabels$
    selectedLanguage$ = this.languageService.selectedLanguage$
    teamOptions$: Observable<Team[]>

    constructor(
        public fb: FormBuilder,
        public windowService: WindowService,
        private enumService: EnumService,
        private teamService: TeamService,
        private languageService: LanguageService
    ) {
        this.formGroup = this.fb.group({
            givenName: [null, [Validators.required]],
            surname: [null, [Validators.required]],
            language: [null, [Validators.required]],
            sex: [null, [Validators.required]],
            birthDate: [null, [Validators.required, DateValidators.dateIsInPast()]],
            description: null,
            teamName: null,
            maxDistance: null,
            homeLocation: null,
            allowMatchmaking: [null, [Validators.required]],
            shareActivitiesWithFollowers: [null, [Validators.required]],
            sameSexOnly: [null, [Validators.required]],
            isPublic: [null, [Validators.required]],
            runningMonthly: new FormControl(null, [
                RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
            ]),
            runningShoeCount: new FormControl(null, [
                RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false }),
            ]),
            runningPB: new FormGroup({
                time: new FormControl(null, []),
                distance: new FormControl(10.0, [
                    RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                ]),
                distanceUnit: new FormControl('KM'),
            }),
            registrationPriceLimit: new FormGroup({
                value: new FormControl(null, [
                    //Validators.required,
                    RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                ]),
                currency: new FormControl(Currency.CZK),
            }),
            activityTypes: new FormControl([]),
            activityLabels: new FormControl([])
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        //TODO fix disabled fields are not send - failed to coerce error
        // if (this.userProfile.sex != Sex.None) this.formGroup.controls.sex.disable()
        // if (this.userProfile.birthDate != undefined) this.formGroup.controls.birthDate.disable()
    }

    changeLanguage(event) {
        this.languageService.changeLanguageByCode(event.value)
    }

    @Input()
    isSetup = false

    @Input()
    userProfile: UserProfile

    @Output()
    saveProfile = new EventEmitter<UserProfile>()

    ngOnInit(): void {
        const input = R.clone(this.userProfile)
        if (input.runningPB)
            input.runningPB.time = input.runningPB.time ? sec2time(input.runningPB.time / 1000) : ''
        if (input.registrationPriceLimit && !input.registrationPriceLimit.value)
            input.registrationPriceLimit.value = ''
        this.formGroup.patchValue(input)
        this.teamOptions$ = this.formGroup.controls['teamName'].valueChanges.pipe(
            startWith(''),
            filter(R_.isNotEmpty),
            debounceTime(Globals.valueChangeDebounce),
            distinctUntilChanged(),
            switchMap((value) => {
                return this.teamService.getTeams$(typeof value === 'string' ? value : value?.name, 20)
            }),
            untilDestroyed(this),
        )
    }

    displayTeam(value: any) {
        return typeof value === 'string' ? value : value?.name ?? ''
    }

    updatePosition(position: Position) {
        this.formGroup.controls['homeLocation'].setValue(position)
    }

    submitForm(value: UserProfile): void {
        let birthDate = dayjs(value.birthDate)
        value.birthDate = isNaN(birthDate.year()) || isNaN(birthDate.month()) || isNaN(birthDate.date()) ? null :
            `${birthDate.year()}-${(birthDate.month() + 1).toString().padStart(2, '0')}-${birthDate.date().toString().padStart(2, '0')}T00:00:00.000Z`

        const input = R.clone(value)
        input.runningPB.time = input.runningPB.time ? str2sec(input.runningPB.time) * 1000 : null
        input.teamName = typeof input.teamName === 'string' ? input.teamName : input.teamName?.name
        delete input['homeLocation']?.['__typename']
        delete input['__typename']
        this.saveProfile.emit(input)
    }
}
