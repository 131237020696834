import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { UserService } from 'app/users/services/user.service'
import { User, UserProfile } from 'app/users/users.models'
import { Address, VerifiedContact } from 'app/core/core.models'
import { ActivatedRoute, Router } from '@angular/router'
import { filter, tap } from 'rxjs/operators'
import { switchMap } from 'rxjs'
import { LoginService } from 'app/users/services/login.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Globals } from '../../../app.consts'
import { TranslateService } from '@ngx-translate/core'

@UntilDestroy()
@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
})
export class UserSettingsComponent implements OnInit {
    formGroup: FormGroup
    user: User = null

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private loginService: LoginService,
        private translateService: TranslateService,
        private snackBar: MatSnackBar,
        public fb: FormBuilder,
    ) {
        this.formGroup = this.fb.group({
            contactEmail: [null, [Validators.required]],
        })
    }

    ngOnInit() {
        this.loginService.userLoggedIn$
            .pipe(
                tap((user) => {
                    this.user = user
                }),
                switchMap(() => this.route.queryParams),
                untilDestroyed(this),
            )
            .subscribe((params) => {
                let property = this.route.snapshot.params['property']
                let type = this.route.snapshot.params['type']
                let code = params['code']
                if (type == 'verify' && typeof code != 'undefined') {
                    console.log(`verification property=${property} code=${code}`)
                    if (property == 'contact-email') {
                        this.userService.verifyUserContactEmail$(code).subscribe((it) => {
                            console.log('verified contactEmail', it)
                            if (it) {
                                this.user.contactEmail.value = this.user.contactEmail.newValue
                                this.openSnackbar(this.translateService.instant('contactEmailVerified'))
                            } else {
                                this.openSnackbar(
                                    this.translateService.instant('contactEmailNotVerified', 'snack-bar-error'),
                                )
                            }
                            this.router.navigateByUrl('/users/settings')
                        })
                    }
                    if (property == 'phone-number') {
                        this.userService.verifyUserPhoneNumber$(code).subscribe((it) => {
                            console.log('verified phoneNumber', it)
                            if (it) {
                                this.user.phoneNumber.value = this.user.phoneNumber.newValue
                                this.openSnackbar(this.translateService.instant('phoneNumberVerified'))
                            } else {
                                this.openSnackbar(
                                    this.translateService.instant('phoneNumberNotVerified', 'snack-bar-error'),
                                )
                            }
                            this.router.navigateByUrl('/users/settings')
                        })
                    }
                }
            })
    }

    updateAddress(address: Address) {
        this.userService.updateUserAddress(this.user.id, address).subscribe((it) => {
            console.log('updated address', it)
        })
    }

    updateProfile(profile: UserProfile) {
        this.userService.updateUserProfile(this.user.id, profile).subscribe((it) => {
            console.log('updated profile', it)
            this.loginService.currentUser = it
        })
    }

    updateContactEmail(verifiedContact: VerifiedContact) {
        this.userService.updateUserContactEmail$(this.user.id, verifiedContact.value).subscribe((it) => {
            console.log('updated contactEmail', it)
            if (it) this.openSnackbar(this.translateService.instant('contactEmailUpdated'))
            else this.openSnackbar(this.translateService.instant('contactEmailNotUpdated', 'snack-bar-error'))
        })
    }

    updatePhoneNumber(verifiedContact: VerifiedContact) {
        this.userService.updateUserPhoneNumber$(this.user.id, verifiedContact.value).subscribe((it) => {
            console.log('updated phoneNumber', it)
            if (it) this.openSnackbar(this.translateService.instant('phoneNumberUpdated'))
            else this.openSnackbar(this.translateService.instant('phoneNumberNotUpdated', 'snack-bar-error'))
        })
    }

    openSnackbar(message: string, panelClass: string = 'snack-bar-info') {
        console.log('opening snackbar with message', message)
        this.snackBar.open(message, null, {
            duration: Globals.durationSnackBarMessage,
            panelClass: panelClass,
        })
    }
}
