import { Injectable } from '@angular/core'
import { BehaviorSubject, from, Observable, zip } from 'rxjs'
import { Router } from '@angular/router'
import { Capacitor } from '@capacitor/core'
import { Device, DeviceId, DeviceInfo } from '@capacitor/device'
import { Browser } from '@capacitor/browser'
import { LanguageService } from './language.service'
import { scrollToElementFnc } from 'app/core/utils/scroll-to-element.fnc'
import { Share } from '@capacitor/share'
import { UntilDestroy } from '@ngneat/until-destroy'
import { PostService } from './post.service'
import { Post } from 'app/core/core.models'
import { environment } from 'environments/environment'
import { map } from 'rxjs/operators'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class WindowService {
    // todo language by browser or url
    selectedLanguage$ = this.languageService.selectedLanguage$
    darkMode$ = new BehaviorSubject<boolean>(false)
    screenOrientation$ = new BehaviorSubject<any | null>(null)
    public notificationToken$ = new BehaviorSubject<string>('')
    isProduction = environment.production

    get currentLanguageCode() {
        return this.selectedLanguage$.value.code
    }

    get isNative() {
        return Capacitor.isNativePlatform()
    }

    get listLimit() {
        return this.isNative ? 10 : 20
    }

    get device$(): Observable<DeviceId & DeviceInfo> {
        return zip(from(Device.getId()), from(Device.getInfo()))
            .pipe(map(it => Object.assign({}, it[0], it[1])))
    }

    constructor(
        private router: Router,
        private postService: PostService,
        private languageService: LanguageService,
    ) {
        window.addEventListener(
            'orientationchange',
            () => {
                this.screenOrientation$.next(window.orientation)
            },
            false,
        )
    }

    receive(post: Post) {
        if (post.ref) {
            this.router.navigate(['/'].concat(post.ref.split('/'))).then(
                (value) => {
                    console.log('Receiving post after reference routing', value)
                    this.postService.receivePost$(post.id).subscribe((result) => {
                        console.log('Post received', result)
                    })
                },
                (reason) => {
                    alert('Post routing failed')
                },
            )
        } else {
            console.log('Receiving post w/o reference')
        }
    }

    //TODO add optional browserFinished/browserPageLoaded listener callbacks
    open(url: string, target: string = '_self') {
        if (this.isNative && !!url.startsWith('tel:') && !!url.startsWith('mailto:') && !!url.startsWith('webcal:')) {
            console.log("Native browser open URL", url)
            Browser.open({ url: url }).then(() => {
                console.log(`Browser open ${url} done`)
            }).catch(console.error)
        } else {
            console.log("Window open URL", url)
            window.open(url, target)
        }
    }

    share(url: string, text: string = '') {
        Share.canShare().then((result) => {
            let options = {
                title: 'Actigo',
                dialogTitle: 'Actigo',
                text: text,
                url: url
            }
            if (result.value) {
                Share.share(options).then(() => {
                    console.log(`Sharing ${url} via capacitor`)
                }).catch(console.error)
            } else if (navigator.share) {
                navigator.share(options).then(() => {
                    console.log(`Sharing ${url} via navigator`)
                }).catch(console.error)
            } else {
                console.log(`Sharing ${url} via browser open`)
                this.open(url)
            }
        })
    }

    scrollTop() {
        scrollToElementFnc('topbar', 0, 'content')
    }
}
