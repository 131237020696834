import { gql } from 'apollo-angular'

export const ARTICLE_FRAGMENT = gql`
    fragment ArticleFields on Article {
        id
        icon
        ref
        name
        title
        text
        picture
        isCommercial
        updated
    }
`
