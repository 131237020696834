import { Injectable } from '@angular/core'
import { ApolloService } from 'app/core/services/apollo.service'
import { UntilDestroy } from '@ngneat/until-destroy'
import { TEAMS } from '../users.queries'
import { Team } from '../users.models'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class TeamService {

    constructor(private apolloService: ApolloService) {
    }

    getTeams$(name?: string, limit: number = 20) {
        return this.apolloService.watchQuery<Team[]>({
            fetchPolicy: 'no-cache',
            query: TEAMS,
            variables: {
                query: {
                    name: name,
                    limit: limit,
                },
            },
        })
    }
}
