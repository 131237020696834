import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core'
import { OrganizedEventServiceState } from 'app/events/components/organized-event/organized-event-service.state'
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs/tab-group'
import { FormControl } from '@angular/forms'

@Component({
    selector: 'app-organized-event-activities',
    templateUrl: './organized-event-activities.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedEventActivitiesComponent {
    @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup
    activeIndex = 0
    isCreateTab = false
    onlyLastControl = new FormControl(this.organizedEventServiceState.onlyLastActivities$.value)

    constructor(public readonly organizedEventServiceState: OrganizedEventServiceState) {
        this.onlyLastControl.valueChanges.subscribe((value) => {
            console.log('VALUE CHANGE', value)
            this.organizedEventServiceState.onlyLastActivities$.next(value)
        })
    }

    selectedTabChange(matTabChangeEvent: MatTabChangeEvent) {
        // this.activeIndex = matTabChangeEvent.index
        // this.isCreateTab =
        //     this.organizedEventServiceState.organizedEvent$?.value?.activities?.length === matTabChangeEvent.index
    }

    trackByKey = (index: number, obj): string => {
        return obj.id
    }

    public changeToLastFilledTab() {
        this.tabGroup.selectedIndex = this.tabGroup._tabs.length - 2
    }
}
