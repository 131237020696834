import { gql } from 'apollo-angular'
import { ORGANIZER_FRAGMENT } from './organizers.fragments'
import { PERSON_FRAGMENT } from '../core/core.fragments'

export const CREATE_ORGANIZER = gql`
    ${ORGANIZER_FRAGMENT}
    mutation createOrganizer($command: CreateOrganizer!) {
        createOrganizer(command: $command) {
            ...OrganizerFields
        }
    }
`
export const UPDATE_ORGANIZER = gql`
    mutation updateOrganizer($command: UpdateOrganizer!) {
        updateOrganizer(command: $command)
    }
`
export const UPDATE_ORGANIZER_ADDRESS = gql`
    mutation updateOrganizerAddress($command: UpdateOrganizerAddress!) {
        updateOrganizerAddress(command: $command)
    }
`
export const ADD_ORGANIZER_MEMBER = gql`
    ${PERSON_FRAGMENT}
    mutation addOrganizerMember($command: AddOrganizerMember!) {
        addOrganizerMember(command: $command) {
            person {
                ...PersonFields
            }
            role
        }
    }
`
export const REMOVE_ORGANIZER_MEMBER = gql`
    mutation removeOrganizerMember($command: RemoveOrganizerMember!) {
        removeOrganizerMember(command: $command)
    }
`
