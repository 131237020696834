<mat-card>
    <mat-card-header>
        <mat-card-title> {{article.title}} </mat-card-title>
        <mat-card-subtitle>
            {{'updated' | translate}}: {{article.updated | localizeDate: 'short'}} •
            {{'source' | translate}}: <a [href]="article.ref" target="_blank">Běhej.com</a>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content [innerHTML]="article.text | keepHtml"></mat-card-content>
    <app-messages entityType="Article" [entityId]="article.id"></app-messages>
</mat-card>
