<ng-template #search let-data="data">
    <mat-card-content>
        <div fxLayout="row wrap">
            <div fxFlex.gt-sm="25%" fxFlex="100">
                <form [formGroup]="addressForm" class="basic-form">
                    <div class="me-lg-4 me-sm-0 mb-4">
                        <mat-form-field>
                            <input
                                type="text"
                                [placeholder]="'position' | translate"
                                formControlName="address"
                                matInput
                                [matAutocomplete]="auto"
                            />
                            <mat-autocomplete
                                #auto="matAutocomplete"
                                (optionSelected)="addressSelected($event)"
                                [displayWith]="displayAddressFn"
                            >
                                <mat-option *ngFor="let option of locationOptions$ | async" [value]="option">
                                    {{option.formatted_address}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <app-map
                            mapHeight="150pt"
                            [inputMarkerAllowed]="true"
                            [inputMarkerRemovingAllowed]="false"
                            (inputMarkerAction)="updatePosition($event)"
                            [inputMarker]="this.form.controls['myPosition'].value"
                            [darkMode]="windowService.darkMode$ | async"
                            class="mb-1 d-block"
                        ></app-map>
                    </div>
                </form>
            </div>

            <div fxFlex.gt-sm="75%" fxFlex="100">
                <form [formGroup]="form" class="basic-form">
                    <div fxLayout="row wrap" fxFlexAlign="center" class="row">
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="50">
                            <mat-form-field>
                                <mat-label>{{'or' | translate | uppercase}} {{'name' | translate}}</mat-label>
                                <input matInput formControlName="name" />
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="50">
                            <mat-form-field>
                                <input
                                    type="text"
                                    [placeholder]="'organizer' | translate"
                                    formControlName="organizerName"
                                    matInput
                                    />
<!--                                    [matAutocomplete]="auto2"-->
<!--                                />-->
<!--                                <mat-autocomplete-->
<!--                                    #auto2="matAutocomplete"-->
<!--                                    (optionSelected)="organizerSelected($event)"-->
<!--                                    [displayWith]="displayOrganizerFn"-->
<!--                                >-->
<!--                                    <mat-option *ngFor="let option of organizerOptions$ | async" [value]="option">-->
<!--                                        {{option.name}}-->
<!--                                    </mat-option>-->
<!--                                </mat-autocomplete>-->
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxFlexAlign="center" class="row">
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="50">
                            <mat-form-field>
                                <mat-select formControlName="activityTypes" [placeholder]="'activityType' | translate">
                                    <mat-option [value]="[]">-</mat-option>
                                    <mat-option
                                        *ngFor="let type of data.activityTypes | arrayOfValuesToOptions"
                                        [value]="type.value"
                                        >{{"ActivityType." + type.label | translate}}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="50">
                            <mat-form-field>
                                <mat-select
                                    formControlName="maxDistanceFromPosition"
                                    [placeholder]="'maxDistanceFromPosition' | translate"
                                >
                                    <mat-option
                                        *ngFor="let option of maxDistanceFromPositionOptions"
                                        [value]="option.value"
                                        >{{"MaxDistance." + option.label | translate}}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="50">
                            <mat-form-field>
                                <input
                                    matInput
                                    [matDatepicker]="dateFrom"
                                    [placeholder]='"dateFrom" | translate'
                                    formControlName="dateFrom"
                                />
                                <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                                <mat-datepicker #dateFrom></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="50">
                            <mat-form-field>
                                <input
                                    matInput
                                    [matDatepicker]="dateTo"
                                    [placeholder]='"dateTo" | translate'
                                    formControlName="dateTo"
                                />
                                <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                                <mat-datepicker #dateTo></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <p>
                            <app-chips-radio-input
                                [control]="$any(form.get('distanceRanges'))"
                                [options]='$any(distanceRangesOptions) | optionsTranslate: null: "DistanceRange.": true'
                            >
                            </app-chips-radio-input>
                        </p>
                        <p>
                            <app-chips-radio-input
                                [control]="$any(form.get('labels'))"
                                [options]='data.activityLabels | localizedEnumOptions : data.language.code'
                            >
                            </app-chips-radio-input>
                        </p>
                    </div>

                    <!--
        <mat-checkbox [formControl]="$any(searchOrganizedActivitiesForm.get('myEvents'))" [disabled]="true">
            {{ 'my_activities' | translate }}
        </mat-checkbox>
        -->
                </form>
            </div>
        </div>
    </mat-card-content>
</ng-template>
<ng-container
    *ngIf="{
        activityLabels: organizedActivitiesStateService.activityLabels$ | async,
        activityTypes: organizedActivitiesStateService.activityTypes$ | async,
        language: windowService.selectedLanguage$ | async
    } as data"
>
    <mat-card *ngIf="data.activityLabels && data.activityTypes && data.language" class="organized-activity-search--form">
        <!--        <mat-tab-group>-->
        <!--            <mat-tab>-->
        <!--                <ng-template mat-tab-label> <mat-icon>search</mat-icon>&nbsp;{{'search' | translate}} </ng-template>-->
        <!--                <ng-container *ngTemplateOutlet="search; context: {data: data}"></ng-container>-->
        <!--            </mat-tab>-->
        <!--            <mat-tab [disabled]="true">-->
        <!--                <ng-template mat-tab-label>-->
        <!--                    <mat-icon>person</mat-icon>&nbsp;{{'personalized' | translate}}-->
        <!--                </ng-template>-->
        <!--            </mat-tab>-->
        <!--            <mat-tab>-->
        <!--                <ng-template mat-tab-label> <mat-icon>star</mat-icon>&nbsp;{{'featured' | translate}} </ng-template>-->
        <!--            </mat-tab>-->
        <!--            <mat-tab>-->
        <!--                <ng-template mat-tab-label>-->
        <!--                    <mat-icon>trending_up</mat-icon>&nbsp;{{'trending' | translate}}-->
        <!--                </ng-template>-->
        <!--            </mat-tab>-->
        <!--            <mat-tab>-->
        <!--                <ng-template mat-tab-label> <mat-icon>new_releases</mat-icon>&nbsp;{{'new' | translate}} </ng-template>-->
        <!--            </mat-tab>-->
        <!--        </mat-tab-group>-->
        <ng-container>
            <ng-container *ngTemplateOutlet="search; context: {data: data}"></ng-container>
        </ng-container>
        <mat-card-content class="pt-0">
            <div class="d-flex">
                <!--                <mat-button-toggle-group (change)="viewChange($event)" [value]="view">-->
                <!--                    <mat-button-toggle-->
                <!--                        *ngFor="let buttonsGroup of buttonsGroups"-->
                <!--                        [checked]="buttonsGroup.isDefault"-->
                <!--                        [disabled]="buttonsGroup.disabled"-->
                <!--                        [aria-label]="buttonsGroup.ariaLabel | translate"-->
                <!--                        [value]="buttonsGroup.value"-->
                <!--                    >-->
                <!--                        <mat-icon>{{buttonsGroup.icon}}</mat-icon>-->
                <!--                    </mat-button-toggle>-->
                <!--                </mat-button-toggle-group>-->
                <button
                    mat-raised-button
                    color="accent"
                    class="me-2 mb-2 align-self-end ml-auto"
                    *ngIf="!inResults"
                    (click)="goToActivities();goToActivities(true)"
                >
                    <mat-icon>search</mat-icon>
                    {{ 'searchButton' | translate }}
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    class="me-2 mb-2 align-self-end ml-auto"
                    *ngIf="inResults"
                    (click)="goToActivities(true)"
                >
                    <mat-icon>search</mat-icon>
                    {{ 'searchButton' | translate }}
                </button>
            </div>
        </mat-card-content>
    </mat-card>
    <!--    <div class="h4 text-shadow text-center">-->
    <!--        {{organizedActivitiesStateService.activityCount$ | async}} {{'result_s' | translate | lowercase}}-->
    <!--    </div>-->
</ng-container>
