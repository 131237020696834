import * as R from 'ramda'
import { AbstractControl, FormArray, FormControl } from '@angular/forms'
import { LanguageCode } from '../constants/languages'

export const localizeArrayForm = (formArray: FormArray | AbstractControl, language: LanguageCode): FormControl =>
    R.pipe(
        R.prop('controls'),
        R.find(R.pathEq(['value', 'language'], language)),
        R.path(['controls', 'text']),
    )(formArray)
