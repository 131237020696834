import { Component, OnInit } from '@angular/core'
import { PostService } from '../../core/services/post.service'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
})
export class UnsubscribeComponent implements OnInit {

    id = this.route.snapshot.params['id']
    result?: boolean

    constructor(
        private route: ActivatedRoute,
        private postService: PostService
    ) { }

    ngOnInit() {
        this.postService.unsubscribePost$(this.id).subscribe((result) => {
            this.result = result
        })
    }

}
