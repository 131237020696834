import { Component } from '@angular/core'
import { LoginService } from 'app/users/services/login.service'

@Component({
    selector: 'app-activity-results',
    templateUrl: './activity-results.component.html',
})
export class ActivityResultsComponent {

    constructor(
        public readonly loginService: LoginService,
    ) {}
}
