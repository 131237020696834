import { Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject, switchMap } from 'rxjs'
import * as R_ from 'ramda-extension'
import { filter } from 'rxjs/operators'
import { Message, MessageType } from 'app/core/core.models'
import { MessageService } from 'app/core/services/message.service'
import { LoginService } from 'app/users/services/login.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MentionConfig } from "angular-mentions";

@UntilDestroy()
@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
})
export class MessagesComponent implements OnInit {
    @Input()
    entityType: string

    @Input()
    entityId: string

    @Input()
    isEager: boolean = true

    @Input()
    type: MessageType = MessageType.Post

    @Input()
    entityMessages: boolean = false

    @Input()
    isShowingPerson: boolean = true

    @Input()
    isShowingInput: boolean = true

    messages$ = new BehaviorSubject<Message[]>([])
    text = ''

    constructor(
        private readonly messageService: MessageService,
        public loginService: LoginService
    ) {}

    ngOnInit(): void {
        if (this.entityMessages) {
            // message thread
            this.messageService
                .getEntityMessages$(this.entityId)
                .pipe(untilDestroyed(this))
                .subscribe((messages) => {
                    messages.forEach((m) => {
                        this.fillWithChildren(m, messages)
                    })
                    let thread = messages.filter((m) => m.parentId == null)
                    console.log('message thread', thread)
                    this.messages$.next(thread)
                })
        } else {
            this.messageService
                .getMessages$(this.entityType, this.entityId, this.isEager ? [] : [this.type], null)
                .pipe(untilDestroyed(this))
                .subscribe((messages) => {
                    if (this.isEager) {
                        messages.forEach((m) => {
                            this.fillWithChildren(m, messages)
                        })
                    }
                    console.log('messages', messages)
                    this.messages$.next(messages.filter((m) => m.parentId == null))
                })
        }
    }

    fillWithChildren(message: Message, messages: Message[]) {
        message.children = []
        messages.forEach((m) => {
            if (message != m && message.id == m.parentId)
                message.children.push(m)
        })
    }

    createMessage() {
        console.log('createMessage', this.text)
        if (!this.loginService.isLoggedIn) {
            this.loginService.login()
        } else {
            this.messageService
                .createMessage$(this.entityType, this.entityId, this.text, this.type, null, null)
                .subscribe((message) => {
                    this.messages$.next([message].concat(this.messages$.value))
                    this.text = ''
                })
        }
    }
}
