import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WallPost } from 'app/core/core.models'
import { LoginService } from 'app/users/services/login.service'
import { WindowService } from 'app/core/services/window.service'
import { UserService } from 'app/users/services/user.service'
import { OrganizedActivityService } from 'app/events/services/organized-activity.service'
import { ArticleService } from 'app/articles/services/article.service'
import { Router } from '@angular/router'
import { LikeService } from 'app/core/services/like.service'
import { filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import * as R_ from 'ramda-extension'
import { environment } from 'environments/environment'
import { UserFollowStatus } from  'app/users/users.models'
import { server } from 'app/core/core.server'
import { MatDialog } from '@angular/material/dialog'
import { UserFeedbackComponent } from '../../users/components/user-feedback/user-feedback.component'

@UntilDestroy()
@Component({
    selector: "app-wall-post",
    templateUrl: "./wall-post.component.html",
})
export class WallPostComponent implements OnInit {

    @Input()
    wallPost: WallPost

    @Output()
    remove: EventEmitter<WallPost> = new EventEmitter<WallPost>()

    isLive = false
    isTogglingLike = false
    entity?: any = null

    constructor(
        private readonly router: Router,
        public readonly loginService: LoginService,
        public readonly windowService: WindowService,
        public readonly articleService: ArticleService,
        public readonly userService: UserService,
        public readonly likeService: LikeService,
        public readonly organizedActivityService: OrganizedActivityService,
        private readonly dialog: MatDialog,
        private cd: ChangeDetectorRef
    ) {

    }

    ngOnInit(): void {
        switch (this.wallPost.entityType) {
            case 'OrganizedActivity':
                this.organizedActivityService
                    .getOrganizedActivity$({ organizedActivityId: this.wallPost.entityId })
                    .pipe(untilDestroyed(this))
                    .subscribe((entity) => {
                        this.entity = entity
                    })
                break
            case 'UserFollow':
                this.userService.getUserFollowStatus$(this.wallPost.entityId, true).subscribe((status) => {
                    this.entity = status
                })
                break
            case 'UserActivity':
                this.userService
                    .getUserActivity$(this.wallPost.entityId)
                    .pipe(untilDestroyed(this))
                    .subscribe((entity) => {
                        this.entity = entity
                        this.isLive = entity.isLive
                        console.log('User activity id =', entity.id, ', isLive =', this.isLive)
                        if (this.isLive) {
                            setTimeout(() => {
                                this.userService
                                    .getLiveUserActivity$(this.wallPost.entityId)
                                    .pipe(untilDestroyed(this))
                                    .subscribe((entity) => {
                                        this.entity = { ...entity }
                                        this.isLive = entity.isLive
                                        console.log('User activity update', entity.id, entity.activity.name, entity.isLive)
                                        this.cd.markForCheck()
                                    })
                            }, environment.pollInterval)
                        }
                    })
                break
            case 'User':
            case 'Message':
                this.entity = true
                break
            case 'Person':
                this.userService
                    .getPerson$(this.wallPost.entityId)
                    .pipe(untilDestroyed(this))
                    .subscribe((entity) => {
                        this.entity = entity
                    })
                break
            case 'Article':
                this.articleService
                    .getArticle$(this.wallPost.entityId)
                    .pipe(untilDestroyed(this))
                    .subscribe((entity) => {
                        this.entity = entity
                    })
        }
    }

    open(ref: string) {
        if (ref.startsWith('http'))
            this.windowService.open(ref)
        else
            this.router.navigateByUrl(ref)

    }

    like(entityType: string, entity: any) {
        if (!this.isTogglingLike) {
            this.isTogglingLike = true
            this.likeService.toggleLike$(entityType, entity.id).subscribe((result) => {
                console.log('Like', entityType, entity.id, result)
                entity.likeCount += result
                this.isTogglingLike = false
            })
        }
    }

    icon(icon: string) {
        return icon ? (icon.startsWith('/') ? server.apiUrl + icon : icon) + '?time=' + server.time : '/assets/images/logo-actigo.png'
    }

    approveUserFollow() {
        this.userService.approveUserFollow$(this.wallPost.entityId).subscribe((result) => {
            if (result)
                this.entity = UserFollowStatus.Following
        })
    }

    updateUserPostRule(isEnabled: boolean, notificationPriority: string) {
        this.userService.updateUserPostRules$([{
            type: this.wallPost.type,
            isEnabled: isEnabled,
            notificationPriority: notificationPriority,
            entityId: null//this.wallPost.entityId
        }]).subscribe((postRules) => {
            console.log('User post rules updated', postRules)
        })
    }

    report() {
        const dialogRef = this.dialog.open(UserFeedbackComponent, {
            width: '300px',
            data: {
                id: this.wallPost.id,
                text: ''
            }
        })
        dialogRef.afterClosed().subscribe((data) => {
            this.userService.createFeedback$(this.wallPost.entityId, this.wallPost.entityType, `${this.wallPost.name}: ${this.wallPost.text} - ${data.text}`)
                .subscribe((result) => {
                    console.log('User feedback created', result)
                })
        })
    }
}
