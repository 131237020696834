import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApolloService } from './apollo.service'
import { Message, MessageType } from 'app/core/core.models'
import { CREATE_MESSAGE } from '../core.mutations'
import { ENTITY_MESSAGES, MESSAGES } from '../core.queries'

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(private readonly apolloService: ApolloService) {}

    createMessage$(
        entityType: string,
        entityId: string,
        text: string,
        type: MessageType = MessageType.Comment,
        parentId?: string,
        rating?: number,
    ): Observable<Message> {
        return this.apolloService.mutate<Message>({
            mutation: CREATE_MESSAGE,
            variables: {
                command: {
                    entityType: entityType,
                    entityId: entityId,
                    text: text,
                    type: type,
                    parentId: parentId,
                    rating: rating,
                },
            },
        })
    }

    getMessages$(
        entityType: string,
        entityId: string,
        types: MessageType[] = [MessageType.Post],
        parentId?: string,
        seekId?: string,
        limit: number = 20,
    ): Observable<Message[]> {
        return this.apolloService.watchQuery<Message[]>({
            query: MESSAGES,
            variables: {
                query: {
                    types: types,
                    entityType: entityType,
                    entityId: entityId,
                    parentId: parentId,
                    seekId: seekId,
                    limit: limit,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getEntityMessages$(entityId: string, seekId?: string, limit: number = 100): Observable<Message[]> {
        return this.apolloService.watchQuery<Message[]>({
            query: ENTITY_MESSAGES,
            variables: {
                query: {
                    entityId: entityId,
                    seekId: seekId,
                    limit: limit,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }
}
