import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {ThemePalette} from "@angular/material/core"

export interface PromptData {
    title: string
    message: string
    color?: ThemePalette
}

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
})
export class PromptComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: PromptData) {}
}


