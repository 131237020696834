import {
    ActivityType,
    Address,
    Amount,
    Currency,
    Link,
    Person,
    PersonalResult,
    Position,
    VerifiedContact,
} from 'app/core/core.models'
import { Activity, OrganizedActivity } from 'app/events/events.models'

export const userActivityConnectionUrl = {
    actigo: '/users/activities/',
    garmin: 'https://connect.garmin.com/modern/activity/',
    strava: 'https://www.strava.com/activities/',
    fitbit: 'https://www.fitbit.com/activities/exercise/',
}

export const enum UserFollowStatus {
    NotFollowing = 'NotFollowing',
    Pending = 'Pending',
    Following = 'Following',
    Unknown = 'Unknown',
}

export const enum TrackerState {
    Stopped = 'Stopped',
    Running = 'Running',
    Paused = 'Paused',
}

export const enum AccessRight {
    Read = 'Read',
    Write = 'Write',
}

export const enum ConnectionType {
    None = 'None',
    Actigo = 'Actigo',
    Facebook = 'Facebook',
    Fitbit = 'Fitbit',
    Strava = 'Strava',
    Garmin = 'Garmin',
}

export const enum UserNotificationType {
    Firebase = 'Firebase',
}

interface UserAccount {
    balance: number
    currency: Currency
    id: string
}

export interface UserAccessKey {
    created?: Date
    name: string
    rights: AccessRight[]
    secret: string
    info?: string
}

export interface UserConnection {
    type: ConnectionType
}

export interface UserProfile {
    sex?: string
    givenName: string
    surname: string
    middleName?: string
    language: string
    birthDate?: string
    homeLocation?: Position
    maxDistance?: number
    allowMatchmaking: boolean
    shareActivitiesWithFollowers: boolean
    sameSexOnly: boolean
    isPublic: boolean
    description: string
    teamName?: string
    runningMonthly?: number
    runningShoeCount?: number
    runningPB?: PersonalResult
    registrationPriceLimit?: Amount
    activityTypes?: ActivityType[]
    activityLabels?: string[]
    updated?: Date
}

interface UserSubscription {
    expires?: string
    id: string
    organizerId: string
    planId: string
    transactionId: string
}

export interface User {
    id: string
    email: string
    nickname?: string
    address: Address
    contactEmail: VerifiedContact
    phoneNumber: VerifiedContact
    accessKeys: UserAccessKey[]
    postRules: PostRule[]
    accounts: UserAccount[]
    connections: UserConnection[]
    profile: UserProfile
    subscriptions: UserSubscription[]
    created: Date
    accessed: Date
}

export interface UserActivity {
    id: string
    person?: Person
    activity: Activity
    organizedActivity?: OrganizedActivity
    connectionType: ConnectionType
    connectionId?: string
    externalId?: string
    started?: Date
    finished?: Date
    distance: number
    pace: number
    elapsedTime: number
    movingTime: number
    isLive: boolean
    likeCount: number
    messageCount: number
}

export interface Team {
    id: string
    name: string
}

export interface AppState {
    url: string
}

export interface Feedback {
    id: string
    text: string
}

export interface PostRule {
    type: string
    isEnabled: boolean
    notificationPriority: string
    entityId?: string
}
