import { Component, OnInit } from '@angular/core'
import { LoginService } from 'app/users/services/login.service'
import { WindowService } from 'app/core/services/window.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { switchMap } from 'rxjs'
import { PostService } from 'app/core/services/post.service'
import { UserService } from 'app/users/services/user.service'
import { Audience, WallPost } from 'app/core/core.models'
import { infiniteScrollObservable } from 'app/core/utils'
import { LanguageService } from '../../core/services/language.service'
import { Language } from '../../core/constants/languages'
import { Capacitor } from '@capacitor/core'
import { OrganizerService } from '../../organizers/services/organizer.service'

@UntilDestroy()
@Component({
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    // featuredActivities$ = this.organizedActivityService
    //     .getSelectedOrganizedActivities$(SelectionType.Featured)
    //     .pipe(debounceTime(1000), untilDestroyed(this))
    audiences = [ Audience.Followers, Audience.Active, Audience.Newbies, Audience.Sleepers, Audience.Nobody ]
    language: Language
    wallPosts: WallPost[] = []
    listCount = 0
    text = ''
    audience = Audience.Followers
    isNative = Capacitor.isNativePlatform()
    isRootOrganizerMember$ = this.organizerService.isRootOrganizerMember$()

    constructor(
        public readonly loginService: LoginService,
        public readonly languageService: LanguageService,
        public readonly windowService: WindowService,
        public readonly userService: UserService,
        //public readonly organizedActivityService: OrganizedActivityService,
        private readonly organizerService: OrganizerService,
        private readonly postService: PostService,
    ) {}

    ngOnInit() {
        this.load()
        infiniteScrollObservable(1000, 200, document.querySelector('.mat-drawer-content'))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.loadMore()
            })
    }

    createPost() {
        this.postService.createPost$(this.text, this.audience).subscribe((wallPost) => {
            console.log('Wall post created for', this.audience, wallPost)
            this.wallPosts = [wallPost, ...this.wallPosts]
            this.text = ''
        })
    }

    remove(wallPost: WallPost) {
        this.postService.removePost$(wallPost.id).subscribe((result) => {
            console.log('Wall post removed', result)
            if (result) this.wallPosts = this.wallPosts.filter((w) => w.id != wallPost.id)
        })
    }

    load() {
        this.loginService.loggedIn$
            .pipe(
                switchMap(() => this.languageService.selectedLanguage$),
                switchMap((language) => {
                    this.language = language
                    return this.postService.getWallPosts$(language.code, null, this.windowService.listLimit)
                }),
                untilDestroyed(this),
            )
            .subscribe((wallPosts) => {
                this.listCount = wallPosts.length
                this.wallPosts = wallPosts
            })
    }

    loadMore() {
        if (this.wallPosts.length > 0) {
            this.postService

                .getWallPosts$(
                    this.language.code,
                    this.wallPosts[this.wallPosts.length - 1].id,
                    this.windowService.listLimit,
                )
                .pipe(untilDestroyed(this))
                .subscribe((wallPosts) => {
                    this.listCount = wallPosts.length
                    this.wallPosts = [...this.wallPosts, ...wallPosts]
                })
        }
    }
}
