<div *ngIf="organizedActivitiesStateService.view$ | async as actualView" fxLayout="row wrap">
    <div
        fxFlex.gt-sm="100%"
        fxFlex="100"
        fxLayoutAlign="end start"
        *ngIf="reload"
    >
        <mat-button-toggle-group (change)="viewChange($event)" [value]="actualView">
            <mat-button-toggle
                *ngFor="let buttonsGroup of buttonsGroups"
                [checked]="buttonsGroup.isDefault"
                [disabled]="buttonsGroup.disabled"
                [aria-label]="buttonsGroup.ariaLabel | translate"
                [value]="buttonsGroup.value"
            >
                <mat-icon>{{buttonsGroup.icon}}</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <ng-container [ngSwitch]="actualView">
            <app-organized-activities-cards *ngSwitchCase="VIEW.CARDS"></app-organized-activities-cards>
            <app-organized-activities-list *ngSwitchCase="VIEW.LIST"></app-organized-activities-list>
            <app-organized-activities-map *ngSwitchCase="VIEW.MAP"></app-organized-activities-map>
        </ng-container>
    </div>
    <div *ngIf="organizedActivitiesStateService.noActivities$ | async" fxFlex="100">
        <p class="no-data h2 text-shadow">{{"noActivities" | translate}}</p>
    </div>
</div>
