<div class="events-totals-list" fxLayout="row wrap" *ngIf="eventsTotals$ | async as eventsTotals">
    <div class="events-totals-list__item" fxFlex.gt-md="25" fxFlex.gt-xs="50" fxFlex="100">
        <mat-card>
            <div class="box p-20 bg-primary text-center">
                <h1 class="font-light text-white m-0">
                    <mat-icon>directions_run</mat-icon>
                    {{eventsTotals.organizedActivityCount}}
                </h1>
                <h4 class="text-white m-0">{{'organized' | translate}} {{'activities' | translate | lowercase}}</h4>
            </div>
        </mat-card>
    </div>

<!--    <div class="events-totals-list__item" fxFlex.gt-md="25" fxFlex.gt-xs="50" fxFlex="100">-->
<!--        <mat-card>-->
<!--            <div class="box p-20 bg-danger text-center">-->
<!--                <h1 class="font-light text-white m-0">-->
<!--                    <mat-icon>place</mat-icon>-->
<!--                    {{eventsTotals.organizedEventCount}}-->
<!--                </h1>-->
<!--                <h4 class="text-white m-0">{{'events' | translate}}</h4>-->
<!--            </div>-->
<!--        </mat-card>-->
<!--    </div>-->

    <div class="events-totals-list__item" fxFlex.gt-md="25" fxFlex.gt-xs="50" fxFlex="100">
        <mat-card>
            <div class="box p-20 bg-success text-center">
                <h1 class="font-light text-white m-0">
                    <mat-icon>check</mat-icon>
                    {{eventsTotals.registrationCount}}
                </h1>
                <h4 class="text-white m-0">{{'registrations' | translate}}</h4>
            </div>
        </mat-card>
    </div>

    <div class="events-totals-list__item" fxFlex.gt-md="25" fxFlex.gt-xs="50" fxFlex="100">
        <mat-card>
            <div class="box p-20 bg-warning text-center">
                <h1 class="font-light text-white m-0">
                    <mat-icon>timer</mat-icon>
                    {{eventsTotals.activityResultCount}}
                </h1>
                <h4 class="text-white m-0">{{'results' | translate}}</h4>
            </div>
        </mat-card>
    </div>
</div>
