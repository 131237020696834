<div class="responsive-table">
    <mat-table #table [dataSource]="registrations$ | async">
        <ng-container matColumnDef="activity">
            <mat-header-cell *matHeaderCellDef>{{"organized_activity" |translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" [attr.data-label]="'organized_activity'|translate">
                {{element.organizedActivity.name | localize}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startTime">
            <mat-header-cell *matHeaderCellDef>{{"startTime" |translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" [attr.data-label]="'startTime'|translate">
                {{element.organizedActivity.startTime | localizeDate: 'short'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startNumber">
            <mat-header-cell *matHeaderCellDef>{{"startNumber" |translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" [attr.data-label]="'startNumber'|translate">
                {{element.startNumber ? element.startNumber : '-'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef>{{"State" |translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" [attr.data-label]="'State' |translate">
                {{"RegistrationState." + element.state |translate}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef>{{"registered" |translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" [attr.data-label]="'registered'|translate">
                {{element.created | localizeDate: 'short'}}
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let element; columns: columns"
            [routerLink]="openOrganizedActivity ? ['/events/activities/', element.organizedActivity.id] : ['/events/registrations/', element.id]"
        ></mat-row>
        <p *matNoDataRow class="no-data">{{'noData'|translate}}</p>
    </mat-table>
</div>
