import { Pipe, PipeTransform } from '@angular/core'
import { sec2time } from '../../core.functions'

@Pipe({
    name: 'time',
})
export class TimePipe implements PipeTransform {
    transform(value: number, options: { thousandths: boolean } = { thousandths: false }): string {
        return sec2time(value / 1000) + (options.thousandths ? '.' + String(value % 1000).padStart(3, '0') : '')
    }
}
