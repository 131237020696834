import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Location } from '@angular/common'
import {
    buttonsGroups,
    defaultFormValue,
    distanceRangesOptions,
    maxDistanceFromFromPositionOptions,
} from 'app/events/components/organized-activities-search/organized-activities-search.config'
import { FormControl, FormGroup } from '@angular/forms'
import { MatButtonToggleChange } from '@angular/material/button-toggle'
import { OrganizedActivitiesStateService } from '../organized-activities/organized-activities-state.service'
import { debounceTime, distinctUntilChanged, from, Observable, startWith, switchMap, tap } from 'rxjs'
import { WindowService } from 'app/core/services/window.service'
import { CreateEventForm, GetOrganizedActivities } from 'app/events/events.models'
import { Globals } from 'app/app.consts'
import { LocationService } from 'app/core/services/location.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { AbstractFormComponent } from 'app/core/components/abstract.form.component'
import { DateAdapter } from '@angular/material/core'
import { LanguageService } from 'app/core/services/language.service'
import { ActivatedRoute, Router } from '@angular/router'
import { LayoutService } from 'app/layouts/services/layout.service'
import { View } from 'app/events/components/organized-activities/organized-activities.model'
import { filter, map } from 'rxjs/operators'
import * as R from 'ramda'
import * as R_ from 'ramda-extension'
import { ActivityType, GeocoderResponse, Position } from 'app/core/core.models'
import { HttpClient } from '@angular/common/http'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { GeocodingService } from 'app/core/services/geocoding.service'
import { Organizer } from '../../../organizers/organizers.models'
import { OrganizerService } from '../../../organizers/services/organizer.service'

@UntilDestroy()
@Component({
    selector: 'app-organized-activities-search',
    templateUrl: './organized-activities-search.component.html',
    styleUrls: ['organized-activities-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedActivitiesSearchComponent extends AbstractFormComponent implements OnInit {
    readonly distanceRangesOptions = distanceRangesOptions
    readonly maxDistanceFromPositionOptions = maxDistanceFromFromPositionOptions
    readonly buttonsGroups = buttonsGroups

    readonly form = new FormGroup({
        // activityTypes: new FormControl(defaultFormValue.activityTypes),
        activityTypes: new FormControl(ActivityType.Run),
        maxDistanceFromPosition: new FormControl(defaultFormValue.maxDistanceFromPosition),
        dateFrom: new FormControl(defaultFormValue.dateFrom),
        dateTo: new FormControl(defaultFormValue.dateTo),
        distanceRanges: new FormControl(defaultFormValue.distanceRanges),
        labels: new FormControl(defaultFormValue.labels),
        organizerName: new FormControl(''),
        organizerIds: new FormControl(defaultFormValue.organizerIds),
        seekId: new FormControl(defaultFormValue.seekId),
        myPosition: new FormControl(defaultFormValue.myPosition),
        limit: new FormControl(defaultFormValue.limit),
        showResults: new FormControl(true),
        name: new FormControl(''),
        address: new FormControl(null),
    })

    readonly addressForm = new FormGroup({
        address: new FormControl(null),
    })

    @Output()
    searchParams: EventEmitter<GetOrganizedActivities> = new EventEmitter<GetOrganizedActivities>()

    @Output()
    searchCallback: EventEmitter<any> = new EventEmitter<any>()

    view: View
    // ISSUE 206 - disable immediate searchs
    inResults = false // !!this.route.snapshot.queryParams.showResults
    options = []
    locationOptions$: Observable<google.maps.GeocoderResult[]>
    organizerOptions$: Observable<Organizer[]>

    constructor(
        public readonly organizedActivitiesStateService: OrganizedActivitiesStateService,
        public override readonly windowService: WindowService,
        private readonly organizerService: OrganizerService,
        public readonly locationService: LocationService,
        private router: Router,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
        _adapter: DateAdapter<any>,
        languageService: LanguageService,
        public layoutService: LayoutService,
        private readonly location: Location,
        private http: HttpClient,
        private geocodingService: GeocodingService,
    ) {
        super(windowService, languageService, _adapter)
    }

    ngOnInit() {
        this.view = this.organizedActivitiesStateService.view$.value
        from(this.locationService.currentPosition)
            .pipe(
                tap((position) => {
                    if (!this.form.controls['myPosition'].value) {
                        delete position['source']
                        this.form.controls['myPosition'].setValue(position)
                        this.cd.markForCheck()
                    } else {
                        this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true })
                    }
                }),
                untilDestroyed(this),
            )
            .subscribe()
        this.route.queryParams
            .pipe(
                // ISSUE 206 - disable immediate search
                // tap((prop) => {
                //     this.inResults = !!prop.showResults
                //     this.cd.markForCheck()
                // }),
                filter(({ showResults }) => showResults),
                distinctUntilChanged(),
                untilDestroyed(this),
            )
            .subscribe((params) => {
                params = {
                    ...params,
                    dateFrom: new Date(params.dateFrom),
                    dateTo: new Date(params.dateTo),
                    myPosition: JSON.parse(params.myPosition),
                    maxDistanceFromPosition: parseInt(params.maxDistanceFromPosition),
                    limit: parseInt(params.limit),
                    labels:
                        (params.labels && typeof params.labels === 'string' ? [params.labels] : params.labels) ||
                        defaultFormValue.labels,
                    distanceRanges:
                        (params.distanceRanges && typeof params.distanceRanges === 'string'
                            ? [params.distanceRanges]
                            : params.distanceRanges) || defaultFormValue.distanceRanges,
                }

                this.form.setValue(
                    {
                        ...this.form.value,
                        ...params,
                    },
                    { emitEvent: false },
                )
                this.layoutService.headerSearchFormData = this.form.value
                this.emitSearchParams(this.form.value)
            })

        if (!this.layoutService.headerSearchFormData) {
            this.emitSearchParams(this.form.value)
        } else {
            this.emitSearchParams(this.layoutService.headerSearchFormData)
            this.form.setValue({ ...this.layoutService.headerSearchFormData })
            this.addressForm.controls['address'].setValue(
                { formatted_address: this.layoutService.headerSearchFormData.address },
                { emitEvent: false },
            )
        }
        this.locationOptions$ = this.addressForm.controls['address'].valueChanges.pipe(
            // startWith(''),
            filter(R_.isNotEmpty),
            debounceTime(Globals.valueChangeDebounce),
            distinctUntilChanged(),
            switchMap((val) => {
                return this.getLocation(val || '')
            }),
            untilDestroyed(this),
        )
        this.organizerOptions$ = this.form.controls['organizerName'].valueChanges.pipe(
            // startWith(''),
            filter(R_.isNotEmpty),
            debounceTime(Globals.valueChangeDebounce),
            distinctUntilChanged(),
            switchMap((value) => {
                return this.organizerService.getOrganizers$(typeof value === 'string' ? value : value?.name, 20)
            }),
            untilDestroyed(this),
        )
        this.form.controls['myPosition'].valueChanges
            .pipe(
                distinctUntilChanged(),
                switchMap((value) => this.getAddress(`${value.latitude},${value.longitude}`)),
                untilDestroyed(this),
            )
            .subscribe((address) => {
                this.addressForm.controls['address'].setValue(address, { emitEvent: false })
                this.form.controls['address'].setValue(address.formatted_address)
            })
        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            this.layoutService.headerSearchFormData = { ...values }
            this.emitSearchParams(values)
        })
        // ISSUE 206 - disable immediate search
        // this.form.valueChanges
        //     .pipe(debounceTime(!this.inResults ? 0 : Globals.valueChangeDebounce), untilDestroyed(this))
        //     .subscribe((values) => {
        //         if (this.route.snapshot.queryParams.showResults) {
        //             this.goToActivities()
        //         }
        //     })
        if (this.route.snapshot.queryParams.showResults) {
            this.goToActivities()
        }
        //EOF ISSUE 206
    }

    getAddress(val: string): Observable<google.maps.GeocoderResult> {
        return this.geocodingService.getAddress(val).pipe(
            filter(R_.isNotNil),
            map((response: GeocoderResponse) => response?.results[0]),
        )
    }

    getLocation(val: string): Observable<google.maps.GeocoderResult[]> {
        return this.geocodingService.getLocation(val).pipe(
            filter(R_.isNotNil),
            map((response: GeocoderResponse) => response?.results),
        )
    }

    addressSelected(selection: MatAutocompleteSelectedEvent) {
        if (selection.option.value.geometry) {
            this.form.controls['myPosition'].setValue(
                {
                    latitude: selection.option.value.geometry.location.lat,
                    longitude: selection.option.value.geometry.location.lng,
                },
                { emitEvent: false },
            )
            this.form.controls['address'].setValue(selection.option.value.formatted_address)
        }
    }

    organizerSelected(selection: MatAutocompleteSelectedEvent) {
        console.log('XXXXXX', selection.option.value)
    }

    displayAddressFn(options: google.maps.GeocoderResult) {
        return options?.formatted_address
    }

    displayOrganizerFn(options: Organizer) {
        return options?.name
    }

    emitSearchParams(values) {
        values = {
            ...values,
            distanceRanges: R.map((v) => JSON.parse(v))(values.distanceRanges),
        }
        this.searchParams.emit(values)
    }

    viewChange({ value }: MatButtonToggleChange) {
        this.view = value
        this.organizedActivitiesStateService.view$.next(this.view)
        this.layoutService.headerSearchView = this.view
        if (this.inResults) {
            this.goToActivities()
        }
    }

    onSubmitValidForm(values: CreateEventForm) {}

    goToActivities(skip = false) {
        if (!skip) {
            const queryParams = {
                ...this.form.value,
                dateFrom: this.form.controls['dateFrom'].value.toISOString(),
                dateTo: this.form.controls['dateTo'].value.toISOString(),
                myPosition: JSON.stringify(this.form.controls['myPosition'].value),
            }
            this.router
                .navigate(['/events/activities', this.view], {
                    queryParams: queryParams,
                    queryParamsHandling: 'merge',
                })
                .then(() => {
                    const params = R.clone(this.layoutService.headerSearchFormData)
                    delete params.showResults
                    delete params.address
                    params.distanceRanges = R.map(JSON.parse)(params.distanceRanges)
                    this.organizedActivitiesStateService.organizedActivitiesFormState$.next(params)
                    this.organizedActivitiesStateService.view$.next(this.view)
                    this.layoutService.headerSearchView = this.view
                    this.cd.markForCheck()
                })
        } else {
            this.searchCallback.emit()
        }
    }

    updatePosition(position: Position) {
        this.form.controls['myPosition'].setValue(position)
    }
}
