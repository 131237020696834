export const environment = {
    production: true,
    version: 'e4b5f932',
    features: {
        carpooling: false,
        garmin: true,
    },
    auth0: {
        audience: 'https://api.actigo.app',
        clientId: 'c9U0196v5YQePNxxZgAlSoVs56jWwThv',
        mobileClientId: 'sZUhjNZJO2vTg9XCZxfn317F1QiiFvWQ',
        domain: 'auth.actigo.app',
    },
    strava: {
        clientId: 78624
    },
    ipGeolocation: {
        apiKey: 'eb0d82911c9c4930a139460599b932eb',
    },
    geocoding: {
        apiKey: 'AIzaSyCOSSMpXWEE3g1UlmuH71kxzRAENMJmCoA',
    },
    googleApiKey: 'AIzaSyBfH-0JsWbg1Xf6Ib_cAd8E6BVMULkBZjE',
    appUrl: 'https://preview.actigo.app',
    apiUrl: 'https://preview.actigo.app/api',
    graphqlUrl: 'https://preview.actigo.app/graphql',
    pollInterval: 10000,
}
