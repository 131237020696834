<mat-card>
    <mat-card-content>
        <mat-card-title>{{'accessKeys'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'accessKeys'|translate}} <a [href]="apiUrl" target="_blank">{{'app'|translate}} API</a></mat-card-subtitle>
    </mat-card-content>

    <mat-table #table [dataSource]="accessKeys$ | async">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>{{'name' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="info">
            <mat-header-cell *matHeaderCellDef>{{'description' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.info}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell class="cursor-pointer" (click)="removeAccessKey(element)" *matCellDef="let element">
                <mat-icon>delete</mat-icon>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;"></mat-row>
    </mat-table>

    <mat-card-content>
        <div class="form-element text-right">
        <button mat-raised-button color="primary" (click)="addAccessKey()">
            {{'create'|translate}} {{'key'|translate|lowercase}}
        </button>
        </div>
    </mat-card-content>

</mat-card>
