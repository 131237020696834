import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { LoginService } from 'app/users/services/login.service'
import { UserService } from 'app/users/services/user.service'

@Component({
    selector: 'app-user-nickname',
    templateUrl: './user-nickname.component.html',
})
export class UserNicknameComponent implements OnInit {
    formGroup: FormGroup

    constructor(public loginService: LoginService, public fb: FormBuilder, private userService: UserService) {
        this.formGroup = this.fb.group({
            nickname: [null, [Validators.required]],
        })
    }

    ngOnInit(): void {}

    submitForm(value): void {
        this.userService.updateUserNickname$(value.nickname).subscribe((result) => {
            console.log('User nickname update', result)
        })
    }
}
