<div fxLayout="row wrap">
    <div fxFlex="100" [class.map-detail]="mapActivityDetail">
        <mat-card class="map-detail--map" [class.map-detail--map-opened]="mapActivityDetail">
            <app-map
                [organizedActivity]="organizedActivitiesStateService.activities$ | async"
                [darkMode]="windowService.darkMode$ | async"
                [showLegendForTracks]="true"
                (markerClick)="detailActivity($event)"
                [markerDisabled]="markerDisabled"
            >
            </app-map>
        </mat-card>
        <div class="map-detail--card mat-card" *ngIf="mapActivityDetail">
            <app-organized-activity-card [organizedActivity]="mapActivityDetail">
                <div fxFlex></div>
                <button mat-button class="close-icon" (click)="closeDetailActivity($event)">
                    <mat-icon>close</mat-icon>
                </button>
            </app-organized-activity-card>
        </div>
    </div>
</div>
