<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card [class]="trackerService.isLive ? 'make-me-magic' : ''">
            <mat-card-header>
                <div fxFlex="1 1 0" class="ps-3">
                    <mat-card-title>{{'tracker' | translate}}</mat-card-title>
                </div>
                <div fxFlex="0 0 auto">
                    <button
                        *ngIf="windowService.isNative"
                        class="d-inline-block"
                        mat-button
                        [disabled]="!trackerService.userActivityId"
                        (click)="windowService.share(appUrl + '/users/activities/' + trackerService.userActivityId, trackerService.activity.name)"
                        style="position: absolute; right: 0; top: 0; margin: 5pt"
                    >
                        <mat-icon>share</mat-icon>
                        {{'share' | translate}}
                    </button>
                </div>
            </mat-card-header>
            <mat-card-content>
                <!-- activity properties -->
                <div fxLayout="row wrap" fxFlexAlign="center" class="row"
                     *ngIf="activityTypes$ | async as activityTypes"
                     [formGroup]="formGroup"
                >
                    <div fxFlex.gt-sm="100%" fxFlex="100">
                        <mat-checkbox
                            formControlName="isLive"
                            [disabled]="!(!trackerService.userActivityId && trackerService.elapsedTime == 0 && trackerService.isStopped)"
                            [(ngModel)]="trackerService.isLive"
                        >{{'live_broadcasting' | translate}}</mat-checkbox>
                    </div>
                    <div fxFlex.gt-sm="50%" fxFlex="50">
                        <mat-form-field>
                            <mat-select
                                formControlName="type"
                                [(ngModel)]="trackerService.activity.type"
                                [placeholder]="'activityType' | translate"
                            >
                                <mat-option
                                    *ngFor="let type of activityTypes | arrayOfValuesToOptions"
                                    [value]="type.value"
                                >{{"ActivityType." + type.label | translate}}</mat-option
                                >
                            </mat-select>
                            <mat-error *ngIf="!formGroup.controls['type'].valid && formGroup.controls['type'].touched">
                                {{'missing' | translate}} {{'activityType' | translate | lowercase}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="50%" fxFlex="50">
                        <mat-form-field class="form-element">
                            <input
                                matInput
                                formControlName="name"
                                [(ngModel)]="trackerService.activity.name"
                                [placeholder]="'name' | translate"/>
                            <mat-error *ngIf="!formGroup.controls['name'].valid && formGroup.controls['name'].touched">
                                {{'missing' | translate}} {{'name' | translate | lowercase}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- tracker pane -->
                <div align="center">
                    <p style="font-size: 25pt; padding-bottom: 5pt">

                        {{trackerService.timeAsString}} • {{trackerService.activity.distance | number : '.2-2'}} km
                    </p>

                    <button color="primary" mat-fab (click)="trackerService.start()" [disabled]="trackerService.isRunning || (trackerService.isStopped && trackerService.elapsedTime > 0)">
                        <mat-icon>play_arrow</mat-icon>
                    </button>

                    <button color="primary" mat-fab (click)="trackerService.pause()" [disabled]="!trackerService.isRunning">
                        <mat-icon>pause</mat-icon>
                    </button>

                    <button color="primary" mat-fab (click)="trackerService.stop()" [disabled]="trackerService.isStopped">
                        <mat-icon>stop</mat-icon>
                    </button>
                </div>
            </mat-card-content>
            <app-map
                mapHeight="150pt"
                [defaultZoom]="15"
                [activityTracking]="true"
                [activity]="trackerService.activity"
                [darkMode]="windowService.darkMode$ | async"
            ></app-map>
            <div align="center">{{trackerService.segment.points.length}} {{'points' | translate}}</div>
            <mat-card-content>
                <!-- activity actions -->
                <mat-card-actions>
                    <button
                        mat-raised-button
                        color="warn"
                        [disabled]="!(trackerService.isStopped && trackerService.elapsedTime > 0)"
                        (click)="reset()"
                    >
                        {{'reset' | translate}}
                    </button>

                    <button
                        mat-raised-button
                        color="primary"
                        [disabled]="!(!trackerService.userActivityId && trackerService.elapsedTime > 0 && trackerService.isStopped)"
                        (click)="save()"
                        style="float: right"
                    >
                        {{'save' | translate}}
                    </button>
                </mat-card-actions>
            </mat-card-content>
        </mat-card>
    </div>
</div>
