import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { WindowService } from 'app/core/services/window.service'
import { OrganizedActivityService } from 'app/events/services/organized-activity.service'

@Component({
    templateUrl: './organized-activity.container.html',
})
export class OrganizedActivityContainer {
    readonly organizedActivityId = this.route.snapshot.params['id']
    readonly organizedActivity$ = this.organizedActivityService.getOrganizedActivity$({
        organizedActivityId: this.organizedActivityId,
    })

    constructor(
        private organizedActivityService: OrganizedActivityService,
        private route: ActivatedRoute,
        public windowService: WindowService,
    ) {}
}
