<div
    *ngIf="{
    uploadingFile: uploadingFile$ | async,
    organizedEvent: organizedEventServiceState.organizedEvent$ | async,
    showUploadingForm: showUploadingForm$ | async
 } as data"
    fxLayout="row wrap"
>
    <ng-container *ngIf="!!data?.organizedEvent">
        <div class="section" fxFlex="100" fxFlex.gt-md="50">
            <form [formGroup]="form">
                <div fxLayout="row wrap" fxFlexAlign="center" class="row">
                    <h2>{{'new'|translate}}</h2>
                    <div fxFlex="50" class="p-10">
                        <mat-form-field>
                            <mat-select formControlName="type" [placeholder]="'type' | translate" [value]="'image'">
                                <mat-option *ngFor="let mediaType of mediaTypeOptions" [value]="mediaType.value"
                                    >{{mediaType.label | translate}}</mat-option
                                >
                            </mat-select>
                            <mat-error *ngIf="!form.controls['type'].valid && form.controls['type'].touched">
                                {{ 'isRequired' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="data.showUploadingForm" fxFlex.gt-sm="50" fxFlex="100" class="p-10">
                        <app-file-uploader [disabled]="data.uploadingFile" [uploader]="uploader"></app-file-uploader>
                    </div>
                </div>
                <mat-form-field *ngIf="!isMedia()">
                    <mat-label>{{'ref'|translate}}</mat-label>
                    <input formControlName="ref" matInput />
                    <mat-error *ngIf="!form.controls['ref'].valid && form.controls['ref'].touched">
                        <ng-container *ngIf='form.controls["ref"].errors?.url'>
                            {{ 'badFormat' | translate }}</ng-container
                        >
                        <ng-container *ngIf='form.controls["ref"].errors?.required'
                        >{{ 'isRequired' | translate }}</ng-container
                        >
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'title'|translate}}</mat-label>
                    <input formControlName="title" matInput />
<!--                    <mat-error *ngIf="!form.controls['title'].valid && form.controls['title'].touched">-->
<!--                        {{ 'isRequired' | translate }}-->
<!--                    </mat-error>-->
                </mat-form-field>
                <div class="form-element text-right">
                    <button
                        mat-raised-button
                        [disabled]="form.disabled"
                        color="primary"
                        type="submit"
                        class="button"
                        (click)="submitForm()"
                    >
                        {{ 'add' | translate }}
                    </button>
                </div>
            </form>
            <app-loader *ngIf="data.uploadingFile"> </app-loader>
        </div>
        <div class="section" fxFlex="100" fxFlex.gt-md="50">
            <h2>{{'media'|translate}}</h2>
            <div fxLayout="row wrap">
                <div #mediaSection fxFlex="100">
                    <div fxLayout="row wrap">
                        <ng-container *ngIf="size && data?.organizedEvent?.media?.length > 0; else noData">
                            <div
                                style="position: relative"
                                fxFlex="33"
                                *ngFor="let media of data.organizedEvent.media; trackBy: trackByRef; let i = index;"
                            >
                                <img
                                    *ngIf="media.type === MediaType.image"
                                    src="{{apiUrl}}/files/events/{{organizedEventServiceState.organizedEventId}}/{{media.ref}}?size={{size}}"
                                    class="img-preview"
                                    [alt]="media.title"
                                    [title]="media.title"
                                />
                                <video
                                    *ngIf="media.type === MediaType.video"
                                    class="img-preview"
                                    controls
                                    preload="metadata"
                                >
                                    <source
                                        src="{{apiUrl}}/files/events/{{organizedEventServiceState.organizedEventId}}/{{media.ref}}"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                                <embed
                                    *ngIf="media.type === MediaType.youtube"
                                    [src]="('https://www.youtube.com/embed/' + media.ref) | safe:'resourceUrl'"
                                    class="img-preview"
                                    wmode="transparent"
                                    type="video/mp4"
                                    width="100%"
                                    allow="autoplay; encrypted-media; picture-in-picture"
                                    allowfullscreen
                                />
<!--                                <iframe src="http://www.youtube.com/embed/VQiZdWl1Bng"-->
<!--                                        width="560" height="315" frameborder="0" allowfullscreen></iframe>-->

                                <button
                                    [disabled]="form.disabled"
                                    (click)="deleteMedia(media)"
                                    class="delete-media-icon"
                                    mat-button
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button
                                    [disabled]="form.disabled"
                                    (click)="setFavoriteMedia(media, data.organizedEvent.media)"
                                    class="favorite-media-icon"
                                    [class.favorite-media-icon--selected]="media.isFavorite"
                                    mat-button
                                >
                                    <mat-icon>favorite</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #noData> {{'noMediaUploaded' | translate}} </ng-template>
