import { Injectable } from '@angular/core'
import { ActivityResult, GetActivityResults } from 'app/events/events.models'
import { ApolloService } from 'app/core/services/apollo.service'
import { ACTIVITY_RESULTS } from '../events.queries'

@Injectable({
    providedIn: 'root',
})
export class ActivityResultService {
    constructor(private apolloService: ApolloService) {}

    getActivityResults$(query: GetActivityResults) {
        return this.apolloService.skipQuery<ActivityResult, { query: GetActivityResults }>({
            fetchPolicy: 'no-cache',
            query: ACTIVITY_RESULTS,
            variables: {
                query,
            },
        })
    }
}
