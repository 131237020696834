import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { generateUUID } from 'app/core/utils/generate-uuid'
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators'
import dayjs from 'dayjs'
import { findIndexOfId } from 'app/core/utils'
import { Currency, LocalizedText } from 'app/core/core.models'

@Component({
    selector: 'app-organized-event-activity-merchandise-price-list',
    templateUrl: './organized-event-activity-merchandise-price-list.component.html',
    styleUrls: ['./organized-event-activity-merchandise-price-list.component.scss'],
})
export class OrganizedEventActivityMerchandisePriceListComponent implements OnInit {
    @Input()
    itemForm: FormGroup
    itemName = 'merchandisePriceList'

    dataSource: MatTableDataSource<any>
    readonly columns = ['sku', 'name', 'description', 'amount', 'currency', 'validTo', 'action']
    currencies = ['CZK', 'EUR']

    ngOnInit(): void {
        <FormArray>this.itemForm.get(this.itemName) //.push(this.createPriceItem())
        this.setDataSource()
    }

    create(sku = '', name: LocalizedText[] = [], description: LocalizedText[] = [], amount = null, validTo = null): FormGroup {
        return new FormGroup({
            id: new FormControl(generateUUID()),
            sku: new FormControl(sku),
            name: new FormControl(name.length ? name[0].text : ''),
            description: new FormControl(description.length ? description[0].text : ''),
            amount: new FormGroup({
                value: new FormControl(amount ? (amount.value != null ? amount.value / 100 : null) : null, [
                    Validators.required,
                    RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
                ]),
                currency: new FormControl(amount ? amount.currency : Currency.CZK),
            }),
            validTo: new FormControl(dayjs(validTo).toDate(), [
                Validators.required,
                // RxwebValidators.minDate({ value: dayjs().toDate() }),
            ]),
        })
    }

    pushToArray(): void {
        const items = <FormArray>this.itemForm.get(this.itemName)
        const skus = []
        for (let i = 0; i < items.length; i++) {
            skus.push((items.at(i) as FormGroup).controls['sku'].value)
        }
        let id = 0
        while (skus.includes(`id${id}`))
            id++
        items.push(this.create(`id${id}`, [], [],null, this.itemForm.controls['startDate'].value))
        this.setDataSource()
    }

    resetForm() {
        ;(<FormArray>this.itemForm.get(this.itemName)).clear()
        this.ngOnInit()
    }

    remove(element): void {
        const items = <FormArray>this.itemForm.get(this.itemName)
        const item = items.value
        if (item.length !== 0) {
            const indexToRemove = findIndexOfId(element, item)
            items.removeAt(indexToRemove)
            this.setDataSource()
        }
    }

    trackById(index, price): void {
        return price.id
    }

    setDataSource() {
        this.dataSource = new MatTableDataSource((this.itemForm.get(this.itemName) as FormArray).controls)
    }
}
