import { Component, OnInit } from '@angular/core'
import { LoginService } from 'app/users/services/login.service'
import { ActivatedRoute, Router } from '@angular/router'
import { UserService } from 'app/users/services/user.service'
import { ConnectionType, User, UserConnection } from 'app/users/users.models'
import { BehaviorSubject } from 'rxjs'
import { WindowService } from 'app/core/services/window.service'
import { TranslateService } from '@ngx-translate/core'
import { server } from 'app/core/core.server'
import { environment } from '../../../../environments/environment'

@Component({
    selector: 'app-user-connections',
    templateUrl: './user-connections.component.html',
})
export class UserConnectionsComponent implements OnInit {
    connections$ = new BehaviorSubject<UserConnection[]>([])
    isStravaConnected = false
    isFitbitConnected = false
    isGarminConnected = false
    isFacebookConnected = false
    isGarminEnabled = environment.features['garmin']

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private windowService: WindowService,
        private translateService: TranslateService,
        private loginService: LoginService,
        private userService: UserService,
    ) {
        this.connections$.subscribe((connections) => {
            this.isStravaConnected = connections.filter((item) => item.type == ConnectionType.Strava).length > 0
            this.isFitbitConnected = connections.filter((item) => item.type == ConnectionType.Fitbit).length > 0
            this.isGarminConnected = connections.filter((item) => item.type == ConnectionType.Garmin).length > 0
            this.isFacebookConnected = connections.filter((item) => item.type == ConnectionType.Facebook).length > 0
        })
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            let property = this.route.snapshot.params['property']
            let type = this.route.snapshot.params['type']
            let code = params['oauth_verifier'] ? params['oauth_verifier'] : params['code']
            let token = params['oauth_token']
            if (property == 'connections' && typeof type != 'undefined' && (typeof code != 'undefined' || typeof token != 'undefined')) {
                type = type.charAt(0).toUpperCase() + type.slice(1)
                console.log(`connection type=${type} code=${code}`)
                this.userService.setUserConnection$(type, code, this.windowService.isNative, token, localStorage.getItem('oauthTokenSecret')).subscribe((userConnection) => {
                    console.log('user.connection', userConnection)
                    this.connections$.next(this.connections$.value.concat(userConnection))
                })
                this.router.navigate(['.'], {
                    relativeTo: this.route,
                })
            }
        })
        this.userService.getCurrentUser$().subscribe((user) => {
            this.connections$.next(user.connections)
        })
    }

    getUser(): User {
        return this.loginService.currentUser
    }

    connect(type: string) {
        let redirectUri = (this.windowService.isNative ? 'app.actigo://actigo.app' : environment.appUrl) + '/users/settings/connections/' + type
        if (type == 'strava') {
            this.windowService.open(`https://www.strava.com/oauth/authorize?client_id=${environment.strava.clientId}&response_type=code&redirect_uri=${redirectUri}&approval_prompt=force&scope=activity:read_all`)
        } else if (type == 'garmin') {
            this.userService.getOAuthRequestToken(ConnectionType.Garmin).subscribe((token) => {
                console.log('Received Garmin request token', token)
                localStorage.setItem('oauthTokenSecret', token.tokenSecret)
                this.windowService.open(`https://connect.garmin.com/oauthConfirm?oauth_token=${token.token}&oauth_callback=${redirectUri}`)
            })
        } else {
            this.windowService.open(`${server.apiUrl}/users/connections/${type}/authorize?isNative=${this.windowService.isNative}`)
        }
    }

    removeUserConnection(connection: UserConnection) {
        if (confirm(this.translateService.instant('confirm_delete') + ` ${connection.type}?`)) {
            console.log('Deleting user connection', connection)
            this.userService.removeUserConnection$(connection).subscribe((result) => {
                console.log('User connection deleted', result)
                this.connections$.next(this.connections$.value.filter((item) => item.type != connection.type))
            })
        }
    }
}
