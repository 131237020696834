import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FileUploaderComponent } from 'app/core/components/file-uploader/file-uploader.component'
import { TranslateModule } from '@ngx-translate/core'
import { FileUploadModule } from 'lib/file-upload/file-upload.module'

@NgModule({
    declarations: [FileUploaderComponent],
    imports: [CommonModule, FileUploadModule, TranslateModule],
    providers: [],
    exports: [FileUploaderComponent],
})
export class FileUploaderModule {}
