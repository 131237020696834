import { gql } from 'apollo-angular'
import { USER_FRAGMENT } from './users.fragments'

export const SIGNUP = gql`
    ${USER_FRAGMENT}
    mutation signUp {
        signUp {
            ...UserFields
        }
    }
`
export const TOUCH_USER = gql`
    ${USER_FRAGMENT}
    mutation touchUser($command: TouchUser!) {
        touchUser(command: $command)
    }
`
export const PURGE_USER = gql`
    mutation purgeUser {
        purgeUser
    }
`
export const SYNC_PROFILE = gql`
    ${USER_FRAGMENT}
    mutation syncProfile {
        syncProfile {
            ...UserFields
        }
    }
`
export const CREATE_FEEDBACK = gql`
    mutation createFeedback($command: CreateFeedback!) {
        createFeedback(command: $command)
    }
`
export const UPDATE_USER_NICKNAME = gql`
    mutation updateUserNickname($command: UpdateUserNickname!) {
        updateUserNickname(command: $command)
    }
`
export const UPDATE_USER_CONTACT_EMAIL = gql`
    mutation updateUserContactEmail($command: UpdateUserContactEmail!) {
        updateUserContactEmail(command: $command)
    }
`
export const UPDATE_USER_PHONE_NUMBER = gql`
    mutation updateUserPhoneNumber($command: UpdateUserPhoneNumber!) {
        updateUserPhoneNumber(command: $command)
    }
`
export const VERIFY_USER_CONTACT_EMAIL = gql`
    mutation verifyUserContactEmail($command: VerifyUserContactEmail!) {
        verifyUserContactEmail(command: $command)
    }
`
export const VERIFY_USER_PHONE_NUMBER = gql`
    mutation verifyUserPhoneNumber($command: VerifyUserPhoneNumber!) {
        verifyUserPhoneNumber(command: $command)
    }
`
export const CREATE_USER_ACTIVITY = gql`
    mutation createUserActivity($command: CreateUserActivity!) {
        createUserActivity(command: $command)
    }
`
export const UPDATE_USER_ACTIVITY = gql`
    mutation updateUserActivity($command: UpdateUserActivity!) {
        updateUserActivity(command: $command)
    }
`
export const UPDATE_USER_ACTIVITY_NAME = gql`
    mutation updateUserActivityName($command: UpdateUserActivityName!) {
        updateUserActivityName(command: $command)
    }
`
export const FOLLOW_USER = gql`
    mutation followUser($command: FollowUser!) {
        followUser(command: $command)
    }
`
export const UNFOLLOW_USER = gql`
    mutation unfollowUser($command: UnfollowUser!) {
        unfollowUser(command: $command)
    }
`
export const APPROVE_USER_FOLLOW = gql`
    mutation approveUserFollow($command: ApproveUserFollow!) {
        approveUserFollow(command: $command)
    }
`
export const DELETE_FOLLOW_USER = gql`
    mutation deleteUserFollow($command: DeleteUserFollow!) {
        deleteUserFollow(command: $command)
    }
`
export const SET_USER_NOTIFICATION_TOKEN = gql`
    mutation setUserNotificationToken($command: SetUserNotificationToken!) {
        setUserNotificationToken(command: $command)
    }
`
export const REMOVE_USER_NOTIFICATION_TOKEN = gql`
    mutation removeUserNotificationToken($command: RemoveUserNotificationToken!) {
        removeUserNotificationToken(command: $command)
    }
`
export const SET_USER_CONNECTION = gql`
    mutation setUserConnection($command: SetUserConnection!) {
        setUserConnection(command: $command) {
            type
        }
    }
`
export const REMOVE_USER_CONNECTION = gql`
    mutation removeUserConnection($command: RemoveUserConnection!) {
        removeUserConnection(command: $command)
    }
`
export const SET_USER_ACCESS_KEY = gql`
    mutation setUserAccessKey($command: SetUserAccessKey!) {
        setUserAccessKey(command: $command) {
            name
            info
            secret
            rights
        }
    }
`
export const REMOVE_USER_ACCESS_KEY = gql`
    mutation removeUserAccessKey($command: RemoveUserAccessKey!) {
        removeUserAccessKey(command: $command)
    }
`

export const UPDATE_USER_POST_RULES = gql`
    mutation updateUserPostRules($command: UpdateUserPostRules!) {
        updateUserPostRules(command: $command) {
            type
            isEnabled
            notificationPriority
            entityId
        }
    }
`

export const IMPORT_USER_ACTIVITIES = gql`
    mutation importUserActivities($command: ImportUserActivities!) {
        importUserActivities(command: $command) {
            counters {
                name
                value
            }
            totalCount
        }
    }
`
export const IMPORT_USER_FRIENDS = gql`
    mutation importUserFriends($command: ImportUserFriends!) {
        importUserFriends(command: $command) {
            counters {
                name
                value
            }
            totalCount
        }
    }
`
export const UPDATE_USER_ADDRESS = gql`
    ${USER_FRAGMENT}
    mutation updateUserAddress($command: UpdateUserAddressInput!) {
        updateUserAddress(command: $command) {
            ...UserFields
        }
    }
`
export const UPDATE_USER_PROFILE = gql`
    ${USER_FRAGMENT}
    mutation updateUserProfile($command: UpdateUserProfileInput!) {
        updateUserProfile(command: $command) {
            ...UserFields
        }
    }
`
