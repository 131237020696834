<form [formGroup]="form" autocomplete="off">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
<!--        <mat-tab>-->
<!--            <ng-template mat-tab-label> <mat-icon>code</mat-icon>&nbsp;JSON </ng-template>-->
<!--            <ng-template matTabContent><pre>{{enums | json}}</pre></ng-template>-->
<!--        </mat-tab>-->
        <mat-tab>
            <ng-template mat-tab-label> <mat-icon>settings</mat-icon>&nbsp;{{'settings'|translate}} </ng-template>
            <ng-template matTabContent>
                <mat-card-content>
                    <div fxLayout="row wrap" fxFlexAlign="center" class="row">
                        <div fxFlex.gt-sm="50%" fxFlex="100">
                            <mat-form-field>
                                <mat-label>{{'name'|translate}}</mat-label>
                                <input
                                    matInput
                                    required
                                    [placeholder]="formControlNamePlaceholder"
                                    [formControl]="formControlName"
                                />
                                <mat-error *ngIf="!formControlName?.valid && formControlName?.touched">
                                    {{ 'isRequired' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="25%" fxFlex="100">
                            <mat-form-field>
                                <mat-select
                                    [formControl]="$any(form.controls['activity'].get('type'))"
                                    [placeholder]="'activityType' | translate"
                                >
                                    <mat-option
                                        *ngFor="let type of activityTypes$ | async"
                                        [value]="type"
                                        >{{ "ActivityType." + type | translate }}</mat-option
                                    >
                                </mat-select>
                                <mat-error *ngIf="!formActivity.get('type').valid && formActivity.get('type').touched">
                                    {{ 'isRequired' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="25%" fxFlex="100">
                            <mat-form-field>
                                <mat-label>{{'distance'|translate}}</mat-label>
                                <input
                                    required
                                    [formControl]="$any(form.controls['activity'].get('distance'))"
                                    matInput
                                />
                                <span matSuffix>{{'Unit.KM' | translate}}</span>
                                <mat-error
                                    *ngIf="!formActivity.get('distance').valid && formActivity.get('distance').touched"
                                >
                                    <ng-container *ngIf="formActivity.get('distance').errors?.numeric">
                                        {{ 'numeric' | translate }}</ng-container
                                    >
                                    <ng-container *ngIf="formActivity.get('distance').errors?.required"
                                        >{{ 'isRequired' | translate }}</ng-container
                                    >
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="25%" fxFlex="100">
                            <mat-form-field>
                                <input
                                    matInput
                                    [matDatepicker]="startDate"
                                    [placeholder]='"Date" | translate'
                                    formControlName="startDate"
                                />
                                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate [disabled]="false"></mat-datepicker>
                                <mat-error *ngIf="!formStartDate.valid && formStartDate.touched">
                                    <ng-container *ngIf="formStartDate.errors?.matDatepickerMin">
                                        {{ 'minDate' | translate }}</ng-container
                                    >
                                    <ng-container *ngIf="formStartDate.errors?.required"
                                        >{{ 'isRequired' | translate }}</ng-container
                                    >
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--                        <div fxFlex.gt-sm="25%" fxFlex="100">-->
                        <!--                            <mat-form-field>-->
                        <!--                                <input-->
                        <!--                                    matInput-->
                        <!--                                    name="startTime"-->
                        <!--                                    formControlName="startTime"-->
                        <!--                                    [ngxMatTimepicker]="pickerA"-->
                        <!--                                    [placeholder]="'startTime' | translate"-->
                        <!--                                    [format]="24"-->
                        <!--                                />-->
                        <!--                                <mat-icon matSuffix (click)="pickerA.open()"> watch_later </mat-icon>-->
                        <!--                                <mat-error-->
                        <!--                                    *ngIf="!this.form.controls['startTime'].valid && this.form.controls['startTime'].touched"-->
                        <!--                                >-->
                        <!--                                    {{ 'isRequired' | translate }}-->
                        <!--                                </mat-error>-->
                        <!--                            </mat-form-field>-->
                        <!--                            <ngx-mat-timepicker [appendToInput]="true" #pickerA></ngx-mat-timepicker>-->
                        <!--                            {{this.form.controls['startTime'].value}}<br />-->
                        <!--                            {{this.form.controls['startTime'].value | json}}<br />-->
                        <!--                        </div>-->
                        <div
                            fxFlex.gt-sm="25%"
                            fxFlex="100"
                            class="mat-form-field-appearance-legacy time-input-wrapper"
                        >
                            <mat-label class="mat-form-field-label">{{'startTime'|translate}} *</mat-label>
                            <ngx-mat-timepicker-field
                                class="d-block"
                                color="primary"
                                [format]="24"
                                [defaultTime]="'12:00'"
                                formControlName="startTime"
                            ></ngx-mat-timepicker-field>
                        </div>
                        <div fxFlex.gt-sm="25%" fxFlex="100">
                            <mat-form-field>
                                <mat-label>{{'timeLimit'|translate}}</mat-label>
                                <input formControlName="timeLimit" matInput placeholder="1.0" />
                                <span matSuffix>{{'Unit.HH' | translate}}</span>
                                <mat-error
                                    *ngIf="!this.form.controls['timeLimit'].valid && this.form.controls['timeLimit'].touched"
                                >
                                    <ng-container
                                        *ngIf="this.form.controls['timeLimit'].errors?.number || this.form.controls['timeLimit'].errors?.decimal"
                                    >
                                        {{ 'numeric' | translate }}</ng-container
                                    >
                                    <ng-container *ngIf="this.form.controls['timeLimit'].errors?.required"
                                        >{{ 'isRequired' | translate }}</ng-container
                                    >
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="25%" fxFlex="100">
<!--                            <mat-form-field>-->
<!--                                <mat-label>{{'externalId'|translate}}</mat-label>-->
<!--                                <input formControlName="externalId" matInput />-->
<!--                            </mat-form-field>-->
                        </div>
                        <div fxFlex.gt-sm="50%" fxFlex="100">
                            <mat-form-field>
                                <mat-select formControlName="state" [placeholder]="'State' | translate" value="Draft">
                                    <mat-option
                                        *ngFor="let type of (workflowStates$ | async) | arrayOfValuesToOptions"
                                        [value]="type.value"
                                        >{{ "WorkflowState." + type.label | translate }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="!form.get('state').valid && form.get('state').touched">
                                {{ 'isRequired' | translate }}
                            </mat-error>
                        </div>
                        <div fxFlex.gt-sm="50%" fxFlex="100">
                            <mat-form-field>
                                <mat-label>{{'place'|translate}}</mat-label>
                                <input
                                    matInput
                                    required
                                    [placeholder]="formControlDistancePlaceholder"
                                    [formControl]="formControlPlace"
                                />
                                <mat-error *ngIf="!formControlPlace.valid && formControlPlace.touched">
                                    {{ 'isRequired' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="100%" fxFlex="100"
                             *ngIf="(organizedEventServiceState.organizedEvent$ | async) as organizedEvent"
                        >
                            <mat-label>{{'preferred_image' | translate}}</mat-label>
                            <div>
                                <mat-radio-group aria-label="Select an option" formControlName="favoriteMediaRef">
                                    <mat-radio-button value="">{{'none' | translate}}&nbsp;</mat-radio-button>
                                    <ng-container *ngFor="let media of organizedEvent.media">
                                        <mat-radio-button
                                            *ngIf="media.type === MediaType.image"
                                            [value]="media.ref"
                                        >
                                            <img with="50" height="50" src="{{apiUrl}}/files/events/{{organizedEventServiceState.organizedEventId}}/{{media.ref}}?size=50">
                                            &nbsp;
                                        </mat-radio-button>
                                    </ng-container>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div fxFlex.gt-sm="50%" fxFlex="100">
                            <p class="m-t-10">
                                <app-chips-radio-input
                                    [control]="$any(form.get('labels'))"
                                    [options]="activityLabels$ | async | localizedEnumOptions : (selectedLanguage$ | async).code"
                                >
                                </app-chips-radio-input>
                            </p>
                        </div>
                        <div fxFlex.gt-sm="50%" fxFlex="100">
                            <form [formGroup]="addressForm" class="basic-form">
                                <mat-form-field>
                                    <input
                                        type="text"
                                        [placeholder]="'position' | translate"
                                        formControlName="address"
                                        matInput
                                        required
                                        [matAutocomplete]="auto"
                                    />
                                    <mat-autocomplete
                                        #auto="matAutocomplete"
                                        (optionSelected)="addressSelected($event)"
                                        [displayWith]="displayAddressFn"
                                    >
                                        <mat-option *ngFor="let option of locationOptions$ | async" [value]="option">
                                            {{option.formatted_address}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <app-map
                                    mapHeight="150pt"
                                    [inputMarkerAllowed]="true"
                                    [inputMarkerRemovingAllowed]="false"
                                    (inputMarkerAction)="updatePosition($event)"
                                    [inputMarker]="this.form.controls['position'].value"
                                    [darkMode]="windowService.darkMode$ | async"
                                    class="mb-1 d-block"
                                ></app-map>
                                <mat-error
                                    class="small"
                                    *ngIf="!this.form.controls['position'].valid && this.form.controls['position'].touched"
                                    >{{ 'isRequired' | translate }}
                                </mat-error>
                            </form>
                        </div>
                        <div fxFlex.gt-sm="100%" fxFlex="100">
                            <mat-error *ngIf="!formControlDescription.valid && formControlDescription.touched">
                            </mat-error>
                            <mat-form-field>
                                <mat-label>{{'description'|translate}}</mat-label>
                                <textarea
                                    matInput
                                    style="height: 180pt"
                                    [formControl]="formControlDescription"
                                ></textarea>
                            </mat-form-field>
                        </div>
                        <h3>{{"links" | translate}}</h3>
                        <app-link-list
                            [formGroup]="form"
                        ></app-link-list>

                        <h3>{{'track' | translate}}</h3>
                    </div>
                </mat-card-content>
                <div *ngIf="uploadingTrack$ | async">
                    <app-loader style="margin-bottom: 3rem"></app-loader>
                </div>
                <div *ngIf="!(uploadingTrack$ | async)">
                    <app-map [activity]="this.formActivity.value" mapHeight="200pt"></app-map>
                </div>
                <mat-card-content>
                    <div fxLayout="row wrap" fxFlexAlign="center" class="row">
                        <div fxFlex.gt-sm="50%" fxFlex="100">
                            <app-file-uploader [uploader]="uploader"></app-file-uploader>
                        </div>
                        <div fxFlex.gt-sm="50%" fxFlex="100" class="text-right">
                            <a href="https://gpx.studio" target="_blank"
                            ><img src="../../../../assets/images/logo-gpx.studio.png" width="102" height="25"
                            /></a>
                            <button *ngIf='form.get("id").value' mat-button (click)="downloadGpx()" [disabled]="false">
                                <mat-icon>satellite</mat-icon>
                                {{'download'|translate}} GPX
                            </button>
                        </div>
                    </div>
                    <mat-error
                        class="track-error"
                        *ngIf="!$any(form.controls['activity'].get('track')).valid && $any(form.controls['activity'].get('track')).touched"
                    >
                        {{ 'isRequired' | translate }}
                    </mat-error>

                    <h3><br/>{{"priceList" | translate}} • {{"registration" | translate}}</h3>
                    <app-organized-event-activity-price-list
                        [itemForm]="form"
                    ></app-organized-event-activity-price-list>
                    <h3><br/>{{"priceList" | translate}} • {{"merchandise" | translate}}</h3>
                    <app-organized-event-activity-merchandise-price-list
                        [itemForm]="form"
                    ></app-organized-event-activity-merchandise-price-list>
                    <h3></h3>

                    <div class="form-element text-right">
                        <ng-container *ngIf="organizedActivity">
                            <button
                                [disabled]="isInThePast || organizedActivity?.isOpen"
                                (click)="open()"
                                mat-raised-button
                                color="warn"
                                type="submit"
                                class="button"
                            >
                                {{'open'|translate}} {{'registration'|translate|lowercase}}
                            </button>&nbsp;
                            <button
                                [disabled]="form.disabled"
                                (click)="copy()"
                                mat-raised-button
                                color="accent"
                                type="submit"
                                class="button"
                            >
                                {{'copy'|translate}}
                            </button>&nbsp;
                        </ng-container>
                        <button
                            [disabled]="form.disabled"
                            (click)="submitForm()"
                            mat-raised-button
                            color="primary"
                            type="submit"
                            class="button"
                        >
                            {{'save'|translate}}
                        </button>
                    </div>
                </mat-card-content>
            </ng-template>
        </mat-tab>
        <mat-tab [disabled]="!form.get('id').value">
            <ng-template mat-tab-label> <mat-icon>pageview</mat-icon>&nbsp;{{'view'|translate}} </ng-template>
            <ng-container *ngIf="organizedActivity?.organizedEvent">
                <organized-activity [organizedActivity]="organizedActivity"></organized-activity>
                <mat-card-content>
                    {{'app' | translate}} ID:
                    <a [href]="'/events/activities/' + organizedActivity.id" target="_blank">{{organizedActivity.id}}</a>
                </mat-card-content>
            </ng-container>
        </mat-tab>
        <mat-tab *ngIf="organizedActivity">
            <ng-template mat-tab-label> <mat-icon>sync</mat-icon>&nbsp;{{'synchronization'|translate}} </ng-template>
            <mat-card-content>
                <app-organized-event-activity-sync type="Registration" [organizedActivity]="organizedActivity"></app-organized-event-activity-sync>
                <app-organized-event-activity-sync type="ActivityResult" [organizedActivity]="organizedActivity"></app-organized-event-activity-sync>
            </mat-card-content>
        </mat-tab>
        <!-- organized activity workflow (TBD) -->
<!--        <mat-tab [disabled]="true">-->
<!--            <ng-template mat-tab-label> <mat-icon>autorenew</mat-icon>&nbsp;{{'workflow'|translate}} </ng-template>-->
<!--            <mat-card-content> </mat-card-content>-->
<!--        </mat-tab>-->
<!--        <mat-tab *ngIf="organizedActivity">-->
<!--            <ng-template mat-tab-label> <mat-icon>code</mat-icon>&nbsp;JSON </ng-template>-->
<!--            <mat-card-content>-->
<!--                <pre>{{stringifyOrganizedActivity}}</pre>-->
<!--            </mat-card-content>-->
<!--        </mat-tab>-->
    </mat-tab-group>
    <app-action-button
        icon="archive"
        [disabled]="form.disabled"
        (click)="submitForm()"
    ></app-action-button>
</form>
