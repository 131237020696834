<div fxLayout="row wrap" *ngIf="person">
    <div fxFlex="100">
        <mat-card style="position: relative">
            <mat-card-content class="text-center person-component person-component_normal">
                <!-- QR code -->
                <a (click)="windowService.open(this.apiUrl + '.qr.png?invert=' + darkMode)" style="cursor: pointer"
                ><img
                    [src]="this.apiUrl + '/users/' + person.id + '.qr.png?invert=' + darkMode"
                    style="position: absolute; left: 0; top: 0; width: 80pt; height: 80pt"
                /></a>
                <app-person
                    [person]="person"
                    [asCard]="true"
                    [withDescription]="true"
                    [withTeams]="true"
                    [followStatus]="followStatus"
                    (follow)="follow($event)"
                    (unfollow)="unfollow($event)"
                ></app-person>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-tab-group>
                <mat-tab *ngIf="user">
                    <ng-template mat-tab-label>
                        <mat-icon>information</mat-icon>&nbsp;{{'information' | translate}}
                    </ng-template>
                    <mat-card-content>
                        <small>Actigo ID</small>
                        <p>{{user.id}}</p>
                        <small>{{'birthDate' | translate}}</small>
                        <p>{{user.profile.birthDate ? (toDate(user.profile.birthDate) | localizeDate :'longDate') : '-'}}</p>
                        <small>{{'contactEmail' | translate}}</small>
                        <p>{{user.contactEmail.value}}</p>
                        <small>{{'phoneNumber' | translate}}</small>
                        <p>{{user.phoneNumber.value == '+0' ? '-' : user.phoneNumber.value}}</p>
                        <small>{{'street' | translate}}</small>
                        <p>{{user.address?.street || '-'}}</p>
                        <small>{{'city' | translate}}</small>
                        <p>{{user.address?.city || '-'}}</p>
                        <small>{{'created' | translate}}</small>
                        <p>{{user.created | localizeDate :'short'}}</p>
                        <small>{{'accessed' | translate}}</small>
                        <p>{{user.accessed | localizeDate :'short'}}</p>
                        <ng-container *ngIf="organizers?.length > 0 && organizers?.length < 10">
                            <small>{{'organizers' | translate}}</small>
                            <ul>
                                <li *ngFor="let organizer of organizers">{{organizer.name}}</li>
                            </ul>
                        </ng-container>
                    </mat-card-content>
                </mat-tab>
                <ng-container *ngIf="((person.isProfilePublic || followStatus == 'Following') || canEdit)">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>directions_run</mat-icon>&nbsp;{{'activities' | translate}}
                        </ng-template>
                        <mat-card-content>
                            <div class="responsive-table">
                                <mat-table #table [dataSource]="userActivities">
                                    <ng-container matColumnDef="connectionType">
                                        <mat-header-cell *matHeaderCellDef>{{'connectionType' | translate}}</mat-header-cell>
                                        <mat-cell *matCellDef="let element" [attr.data-label]="'connectionType' | translate">
                                            {{element.connectionType}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="activity">
                                        <mat-header-cell *matHeaderCellDef>{{'activity' | translate}}</mat-header-cell>
                                        <mat-cell *matCellDef="let element" [attr.data-label]="'activity' | translate">
                                            <a [routerLink]="['/users/activities', element.id]"
                                            >{{'ActivityType.' + element.activity.type | translate}} - {{element.activity.name}}</a
                                            >
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="elapsedTime">
                                        <mat-header-cell *matHeaderCellDef class="justify-content-end"
                                        >{{'elapsedTime' | translate}}</mat-header-cell
                                        >
                                        <mat-cell
                                            *matCellDef="let element"
                                            [attr.data-label]="'elapsedTime' | translate"
                                            class="justify-content-end"
                                        >
                                            {{element.elapsedTime | time}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="movingTime">
                                        <mat-header-cell *matHeaderCellDef class="justify-content-end"
                                        >{{'movingTime' | translate}}</mat-header-cell
                                        >
                                        <mat-cell
                                            *matCellDef="let element"
                                            [attr.data-label]="'movingTime' | translate"
                                            class="justify-content-end"
                                        >
                                            {{element.movingTime | time}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="started">
                                        <mat-header-cell *matHeaderCellDef>{{'started' | translate}}</mat-header-cell>
                                        <mat-cell *matCellDef="let element" [attr.data-label]="'started' | translate">
                                            {{element.started | localizeDate : 'd.MM.y HH:mm:ss'}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="distance" class="justify-content-end">
                                        <mat-header-cell *matHeaderCellDef class="justify-content-end"
                                        >{{'distance' | translate}}</mat-header-cell
                                        >
                                        <mat-cell
                                            *matCellDef="let element"
                                            [attr.data-label]="'distance' | translate"
                                            class="justify-content-end"
                                        >
                                            {{element.distance | number: '1.0-3'}} {{'Unit.KM' | translate}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="pace" class="justify-content-end">
                                        <mat-header-cell *matHeaderCellDef class="justify-content-end"
                                        >{{'pace' | translate}}</mat-header-cell
                                        >
                                        <mat-cell
                                            *matCellDef="let element"
                                            [attr.data-label]="'pace' | translate"
                                            class="justify-content-end"
                                        >
                                            {{element.pace | pace}}/{{'Unit.KM' | translate}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="expandedDetail">
                                        <mat-cell *matCellDef="let row" [attr.colspan]="userActivityColumns.length">
                                            <div
                                                class="example-element-detail"
                                                [@detailExpand]="row === userActivityExpanded ? 'expanded' : 'collapsed'"
                                            >
                                                TODO detail
                                            </div>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="userActivityColumns"></mat-header-row>
                                    <mat-row
                                        *matRowDef="let row; columns: userActivityColumns"
                                        class="example-element-row"
                                        [class.example-expanded-row]="userActivityExpanded === row"
                                        (click)="userActivityExpanded = userActivityExpanded === row ? null : row"
                                    ></mat-row>
                                    <p *matNoDataRow class="no-data">{{'noData'|translate}}</p>
                                </mat-table>
                            </div>
                        </mat-card-content>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>check</mat-icon>&nbsp;{{'registrations' | translate}}
                        </ng-template>
                        <mat-card-content>
                            <app-registrations-table
                                [userId]="person.id"
                                [futureOnly]="true"
                                [openOrganizedActivity]="true"
                            ></app-registrations-table>
                        </mat-card-content>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>timer</mat-icon>&nbsp;{{'results' | translate}}
                        </ng-template>
                        <mat-card-content>
                            <app-activity-results-table [userId]="person.id" [limit]="20"></app-activity-results-table>
                        </mat-card-content>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>message</mat-icon>&nbsp;{{'posts' | translate}}
                        </ng-template>
                        <mat-card-content>
                            <app-messages entityType="Person" [entityId]="person.id">></app-messages>
                        </mat-card-content>
                    </mat-tab>
                    <mat-tab *ngIf="isLoggedIn">
                        <ng-template mat-tab-label>
                            <mat-icon>people</mat-icon>&nbsp;{{followerPersons.length}} {{'followers' | translate |lowercase}}
                        </ng-template>
                        <mat-card-content class="pt-2 pe-0 pb-0 ps-0">
                            <div fxLayout="row wrap">
                                <app-person
                                    *ngFor="let followerPerson of followerPersons"
                                    [person]="followerPerson"
                                    [showRemoveButton]="false"
                                    fxFlex.gt-sm="50"
                                    fxFlex.gt-md="33.3"
                                    fxFlex.gt-lg="33.3"
                                    fxFlex.lt-sm="100"
                                    fxFlex.lt-md="50"
                                    fxFlex="33.3"
                                    class="pb-3"
                                ></app-person>
                            </div>
                        </mat-card-content>
                    </mat-tab>
                    <mat-tab *ngIf="isLoggedIn">
                        <ng-template mat-tab-label>
                            <mat-icon>person_pin</mat-icon>&nbsp;{{followingPersons.length}} {{'following' | translate | lowercase}}
                        </ng-template>
                        <mat-card-content class="pt-2 pe-0 pb-0 ps-0">
                            <div fxLayout="row wrap">
                                <app-person
                                    *ngFor="let followingPerson of followingPersons"
                                    [person]="followingPerson"
                                    [showRemoveButton]="false"
                                    fxFlex.gt-sm="50"
                                    fxFlex.gt-md="33.3"
                                    fxFlex.gt-lg="33.3"
                                    fxFlex.lt-sm="100"
                                    fxFlex.lt-md="50"
                                    fxFlex="33.3"
                                    class="pb-3"
                                ></app-person>
                            </div>
                        </mat-card-content>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
        </mat-card>
    </div>
</div>
