import { gql } from 'apollo-angular'

export const PAY_REGISTRATION = gql`
    mutation payRegistration($command: PayRegistration!) {
        payRegistration(command: $command) {
            paymentId
            providerPaymentId
            redirectUrl
            paymentState
        }
    }
`
export const CHECK_PAYMENT = gql`
    mutation checkPayment($command: CheckPayment!) {
        checkPayment(command: $command) {
            paymentId
            providerPaymentId
            redirectUrl
            paymentState
        }
    }
`
