import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { AbstractFormComponent } from 'app/core/components/abstract.form.component'
import {
    organizedEventMediaConfig,
    mediaTypeOptions,
} from 'app/events/components/organized-event-media/organized-event-media.config'
import { Media, MediaType, Nullish } from 'app/core/core.models'
import { FileUploader } from 'lib/file-upload/file-uploader.class'
import { FileItem } from 'lib/file-upload/file-item.class'
import { CreateMediaInput } from 'app/events/events.models'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Globals } from 'app/app.consts'
import { TranslateService } from '@ngx-translate/core'
import { Apollo } from 'apollo-angular'
import { OrganizedEventServiceState } from 'app/events/components/organized-event/organized-event-service.state'
import { WindowService } from 'app/core/services/window.service'
import { BehaviorSubject, distinctUntilChanged, takeUntil } from 'rxjs'
import { OrganizedEventService } from 'app/events/services/organized-event.service'
import { LanguageService } from 'app/core/services/language.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { DateAdapter } from '@angular/material/core'
import { Auth0SpaService } from 'app/users/services/auth0-spa.service'
import { server } from 'app/core/core.server'
import { getYouTubeIdFromUrl } from 'app/core/utils'

@UntilDestroy()
@Component({
    selector: 'app-organized-event-media',
    templateUrl: './organized-event-media.component.html',
    styleUrls: ['organized-event-media.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedEventMediaComponent extends AbstractFormComponent implements OnInit {
    @ViewChild('mediaSection')
    set pane(elementRef: ElementRef) {
        if (elementRef) {
            this.size = Math.round(elementRef.nativeElement.getBoundingClientRect().width / 3)
        }
    }

    readonly apiUrl = server.apiUrl
    readonly MediaType = MediaType
    readonly mediaTypeOptions = mediaTypeOptions
    readonly showUploadingForm$ = new BehaviorSubject<Nullish<boolean>>(null)
    readonly uploader = new FileUploader({
        url: null,
        method: 'post',
        disableMultipart: true,
    })
    readonly uploadingFile$ = new BehaviorSubject(false)

    baseUlrToUploadFile = ''
    size: number = null

    form: FormGroup = new FormGroup({
        type: new FormControl(MediaType.image, Validators.required),
        title: new FormControl(''),
        ref: new FormControl('', Validators.required),
    })

    constructor(
        private readonly apollo: Apollo,
        public readonly organizedEventService: OrganizedEventService,
        public readonly organizedEventServiceState: OrganizedEventServiceState,
        private readonly authService: Auth0SpaService,
        private readonly snackBar: MatSnackBar,
        private readonly translateService: TranslateService,
        private readonly cd: ChangeDetectorRef,
        public override readonly windowService: WindowService,
        _adapter: DateAdapter<any>,
        languageService: LanguageService,
    ) {
        super(windowService, languageService, _adapter)

        this.organizedEventServiceState.organizedEvent$.pipe(untilDestroyed(this)).subscribe((organizedEvent) => {
            if (organizedEvent) {
                this.baseUlrToUploadFile = `${server.apiUrl}/events/${organizedEvent.id}/files`
            }
        })
        this.form.get('type').valueChanges.subscribe((value) => {
            if (value != MediaType.image) {
                this.form.get('ref').setValidators(Validators.required)
            } else {
                this.form.get('ref').clearValidators()
            }
        })
    }

    ngOnInit() {
        this.uploader.onSuccessItem = (fileItem: FileItem, response, status) => {
            this.snackBar.open(this.translateService.instant('successResult'), null, {
                duration: Globals.durationSnackBarMessage,
                panelClass: 'snack-bar-success',
            })
            this.processFileUploadResponse()
        }

        this.uploader.onErrorItem = (fileItem: FileItem, response, status) => {
            this.uploader.clearQueue()
            this.snackBar.open(response || this.translateService.instant('globalError'), null, {
                duration: Globals.durationSnackBarMessage,
                panelClass: 'snack-bar-error',
            })
            this.form.enable()
            this.uploadingFile$.next(false)
        }

        this.uploader.onWhenAddingFileFailed = (item, filter) => {
            this.uploader.clearQueue()
            switch (filter.name) {
                case 'fileType':
                    this.snackBar.open(this.translateService.instant('badType'), null, {
                        duration: Globals.durationSnackBarMessage,
                        panelClass: 'snack-bar-error',
                    })
            }
        }

        this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
            const isUploadedMaxFiles =
                this.organizedEventServiceState?.organizedEvent$?.value?.media?.length >
                organizedEventMediaConfig.maxMedia - 1
            if (isUploadedMaxFiles) {
                this.snackBar.open(this.translateService.instant('maxFilesError'), null, {
                    duration: Globals.durationSnackBarMessage,
                    panelClass: 'snack-bar-error',
                })
            }
        }

        this.form
            .get('type')
            .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe((mediaType: MediaType) => {
                this.uploader.clearQueue()
                this.uploader.setOptions({
                    ...this.uploader.options,
                    allowedFileType: [mediaType === MediaType.image ? MediaType.image : MediaType.video],
                })
                this.showUploadingForm$.next(organizedEventMediaConfig.mediaTypesWithVideo[mediaType])
            })

        this.showUploadingForm$.pipe(untilDestroyed(this)).subscribe((showUploadingForm: boolean) => {
            const refControl = this.form.get('ref')
            //refControl.setValidators(showUploadingForm ? null : [Validators.required, RxwebValidators.url()])
            showUploadingForm ? refControl.disable() : refControl.enable()
            this.cd.detectChanges()
        })

        this.form.get('type').setValue(organizedEventMediaConfig.initMediaType)
    }

    onSubmitValidForm(values: CreateMediaInput, fileItem: FileItem) {
        this.form.disable()
        if (values.type === MediaType.youtube) {
            values.ref = getYouTubeIdFromUrl(values.ref)
        }
        if (this.isMedia()) {
            this.authService.getAccessToken$().subscribe((token) => {
                const fileItem = this.uploader?.queue?.[0]
                if (!fileItem) {
                    this.snackBar.open(this.translateService.instant('fileRequired'), null, {
                        duration: Globals.durationSnackBarMessage,
                        panelClass: 'snack-bar-error',
                    })
                    this.form.enable()
                    return
                }

                this.uploader.setOptions({
                    ...this.uploader.options,
                    headers: [
                        {
                            name: 'Authorization',
                            value: 'Bearer ' + token,
                        },
                        {
                            name: 'content-type',
                            value: fileItem?.file?.type,
                        },
                    ],
                    url: `${this.baseUlrToUploadFile}?title=${this.form.get('title').value}`,
                })
                fileItem.upload()
                this.uploadingFile$.next(true)
            })
        } else {
            this.organizedEventService
                .addOrganizedEventMedia$({
                    organizerEventId: this.organizedEventServiceState.organizedEventId,
                    media: {
                        ...values,
                    },
                })
                .subscribe(() => {
                    this.processFileUploadResponse()
                    this.form.enable()
                })
        }
    }

    isMedia(type: MediaType = null) {
        const actualType = this.form.getRawValue().type
        return (type ?? actualType) === MediaType.video || (type ?? actualType) === MediaType.image
    }

    setFavoriteMedia(media: Media, allMedia: Media[]) {
        allMedia.forEach((m: Media) => {
            m.isFavorite = m.ref === media.ref
            if (m.isFavorite) {
                this.organizedEventService
                    .setOrganizedEventMediaFavorite$({
                        organizerEventId: this.organizedEventServiceState.organizedEventId,
                        mediaRef: media.ref,
                    })
                    .subscribe((result) => {
                        console.log(
                            'Set favorite media',
                            this.organizedEventServiceState.organizedEventId,
                            media.ref,
                            result,
                        )
                    })
            }
        })
    }

    deleteMedia(media: Media) {
        this.form.disable()
        this.uploadingFile$.next(true)

        this.organizedEventService
            .removeOrganizedEventMedia$({
                mediaRef: media.ref,
                organizerEventId: this.organizedEventServiceState.organizedEventId,
            })
            .subscribe(() => {
                this.form.enable()
                this.uploadingFile$.next(false)
                this.snackBar.open(this.translateService.instant('successResult'), null, {
                    duration: Globals.durationSnackBarMessage,
                    panelClass: 'snack-bar-success',
                })
                this.processFileUploadResponse()
            })
    }

    private processFileUploadResponse() {
        this.organizedEventServiceState.loadOrganizedEvent(this.organizedEventServiceState.organizedEventId)
        this.form.enable()
        this.form.reset({
            type: this.form.get('type').value,
        })
        this.uploader.clearQueue()
        this.uploadingFile$.next(false)
    }

    trackByRef(index, media) {
        return media.ref
    }
}
