import { Pipe, PipeTransform } from '@angular/core'
import { IOptions } from 'app/core/core.models'

@Pipe({
    name: 'arrayOfValuesToOptions',
})
export class ArrayOfValuesToOptionsPipe implements PipeTransform {
    transform(values: string[]): IOptions<any> {
        return values.map((value) => ({
            value: value,
            label: value,
        }))
    }
}
