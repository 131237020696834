import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Payment, PaymentStatus, PayRegistration } from 'app/payments/payments.models'
import { ApolloService } from 'app/core/services/apollo.service'
import { filter } from 'rxjs/operators'
import * as R_ from 'ramda-extension'
import { CHECK_PAYMENT, PAY_REGISTRATION } from '../payments.mutations'
import { PAYMENT } from '../payments.queries'

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    constructor(private apolloService: ApolloService) {}

    payRegistration$(command: PayRegistration): Observable<PaymentStatus> {
        return this.apolloService
            .mutate<PaymentStatus>({
                mutation: PAY_REGISTRATION,
                variables: {
                    command,
                },
            })
            .pipe(filter(R_.isNotNil))
    }

    checkPayment$(paymentId: string): Observable<PaymentStatus> {
        return this.apolloService.mutate<PaymentStatus>({
            mutation: CHECK_PAYMENT,
            variables: {
                command: {
                    paymentId: paymentId,
                },
            },
        })
    }

    getPayment$(paymentId: string): Observable<Payment> {
        return this.apolloService.query<Payment>({
            query: PAYMENT,
            variables: {
                query: {
                    paymentId: paymentId,
                },
            },
            fetchPolicy: 'no-cache',
        })
    }
}
