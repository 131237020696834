import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'
import { OrganizedEvent } from 'app/events/events.models'
import { Media, MediaType } from 'app/core/core.models'
import { server } from 'app/core/core.server'

@Pipe({
    name: 'eventPictureUrl',
})
export class EventPictureUrlPipe implements PipeTransform {
    transform(organizedEvent: OrganizedEvent): Media[] {
        const isInternalLink = (ref: string): boolean => ref && ref.indexOf('http') !== 0
        const makeUrl = (ref: string): string => `${server.apiUrl}/files/events/${organizedEvent.id}/${ref}`
        const getThumbRef = (ref: string, fallbackQueryParam = ''): string =>
            isInternalLink(ref) ? makeUrl(`${ref}?${fallbackQueryParam}`) : ref
        return R.pipe(
            R.prop('media'),
            R.defaultTo([]),
            R.map((media) => {
                return {
                    ...media,
                    ref: isInternalLink(media.ref) && media.type !== MediaType.youtube ? makeUrl(media.ref) : media.ref,
                    thumbRef: getThumbRef(media.thumbRef) || getThumbRef(media.ref, 'size=800'),
                }
            }),
        )(organizedEvent)
    }
}
