import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { OrganizedActivity, SyncConfig, SyncType } from '../../events.models'
import { OrganizedActivityService } from '../../services/organized-activity.service'

@Component({
  selector: 'app-organized-event-activity-sync',
  templateUrl: './organized-event-activity-sync.component.html',
})
export class OrganizedEventActivitySyncComponent implements OnInit {

    @Input()
    organizedActivity: OrganizedActivity

    @Input()
    type: string

    formGroup: FormGroup

    constructor(
        public fb: FormBuilder,
        private organizedActivityService: OrganizedActivityService
    ) {
        this.formGroup = this.fb.group({
            endpointUrl: [null, []],
            dataFileFormat: [null, []],
            runImmediately: [false, [Validators.required]],
            dryRun: [false, [Validators.required]],
        })
    }

    ngOnInit(): void {
        this.formGroup.patchValue(this.organizedActivity.registrationSyncConfig)
    }

    submit(config: SyncConfig): void {
        this.organizedActivityService.updateOrganizedActivitySyncConfig$(
            this.organizedActivity.id,
            this.type,
            config.endpointUrl,
            config.dataFileFormat,
            config.runImmediately,
            config.dryRun
        ).subscribe((result) => {
            console.log(`Organized activity ${this.type} sync config update`, this.organizedActivity.id, result)
            if (result) {
                if (this.type == SyncType.Registration)
                    this.organizedActivity.registrationSyncConfig = config
                if (this.type == SyncType.ActivityResult)
                    this.organizedActivity.activityResultSyncConfig = config
            }
        })
    }
}
