import { Pipe, PipeTransform } from '@angular/core'
import { AbstractControl, FormArray, FormControl } from '@angular/forms'
import { localizeArrayForm } from 'app/core/utils'
import { LanguageCode } from '../../constants/languages'

@Pipe({
    name: 'localizeArrayForm',
})
export class LocalizeArrayFormPipe implements PipeTransform {
    transform(formArray: FormArray | AbstractControl, language: LanguageCode): FormControl {
        return localizeArrayForm(formArray, language)
    }
}
