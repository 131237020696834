import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { OrganizedActivitiesStateService } from './organized-activities-state.service'
import { View } from './organized-activities.model'
import { ActivatedRoute, Router } from '@angular/router'
import { buttonsGroups } from '../organized-activities-search/organized-activities-search.config'
import { MatButtonToggleChange } from '@angular/material/button-toggle'

@Component({
    selector: 'app-organized-activities',
    templateUrl: './organized-activities.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    // providers: [OrganizedActivitiesStateService],
})
export class OrganizedActivitiesComponent implements OnInit {
    VIEW = View
    view: View
    readonly buttonsGroups = buttonsGroups
    reload = new Date().getTime()

    constructor(
        public readonly organizedActivitiesStateService: OrganizedActivitiesStateService,
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef,
    ) {
        this.organizedActivitiesStateService.view$.next(this.route.snapshot.data.view)
        this.view = this.route.snapshot.data.view
    }

    ngOnInit() {
        // TODO - temporary to make proper selection on view tab
        setTimeout(() => {
            this.reload = new Date().getTime()
            this.cd.detectChanges()
        })
    }

    viewChange({ value }: MatButtonToggleChange) {
        this.router.navigate(['/events/activities', value], {
            queryParamsHandling: 'merge',
        })
    }
}
