<label
    label
    for="files"
    ng2FileDrop
    [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
    (fileOver)="fileOverBase($event)"
    [uploader]="uploader"
    class="well my-drop-zone"
    [class.disabled]="disabled"
    [class.success]="uploader.queue.length === 1"
>
    {{"uploadFiles" | translate}}
    <input
        [disabled]="disabled"
        style="display: none"
        id="files"
        class="hidden"
        type="file"
        ng2FileSelect
        [uploader]="uploader"
        multiple
    />
</label>
