import { UntilDestroy } from '@ngneat/until-destroy'
import { Injectable } from '@angular/core'
import { ApolloService } from 'app/core/services/apollo.service'
import { Observable } from 'rxjs'
import { Article } from '../articles.models'
import { ARTICLE, ARTICLES } from '../articles.queries'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ArticleService {
    constructor(private apolloService: ApolloService) {}

    getArticles$(limit: number): Observable<Article[]> {
        return this.apolloService.watchQuery<Article[]>({
            query: ARTICLES,
            variables: {
                query: {
                    limit: limit,
                },
            },
        })
    }

    getArticle$(id: string): Observable<Article> {
        return this.apolloService.watchQuery<Article>({
            query: ARTICLE,
            variables: {
                query: {
                    articleId: id,
                },
            },
        })
    }
}
