import { Component, OnInit } from '@angular/core'
import { UserService } from '../../services/user.service'
import { LoginService } from '../../services/login.service'

@Component({
  selector: 'app-user-delete',
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss']
})
export class UserDeleteComponent implements OnInit {

    constructor(
        private userService: UserService,
        private loginService: LoginService
    ) { }

    ngOnInit(): void {
    }

    delete() {
        this.userService.purgeUser$().subscribe((result) => {
            if (result) {
                this.loginService.logout()
            }
        })
    }
}
