import {
    OrganizedEventModelTab,
    OrganizedEventParams,
} from 'app/events/components/organized-event/organized-event.model'

export const organizedEventConfig = {
    defaultTab: OrganizedEventModelTab.media,
    paramForCreate: 'new',
    params: {
        id: 'id',
        activeTab: 'active-tab',
    } as OrganizedEventParams,
} as const
