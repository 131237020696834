

export const privacy = {
    'cs': `
# Ochrana soukromí
bla
## H2
bla bla

### H3
bla bla bla

`,

    'en': `
# Privacy
bla
## H2
bla bla

### H3
bla bla bla

`,
}
