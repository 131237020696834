import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { LanguageService } from 'app/core/services/language.service'
import { LanguageCode } from 'app/core/constants/languages'

@Pipe({
    name: 'localizeDate',
    pure: false,
})
export class LocalizeDatePipe implements PipeTransform {
    private result: string = ''
    private latestFormat: string
    private latestTimeZone: string
    private latestDate: Date | string
    private latestCode: LanguageCode

    constructor(private languageService: LanguageService) {}

    transform(date: Date | string, format = 'mediumDate', languageCode = null, timeZone = null): string {
        if (languageCode === null) {
            languageCode = this.languageService.selectedLanguageCode
        }
        if (
            this.latestDate === date &&
            this.latestCode === languageCode &&
            this.latestFormat === format &&
            this.latestTimeZone === timeZone
        ) {
            return this.result
        }
        this.latestDate = date
        this.latestCode = languageCode
        this.latestFormat = format
        this.latestTimeZone = timeZone
        const datePipe = new DatePipe(languageCode, timeZone)
        this.result = datePipe.transform(date, format)
        return this.result
    }
}
