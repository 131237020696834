import { Component, OnInit } from '@angular/core'
import { OrganizedActivityService } from 'app/events/services/organized-activity.service'
import { OrganizedActivity } from '../../events.models'
import { Observable, switchMap } from 'rxjs'
import { LoginService } from 'app/users/services/login.service'
import { filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import * as R_ from 'ramda-extension'

@UntilDestroy()
@Component({
    selector: 'app-organized-activities-mine',
    templateUrl: './organized-activities-mine.component.html',
})
export class OrganizedActivitiesMineComponent implements OnInit {
    organizedActivities$: Observable<OrganizedActivity[]>
    now = new Date()

    constructor(
        private readonly loginService: LoginService,
        private readonly organizedActivityService: OrganizedActivityService,
    ) {}

    ngOnInit(): void {
        this.organizedActivities$ = this.loginService.loggedIn$.pipe(
            switchMap(() => {
                return this.organizedActivityService.getMyOrganizedActivities$(false)
            }),
            untilDestroyed(this),
        )
    }
}
