import { Component, Input } from '@angular/core'
import { CarouselTypes } from 'app/core/components/carousel/carousel.model'
import { ActivityLabel, Media } from 'app/core/core.models'
import { AbstractFollowComponent } from '../abstract-follow.component'
import dayjs from 'dayjs'
import { EnumService } from '../../../core/services/enum.service'

@Component({
    selector: 'app-organized-activity-card',
    templateUrl: './organized-activity-card.component.html',
    styleUrls: ['./organized-activity-card.component.scss'],
})
export class OrganizedActivityCardComponent extends AbstractFollowComponent {

    @Input() selectedActivityLabels: string[] = []
    @Input() showCarousel = false
    @Input() fixedAspectRatio = true
    carouselTypes = CarouselTypes
    now = new Date()

    get isInThePast() {
        const startTime = dayjs(this.organizedActivity.startTime)
        const now = dayjs()
        return startTime < now
    }

    favoriteOf(media: Media[], favoriteRef: string): Media {
        let favoriteMedia = media.find((m) => favoriteRef ? m.ref == favoriteRef || m.ref.endsWith(favoriteRef) : m.isFavorite)
        return favoriteMedia ? favoriteMedia : media[0]
    }
}
