import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { OrganizedActivitiesSearchComponent } from 'app/events/components/organized-activities-search/organized-activities-search.component'
import { CoreModule } from 'app/core/core.module'
import { EventsRoutingModule } from 'app/events/events-routing.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatCardModule } from '@angular/material/card'
import { MatTabsModule } from '@angular/material/tabs'
import { MatChipsModule } from '@angular/material/chips'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MapModule } from 'app/core/components/map/map.module'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { MaterialModule } from 'app/layouts/material.module'
import { PipesModule } from 'app/core/pipes/pipes.module'
import { CarouselModule } from 'app/core/components/carousel/carousel.module'
import { ChipsRadioInputModule } from 'app/core/components/forms/chips-radio-input/chips-radio-input.module'
import { LoaderModule } from 'app/core/components/loader/loader.module'
import { ActionButtonModule } from 'app/core/components/action-button/action-button.module'
import { FileUploaderModule } from 'app/core/components/file-uploader/file-uploader.module'
import { TrackProfileModule } from 'app/core/components/track-profile/track-profile.module'
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker'

@NgModule({
    declarations: [OrganizedActivitiesSearchComponent],
    imports: [
        CommonModule,
        CoreModule,
        EventsRoutingModule,
        FlexLayoutModule,
        TranslateModule,
        MatCardModule,
        MatTabsModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        MatButtonToggleModule,
        MapModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PipesModule,
        CarouselModule,
        PipesModule,
        ChipsRadioInputModule,
        LoaderModule,
        ActionButtonModule,
        FileUploaderModule,
        TrackProfileModule,
        NgxMatTimepickerModule,
    ],
    providers: [],
    exports: [OrganizedActivitiesSearchComponent],
})
export class OrganizedActivitiesSearchModule {}
