<footer class="footer">
    <ul class="footer-navigation__list">
        <li class="footer-navigation__item" *ngFor="let footerItem of footerItems; let i = index">
            <span *ngIf="i !== 0" class="footer-navigation__dot">·</span>
            <a class="footer-navigation__link text-shadow" [routerLink]="footerItem.href"
                >{{footerItem.name | translate}}</a
            >
        </li>
    </ul>
    <div class="footer__copyright">
        <span class="footer-navigation__dot">·</span
        ><span class="text-shadow" (click)="openDevTools()">{{copyright}}</span>
    </div>
</footer>
