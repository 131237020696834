<div fxLayout="row wrap" class="articles-list">
    <div *ngFor="let article of articles$ | async" fxFlex.gt-md="33" fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100" class="articles-article">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <!--                    <a href="javascript:void(0)" (click)="navigate(article)">{{article.title}}</a>-->
                    <a [href]="article.ref" target="_blank" class="h2">{{article.title}}</a>
                </mat-card-title>
                <mat-card-subtitle>
                    <div class="articles-article__meta">
                        <div class="articles-article__meta-updated">{{'updated' | translate}}: {{article.updated | localizeDate:  'short'}}</div>
                        <div class="articles-article__meta-source">{{'source' | translate}}: <a [href]="article.ref" target="_blank">Běhej.com</a></div>
                    </div>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content [innerHTML]="article.text | keepHtml"></mat-card-content>
            <div class="articles-article__more">
                <a [href]="article.ref" target="_blank" class="btn-transition btn-shadow btn btn-outline-secondary">{{'readMore' | translate}}&hellip;</a>
            </div>
        </mat-card>
    </div>
</div>
