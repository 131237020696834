import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { LoginService } from 'app/users/services/login.service'
import { VerifiedContact } from 'app/core/core.models'

@Component({
    selector: 'app-verified-contact',
    templateUrl: './verified-contact.component.html',
})
export class VerifiedContactComponent implements OnInit {
    formGroup: FormGroup

    constructor(public loginService: LoginService, public fb: FormBuilder) {
        this.formGroup = this.fb.group({
            value: [null, [Validators.required]],
        })
    }

    @Input()
    title: string

    @Input()
    subtitle: string

    @Input()
    placeholder: string = ''

    @Input()
    verifiedContact: VerifiedContact

    @Output()
    updateVerifiedContact = new EventEmitter<VerifiedContact>()

    ngOnInit(): void {
        //this.formGroup.patchValue(this.verifiedContact)
        if (!this.placeholder) this.placeholder = this.verifiedContact.value
    }

    submitForm(verifiedContact: VerifiedContact): void {
        this.updateVerifiedContact.emit(verifiedContact)
    }
}
