<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="responsive-table">
                    <mat-table #table [dataSource]="events$ | async">
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef> {{'name' | translate}} </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.name | localize}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="labels">
                            <mat-header-cell *matHeaderCellDef> {{'labels' | translate}} </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <ng-container
                                    *ngFor="let label of element.labels.slice(0, maxLabelsToShowInTable); let last = last;"
                                >
                                    <span>{{"ActivityLabel." + label | translate}}</span
                                    ><span *ngIf="!last">,&nbsp;</span>
                                </ng-container>
                                <span *ngIf="element?.labels?.length > maxLabelsToShowInTable">, ...</span>
                            </mat-cell>
                        </ng-container>
                        <!--                        <ng-container matColumnDef="workflow.state">-->
                        <!--                            <mat-header-cell *matHeaderCellDef> {{'State' | translate}} </mat-header-cell>-->
                        <!--                            <mat-cell *matCellDef="let element">-->
                        <!--                                {{"WorkflowState." + element?.workflow?.state | translate}}-->
                        <!--                            </mat-cell>-->
                        <!--                        </ng-container>-->
                        <ng-container matColumnDef="workflow.updated">
                            <mat-header-cell *matHeaderCellDef> {{'updated' | translate}} </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element?.workflow?.updated | localizeDate : 'short'}}
                            </mat-cell>
                        </ng-container>
                        <!--                        <ng-container matColumnDef="externalId">-->
                        <!--                            <mat-header-cell *matHeaderCellDef> {{'externalId' | translate}} </mat-header-cell>-->
                        <!--                            <mat-cell *matCellDef="let element"> {{element?.externalId}} </mat-cell>-->
                        <!--                        </ng-container>-->

                        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                        <mat-row
                            class="cursor-pointer"
                            (click)="navigateToDetail(row)"
                            *matRowDef="let row; columns: columns"
                        ></mat-row>
                        <p *matNoDataRow class="no-data">{{'noData'|translate}}</p>
                    </mat-table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <app-action-button (action)="plusButtonAction()"></app-action-button>
</div>
