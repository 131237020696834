import { Component } from '@angular/core'
import { filter, map } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router'
import { RegistrationService } from '../../services/registration.service'
import { Registration, RegistrationState } from '../../events.models'
import { of, switchMap } from 'rxjs'
import { WindowService } from 'app/core/services/window.service'
import { PaymentService } from 'app/payments/services/payment.service'
import { server } from 'app/core/core.server'
import { MerchandisePrice } from '../../../core/core.models'

@Component({
    selector: 'app-registrations',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
    constructor(
        public route: ActivatedRoute,
        private registrationService: RegistrationService,
        public windowService: WindowService,
        private paymentService: PaymentService,
    ) {}

    id: string = this.route.snapshot.params['id']
    apiUrl = `${server.apiUrl}/events/registrations`
    registration$ = this.registrationService.userRegistrations$.pipe(
        map((it) => it.find((e) => (this.id.startsWith('R') ? e.code : e.id) == this.id)),
    )

    payment$ = this.registration$.pipe(
        switchMap((it: Registration) => {
            if (it.paymentId) {
                return this.paymentService.getPayment$(it.paymentId)
            } else {
                return of(null)
            }
        }),
    )

    paymentStatus$ = this.payment$.pipe(
        filter((it) => it != null),
        switchMap((it) => this.paymentService.checkPayment$(it.id)),
    )

    cancelable$ = this.registration$.pipe(
        map((it) => {
            return false // TODO finish BE for canceling registrations
            return it.state == RegistrationState.New
        }),
    )

    cancel() {
        this.registrationService.cancelRegistration(this.id)
    }

    findMerchandisePrice(merchandisePriceList: MerchandisePrice[], sku: string) {
        return merchandisePriceList.find((price) => price.sku == sku)
    }
}
