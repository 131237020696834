<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <mat-form-field>
                    <mat-label>{{'person_search' | translate}}</mat-label>
                    <input
                        type="text"
                        matInput
                        [formControl]="personControl"
                        [matAutocomplete]="auto"
                    >
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="stringify">
                        <mat-option *ngFor="let person of filteredPersons$ | async" [value]="person">
                            {{stringify(person)}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </mat-card-content>
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>people</mat-icon>&nbsp;{{followerPersons.length}} {{'followers' | translate |lowercase}}
                    </ng-template>
                    <mat-card-content class="pt-2 pe-0 pb-0 ps-0">
                        <div fxLayout="row wrap">
                            <app-person
                                *ngFor="let followerPerson of followerPersons"
                                [person]="followerPerson"
                                (remove)="deleteFollower(followerPerson)"
                                fxFlex.gt-sm="50"
                                fxFlex.gt-md="33.3"
                                fxFlex.gt-lg="33.3"
                                fxFlex.lt-sm="100"
                                fxFlex.lt-md="50"
                                fxFlex="33.3"
                                class="pb-3"
                            ></app-person>
                        </div>
                    </mat-card-content>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>person_pin</mat-icon>&nbsp;{{followingPersons.length}} {{'following' | translate | lowercase}}
                    </ng-template>
                    <mat-card-content class="pt-2 pe-0 pb-0 ps-0">
                        <div fxLayout="row wrap">
                            <app-person
                                *ngFor="let followingPerson of followingPersons"
                                [person]="followingPerson"
                                (remove)="unfollow(followingPerson)"
                                fxFlex.gt-sm="50"
                                fxFlex.gt-md="33.3"
                                fxFlex.gt-lg="33.3"
                                fxFlex.lt-sm="100"
                                fxFlex.lt-md="50"
                                fxFlex="33.3"
                                class="pb-3"
                            ></app-person>
                        </div>
                    </mat-card-content>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
</div>
