import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { OrganizerService } from 'app/organizers/services/organizer.service'
import { ActivatedRoute, Router } from '@angular/router'
import { Organizer } from 'app/organizers/organizers.models'
import { LoginService } from 'app/users/services/login.service'
import { FormControl } from '@angular/forms'
import { Observable, startWith, Subject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { OrganizedEventService } from 'app/events/services/organized-event.service'
import { OrganizedEvent } from 'app/events/events.models'
import { organizedEventConfig } from 'app/events/components/organized-event/organized-event.config'
import { OrganizedEventModelTab } from 'app/events/components/organized-event/organized-event.model'
import * as R_ from 'ramda-extension'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import latinize from 'latinize'
import { LanguageService } from '../../../core/services/language.service'
import { EnumService } from '../../../core/services/enum.service'
import { OrganizerEvents } from '../../../app.consts'
import { WindowService } from '../../../core/services/window.service'

@UntilDestroy()
@Component({
    selector: 'app-organizer-home',
    templateUrl: './organizer-home.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizerHomeComponent implements OnInit {
    organizerControl = new FormControl('')
    organizers$ = this.organizerService.myOrganizers$
    selectedLanguage$ = this.languageService.selectedLanguage$
    activityLabels$ = this.enumService.activityLabels$
    filteredOrganizers$: Observable<Organizer[]>
    events$ = new Subject<OrganizedEvent[]>()
    readonly columns = ['name', 'labels', /*'workflow.state',*/ 'workflow.updated' /*, 'externalId'*/]
    readonly maxLabelsToShowInTable = 3
    text = ''

    constructor(
        public organizerService: OrganizerService,
        private organizedEventService: OrganizedEventService,
        private languageService: LanguageService,
        private windowService: WindowService,
        private loginService: LoginService,
        private enumService: EnumService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            let organizerId = params['id']
            if (organizerId) {
                console.log('switching organizer id', organizerId)
                this.organizerService.currentOrganizerId = organizerId
            }
        })

        this.organizerService.currentOrganizer$.pipe(
            untilDestroyed(this),
            filter(R_.isNotNil)
        ).subscribe((organizer) => {
            this.organizedEventService
                .getOrganizedEvents$({
                    organizerId: organizer.id,
                })
                .pipe(untilDestroyed(this))
                .subscribe((events) => {
                    this.events$.next(events)
                })
        })

        this.filteredOrganizers$ = this.organizerControl.valueChanges.pipe(
            startWith(''),
            map((value) => {
                const name = typeof value === 'string' ? value : value?.name
                return name ? this.filter(name as string) : []
            }),
        )
        this.windowService.selectedLanguage$.subscribe((language) => {
            this.text = OrganizerEvents[language.code] ? OrganizerEvents[language.code] : OrganizerEvents['cs']
        })
    }

    private filter(name: string): Organizer[] {
        const filterValue = this.normalize(name)
        return this.organizers$.value.filter((option) => this.normalize(option.name).includes(filterValue))
    }

    normalize(str: string): string {
        return latinize(str).toLowerCase()
    }

    stringify(organizer: Organizer): string {
        return organizer && organizer.name ? organizer.name : ''
    }

    createOrganizer() {
        this.router.navigate(['/', 'organizers', 'create'])
    }

    switchOrganizer() {
        this.organizerService.switchOrganizer(this.organizerControl.value)
        this.organizerControl.reset()
    }

    createOrganizedEvent() {
        this.router.navigate([
            '/',
            'events',
            `${organizedEventConfig.paramForCreate}`,
            OrganizedEventModelTab[OrganizedEventModelTab.settings],
        ])
    }

    navigateToDetail({ id }: OrganizedEvent) {
        this.router.navigate(['/', 'events', id, OrganizedEventModelTab[OrganizedEventModelTab.settings]])
    }
}
