<ng-container
    *ngIf="{
        language: (languageService.selectedLanguage$ | async).code,
        activities: organizedActivitiesStateService.activities$ | async
    } as data"
>
    <mat-card>
        <mat-card-content>
            <div class="responsive-table" *ngIf="data.activities && data.activities.length > 0">
                <mat-table [dataSource]="data.activities">
                    <ng-container matColumnDef="name">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> {{"name" | translate}} </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element" [attr.data-label]="'name'|translate">
                            {{element.name | localize: data.language}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="place">
                        <mat-cell *matHeaderCellDef> {{"place" | translate}} </mat-cell>
                        <mat-cell mat-cell *matCellDef="let element" [attr.data-label]="'place'|translate">
                            {{element.place | localize: data.language}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="distance">
                        <mat-cell *matHeaderCellDef class="cell-150"> {{"distance" | translate}} </mat-cell>
                        <mat-cell
                            mat-cell
                            *matCellDef="let element"
                            [attr.data-label]="'distance'|translate"
                            class="cell-150"
                        >
                            {{element?.activity?.distance}} {{'Unit.KM' | translate}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="startTime">
                        <mat-cell *matHeaderCellDef> {{"startTime" | translate}} </mat-cell>
                        <mat-cell mat-cell *matCellDef="let element" [attr.data-label]="'startTime'|translate">
                            {{element.startTime | localizeDate: "short"}}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: columns"
                        class="cursor-pointer"
                        (click)="navigateToDetail(row)"
                    ></mat-row>
                    <p *matNoDataRow class="no-data">{{'noData'|translate}}</p>
                </mat-table>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>
