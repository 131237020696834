<ng-container [formGroup]="itemForm">
    <mat-table [trackBy]="trackById" [dataSource]="dataSource">
        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef> {{"amount" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element;" [formGroup]="element">
                <mat-form-field>
                    <input matInput [formControl]="$any(element.get('amount').get('value'))" required />
                    <!--                    <span matSuffix>{{"CZK" | translate}}</span>-->
                    <mat-error
                        *ngIf="!element.get('amount').get('value').valid && element.get('amount').get('value').touched"
                    >
                        <ng-container *ngIf="element.get('amount').get('value').errors?.numeric">
                            {{ 'numeric' | translate }}</ng-container
                        >
                        <ng-container *ngIf="element.get('amount').get('value').errors?.required"
                            >{{ 'isRequired' | translate }}</ng-container
                        >
                    </mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="currency">
            <mat-header-cell *matHeaderCellDef> {{"currency" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element;" [formGroup]="element">
                <mat-form-field>
                    <mat-select [formControl]="$any(element.get('amount').get('currency'))" required>
                        <mat-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="!element.get('amount').get('currency').valid && element.get('amount').get('currency').touched"
                    >
                        <ng-container *ngIf="element.get('amount').get('currency').errors?.numeric">
                            {{ 'numeric' | translate }}</ng-container
                        >
                        <ng-container *ngIf="element.get('amount').get('currency').errors?.required"
                            >{{ 'isRequired' | translate }}</ng-container
                        >
                    </mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="validTo">
            <mat-header-cell *matHeaderCellDef> {{"validTo" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element;" [formGroup]="element">
                <mat-form-field>
                    <input matInput [matDatepicker]="validTo" formControlName="validTo" />
                    <mat-datepicker-toggle matSuffix [for]="validTo"></mat-datepicker-toggle>
                    <mat-datepicker #validTo></mat-datepicker>
                    <mat-error *ngIf="!element.get('validTo').valid && element.get('validTo').touched">
                        <ng-container *ngIf="element.get('validTo').errors?.required"
                            >{{ 'isRequired' | translate }}</ng-container
                        >
                        <ng-container *ngIf="element.get('validTo').errors?.matDatepickerMin">
                            {{ 'minDate' | translate }}</ng-container
                        >
                    </mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="validUntilRegistrationCount">
            <mat-header-cell *matHeaderCellDef> {{"validFor" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
                <mat-form-field>
                    <input matInput formControlName="validUntilRegistrationCount" required />
                    <mat-error
                        *ngIf="!element.get('validUntilRegistrationCount').valid && element.get('validUntilRegistrationCount').touched"
                    >
                        {{ 'positiveNumber' | translate }}
                    </mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell class="cursor-pointer" (click)="remove(element.value.id)" *matCellDef="let element">
                <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;"></mat-row>
    </mat-table>
</ng-container>
<div class="form-element text-right">
    <button
        [disabled]="itemForm.disabled"
        (click)="pushToArray()"
        style="margin-top: 20px"
        mat-raised-button
        color="primary"
    >
        <mat-icon>add</mat-icon> {{ "add" | translate}}
    </button>
</div>
