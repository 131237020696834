import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PaymentResultComponent } from './components/payment-result/payment-result.component'

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'result',
                component: PaymentResultComponent,
                data: {
                    title: 'title-payment',
                    urls: [{ title: 'home', url: '/' }, { title: 'title-payment' }],
                },
            },
            {
                path: '**',
                redirectTo: '',
            },
        ],
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PaymentsRoutingModule {}
