import * as R from 'ramda'
import { AbstractControl, FormArray } from '@angular/forms'
import { Globals } from 'app/app.consts'
import { LanguageCode } from '../constants/languages'

export const localizeArrayFormHint = (formArray: FormArray | AbstractControl, language: LanguageCode): string =>
    R.pipe(
        R.prop('controls'),
        R.map(R.prop('value')),
        R.reject(R.propEq('language', language)),
        R.reject(R.propEq('text', null)),
        R.cond([
            [
                R.find(R.propEq('language', Globals.defaultFallbackLanguage)),
                R.find(R.propEq('language', Globals.defaultFallbackLanguage)),
            ],
            [R.T, R.pipe(R.find((localizedText) => !!localizedText.text))],
        ]),
        R.prop('text'),
    )(formArray)
