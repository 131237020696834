import { gql } from 'apollo-angular'
import { PERSON_FRAGMENT } from '../core/core.fragments'
import { ORGANIZER_FRAGMENT } from './organizers.fragments'

export const MY_ORGANIZERS = gql`
    ${ORGANIZER_FRAGMENT}
    query myOrganizers($query: GetMyOrganizers!) {
        myOrganizers(query: $query) {
            ...OrganizerFields
        }
    }
`

export const ORGANIZERS = gql`
    ${ORGANIZER_FRAGMENT}
    query getOrganizers($query: GetOrganizers!) {
        getOrganizers(query: $query) {
            ...OrganizerFields
        }
    }
`

export const USER_ORGANIZERS = gql`
    ${ORGANIZER_FRAGMENT}
    query userOrganizers($query: GetUserOrganizers!) {
        userOrganizers(query: $query) {
            ...OrganizerFields
        }
    }
`
export const ORGANIZER = gql`
    ${ORGANIZER_FRAGMENT}
    query organizer($query: GetOrganizer!) {
        organizer(query: $query) {
            ...OrganizerFields
        }
    }
`
export const CHECK_ORGANIZER = gql`
    query checkOrganizer($query: CheckOrganizer!) {
        checkOrganizer(query: $query)
    }
`
export const ORGANIZER_MEMBERS = gql`
    ${PERSON_FRAGMENT}
    query organizerMembers($query: GetOrganizerMembers!) {
        organizerMembers(query: $query) {
            person {
                ...PersonFields
            }
            role
        }
    }
`
export const USER_ORGANIZER_ROLE = gql`
    query userOrganizerRole($query: GetUserOrganizerRole!) {
        userOrganizerRole(query: $query)
    }
`

export const IS_ROOT_ORGANIZER_MEMBER = gql`
    query isRootOrganizerMember {
        isRootOrganizerMember
    }
`
