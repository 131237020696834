import { gql } from 'apollo-angular'
import {
    ACTIVITY_RESULT_FRAGMENT,
    ORGANIZED_ACTIVITY_FRAGMENT,
    ORGANIZED_EVENT_FRAGMENT,
    REGISTRATION_FRAGMENT,
} from './events.fragments'
import {
    ACTIVITY_FRAGMENT,
    ACTIVITY_LABEL_FRAGMENT,
    LOCALIZED_TEXT_FRAGMENT,
    MEDIA_FRAGMENT,
    PERSON_FRAGMENT,
    PRICE_FRAGMENT,
    WORKFLOW_FRAGMENT,
} from '../core/core.fragments'
import { WeatherForecastCondition } from './events.models'

export const ACTIVITY_LABELS = gql`
    ${ACTIVITY_LABEL_FRAGMENT}
    query activityLabelsV2 {
        activityLabelsV2 {
            ...ActivityLabelFields
        }
    }
`

export const ACTIVITY_RESULTS = gql`
    ${ACTIVITY_RESULT_FRAGMENT}
    query activityResults($query: GetActivityResults!) {
        activityResults(query: $query) {
            ...ActivityResultFields
        }
    }
`
export const ORGANIZED_ACTIVITY_FOLLOWER_PERSONS = gql`
    ${PERSON_FRAGMENT}
    query organizedActivityFollowerPersons($query: GetOrganizedActivityFollowerPersons!) {
        organizedActivityFollowerPersons(query: $query) {
            ...PersonFields
        }
    }
`
export const ORGANIZED_ACTIVITIES = gql`
    query organizedActivities($query: GetOrganizedActivities!) {
        organizedActivities(query: $query) {
            ...OrganizedActivityFields
        }
    }
    ${ORGANIZED_ACTIVITY_FRAGMENT}
`
export const MY_ORGANIZED_ACTIVITIES = gql`
    query myOrganizedActivities($query: GetMyOrganizedActivities!) {
        myOrganizedActivities(query: $query) {
            ...OrganizedActivityFields
        }
    }
    ${ORGANIZED_ACTIVITY_FRAGMENT}
`
export const ORGANIZER_ORGANIZED_ACTIVITIES = gql`
    query organizerOrganizedActivities($query: GetOrganizerOrganizedActivities!) {
        organizerOrganizedActivities(query: $query) {
            ...OrganizedActivityFields
        }
    }
    ${ORGANIZED_ACTIVITY_FRAGMENT}
`
export const SELECTED_ORGANIZED_ACTIVITIES = gql`
    query selectedOrganizedActivities($query: GetSelectedOrganizedActivities!) {
        selectedOrganizedActivities(query: $query) {
            ...OrganizedActivityFields
        }
    }
    ${ORGANIZED_ACTIVITY_FRAGMENT}
`
export const ORGANIZED_ACTIVITY = gql`
    query organizedActivity($query: GetOrganizedActivity!) {
        organizedActivity(query: $query) {
            ...OrganizedActivityFields
        }
    }
    ${ORGANIZED_ACTIVITY_FRAGMENT}
`

export const ORGANIZED_ACTIVITY_WEATHER_FORECAST = gql`
    query organizedActivityWeatherForecast($query: GetOrganizedActivityWeatherForecast!) {
        organizedActivityWeatherForecast(query: $query) {
            tempC
            tempF
            maxWindMPH
            maxWindKPH
            chanceOfRain
            chanceOfSnow
            condition {
                text
                icon
                code
            }
            uv
        }
    }
`

export const ORGANIZED_ACTIVITY_REGISTERED_PERSONS = gql`
    ${PERSON_FRAGMENT}
    query organizedActivityRegisteredPersons($query: GetOrganizedActivityRegisteredPersons!) {
        organizedActivityRegisteredPersons(query: $query) {
            ...PersonFields
        }
    }
`
export const ORGANIZED_ACTIVITY_FOLLOWS_FOR_USER = gql`
    query organizedActivityFollowsForUser($query: GetOrganizedActivityFollowsForUser!) {
        organizedActivityFollowsForUser(query: $query) {
            id
            userId
            organizedActivityId
        }
    }
`
export const EVENT_TOTALS = gql`
    query eventsTotals {
        eventsTotals {
            organizedEventCount
            organizedActivityCount
            registrationCount
            activityResultCount
        }
    }
`
export const ORGANIZED_EVENT = gql`
    query organizedEvent($query: GetOrganizedEvent!) {
        organizedEvent(query: $query) {
            id
            externalId
            organizerId
            name {
                ...LocalizedTextFields
            }
            description {
                ...LocalizedTextFields
            }
            labels
            media {
                ...MediaFields
            }
            workflow {
                ...WorkflowFields
            }
            activities {
                ...OrganizedActivityFields
            }
        }
    }
    ${PRICE_FRAGMENT}
    ${MEDIA_FRAGMENT}
    ${WORKFLOW_FRAGMENT}
    ${ACTIVITY_FRAGMENT}
    ${ORGANIZED_ACTIVITY_FRAGMENT}
    ${LOCALIZED_TEXT_FRAGMENT}
`
export const ORGANIZED_EVENTS = gql`
    query organizedEvents($query: GetOrganizedEvents!) {
        organizedEvents(query: $query) {
            ...OrganizedEventFields
        }
    }
    ${ORGANIZED_EVENT_FRAGMENT}
`
export const USER_REGISTRATIONS = gql`
    ${REGISTRATION_FRAGMENT}
    query userRegistrations($query: GetUserRegistrations!) {
        userRegistrations(query: $query) {
            ...RegistrationFields
        }
    }
`
export const REGISTERED_PERSONS = gql`
    ${PERSON_FRAGMENT}
    query registeredPersons($query: GetRegisteredPersons!) {
        registeredPersons(query: $query) {
            id
            created
            code
            state
            startNumber
            teamName
            amount {
                value
                currency
            }
            person {
                ...PersonFields
            }
        }
    }
`
