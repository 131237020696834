import { OrganizerMenuItems } from 'app/layouts/constants/organizer/model'
import { MenuItemType } from 'app/layouts/layouts.models'

export const existingOrganizerMenuItems: OrganizerMenuItems = [
    {
        state: 'organizers',
        name: 'dashboard',
        type: MenuItemType.link,
        icon: 'av_timer',
        action: (router) => {
            router.navigate(['/organizers'])
        },
    },
    {
        state: 'events',
        name: 'events',
        type: MenuItemType.link,
        icon: 'place',
        action: (router) => {
            router.navigate(['/events'])
        },
    },
    {
        state: 'organizers_registrations',
        name: 'registrations',
        type: MenuItemType.link,
        icon: 'done_all',
        action: (router) => {
            router.navigate(['/organizers/registrations'])
        },
    },
    {
        state: 'organizers_results',
        name: 'results',
        type: MenuItemType.link,
        icon: 'timer',
        action: (router) => {
            router.navigate(['/organizers/results'])
        },
    },
    {
        state: 'settings',
        name: 'organizer_settings',
        type: MenuItemType.link,
        icon: 'settings',
        action: (router) => {
            router.navigate(['/organizers/settings'])
        },
    },
    {
        state: 'invite_member',
        name: 'organizer_members',
        type: MenuItemType.link,
        icon: 'person_add',
        action: (router) => {
            router.navigate(['/organizers/members'])
        },
    },
    {
        type: MenuItemType.separator,
    },
    {
        state: 'create_organizer',
        name: 'create_organizer',
        type: MenuItemType.link,
        icon: 'add_circle',
        action: (router) => {
            router.navigate(['/organizers/create'])
        },
    },
    {
        state: 'switch_organizer',
        name: 'switch_organizer',
        type: MenuItemType.sub,
        icon: 'cached',
    },
    {
        state: 'terms',
        name: 'terms',
        type: MenuItemType.link,
        icon: 'short_text',
        action: (router) => {
            router.navigate(['/organizers/terms'])
        },
    },
]

export const emptyOrganizerMenuItems: OrganizerMenuItems = [
    {
        state: 'create_organizer',
        name: 'create_organizer',
        type: MenuItemType.link,
        icon: 'create_organizer',
        action: (router) => {
            router.navigate(['/organizers/create'])
        },
    },
]
