<ng-container [formGroup]="formGroup">
    <mat-table [trackBy]="trackById" [dataSource]="dataSource">
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef> {{"type" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element;" [formGroup]="element">
                <mat-form-field>
                    <mat-select
                        formControlName="type"
                    >
                        <mat-option value="Web">WWW</mat-option>
                        <mat-option value="Facebook">Facebook</mat-option>
                        <mat-option value="Instagram">Instagram</mat-option>
                        <mat-option value="Youtube">Youtube</mat-option>
                        <mat-option value="Video">{{ 'video' | translate }}</mat-option>
                        <mat-option value="Gallery">{{ 'gallery' | translate }}</mat-option>
                        <mat-option value="Registration">{{ 'registration' | translate }}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="!element.get('type').valid && element.get('type').touched"
                    >
                        <ng-container *ngIf="element.get('type').errors?.required"
                        >{{ 'isRequired' | translate }}</ng-container
                        >
                    </mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ref">
            <mat-header-cell *matHeaderCellDef> {{"ref" | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element;" [formGroup]="element">
                <mat-form-field>
                    <input
                        matInput
                        formControlName="ref"
                    />
                    <mat-error
                        *ngIf="!element.get('ref').valid && element.get('ref').touched"
                    >
                        <ng-container *ngIf="element.get('ref').errors?.required"
                        >{{ 'isRequired' | translate }}</ng-container
                        >
                    </mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell class="cursor-pointer" (click)="remove(element.value)" *matCellDef="let element">
                <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;"></mat-row>
    </mat-table>
</ng-container>
<div class="form-element text-right">
    <button
        [disabled]="formGroup.disabled"
        (click)="pushToArray()"
        style="margin-top: 20px"
        mat-raised-button
        color="primary"
    >
        <mat-icon>add</mat-icon> {{ "add" | translate}}
    </button>
</div>
