<div fxLayout="row wrap" class="activities-overview">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <div fxLayout="row wrap" fxFlexAlign="center" class="row  activities-overview-list">
            <div
                *ngFor="let activity of $any(organizedActivitiesStateService.activities$ | async)"
                fxFlex="100"
                fxFlex.gt-xs="50"
                fxFlex.gt-md="33"
                class="activities-overview-list__item"
            >
                <mat-card
                    [style]="activity.startTime < now.toISOString() ? 'filter: saturate(0)' : ''"
                    *ngIf="(organizedActivitiesStateService.organizedActivitiesFormState$ | async)?.labels as activityLabels"
                >
                    <app-organized-activity-card
                        [selectedActivityLabels]="$any(activityLabels)"
                        [organizedActivity]="activity"
                    >
                    </app-organized-activity-card>
                </mat-card>
            </div>
        </div>
    </div>
</div>
