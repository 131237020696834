import { Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject, takeUntil } from 'rxjs'
import * as R_ from 'ramda-extension'
import { filter } from 'rxjs/operators'
import { Message, MessageType } from 'app/core/core.models'
import { MessageService } from 'app/core/services/message.service'
import { LikeService } from 'app/core/services/like.service'
import { User } from 'app/users/users.models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { LoginService } from 'app/users/services/login.service'
import { server } from 'app/core/core.server'

@UntilDestroy()
@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
    @Input()
    message: Message
    time = server.time

    @Input()
    childType: MessageType = MessageType.Comment

    @Input()
    user: User

    @Input()
    isShowingPerson: boolean = true

    @Input()
    isShowingChildren: boolean = false

    apiUrl = server.apiUrl
    isShowingInput: boolean = false
    isTogglingLike: boolean = false
    currentUser: User = null
    children$ = new BehaviorSubject<Message[]>([])
    text = ''

    constructor(
        private readonly messageService: MessageService,
        public readonly loginService: LoginService,
        private readonly likeService: LikeService
    ) {}

    ngOnInit(): void {
        if (this.message.children?.length > 0) {
            this.children$.next(this.message.children)
            this.isShowingChildren = true
        }
        this.loginService.currentUser$.subscribe((user) => {
            this.currentUser = user
        })
    }

    createChild() {
        console.log('createChild', this.text)
        this.messageService
            .createMessage$(
                this.message.entityType,
                this.message.entityId,
                this.text,
                this.childType,
                this.message.id,
                null,
            )
            .subscribe((child) => {
                this.children$.next([child].concat(this.children$.value))
                this.text = ''
                this.message.childCount++
                this.isShowingInput = false
                this.toggleChildren(true)
            })
    }

    toggleChildren(forceShow: boolean = false) {
        this.isShowingChildren = forceShow ? true : !this.isShowingChildren
        if (this.isShowingChildren) {
            this.messageService
                .getMessages$(this.message.entityType, this.message.entityId, [MessageType.Comment], this.message.id)
                .pipe(untilDestroyed(this))
                .subscribe((children) => {
                    console.log('showingChildren', children)
                    this.children$.next(children)
                })
        }
    }

    toggleLike() {
        if (!this.isTogglingLike) {
            this.isTogglingLike = true
            if (!this.message.isLiked) {
                this.likeService.createLike$('Message', this.message.id).subscribe((result) => {
                    console.log('liked', result)
                    if (result && !this.message.isLiked) this.message.likeCount++
                    this.message.isLiked = true
                    this.isTogglingLike = false
                })
            } else {
                this.likeService.deleteLike$('Message', this.message.id).subscribe((result) => {
                    console.log('unliked', result)
                    if (result && this.message.isLiked) this.message.likeCount--
                    this.message.isLiked = false
                    this.isTogglingLike = false
                })
            }
        }
    }

    showCommentInput() {
        this.isShowingInput = true
    }
}
