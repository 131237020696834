import { Injectable } from '@angular/core'
import * as R from 'ramda'
import { BehaviorSubject, debounceTime, delay } from 'rxjs'
import { filter } from 'rxjs/operators'
import { Globals } from 'app/app.consts'
import { NavigationEnd, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ApolloStateService {
    private readonly loadingSubject$ = new BehaviorSubject(false)
    private readonly loadingOperationsSubject$ = new BehaviorSubject<string[]>([])
    readonly loading$ = this.loadingSubject$.asObservable()
    readonly loadingOperations$ = this.loadingOperationsSubject$.asObservable()

    private readonly errorSubject$ = new BehaviorSubject(false)
    private readonly errorOperationsSubject$ = new BehaviorSubject<string[]>([])

    constructor(private readonly router: Router) {
        this.loadingOperationsSubject$
            .pipe(debounceTime(Globals.delayOfLoadingAnimation), untilDestroyed(this))
            .subscribe((loadingOperations) => {
                const loadingOperationsWithoutIgnoring = R.reject((operation) =>
                    R.find(R.equals(operation), Globals.ignoreLoadingOperationNames),
                )(loadingOperations)
                this.loadingSubject$.next(loadingOperationsWithoutIgnoring.length > 0)
            })
        this.errorOperationsSubject$.pipe(untilDestroyed(this)).subscribe((errorOperations) => {
            this.errorSubject$.next(errorOperations.length > 0)
        })

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            if (this.loadingOperationsSubject$.value.length > 0) {
                this.loadingOperationsSubject$.next([])
            }
            if (this.errorOperationsSubject$.value.length > 0) {
                this.errorOperationsSubject$.next([])
            }
        })
    }

    addErrorOperation(operationId: string): void {
        this.errorOperationsSubject$.next([...this.errorOperationsSubject$.value, operationId])
    }

    getErrorOperation(operationId: string): string {
        return R.find(R.equals(operationId))(this.errorOperationsSubject$.value)
    }

    removeErrorOperation(operationId: string): void {
        const errorOperations = [...this.errorOperationsSubject$.value]
        this.errorOperationsSubject$.next([...R.remove(operationId, 1, errorOperations)])
    }

    addLoadingOperation(operationId: string): void {
        this.loadingOperationsSubject$.next([...this.loadingOperationsSubject$.value, operationId])
    }

    removeLoadingOperation(operationId: string): void {
        const loadingOperations = [...this.loadingOperationsSubject$.value]
        this.loadingOperationsSubject$.next([...R.remove(operationId, 1, loadingOperations)])
    }
}
