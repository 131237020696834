import { Injectable } from '@angular/core'
import { ApolloService } from './apollo.service'
import { Observable } from 'rxjs'
import { CREATE_LIKE, DELETE_LIKE, TOGGLE_LIKE } from '../core.mutations'

@Injectable({
    providedIn: 'root',
})
export class LikeService {
    constructor(private readonly apolloService: ApolloService) {}

    createLike$(entityType: string, entityId: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: CREATE_LIKE,
            variables: {
                command: {
                    entityType: entityType,
                    entityId: entityId,
                },
            },
        })
    }

    deleteLike$(entityType: string, entityId: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: DELETE_LIKE,
            variables: {
                command: {
                    entityType: entityType,
                    entityId: entityId,
                },
            },
        })
    }

    toggleLike$(entityType: string, entityId: string): Observable<number> {
        return this.apolloService.mutate<number>({
            mutation: TOGGLE_LIKE,
            variables: {
                command: {
                    entityType: entityType,
                    entityId: entityId,
                },
            },
        })
    }
}
