import { Pipe, PipeTransform } from '@angular/core'
import { ActivityLabel } from '../../core.models'
import { LanguageCode } from '../../constants/languages'
import { LanguageService } from '../../services/language.service'
import { getLocalizedText } from '../../utils'

@Pipe({
    name: 'localizeActivityLabel',
})
export class LocalizeActivityLabelPipe implements PipeTransform {

    constructor(
        private languageService: LanguageService,
    ) {
    }

    transform(
        name: string,
        activityLabels: ActivityLabel[],
        languageCode: LanguageCode,
    ): string {
        if (activityLabels) {
            let activityLabel = activityLabels.find((activityLabel) => activityLabel.name == name)
            return activityLabel ? getLocalizedText(activityLabel.text, languageCode, true) : name
        } else {
            return ''
        }
    }
}
