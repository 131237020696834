<div fxLayout="row wrap">
    <!-- Card column -->
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap">
                    <div fxFlex.gt-sm="100%" fxFlex="100">
                        <form [formGroup]="form" class="basic-form">
                            <div fxLayout="row wrap">
                                <div fxFlex="50">
                                    <mat-form-field>
                                        <input
                                            type="text"
                                            matInput
                                            formControlName="organizedActivityId"
                                            [matAutocomplete]="auto"
                                        >
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="stringify">
                                            <mat-option *ngFor="let organizedActivity of filteredOrganizedActivities$ | async" [value]="organizedActivity">
                                                {{stringify(organizedActivity)}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="30">
                                    <mat-form-field>
                                        <mat-select
                                            formControlName="registrationState"
                                            [placeholder]="'registration_state' | translate"
                                        >
                                            <mat-option [value]="null">-</mat-option>
                                            <mat-option value="New">{{'RegistrationState.New' | translate}}</mat-option>
                                            <mat-option value="Confirmed">{{'RegistrationState.Confirmed' | translate}}</mat-option>
                                            <mat-option value="Cancelled">{{'RegistrationState.Cancelled' | translate}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="20">
                                    <mat-form-field>
                                        <mat-select
                                            formControlName="dataFileFormat"
                                            [placeholder]="'format' | translate"
                                        >
                                            <mat-option value="default">{{'app' | translate}}</mat-option>
                                            <mat-option value="orgsu">ORGSU</mat-option>
                                            <mat-option value="irontime">IronTime</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </div>
                    <ng-container>
                        <div fxFlex="100">
                            <app-file-uploader
                                [disabled]="(uploadingFile$ | async) || !exportUrl"
                                [uploader]="uploader"
                            ></app-file-uploader>
                        </div>
                        <div fxFlex="50">
                            <button
                                mat-button
                                [disabled]="!canUpload"
                                (click)="upload()"
                            >
                                <mat-icon>file_upload</mat-icon>
                                CSV
                            </button>
                        </div>
                        <div fxFlex="50" class="text-right" align="right">
                            <button
                                mat-button
                                [disabled]="!exportUrl"
                                (click)="windowService.share(this.exportUrl, '')"
                            >
                                <mat-icon>file_download</mat-icon>
                                CSV
                            </button>
                        </div>
                    </ng-container>
                </div>
            </mat-card-content>
            <div
                class="scrollable-table"
                *ngIf="items$ | async; let items"
            >
                <mat-table #table matSort (matSortChange)="sort($event)" [dataSource]="items">
                    <ng-container matColumnDef="num">
                        <mat-header-cell *matHeaderCellDef>{{'num' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="person">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{'person' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'person' | translate">
                            <app-person [person]="element.person"></app-person
                        ></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="state">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{"State" | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'state' | translate">
                            {{"RegistrationState." + element.state | translate}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="startNumber">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{"startNumber" |translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'startNumber' | translate">
                            {{element.startNumber ? element.startNumber : '-'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <mat-header-cell *matHeaderCellDef mat-sort-heade disableClearr>{{"registered" |translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'registered' | translate">
                            {{element.created | localizeDate: 'short'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{"amount" |translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'amount' | translate">
                            {{element.amount | amount}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{"transaction" | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="'transaction'| translate">
                            <img
                                [src]="this.apiUrl + '/' + element.code + '.qr.png?invert=' + (windowService.darkMode$ |async) "
                                width="75" height="75" align="middle"
                            />
                            <code>{{element.code}}</code>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: columns"></mat-row>
                    <p *matNoDataRow class="no-data">{{'noData' | translate}}</p>
                </mat-table>
            </div>
        </mat-card>
    </div>
</div>
