import { Pipe, PipeTransform } from '@angular/core'
import { IOptions, LocalizedEnum } from '../../core.models'
import { LanguageCode } from '../../constants/languages'
import { getLocalizedText } from '../../utils'

@Pipe({
    name: 'localizedEnumOptions',
})
export class LocalizedEnumOptionsPipe implements PipeTransform {

    transform(
        values: LocalizedEnum[],
        languageCode: LanguageCode,
    ): IOptions<any> {
        return values ? values.map((value) => ({
            value: value.name,
            label: getLocalizedText(value.text, languageCode, true),
        })) : []
    }
}
