import { Component, Input, OnInit } from '@angular/core'
import { TrackProfile } from 'app/events/events.models'
import { TranslateService } from "@ngx-translate/core";

interface LineData {
    data: number[]
    label: string
    yAxisID: string
}

@Component({
    selector: 'app-track-profile',
    templateUrl: './track-profile.component.html',
})
export class TrackProfileComponent implements OnInit {

    @Input()
    left: string = 'altitude'

    @Input()
    right?: string = null

    @Input()
    height: number = 200

    @Input()
    legend: boolean = true

    @Input()
    segments: number = 50

    @Input()
    profile: TrackProfile

    lineChartData: Array<LineData> = [{ data: [], label: '?', yAxisID: this.left }]
    lineChartLabels: Array<any> = []
    lineChartOptions: any = {
        responsive: true,
        scales: {
            yAxes: [
                {
                    id: this.left,
                    position: 'left',
                },
            ],
        },
    }
    lineChartColors: Array<any> = [
        {
            backgroundColor: 'rgba(25,118,210,0.1)',
            borderColor: 'rgba(25,118,210,1)',
            pointBackgroundColor: 'rgba(25,118,210,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(25,118,210,0.5)',
        },
        {
            backgroundColor: 'rgba(38,218,210,0.1)',
            borderColor: 'rgba(38,218,210,1)',
            pointBackgroundColor: 'rgba(38,218,210,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(38,218,210,0.5)',
        },
    ]

    constructor(
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.lineChartData[0].label = this.translateService.instant(this.left)
        if (this.right) {
            this.lineChartData.push({ data: [], label: this.translateService.instant(this.right), yAxisID: this.right })
            this.lineChartOptions.scales.yAxes.push({
                id: this.right,
                position: 'right',
            })
        }

        let unit = this.profile?.totalDistance / this.segments
        for (let i = 0; i < this.profile?.points?.length; i++) {
            let point = this.profile.points[i]
            if (this.lineChartLabels.length * unit <= point.distance || i == this.profile.points.length - 1) {
                this.lineChartLabels.push(point.distance.toFixed(1))
                this.lineChartData[0].data.push(Math.round(point[this.left]))
                if (this.right == 'speed')
                    this.lineChartData[1].data.push(point.speed ? Math.round(point.speed * 10) / 10 : 0.0)
                else if (this.right)
                    this.lineChartData[1].data.push(Math.round(point[this.right]))
            }
        }
        console.log('lineChartLabels =', this.lineChartLabels)
        this.lineChartData.forEach((it) => {
            console.log(`lineChartData '${it.yAxisID}' =`, it.data)
        })
    }

    chartClicked(e: any): void {
        //console.log(e)
    }

    chartHovered(e: any): void {
        //console.log(e)
    }
}
