import * as R from 'ramda'
import { BehaviorSubject } from 'rxjs'
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { getLocalizedText } from 'app/core/utils'
import { Language, LanguageCode } from '../constants/languages'

const getFormControlWithText = (control: AbstractControl, language: LanguageCode): FormControl =>
    R.pipe(
        R.prop('controls'),
        R.find(R.pathEq(['controls', 'language', 'value'], language)),
        R.path(['controls', 'text']),
    )(control)

export const localizedTextValidator = (currentLanguage$: BehaviorSubject<Language>, required = true): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = getLocalizedText(control.value, currentLanguage$.value.code, false)
        const formControlWithText = getFormControlWithText(control, currentLanguage$.value.code)

        if (!value && required) {
            formControlWithText.setErrors({ required: true })
        } else {
            formControlWithText.setErrors(null)
        }

        return null
    }
}
