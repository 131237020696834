import { Injectable } from '@angular/core'
import { ApolloService } from 'app/core/services/apollo.service'
import {
    CreateOrganizedActivityInput,
    GetOrganizedActivities,
    GetOrganizedActivity,
    OrganizedActivity,
    OrganizedActivityFollow,
    SelectionType, SyncType,
    UpdateOrganizedActivityInput, WeatherForecast,
} from 'app/events/events.models'
import { ConnectionType, User } from 'app/users/users.models'
import { Observable } from 'rxjs'
import { Person } from 'app/core/core.models'
import { HttpClient } from '@angular/common/http'
import { server } from 'app/core/core.server'
import {
    ORGANIZED_ACTIVITY_FOLLOWS_FOR_USER,
    MY_ORGANIZED_ACTIVITIES,
    ORGANIZED_ACTIVITIES,
    ORGANIZED_ACTIVITY_FOLLOWER_PERSONS,
    ORGANIZED_ACTIVITY,
    ORGANIZED_ACTIVITY_REGISTERED_PERSONS,
    ORGANIZER_ORGANIZED_ACTIVITIES,
    SELECTED_ORGANIZED_ACTIVITIES,
    ORGANIZED_ACTIVITY_WEATHER_FORECAST,
} from '../events.queries'
import {
    COPY_ORGANIZED_ACTIVITY,
    CREATE_ORGANIZED_ACTIVITY,
    DELETE_ORGANIZED_ACTIVITY,
    EXPORT_ORGANIZED_ACTIVITY_TO_CONNECTION,
    FOLLOW_ORGANIZED_ACTIVITY,
    OPEN_ORGANIZED_ACTIVITY,
    UNFOLLOW_ORGANIZED_ACTIVITY,
    UPDATE_ORGANIZED_ACTIVITY,
    UPDATE_ORGANIZED_ACTIVITY_SYNC_CONFIG,
} from '../events.mutations'

@Injectable({
    providedIn: 'root',
})
export class OrganizedActivityService {
    constructor(private readonly apolloService: ApolloService, private readonly httpClient: HttpClient) {}

    getOrganizedActivities$(query: GetOrganizedActivities, callback: (length: number, isFinished: boolean) => void) {
        return this.apolloService.seekQuery<OrganizedActivity, { query: GetOrganizedActivities }>(
            {
                fetchPolicy: 'no-cache',
                query: ORGANIZED_ACTIVITIES,
                variables: {
                    query,
                },
            },
            callback,
        )
    }

    getMyOrganizedActivities$(futureOnly: boolean) {
        return this.apolloService.watchQuery<OrganizedActivity[]>({
            // fetchPolicy: 'no-cache',
            query: MY_ORGANIZED_ACTIVITIES,
            variables: {
                query: {
                    futureOnly: futureOnly,
                },
            },
        })
    }

    getOrganizerOrganizedActivities$(organizerId: string, onlyWithRegistrations: boolean = true) {
        return this.apolloService.watchQuery<OrganizedActivity[]>({
            fetchPolicy: 'no-cache',
            query: ORGANIZER_ORGANIZED_ACTIVITIES,
            variables: {
                query: {
                    organizerId: organizerId,
                    onlyWithRegistrations: onlyWithRegistrations,
                },
            },
        })
    }

    getSelectedOrganizedActivities$(selectionType: SelectionType, limit: number = 3) {
        return this.apolloService.watchQuery<OrganizedActivity[]>({
            fetchPolicy: 'no-cache',
            query: SELECTED_ORGANIZED_ACTIVITIES,
            variables: {
                query: {
                    selectionType: selectionType,
                    seekId: null,
                    limit: limit,
                },
            },
        })
    }

    getOrganizedActivity$(query: GetOrganizedActivity) {
        return this.apolloService.watchQuery<OrganizedActivity>({
            fetchPolicy: 'no-cache',
            query: ORGANIZED_ACTIVITY,
            variables: {
                query,
            },
        })
    }

    getOrganizedActivityWeatherForecast$(organizedActivityId: string, language: string) {
        return this.apolloService.watchQuery<WeatherForecast>({
            fetchPolicy: 'no-cache',
            query: ORGANIZED_ACTIVITY_WEATHER_FORECAST,
            variables: {
                query: {
                    organizedActivityId,
                    language,
                }
            },
        })
    }

    getOrganizedActivityFollowerPersons$(organizedActivityId: string, userId?: string) {
        return this.apolloService.watchQuery<Person[]>({
            fetchPolicy: 'no-cache',
            query: ORGANIZED_ACTIVITY_FOLLOWER_PERSONS,
            variables: {
                query: {
                    organizedActivityId,
                    userId,
                },
            },
        })
    }

    getOrganizedActivityRegisteredPersons$(userId: string, organizedActivityId: string) {
        return this.apolloService.watchQuery<Person[]>({
            fetchPolicy: 'no-cache',
            query: ORGANIZED_ACTIVITY_REGISTERED_PERSONS,
            variables: {
                query: {
                    userId,
                    organizedActivityId,
                },
            },
        })
    }

    getOrganizedActivityFollowsForUser$(userId: string) {
        return this.apolloService.watchQuery<OrganizedActivityFollow[]>({
            fetchPolicy: 'no-cache',
            query: ORGANIZED_ACTIVITY_FOLLOWS_FOR_USER,
            variables: {
                query: {
                    userId,
                },
            },
        })
    }

    followOrganizedActivity$(userId: string, organizedActivityId: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: FOLLOW_ORGANIZED_ACTIVITY,
            variables: {
                command: {
                    userId: userId,
                    organizedActivityId: organizedActivityId,
                },
            },
        })
    }

    unfollowOrganizedActivity$(userId: string, organizedActivityId: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UNFOLLOW_ORGANIZED_ACTIVITY,
            variables: {
                command: {
                    userId: userId,
                    organizedActivityId: organizedActivityId,
                },
            },
        })
    }

    openOrganizedActivity$(organizedActivityId: string, terms: string, summary: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: OPEN_ORGANIZED_ACTIVITY,
            variables: {
                command: {
                    organizedActivityId: organizedActivityId,
                    terms: terms,
                    summary: summary
                },
            },
        })
    }

    createOrganizedActivity$(command: CreateOrganizedActivityInput) {
        return this.apolloService.mutate<User>({
            mutation: CREATE_ORGANIZED_ACTIVITY,
            variables: {
                command,
            },
        })
    }

    updateOrganizedActivity$(command: UpdateOrganizedActivityInput) {
        return this.apolloService.mutate<User>({
            mutation: UPDATE_ORGANIZED_ACTIVITY,
            variables: {
                command,
            },
        })
    }

    deleteOrganizedActivity$(organizedActivityId: string) {
        return this.apolloService.mutate<User>({
            mutation: DELETE_ORGANIZED_ACTIVITY,
            variables: {
                command: {
                    organizedActivityId: organizedActivityId,
                },
            },
        })
    }

    copyOrganizedActivity$(organizedActivityId: string, startTime: string) {
        return this.apolloService.mutate<string>({
            mutation: COPY_ORGANIZED_ACTIVITY,
            variables: {
                command: {
                    organizedActivityId: organizedActivityId,
                    startTime: startTime
                },
            },
        })
    }

    updateOrganizedActivitySyncConfig$(organizedActivityId: string, type: string, endpointUrl: string, dataFileFormat: string, runImmediately: boolean, dryRun: boolean) {
        return this.apolloService.mutate<boolean>({
            mutation: UPDATE_ORGANIZED_ACTIVITY_SYNC_CONFIG,
            variables: {
                command: {
                    organizedActivityId: organizedActivityId,
                    type: type,
                    endpointUrl: endpointUrl,
                    dataFileFormat: dataFileFormat,
                    runImmediately: runImmediately,
                    dryRun: dryRun
                },
            },
        })
    }

    exportOrganizedActivityToConnection$(connectionType: ConnectionType, organizedActivityId: string) {
        return this.apolloService.mutate<string>({
            mutation: EXPORT_ORGANIZED_ACTIVITY_TO_CONNECTION,
            variables: {
                command: {
                    connectionType: connectionType,
                    organizedActivityId: organizedActivityId,
                },
            },
        })
    }

    downloadGpx$(organizedActivityId: string) {
        return this.httpClient.get(`${server.apiUrl}/events/activities/${organizedActivityId}.gpx`, {
            responseType: 'blob',
        })
    }
}
