import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { ArticleService } from 'app/articles/services/article.service'
import { Article } from 'app/articles/articles.models'
import { WindowService } from 'app/core/services/window.service'

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
})
export class ArticlesComponent implements OnInit {
    feed: string = this.route.snapshot.params['feed']
    articles$: Observable<Article[]> = this.articleService.getArticles$(50)

    constructor(
        private windowService: WindowService,
        private articleService: ArticleService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        //this.articles$ = this.articleService.getArticles$(feed == 'any' ? 'behej:articles' : feed)
    }

    navigate(article: Article) {
        this.router.navigate(['/', 'articles', this.feed, article.id], { state: article })
    }
}
