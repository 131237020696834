import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable, switchMap } from 'rxjs'
import { LoginService } from 'app/users/services/login.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private readonly loginService: LoginService) {}

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (httpRequest.url.startsWith('https://maps.') || this.loginService.isAuthenticated$.value == false) return next.handle(httpRequest)

        return this.loginService.authorizationHeader$.pipe(
            switchMap((value) => {
                const headers = httpRequest.headers.set('Authorization', value)
                const requestClone = httpRequest.clone({
                    headers,
                })
                return next.handle(requestClone)
            }),
        )
    }
}
