import { Component, Input } from '@angular/core'
import { LoginService } from 'app/users/services/login.service'
import { map } from 'rxjs/operators'
import { OrganizedActivity, Registration, RegistrationState } from 'app/events/events.models'
import { RegistrationService } from 'app/events/services/registration.service'
import { Router } from '@angular/router'
import dayjs from 'dayjs'
import { UserService } from '../../../users/services/user.service'
import { MatDialog } from '@angular/material/dialog'
import { RegisterModalComponent } from '../../../core/components/register-modal/register-modal.component'
import { Organizer } from '../../../organizers/organizers.models'

@Component({
    selector: 'app-register-button',
    templateUrl: './register-button.component.html',
})
export class RegisterButtonComponent {
    @Input()
    organizedActivity: OrganizedActivity

    @Input()
    organizer: Organizer

    constructor(
        private loginService: LoginService,
        private registrationService: RegistrationService,
        private userService: UserService,
        private readonly router: Router,
        private dialog: MatDialog,
    ) {}

    private activityRegistrations = (it) => it.filter((e) => e.organizedActivity.id == this.organizedActivity.id)
    private activeRegistrations = (it) =>
        it.filter((e) => e.state == RegistrationState.New || e.state == RegistrationState.Confirmed)

    //not canceled|transferred
    activeRegistration$ = this.registrationService.userRegistrations$.pipe(
        map(this.activityRegistrations),
        map(this.activeRegistrations),
        map((it) => it[0] ?? null),
    )

    hasActiveRegistration$ = this.activeRegistration$.pipe(map((it) => it != null))

    canRegister$ = this.hasActiveRegistration$.pipe(
        map(
            (it) =>
                !it && this.organizedActivity.currentPrice != null && dayjs(this.organizedActivity.startTime) > dayjs(),
        ),
    )

    isLoggedIn$ = this.loginService.isLoggedIn$

    openRegistration(registration: Registration) {
        this.router.navigate(['/', 'events', 'registrations', registration.id])
    }

    register() {
        if (!this.loginService.isLoggedIn) {
            this.loginService.login()
            return
        }

        const modal = this.dialog.open(RegisterModalComponent, {
            data: {
                organizer: this.organizer,
                organizedActivity: this.organizedActivity,
                hasCompletedProfile: this.userService.hasCompletedProfile(this.loginService.currentUser),
                merchandise: [],
            },
        })

        modal.afterClosed().subscribe((data) => {
            console.log('RESULT', data)
            switch (data.result) {
                case 'completeProfile':
                    this.router.navigate(['users', 'settings'])
                    break
                case 'register':
                    if (this.organizedActivity.currentPrice.amount.value == 0) {
                        this.registrationService.registerForFree(this.organizedActivity.id, data.teamName)
                    } else {
                        this.registrationService.registerAndPay(this.organizedActivity.id, data.merchandise, data.teamName)
                    }
                    break
            }
        })
    }
}
