import { Injectable } from '@angular/core'
import { LoginService } from 'app/users/services/login.service'
import { Router } from '@angular/router'
import { PaymentStatus } from 'app/payments/payments.models'
import { Observable, switchMap } from 'rxjs'
import {
    CreateRegistration,
    GetRegisteredPersons,
    GetUserRegistrations,
    OrganizedActivity,
    RegisteredPerson,
    Registration,
} from 'app/events/events.models'
import { map } from 'rxjs/operators'
import { PaymentService } from 'app/payments/services/payment.service'
import { WindowService } from 'app/core/services/window.service'
import { ApolloService } from 'app/core/services/apollo.service'
import { REGISTERED_PERSONS, USER_REGISTRATIONS } from '../events.queries'
import { CANCEL_REGISTRATION, CREATE_REGISTRATION } from '../events.mutations'
import { Merchandise, MerchandisePrice } from '../../core/core.models'

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    constructor(
        private paymentService: PaymentService,
        private apolloService: ApolloService,
        private loginService: LoginService,
        private windowService: WindowService,
        private router: Router,
    ) {}

    userRegistrations$: Observable<Registration[]> = this.getCurrentUserRegistrations$()

    returnPath = this.router.createUrlTree(['payments', 'result']).toString()

    registerAndPay(organizedActivityId: string, merchandise: Merchandise[], teamName: string) {
        if (!this.loginService.isLoggedIn) {
            throw 'Not logged in'
        }
        let user = this.loginService.currentUser

        this.createRegistration$({
            userId: user.id,
            organizedActivityId: organizedActivityId,
            merchandise: merchandise,
            teamName: teamName
        }).pipe(
            switchMap((it) => {
                return this.paymentService.payRegistration$({
                    registrationId: it.id,
                    returnPath: this.returnPath,
                    isNative: this.windowService.isNative,
                })
            }),
        )
        .subscribe((it) => {
            this.processPayment(it)
        })
    }

    registerForFree(organizedActivityId: string, teamName: string) {
        if (!this.loginService.isLoggedIn) {
            throw 'Not logged in.'
        }
        let user = this.loginService.currentUser

        this.createRegistration$({
            userId: user.id,
            organizedActivityId: organizedActivityId,
            merchandise: [],
            teamName: teamName
        }).subscribe((it) => {
            this.router.navigate(['/', 'events', 'registrations', it.id])
        })
    }

    findMyActivityRegistration(organizedActivity: OrganizedActivity): Observable<Registration | null> {
        return this.userRegistrations$.pipe(
            map((it) => {
                return it.find((i) => organizedActivity.id == i.organizedActivity.id)
            }),
        )
    }

    processPayment(status: PaymentStatus) {
        this.windowService.open(status.redirectUrl)
    }

    getCurrentUserRegistrations$() {
        return this.loginService.currentUser$.pipe(switchMap((it) => this.getUserRegistrations$({ userId: it.id, futureOnly: false })))
    }

    getUserRegistrations$(query: GetUserRegistrations) {
        return this.apolloService.watchQuery<Registration[]>({
            query: USER_REGISTRATIONS,
            variables: {
                query,
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    createRegistration$(command: CreateRegistration): Observable<Registration> {
        return this.apolloService.mutate<Registration>({
            mutation: CREATE_REGISTRATION,
            variables: {
                command,
            },
        })
    }

    getRegisteredPersons$(query: GetRegisteredPersons) {
        return this.apolloService.watchQuery<RegisteredPerson[]>({
            query: REGISTERED_PERSONS,
            variables: {
                query,
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    cancelRegistration(registrationId: string) {
        return this.apolloService.mutate<Registration>({
            mutation: CANCEL_REGISTRATION,
            variables: {
                command: {
                    registrationId: registrationId,
                },
            },
        })
    }
}
