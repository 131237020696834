import { Inject, Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { defaultLanguage, Language, LanguageCode, languages } from '../constants/languages'
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core'

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    // todo language by browser or url

    constructor(
        private translate: TranslateService,
        private _adapter: DateAdapter<any>,
        @Inject(MAT_DATE_LOCALE) private _locale: string,
    ) {}

    readonly availableLanguages = languages

    selectedLanguage$ = new BehaviorSubject<Language>(defaultLanguage)

    get selectedLanguageCode() {
        return this.selectedLanguage$.value.code
    }

    changeLanguageByCode(languageCode: string): void {
        let language =
            this.availableLanguages.find((language) => language.code == languageCode)
        if (!language)
            language = this.availableLanguages.find((language) => language.code == LanguageCode.EN)
        this.changeLanguage(language)
    }

    changeLanguage(lang: Language): void {
        this.translate.use(lang.code).subscribe(() => this.selectedLanguage$.next(lang))
        this._locale = lang.locale
        this._adapter.setLocale(lang.locale)
    }
}
