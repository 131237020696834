import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Person } from 'app/core/core.models'
import { LoginService } from 'app/users/services/login.service'
import { Observable, switchMap } from 'rxjs'
import { filter } from 'rxjs/operators'
import * as R_ from 'ramda-extension'
import { User, UserActivity, UserFollowStatus } from 'app/users/users.models'
import { WindowService } from 'app/core/services/window.service'
import { UserService } from 'app/users/services/user.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateService } from '@ngx-translate/core'
import { OrganizerService } from '../../organizers/services/organizer.service'
import dayjs from 'dayjs'
import { Organizer } from '../../organizers/organizers.models'
import { ActivityResult } from '../../events/events.models'
import { ActivityResultService } from '../../events/services/activity-result.service'
import { server } from '../../core/core.server'

@UntilDestroy()
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
    userActivityColumns = ['activity', 'connectionType', 'started', 'elapsedTime', 'movingTime', 'distance', 'pace']
    userActivityExpanded: UserActivity | null = null
    followStatus: string = UserFollowStatus.Unknown
    followerPersons: Person[] = []
    followingPersons: Person[] = []
    person: Person = null
    userActivities: UserActivity[]
    activityResults: ActivityResult[]
    organizers: Organizer[]
    isLoggedIn: boolean = false
    canEdit: boolean = false
    currentUserActivityIndex: number = 0
    darkMode: boolean = false
    apiUrl = server.apiUrl
    user: User

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private translateService: TranslateService,
        public windowService: WindowService,
        public loginService: LoginService,
        private organizerService: OrganizerService,
        private activityResultService: ActivityResultService
    ) {}

    ngOnInit(): void {
        this.windowService.darkMode$.subscribe((darkMode) => {
            this.darkMode = darkMode
        })
        this.route.params
            .pipe(
                switchMap((params, index) => {
                    return this.userService.getPerson$(params['ref'])
                }),
                switchMap((person) => {
                    this.person = person

                    this.userService
                        .getUserActivities$(this.person.id, null, 20)
                        .pipe(untilDestroyed(this))
                        .subscribe((userActivities: UserActivity[]) => {
                            this.userActivities = userActivities
                        })

                    return this.loginService.currentUser$
                }),
                untilDestroyed(this),
            )
            .subscribe((currentUser) => {
                this.canEdit = currentUser.id == this.person.id
                this.isLoggedIn = true
                this.userService
                    .getUserFollowStatus$(this.person.id)
                    .pipe(untilDestroyed(this))
                    .subscribe((status) => {
                        this.followStatus = status
                    })
                this.userService
                    .getUserFollowerPerson$(this.person.id)
                    .pipe(untilDestroyed(this))
                    .subscribe((persons) => {
                        this.followerPersons = persons
                    })
                this.userService
                    .getUserFollowingPerson$(this.person.id)
                    .pipe(untilDestroyed(this))
                    .subscribe((persons) => {
                        this.followingPersons = persons
                    })
                this.organizerService.isRootOrganizerMember$().subscribe((isRootOrganizerMember) => {
                    if (isRootOrganizerMember) {
                        this.canEdit = true
                        this.userService
                            .getUser$(this.person.id)
                            .subscribe((user) => {
                                this.user = user
                            })
                    }
                })
                this.organizerService.getUserOrganizers$(this.person.id).subscribe((organizers) => {
                    this.organizers = organizers
                })
                this.activityResultService.getActivityResults$({
                    userId: this.person.id,
                    skip: 0,
                    limit: 20,
                    ascending: false,
                }).subscribe((activityResults) => {
                    this.activityResults = activityResults
                })
            })
    }

    follow(person: Person) {
        this.userService.followUser$(person.id).subscribe((status) => {
            console.log(`follow user ${person.id} status`, status)
            this.followStatus = status
        })
    }

    unfollow(person: Person) {
        if (confirm(this.translateService.instant('confirm_unfollow') + ` ${person.givenName} ${person.surname}?`)) {
            this.userService.unfollowUser$(person.id).subscribe((result) => {
                console.log(`unfollow user ${person.id} result`, result)
                if (result) {
                    //if (person.id == this.person.id) this.followStatus = UserFollowStatus.NotFollowing
                    this.followingPersons = this.followingPersons.filter((p) => p.id != person.id)
                }
            })
        }
    }

    currentUserActivity(index) {
        this.currentUserActivityIndex = index
    }

    toDate(date: string) {
        return dayjs(date).toDate()
    }
}
