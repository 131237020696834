import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
import { Amount, Currency } from 'app/core/core.models'
import { DecimalPipe } from '@angular/common'

const defaultDigitsInfo = '1.2-2'
const defaultCZDigitsInfo = '1.0-2'

@Pipe({
    name: 'amount',
})
export class AmountPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string, private decimalPipe: DecimalPipe) {}
    transform(amount: Amount, digitsInfo: string = defaultDigitsInfo, locale: string = this.locale): String {
        let currency

        switch (amount?.currency) {
            case Currency.CZK:
                currency = 'Kč'
                digitsInfo = digitsInfo === defaultDigitsInfo ? defaultCZDigitsInfo : digitsInfo
                break
            case Currency.EUR:
                currency = '€'
                break
            case Currency.PLN:
                currency = 'zl'
                break
            case Currency.HUF:
                currency = 'ft'
                break
        }

        return currency ? `${this.decimalPipe.transform(amount.value / 100, digitsInfo, locale)} ${currency}` : '-'
    }
}
