import { Pipe, PipeTransform } from '@angular/core'
import { DistanceRange } from 'app/events/events.models'

@Pipe({
    name: 'distanceRanges',
})
export class DistanceRangesPipe implements PipeTransform {
    public transform(value: DistanceRange[] = [], unit = ''): string {
        if (value.length) {
            let min = 10000
            let max = 0
            value.forEach((distanceRange) => {
                if (min > distanceRange.min)
                    min = distanceRange.min
                if (max < distanceRange.max)
                    max = distanceRange.max
            })
            if (min >= 10 && max <= 10.1) {
                return 'DistanceRange.10K'
            }
            if (min >= 21 && max <= 21.2) {
                return 'DistanceRange.HalfMarathon'
            }
            if (min >= 42 && max <= 42.3) {
                return 'DistanceRange.Marathon'
            }
            return `${min}${max === 10000 ? '+' : ' - ' + max} ${unit}`
        }
        return ''
    }
}
