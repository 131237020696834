import { distinctUntilChanged, fromEvent } from 'rxjs'
import { debounceTime, filter, map, pairwise, tap } from 'rxjs/operators'

const isUserScrollingDown = (positions: number[]) => positions[0] < positions[1]

const isUserAtTheBottomLimit = (pixelsFromBottom: number, element: Document | Element) => (positions: number[]) => {
    const _el = getDocumentElement(element)
    return positions[1] + pixelsFromBottom >= _el.scrollHeight
}

const getDocumentElement = (element: Document | Element): HTMLElement =>
    element !== document ? <HTMLElement>element : element.documentElement

export const infiniteScrollObservable = (
    pixelsFromBottom = 0,
    debounce = 300,
    element: Document | Element = document,
) =>
    fromEvent(element, 'scroll').pipe(
        debounceTime(debounce),
        distinctUntilChanged(),
        map(() => {
            const _el = getDocumentElement(element)
            return _el.scrollTop + window.innerHeight
        }),
        pairwise(),
        filter(isUserScrollingDown),
        filter(isUserAtTheBottomLimit(pixelsFromBottom, element)),
    )
