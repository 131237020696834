import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import dayjs from 'dayjs'

export class DateValidators {
    static dateIsInPast(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = dayjs(control.value)
            const now = dayjs().startOf('day')

            if (!value) {
                return null
            }
            if (now < value) {
                return { isInPast: true }
            }
            return null
        }
    }
}
