import { Injectable } from '@angular/core'
import { map } from 'rxjs'
import { LoginService } from 'app/users/services/login.service'
import { Router } from '@angular/router'
import { OrganizerMenuItem, OrganizerMenuItems } from 'app/layouts/constants/organizer/model'
import { Organizer } from 'app/organizers/organizers.models'
import { emptyOrganizerMenuItems, existingOrganizerMenuItems } from 'app/layouts/constants/organizer/config'
import { OrganizerService } from 'app/organizers/services/organizer.service'

const getMenuItems = (organizer: Organizer): OrganizerMenuItems => {
    return organizer ? existingOrganizerMenuItems : emptyOrganizerMenuItems
}

@Injectable({
    providedIn: 'root',
})
export class OrganizerMenuService {
    menuItemsSubject$ = this.organizerService.currentOrganizer$.pipe(map(getMenuItems))
    menuItemClick = (menuItem: OrganizerMenuItem): void => {
        menuItem.action?.(this.router)
    }

    constructor(
        private loginService: LoginService,
        private router: Router,
        private organizerService: OrganizerService,
    ) {}
}
