import { Component, OnInit } from '@angular/core'
import { UserService } from 'app/users/services/user.service'
import { ActivatedRoute } from '@angular/router'
import { WindowService } from 'app/core/services/window.service'
import { UserActivity, userActivityConnectionUrl } from 'app/users/users.models'
import { filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import * as R_ from 'ramda-extension'
import { environment } from 'environments/environment'
import { server } from 'app/core/core.server'
import { TrackProfile } from '../../../events/events.models'
import { LikeService } from '../../../core/services/like.service'
import { Observable } from 'rxjs'
import { Person } from '../../../core/core.models'
import { LoginService } from '../../services/login.service'

@UntilDestroy()
@Component({
    selector: 'app-user-activity',
    templateUrl: './user-activity.component.html',
    styleUrls: ['./user-activity.component.scss'],
})
export class UserActivityComponent implements OnInit {
    readonly userActivityId = this.route.snapshot.params['id']
    readonly connectionUrl = userActivityConnectionUrl
    userActivityLikingPersons$: Observable<Person[]>
    userActivity: UserActivity
    apiUrl = server.apiUrl
    appUrl = server.appUrl
    time = server.time
    isTogglingLike = false
    canEdit = false

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private likeService: LikeService,
        public loginService: LoginService,
        public windowService: WindowService
    ) {}

    ngOnInit(): void {
        this.userService
            .getUserActivity$(this.userActivityId)
            .pipe(untilDestroyed(this))
            .subscribe((userActivity) => {
                this.userActivity = userActivity
                this.loginService.currentUser$.subscribe((currentUser) => {
                    this.canEdit = currentUser.id == this.userActivity.person?.id
                })
                if (userActivity.isLive) {
                    setTimeout(() => {
                        this.userService
                            .getLiveUserActivity$(userActivity.id)
                            .pipe(untilDestroyed(this))
                            .subscribe((userActivity) => {
                                this.userActivity = { ...userActivity }
                                console.log(
                                    'User activity update',
                                    userActivity.id,
                                    userActivity.activity.name,
                                    userActivity.isLive,
                                )
                                //this.cd.markForCheck()
                            })
                    }, environment.pollInterval)
                }
            })
        this.userActivityLikingPersons$ = this.userService.getUserActivityLikingPersons$(this.userActivityId)
    }

    open(url) {
        window.open(url)
    }

    like() {
        if (!this.isTogglingLike) {
            this.isTogglingLike = true
            this.likeService.toggleLike$('UserActivity', this.userActivityId).subscribe((result) => {
                console.log('Like', this.userActivityId, result)
                this.userActivity.likeCount += result
                this.isTogglingLike = false
            })
        }
    }

    updateName(event: any) {
        this.userService.updateUserActivityName$(this.userActivity.id, this.userActivity.activity.name).subscribe((result) => {
            console.log('Update user activity name', this.userActivity.activity.name, result)
        })
    }

    profilePropertyMaxValue(profile: TrackProfile, propertyName: string) {
        return profile.points.reduce((sum, point) => sum + point[propertyName], 0)
    }
}
