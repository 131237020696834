import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-organized-activity-copy',
    templateUrl: './organized-activity-copy.component.html',
})
export class OrganizedActivityCopyComponent {
    constructor(
        public dialogRef: MatDialogRef<OrganizedActivityCopyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { startDate: string, startTime: string },
    ) {}

    onNoClick(): void {
        this.dialogRef.close()
    }
}
