<h1 mat-dialog-title>{{'report_problem'|translate}}</h1>
<div mat-dialog-content>
    <small>{{'content_id' | translate}}</small>
    <p>{{data.id}}</p>
    <mat-form-field>
        <mat-label>{{'text'|translate}}</mat-label>
        <textarea rows="6" matInput tabindex="1" [(ngModel)]="data.text"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data" tabindex="1">{{'create'|translate}}</button>
    <button mat-button (click)="onNoClick()" tabindex="-1">{{'cancel'|translate}}</button>
</div>
