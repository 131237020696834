import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as R_ from 'ramda-extension'
import { filter, map } from 'rxjs/operators'
import { OrganizedEventServiceState } from 'app/events/components/organized-event/organized-event-service.state'
import { AbstractFormComponent } from 'app/core/components/abstract.form.component'
import { CreateOrganizedEventInput, OrganizedEvent, UpdateOrganizedEventInput } from 'app/events/events.models'
import { OrganizedEventModelTab } from 'app/events/components/organized-event/organized-event.model'
import { localizeArrayForm, localizeArrayFormHint } from 'app/core/utils'
import { WindowService } from 'app/core/services/window.service'
import { OrganizedEventService } from 'app/events/services/organized-event.service'
import { LanguageService } from 'app/core/services/language.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { OrganizerService } from '../../../organizers/services/organizer.service'
import { DateAdapter } from '@angular/material/core'

@UntilDestroy()
@Component({
    selector: 'app-organized-event-settings',
    templateUrl: './organized-event-settings.component.html',
    styleUrls: ['organized-event-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedEventSettingsComponent extends AbstractFormComponent implements OnInit {
    readonly form = new FormGroup({
        id: new FormControl(),
        name: this.createLocalizeFormArray(),
        description: this.createLocalizeFormArray(false),
        externalId: new FormControl(),
        organizerId: new FormControl(),
        labels: new FormControl([]/*, Validators.required*/),
    })
    organizers$ = this.organizerService.myOrganizers$.pipe(
        map((organizers) => organizers.filter((organizer) => organizer.isActive))
    )

    constructor(
        public readonly organizedEventServiceState: OrganizedEventServiceState,
        private readonly organizedEventService: OrganizedEventService,
        private readonly organizerService: OrganizerService,
        public override readonly windowService: WindowService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        languageService: LanguageService,
        _adapter: DateAdapter<any>,
    ) {
        super(windowService, languageService, _adapter)

        this.organizedEventServiceState.loading$.pipe(untilDestroyed(this)).subscribe((loading: boolean) => {
            loading ? this.form.disable() : this.form.enable()
        })
    }

    ngOnInit() {
        this.organizerService.currentOrganizer$.subscribe((organizer) => {
            if (organizer)
                this.form.controls['organizerId'].setValue(organizer.id)
        })
        this.organizedEventServiceState.organizedEvent$
            .pipe(untilDestroyed(this), filter(R_.isNotNil))
            .subscribe((organizedEvent: OrganizedEvent) => {
                this.prefillFormByKeys(organizedEvent, this.windowService.currentLanguageCode)
                this.form.controls['organizerId'].setValue(organizedEvent.organizerId)
            })
    }


    get formControlName() {
        return localizeArrayForm(this.form.controls['name'], this.windowService.currentLanguageCode)
    }

    get formControlNamePlaceholder() {
        return localizeArrayFormHint(this.form.controls['name'], this.windowService.currentLanguageCode)
    }

    get formControlDescription() {
        return localizeArrayForm(this.form.controls['description'], this.windowService.currentLanguageCode)
    }

    onSubmitValidForm(formValues: CreateOrganizedEventInput & UpdateOrganizedEventInput): void {
        const isUpdate = !!this.organizedEventServiceState?.organizedEventId
        if (isUpdate) {
            //delete formValues['organizerId']
        } else {
            delete formValues['id']
            //formValues['organizerId'] = this.getOrganizerId()
        }
        const mutation = isUpdate
            ? this.organizedEventService.updateOrganizedEvent$(formValues)
            : this.organizedEventService.createOrganizedEventInput$(formValues)
        mutation.subscribe((organizedEvent) => {
            this.organizedEventServiceState.organizedEvent$.next(organizedEvent)
            if (!isUpdate)
                this.router.navigate([
                    '/',
                    'events',
                    organizedEvent.id,
                    OrganizedEventModelTab[OrganizedEventModelTab.media],
                ])
        })
    }
}
