<h1 mat-dialog-title>Otevření registrací {{data.organizedActivity.name | localize}} prostřednictvím Actigo</h1>
<div mat-dialog-content>
    <div>
        <ng-container *ngIf="organizer">
            <p>
                {{'organizer' | translate}}: <b>{{organizer.name}}</b>,
                {{organizer.address.street}}, {{organizer.address.postalCode}} {{organizer.address.city}}, {{organizer.address.country}}<br/>
                <!--{{data.organizer.address.state}} -->{{organizer.address.country}}
                <ng-container *ngIf="organizer.orgId">{{'orgId' | translate}}: {{organizer.orgId}}&nbsp;</ng-container>
                <ng-container *ngIf="organizer.vatId">{{'vatId' | translate}}: {{organizer.vatId}}</ng-container>
            </p>

            <h4>Bankovní spojení, na které vám budou vyplaceny finanční prostředky:</h4>
            <p>{{organizer.bankAccount || '(doplnit v nastavení pořadatele)' }}</p>
        </ng-container>

        <h4>Shrnutí obchodních podmínek</h4>
        <p [innerHTML]="data.summary | marked"></p>
        <mat-checkbox [(ngModel)]="agreed">{{'agree_with' | translate}}</mat-checkbox>
        &nbsp;<a href="javascript:void(0)" (click)="openTerms()">{{'terms2' | translate | lowercase}}</a>
    </div>
</div>
<div class="form-element text-right">
    <button
        mat-button
        (click)="cancel()"
        tabindex="-1"
    >
        {{'cancel'|translate}}
    </button>
    <button
        mat-raised-button
        [mat-dialog-close]="data"
        [disabled]="!agreed"
        color="warn"
        tabindex="1"
    >
        {{'open'|translate}} {{'registration'|translate|lowercase}}
    </button>
</div>


