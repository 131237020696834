import { Component, OnInit } from '@angular/core'
import { FileUploader } from 'lib/file-upload/file-uploader.class'
import { LoginService } from '../../services/login.service'
import { server } from 'app/core/core.server'
import { Globals } from 'app/app.consts'
import { BehaviorSubject } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-user-picture',
  templateUrl: './user-picture.component.html'
})
export class UserPictureComponent implements OnInit {

    readonly uploader = new FileUploader({
        url: null,
        method: 'post',
        disableMultipart: true,
    })
    readonly uploadingFile$ = new BehaviorSubject(false)
    private authorizationHeader: string

    constructor(
        private readonly loginService: LoginService,
        private readonly translateService: TranslateService,
        private readonly snackBar: MatSnackBar,
    ) {
        this.uploader.onSuccessItem = (fileItem, response, status) => {
            this.uploader.clearQueue()
            this.snackBar.open(this.translateService.instant('successResult'), null, {
                duration: Globals.durationSnackBarMessage,
                panelClass: 'snack-bar-success',
            })
            this.uploadingFile$.next(false)
            this.loginService.picture$.next(this.uploader.options.url)
        }

        this.uploader.onErrorItem = (fileItem, response, status) => {
            this.uploader.clearQueue()
            this.snackBar.open(response || this.translateService.instant('globalError'), null, {
                duration: Globals.durationSnackBarMessage,
                panelClass: 'snack-bar-error',
            })
            this.uploadingFile$.next(false)
        }

        this.uploader.onAfterAddingFile = (fileItem) => {
            this.uploader.setOptions({
                ...this.uploader.options,
                url: `${server.apiUrl}/users/${this.loginService.currentUser.id}.pic?time=${server.time}`,
                headers: [
                    {
                        name: 'Authorization',
                        value: this.authorizationHeader,
                    }
                ]
            })
            fileItem.upload()
            this.uploadingFile$.next(true)
        }
    }

    ngOnInit() {
        this.loginService.authorizationHeader$.subscribe((header) => {
            this.authorizationHeader = header
        })
    }
}
