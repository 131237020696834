import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {

    constructor(
        private router: Router
    ) {
    }

    path: String

    ngOnInit(): void {
        this.path = this.router.url
    }

}
