export const terms = {
    en: `
    <h2>Chapter 1 for organizers</h2>
    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Curabitur vitae diam non enim vestibulum interdum. Nullam faucibus mi quis velit. Curabitur sagittis hendrerit ante. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Aenean placerat. Etiam dictum tincidunt diam. Duis condimentum augue id magna semper rutrum. Aliquam erat volutpat. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Duis bibendum, lectus ut viverra rhoncus, dolor nunc faucibus libero, eget facilisis enim ipsum id lacus. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Duis risus. Aliquam in lorem sit amet leo accumsan lacinia.

    In dapibus augue non sapien. Nullam eget nisl. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce nibh. Aliquam in lorem sit amet leo accumsan lacinia. Nunc tincidunt ante vitae massa. Pellentesque arcu. Integer malesuada. Pellentesque sapien. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Praesent id justo in neque elementum ultrices. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Aliquam ante. Pellentesque ipsum.
<h2>Chapter 2</h2>
Aenean id metus id velit ullamcorper pulvinar. Nunc auctor. Nam sed tellus id magna elementum tincidunt. Nulla est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Maecenas aliquet accumsan leo. Proin in tellus sit amet nibh dignissim sagittis. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Suspendisse nisl. Quisque porta. Phasellus et lorem id felis nonummy placerat. Etiam neque.

    Aenean fermentum risus id tortor. Nullam lectus justo, vulputate eget mollis sed, tempor sed magna. Nam quis nulla. Nam sed tellus id magna elementum tincidunt. Etiam sapien elit, consequat eget, tristique non, venenatis quis, ante. Nullam sapien sem, ornare ac, nonummy non, lobortis a enim. Morbi scelerisque luctus velit. In rutrum. Maecenas libero. Pellentesque arcu. Integer tempor. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Curabitur vitae diam non enim vestibulum interdum. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Aliquam erat volutpat. Et harum quidem rerum facilis est et expedita distinctio.

    Nulla non lectus sed nisl molestie malesuada. Quisque porta. Aliquam erat volutpat. Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Etiam egestas wisi a erat. Duis sapien nunc, commodo et, interdum suscipit, sollicitudin et, dolor. Integer tempor. Fusce consectetuer risus a nunc. Nulla est. Maecenas libero. Aliquam in lorem sit amet leo accumsan lacinia. Maecenas sollicitudin. Nullam justo enim, consectetuer nec, ullamcorper ac, vestibulum in, elit. Maecenas aliquet accumsan leo. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Duis bibendum, lectus ut viverra rhoncus, dolor nunc faucibus libero, eget facilisis enim ipsum id lacus. Integer in sapien. Nulla quis diam.
    `,
}
