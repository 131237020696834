import { Team } from '../users/users.models'

export type Nullish<T = never> = T | null | undefined

interface Option<T> {
    label: string
    value: T
}

export interface IOptions<T = string> extends Array<Option<T>> {}

export enum Sex {
    Male = 'Male',
    Female = 'Female',
    None = 'None',
}

export enum Currency {
    CZK = 'CZK',
    EUR = 'EUR',
    PLN = 'PLN',
    HUF = 'HUF',
}

export enum WorkflowState {
    Draft = 'Draft',
    Published = 'Published',
    Archived = 'Archived',
}

export enum Audience {
    Active = 'Active',
    Newbies = 'Newbies',
    Sleepers = 'Sleepers',
    Followers = 'Followers',
    Nobody = 'Nobody'
}
export interface Country {
    name: string
    code: string
}

export interface Counter {
    name: string
    value: number
}

export interface Amount {
    value: number
    currency: Currency
}

export interface Price {
    amount: Amount
    validTo?: string
    validUntilRegistrationCount?: number
}

export interface MerchandisePrice {
    sku: string
    name: LocalizedText[]
    description: LocalizedText[]
    amount: Amount
    validTo?: string
}

export interface Merchandise {
    sku: string
    quantity: number
}

export interface Link {
    type: string
    ref: string
}

export interface Position {
    latitude: number
    longitude: number
}

export interface Person {
    id?: string
    isProfilePublic: boolean
    givenName: string
    middleName?: string
    surname: string
    name: string
    ref?: string
    sex?: Sex
    birthDate?: string
    nickname?: string
    country?: string
    description?: string
    teams?: Team[]
}

export interface VerifiedContact {
    value: string
    newValue?: string
    created?: Date
}

export interface Post {
    id?: string
    text: string
    name: string
    ref?: string
    icon?: string
    created?: Date
    received?: Date
}

export interface WallPost {
    id: string
    userId: string
    groupId?: string
    ref?: string
    icon?: string
    type: string
    name: string
    text: string
    received?: Date
    created: Date
    entityType: string
    entityId: string
}

export interface Workflow {
    userId: string
    state: WorkflowState
    updated: Date
}

export interface LocalizedText {
    language: string
    text: string
}

export interface LocalizedTexts extends Array<LocalizedText> {}

export interface ImportResult {
    counters: Counter[]
    totalCount: number
}

export interface Media {
    ref: string // img.jpg, https://server.com/video.mp4, ...<youtube-id>
    type: MediaType
    subtype?: string // jpg, png, mp4, ogg
    thumbRef?: string
    title?: string
    isFavorite?: boolean
}

export enum MediaType {
    'image' = 'image',
    'video' = 'video',
    'iframe' = 'iframe',
    'youtube' = 'youtube',
    'facebook' = 'facebook',
}

export interface Address {
    label: string
    street: string
    city: string
    country: string
    state: string
    postalCode: string
}

export interface ButtonGroup {
    icon: string
    isDefault?: boolean
    value: string
    disabled?: boolean
    ariaLabel: string
}

export interface ButtonGroups extends Array<ButtonGroup> {}

export const enum ActivityType {
    Run = 'Run',
    Bike = 'Bike',
    Swim = 'Swim',
    Hike = 'Hike',
    Walk = 'Walk',
    NordicSki = 'NordicSki',
    OCR = 'OCR',
    Canicross = 'Canicross',
    Triathlon = 'Triathlon',
    Aquathlon = 'Aquathlon',
    Duathlon = 'Duathlon',
    SwimRun = 'SwimRun',
}

export interface LocalizedEnum {
    name: string
    text: LocalizedText[]
}

export interface ActivityLabel extends LocalizedEnum {
    types: ActivityType[]
    isEnabled: boolean
    isActive: boolean
}

export interface PersonalResult {
    time: any
    distance: number
    distanceUnit: string
}

export interface Like {
    entityId: string
    entityType: string
}

export interface Message {
    id: string
    type: string
    entityId: string
    entityType: string
    parentId?: string
    created?: Date
    sender: Person
    text: string
    seen: boolean
    rating?: number
    isLiked: boolean
    likeCount: number
    childCount: number
    children?: Message[]
}

export enum MessageType {
    Post = 'Post',
    Comment = 'Comment',
    Review = 'Review',
}

export interface Seekable {
    seekId?: string
    limit: number
    ascending: boolean
}

export interface SeekableQuery {
    query: Seekable
}

export interface Skipable {
    skip: number
    limit: number
    ascending: boolean
}

export interface SkipableQuery {
    query: Skipable
}

export interface GeocoderResponse {
    status: string
    error_message: string
    results: google.maps.GeocoderResult[]
}

export interface DeviceInfo {
    id: string

}

export interface Parameter {
    name: string
    value: string
}

export interface OAuthToken {
    token: string
    tokenSecret: string
}
