import { Component, Input } from '@angular/core'
import { LoginService } from 'app/users/services/login.service'
import { User } from 'app/users/users.models'
import { UserService } from 'app/users/services/user.service'

@Component({
    selector: 'app-identity',
    templateUrl: './identity.component.html',
})
export class IdentityComponent {
    @Input()
    user: User = null

    userInfo$ = this.loginService.userInfo$

    constructor(private loginService: LoginService, private userService: UserService) {}

    syncWithAuthProvider() {
        this.userService.syncProfile$().subscribe(() => {})
    }
}
