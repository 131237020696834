import { gql } from 'apollo-angular'
import { ARTICLE_FRAGMENT } from './articles.fragments'

export const ARTICLES = gql`
    ${ARTICLE_FRAGMENT}
    query articles($query: GetArticles!) {
        articles(query: $query) {
            ...ArticleFields
        }
    }
`
export const ARTICLE = gql`
    ${ARTICLE_FRAGMENT}
    query article($query: GetArticle!) {
        article(query: $query) {
            ...ArticleFields
        }
    }
`
