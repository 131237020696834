import { Injectable } from '@angular/core'
import { ApolloService } from 'app/core/services/apollo.service'
import { Audience, WallPost } from 'app/core/core.models'
import { Observable } from 'rxjs'
import { environment } from 'environments/environment'
import { WALL_POST_COUNT, WALL_POSTS } from '../core.queries'
import { CREATE_POST, RECEIVE_POST, REMOVE_POST, UNSUBSCRIBE_POST } from '../core.mutations'

@Injectable({
    providedIn: 'root',
})
export class PostService {
    constructor(private apolloService: ApolloService) {}

    getWallPosts$(language: string, seekId?: string, limit: number = 20) {
        return this.apolloService.watchQuery<WallPost[]>({
            query: WALL_POSTS,
            variables: {
                query: {
                    language: language,
                    seekId: seekId,
                    limit: limit,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    createPost$(text: string, audience: Audience = Audience.Followers): Observable<WallPost> {
        return this.apolloService.mutate<WallPost>({
            mutation: CREATE_POST,
            variables: {
                command: {
                    text: text,
                    audience: audience
                },
            },
        })
    }

    unsubscribePost$(id: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UNSUBSCRIBE_POST,
            variables: {
                command: {
                    id: id
                },
            },
        })
    }

    receivePost$(id?: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: RECEIVE_POST,
            variables: {
                command: {
                    id: id,
                },
            },
        })
    }

    removePost$(id: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: REMOVE_POST,
            variables: {
                command: {
                    id: id,
                },
            },
        })
    }

    getPostCount$(all: boolean = false) {
        return this.apolloService.watchQuery<number>({
            query: WALL_POST_COUNT,
            pollInterval: (<any>environment).pollInterval || 10000,
            variables: {
                query: {
                    all: all,
                },
            },
        })
    }
}
