import { Injectable } from '@angular/core'
import { Observable, of, switchMap } from 'rxjs'
import { OrganizerService } from 'app/organizers/services/organizer.service'
import { Organizer } from 'app/organizers/organizers.models'
import { MenuItemType, MenuMainItem } from 'app/layouts/layouts.models'
import { WindowService } from 'app/core/services/window.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { View } from 'app/events/components/organized-activities/organized-activities.model'

@UntilDestroy()
@Injectable()
export class LayoutService {
    private mainMenuItems = (loggedIn = false, hasOrganizers = false): MenuMainItem[] => [
        {
            state: ['/'],
            name: 'home',
            type: MenuItemType.link,
            icon: 'home',
            disabled: false,
            visible: true,
        },
        // {
        //     state: ['/', 'events', 'activities'],
        //     excludedStates: [
        //         ['/', 'events', 'activities', 'results'],
        //         ['/', 'events', 'activities', 'mine'],
        //     ],
        //     name: 'activities_search',
        //     type: MenuItemType.link,
        //     icon: 'search',
        //     disabled: false,
        //     visible: true,
        // },
        {
            state: ['/', 'events', 'activities', 'mine'],
            name: 'activities_mine',
            type: MenuItemType.link,
            icon: 'remove_red_eye',
            disabled: !loggedIn,
            visible: true,
        },
        {
            state: ['/', 'events', 'registrations'],
            name: 'registrations',
            type: MenuItemType.link,
            icon: 'check',
            disabled: !loggedIn,
            visible: true,
        },
        {
            state: ['/', 'people'],
            name: 'people',
            type: MenuItemType.link,
            icon: 'people',
            disabled: !loggedIn,
            visible: true,
        },
        {
            state: ['/', 'users', 'activities'],
            name: 'user_activities',
            type: MenuItemType.link,
            icon: 'directions_run',
            disabled: !loggedIn,
            visible: true,
        },
        {
            state: ['/', 'events', 'activities', 'results'],
            name: 'results',
            type: MenuItemType.link,
            icon: 'timer',
            disabled: !loggedIn,
            visible: true,
        },
        {
            name: 'organizer',
            type: MenuItemType.separator,
            icon: 'av_timer',
            disabled: false,
            visible: true,
        },
        {
            state: ['/', 'organizers'],
            name: 'events',
            type: MenuItemType.link,
            icon: 'event',
            disabled: false,
            visible: hasOrganizers,
        },
        // {
        //     state: ['/', 'events'],
        //     name: 'events',
        //     type: MenuItemType.link,
        //     icon: 'place',
        //     disabled: false,
        //     visible: hasOrganizers,
        // },
        {
            state: ['/', 'organizers', 'registrations'],
            name: 'registrations',
            type: MenuItemType.link,
            icon: 'done_all',
            disabled: false,
            visible: hasOrganizers,
        },
        {
            state: ['/', 'organizers', 'results'],
            name: 'results',
            type: MenuItemType.link,
            icon: 'timer',
            disabled: false,
            visible: hasOrganizers,
        },
        {
            state: ['/', 'organizers', 'settings'],
            name: 'settings',
            type: MenuItemType.link,
            icon: 'settings',
            disabled: false,
            visible: hasOrganizers,
        },
        {
            state: ['/', 'organizers', 'members'],
            name: 'members',
            type: MenuItemType.link,
            icon: 'person_add',
            disabled: false,
            visible: hasOrganizers,
        },
        {
            state: ['/', 'organizers', 'create'],
            name: 'create',
            type: MenuItemType.link,
            icon: 'add_circle',
            disabled: !loggedIn,
            visible: !hasOrganizers,
        },
        // {
        //     state: ['/', 'organizers', 'billing'],
        //     name: 'billing',
        //     type: MenuItemType.link,
        //     icon: 'attach_money',
        //     badge: [{ type: 'warning', value: 'new' }],
        //     disabled: false,
        //     visible: hasOrganizers,
        // },
        // {
        //     name: 'other',
        //     type: MenuItemType.separator,
        //     icon: 'av_timer',
        //     disabled: false,
        //     visible: true,
        // },
        // {
        //     state: ['/', 'articles', 'behej:articles'],
        //     name: 'articles',
        //     type: MenuItemType.link,
        //     icon: 'rss_feed',
        //     disabled: false,
        //     visible: true,
        // },
        // {
        //     state: ['/', 'tools'],
        //     name: 'tools',
        //     type: MenuItemType.link,
        //     icon: 'straighten',
        //     disabled: false,
        //     visible: true,
        // },
    ]

    headerSearchFormData = null
    headerSearchFirstInit = false
    menuLoginState = null
    menuItems: Observable<MenuMainItem[]>
    headerSearchView = View.CARDS

    constructor(private organizersService: OrganizerService, private windowService: WindowService) {}

    getMainMenuItems$(loggedIn): Observable<MenuMainItem[]> {
        if (loggedIn) {
            return this.organizersService.getMyOrganizers$().pipe(
                switchMap((organizers: Organizer[]) => {
                    return of(this.mainMenuItems(loggedIn, organizers.length > 0))
                }),
                untilDestroyed(this),
            )
        } else {
            return of(this.mainMenuItems(loggedIn, false))
        }
    }

    toggleDarkMode() {
        const body = document.getElementsByTagName('body')[0]
        body.classList.toggle('dark')
        this.windowService.darkMode$.next(body.classList.contains('dark'))
    }
}
