<mat-card>
    <mat-card-content>
<!--        <div class="text-right">-->
<!--            <button mat-button (click)="importFromStrava()">-->
<!--                <mat-icon>cloud_download</mat-icon>-->
<!--                Strava-->
<!--            </button>-->
<!--            <button mat-button (click)="importFromFitbit()">-->
<!--                <mat-icon>cloud_download</mat-icon>-->
<!--                Fitbit-->
<!--            </button>-->
<!--            <button mat-button (click)="load()">-->
<!--                <mat-icon>refresh</mat-icon>-->
<!--            </button>-->
<!--        </div>-->

        <div class="responsive-table">
            <mat-table #table [dataSource]="userActivities">
                <ng-container matColumnDef="connectionType">
                    <mat-header-cell *matHeaderCellDef>{{'connectionType' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [attr.data-label]="'connectionType' | translate">
                        {{element.connectionType}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="activity">
                    <mat-header-cell *matHeaderCellDef>{{'activity' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [attr.data-label]="'activity' | translate">
                        <a [routerLink]="['/users/activities', element.id]"
                            >{{'ActivityType.' + element.activity.type | translate}} - {{element.activity.name}}</a
                        >
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="elapsedTime">
                    <mat-header-cell *matHeaderCellDef class="justify-content-end"
                        >{{'elapsedTime' | translate}}</mat-header-cell
                    >
                    <mat-cell
                        *matCellDef="let element"
                        [attr.data-label]="'elapsedTime' | translate"
                        class="justify-content-end"
                    >
                        {{element.elapsedTime | time}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="movingTime">
                    <mat-header-cell *matHeaderCellDef class="justify-content-end"
                        >{{'movingTime' | translate}}</mat-header-cell
                    >
                    <mat-cell
                        *matCellDef="let element"
                        [attr.data-label]="'movingTime' | translate"
                        class="justify-content-end"
                    >
                        {{element.movingTime | time}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="started">
                    <mat-header-cell *matHeaderCellDef>{{'started' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [attr.data-label]="'started' | translate">
                        {{element.started | localizeDate : 'd.MM.y HH:mm:ss'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="distance" class="justify-content-end">
                    <mat-header-cell *matHeaderCellDef class="justify-content-end"
                        >{{'distance' | translate}}</mat-header-cell
                    >
                    <mat-cell
                        *matCellDef="let element"
                        [attr.data-label]="'distance' | translate"
                        class="justify-content-end"
                    >
                        {{element.distance | number: '1.0-3'}} {{'Unit.KM' | translate}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="pace" class="justify-content-end">
                    <mat-header-cell *matHeaderCellDef class="justify-content-end"
                        >{{'pace' | translate}}</mat-header-cell
                    >
                    <mat-cell
                        *matCellDef="let element"
                        [attr.data-label]="'pace' | translate"
                        class="justify-content-end"
                    >
                        {{element.pace | pace}}/{{'Unit.KM' | translate}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <mat-cell *matCellDef="let row" [attr.colspan]="columns.length">
                        <div
                            class="example-element-detail"
                            [@detailExpand]="row === expandedRow ? 'expanded' : 'collapsed'"
                        >
                            TODO detail
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: columns"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedRow === row"
                    (click)="expandedRow = expandedRow === row ? null : row"
                ></mat-row>
                <p *matNoDataRow class="no-data">{{'noData'|translate}}</p>
            </mat-table>
        </div>
    </mat-card-content>
</mat-card>
<div
    *ngIf="listCount == windowService.listLimit"
    class="h4 text-shadow text-center cursor-pointer"
    (click)="loadMore()"
>
    {{'more' | translate}}...
</div>

