<ng-template #notLoggedIn>
    <!-- sign up -->
    <mat-card>
        <mat-card-content>
            {{'account_not_yet' | translate}}
            <mat-card-actions>
                <button mat-raised-button color="warn" (click)="loginService.signup()">
                    <!--    <mat-icon>check</mat-icon>-->
                    {{'signup' | translate}}
                </button>
            </mat-card-actions>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <div class="mobile-only">
            <img src="assets/images/intro2.png" style="width: 100%">
            <img src="assets/images/intro1.png" style="width: 100%">
            <img src="assets/images/intro3.png" [style]="'width: 100%;' + (!isNative ? 'padding-bottom: 150px' : '')">
        </div>
        <div class="desktop-only" style="background: #e0e5e8">
            <img src="assets/images/intro1.png" style="width: 50%">
            <img src="assets/images/intro2.png" style="width: 50%">
            <img src="assets/images/intro3.png" style="width: 50%">
            <img src="assets/images/intro4.png" style="width: 50%" *ngIf="!isNative">
        </div>
    </mat-card>

    <!-- featured activities -->
<!--    <div fxLayout="row wrap" class="activities-overview">-->
<!--        <div fxFlex.gt-sm="100%" fxFlex="100">-->
<!--            <div class="title-wrapper">-->
<!--                <h2 class="text-shadow">{{'events_featured' | translate}}</h2>-->
<!--            </div>-->
<!--            <div-->
<!--                fxLayout="row wrap"-->
<!--                fxFlexAlign="center"-->
<!--                class="row activities-overview-list"-->
<!--                *ngIf="(featuredActivities$ | async) as featuredActivities"-->
<!--            >-->
<!--                <div-->
<!--                    *ngFor="let activity of featuredActivities"-->
<!--                    fxFlex="100"-->
<!--                    fxFlex.gt-xs="50"-->
<!--                    fxFlex.gt-md="33"-->
<!--                    class="activities-overview-list__item"-->
<!--                >-->
<!--                    <mat-card>-->
<!--                        <app-organized-activity-card [organizedActivity]="activity"></app-organized-activity-card>-->
<!--                    </mat-card>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <!-- events totals -->
<!--    <div fxLayout="row wrap" class="activities-overview">-->
<!--        <div fxFlex.gt-sm="100%" fxFlex="100">-->
<!--            <app-events-totals></app-events-totals>-->
<!--        </div>-->
<!--    </div>-->
</ng-template>

<ng-container *ngIf="loginService.loggedIn$ | async; else notLoggedIn">
    <mat-card>
        <div fxLayout="row wrap">
            <button mat-button (click)="load()">
                <mat-icon>refresh</mat-icon>
            </button>
            <mat-form-field fxFlex>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"
                    [(ngModel)]="text"
                    [placeholder]="'post' | translate"
                    [mentionConfig]="(loginService.mentionConfig$ | async) || { mentions: [] }"
                ></textarea>
            </mat-form-field>
            <mat-form-field fxFlex="15" *ngIf="isRootOrganizerMember$ | async">
                <mat-select [(ngModel)]="audience">
                    <ng-container *ngFor="let audience of audiences; let i = index">
                        <mat-option [value]="audience">{{'Audience.' + audience | translate}}&nbsp;</mat-option>
                        <mat-divider *ngIf="i == 0"></mat-divider>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <button mat-button color="accent" (click)="createPost()" [disabled]="!text">
                <mat-icon>send</mat-icon>
            </button>
        </div>
    </mat-card>

    <app-wall-post
        *ngFor="let wallPost of wallPosts"
        [wallPost]="wallPost"
        (remove)="remove($event)"
    ></app-wall-post>
    <div
        *ngIf="listCount == windowService.listLimit"
        class="h4 text-shadow text-center cursor-pointer"
        (click)="loadMore()"
    >
        {{'more' | translate}}...
    </div>
</ng-container>
