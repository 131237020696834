import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './components/app.component'
import { UsersModule } from './users/users.module'
import { CoreModule } from './core/core.module'
import { MessageModule } from 'app/core/components/message/message.module'
import { MatSliderModule } from '@angular/material/slider'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { PrivacyComponent } from './components/privacy/privacy.component'
import { TermsComponent } from './components/terms/terms.component'
import { MatButtonModule } from '@angular/material/button'
import { HomeComponent } from './components/home/home.component'
import { EventsRoutingModule } from './events/events-routing.module'
import { UsersRoutingModule } from './users/users-routing.module'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { MaterialModule } from 'app/layouts/material.module'
import {
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
    PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar'
import { FlexLayoutModule } from '@angular/flex-layout'
import { LayoutsModule } from 'app/layouts/layouts.module'
import { PipesModule } from 'app/core/pipes/pipes.module'
import { MapModule } from 'app/core/components/map/map.module'
import { DevToolsComponent } from './components/dev-tools/dev-tools.component'
import { NotFoundComponent } from './components/not-found/not-found.component'

import { DecimalPipe, registerLocaleData, ViewportScroller } from '@angular/common'
import localeCs from '@angular/common/locales/cs'
import { PaymentsModule } from './payments/payments.module'
import { PaymentsRoutingModule } from './payments/payments-routing.module'
import { SupportComponent } from './components/support/support.component'
import { ProfileComponent } from './components/profile/profile.component'
import { EmailNotVerifiedComponent } from './components/email-not-verified/email-not-verified.component'
import { AuthInterceptor } from 'app/core/infrastructure/auth.interceptor'
import { ArticlesModule } from './articles/articles.module'
import { EventsModule } from './events/events.module'
import { ToolsComponent } from './components/tools/tools.component'
import { environment } from 'environments/environment'
import config from '../../capacitor.config'
import { Router, RouteReuseStrategy, RoutesRecognized, Scroll } from '@angular/router'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { filter, pairwise } from 'rxjs/operators'
import { Capacitor } from '@capacitor/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { WallPostComponent } from './components/wall-post/wall-post.component'
import { Auth0ClientOptions } from '@auth0/auth0-spa-js/src/global'
import { MentionModule } from 'angular-mentions'
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { LandingComponent } from './components/landing/landing.component';
import { PeopleComponent } from './components/people/people.component'

registerLocaleData(localeCs, 'cs')

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true,
}

export const authConfig: Auth0ClientOptions & { returnTo } = {
    domain: environment.auth0.domain,
    client_id: Capacitor.isNativePlatform() ? environment.auth0.mobileClientId : environment.auth0.clientId,
    audience: environment.auth0.audience,
    cacheLocation: Capacitor.isNativePlatform() ? 'localstorage' : 'memory',
    scope: 'openid profile email userinfo',
    useRefreshTokens: true,
    //redirect after login
    redirect_uri: Capacitor.isNativePlatform() ? `${config.appId}://actigo.app/auth/callback` : window.location.origin,
    //redirect after logout
    returnTo: Capacitor.isNativePlatform() ? `${config.appId}://actigo.app/auth/logout` : window.location.origin,
}

@NgModule({
    declarations: [
        AppComponent,
        ProfileComponent,
        PrivacyComponent,
        TermsComponent,
        HomeComponent,
        NotFoundComponent,
        EmailNotVerifiedComponent,
        DevToolsComponent,
        SupportComponent,
        ToolsComponent,
        WallPostComponent,
        UnsubscribeComponent,
        LandingComponent,
        PeopleComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        UsersModule,
        UsersRoutingModule,
        MatSliderModule,
        MessageModule,
        BrowserAnimationsModule,
        LayoutsModule,
        MatButtonModule,
        EventsRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MaterialModule,
        PerfectScrollbarModule,
        FlexLayoutModule,
        PipesModule,
        MapModule,
        PaymentsModule,
        PaymentsRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ArticlesModule,
        EventsModule,
        IonicModule.forRoot(),
        ReactiveFormsModule,
        MentionModule,
        FormsModule,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        DecimalPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    scrollTopPositions: number[] = []
    scrollElement: Element
    constructor(router: Router, viewportScroller: ViewportScroller) {
        router.events
            .pipe(
                filter((e) => e instanceof RoutesRecognized),
                pairwise(),
            )
            .subscribe((event: any[]) => {
                this.scrollElement = document.querySelector('#content')
                if (this.scrollElement)
                    this.scrollTopPositions.push(this.scrollElement.scrollTop)
            })
        router.events.pipe(filter((e: any): any => e instanceof Scroll)).subscribe((e: Scroll) => {
            this.scrollElement = document.querySelector('#content')
            if (e.position) {
                // backward navigation
                if (this.scrollTopPositions.length) {
                    this.scrollTopPositions.pop()
                }
                setTimeout(() => {
                    this.scrollElement.scrollTo({
                        top: this.scrollTopPositions.length ? this.scrollTopPositions.pop() : 0,
                        behavior: 'auto',
                    })
                })
            } else if (e.anchor) {
                // anchor navigation
                viewportScroller.scrollToAnchor(e.anchor)
            } else {
                // forward navigation
                viewportScroller.scrollToPosition([0, 0])
            }
        })
    }
}
