import { Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject, switchMap } from 'rxjs'
import { LoginService } from 'app/users/services/login.service'
import { map } from 'rxjs/operators'
import { Router } from '@angular/router'
import { OrganizedActivityService } from '../services/organized-activity.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { OrganizedActivity } from '../events.models'
import { EnumService } from '../../core/services/enum.service'
import { LanguageService } from '../../core/services/language.service'

@UntilDestroy()
@Component({ template: '' })
export class AbstractFollowComponent implements OnInit {

    @Input()
    organizedActivity: OrganizedActivity

    activityLabels$ = this.enumService.activityLabels$


    constructor(
        private loginService: LoginService,
        private languageService: LanguageService,
        private organizedActivityService: OrganizedActivityService,
        private readonly router: Router,
        private enumService: EnumService,
    ) {}

    private activityFollow = (it) => {
        let r = it.filter((e) => e.organizedActivityId == this.organizedActivity.id)
        switch (r.length) {
            case 0:
                return null
            case 1:
                return r[0]
        }
    }

    isFollowing$ = new BehaviorSubject(false)
    isLoggedIn$ = this.loginService.isLoggedIn$
    selectedLanguage$ = this.languageService.selectedLanguage$

    ngOnInit(): void {
        this.loginService.currentUser$
            .pipe(
                switchMap((it) => this.organizedActivityService.getOrganizedActivityFollowsForUser$(it.id)),
                map(this.activityFollow),
                map((it) => it != null),
                untilDestroyed(this),
            )
            .subscribe((it) => this.isFollowing$.next(it))

    }

    follow() {
        if (!this.loginService.isLoggedIn) {
            this.loginService.login()
            return
        }

        let user = this.loginService.currentUser

        this.organizedActivityService
            .followOrganizedActivity$(user.id, this.organizedActivity.id)
            .subscribe((result) => {
                this.isFollowing$.next(true)
                this.organizedActivity.followCount++
            })
    }

    unfollow() {
        let user = this.loginService.currentUser

        this.organizedActivityService
            .unfollowOrganizedActivity$(user.id, this.organizedActivity.id)
            .subscribe((result) => {
                this.isFollowing$.next(false)
                this.organizedActivity.followCount--
            })
    }
}
