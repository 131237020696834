import { Component, Input, OnInit } from '@angular/core'
import { Position } from 'app/core/core.models'
import { LocationService } from 'app/core/services/location.service'
import { OrganizedActivity } from 'app/events/events.models'
import { WindowService } from 'app/core/services/window.service'

@Component({
    selector: 'app-organized-activity-carpool',
    templateUrl: './organized-activity-carpool.component.html',
})
export class OrganizedActivityCarpoolComponent implements OnInit {
    @Input()
    organizedActivity: OrganizedActivity

    currentPosition: Position | null

    constructor(private windowService: WindowService, private locationService: LocationService) {
        this.locationService.currentPosition.then((position) => {
            this.currentPosition = position
        })
    }

    ngOnInit(): void {}

    offerSeats() {
        this.windowService.open('https://www.blablacar.cz/offer-seats/departure/precise')
    }
}
