import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core'
import { Person, Sex } from 'app/core/core.models'
import { age } from 'app/core/core.functions'
import { TranslateService } from '@ngx-translate/core'
import { LoginService } from 'app/users/services/login.service'
import { WindowService } from 'app/core/services/window.service'
import { UserFollowStatus } from 'app/users/users.models'
import { server } from 'app/core/core.server'

@Component({
    selector: 'app-person',
    templateUrl: './person.component.html',
    styleUrls: ['./person.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PersonComponent implements OnInit, OnChanges {
    @Input()
    person: Person

    @Input()
    asCard: boolean = false

    @Input()
    withDescription: boolean = false

    @Input()
    withTeams: boolean = false

    @Input()
    followStatus: string = UserFollowStatus.Unknown

    @Output()
    follow = new EventEmitter<Person>()

    @Output()
    unfollow = new EventEmitter<Person>()

    @Output()
    remove = new EventEmitter<Person>()

    @Input()
    showRemoveButton: boolean = true

    @Input()
    subtitle: string = null

    label: string = ''
    apiUrl = server.apiUrl
    appUrl: string
    time = server.time

    constructor(
        private loginService: LoginService,
        public windowService: WindowService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.appUrl = `${server.appUrl}/${this.person.id}`
    }

    followPerson() {
        if (!this.loginService.isLoggedIn) {
            this.loginService.login()
            return
        }
        this.follow.emit(this.person)
    }

    unfollowPerson() {
        this.unfollow.emit(this.person)
    }

    removePerson() {
        this.remove.emit(this.person)
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.label =
            (this.person.sex != Sex.None ? this.translateService.instant(this.person.sex) : '') +
            (this.person.sex != Sex.None && this.person.birthDate ? ' • ' : '') +
            (this.person.birthDate ? new Date(this.person.birthDate).getFullYear() : '')
    }
}
