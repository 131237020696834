<button
    [matMenuTriggerFor]="mainMenu"
    mat-button
    class="p-0 organizer-menu-button"
    (menuOpened)="opened = true"
    (menuClosed)="opened = false"
>
    <mat-icon
        class="mat-icon_shadow d-inline-block d-lg-none- ms-0 ms-lg-2"
        [class.d-lg-none-]="currentOrganizer"
        [class.d-lg-inline-block-]="!currentOrganizer"
        >av_timer</mat-icon
    >
    <span *ngIf="currentOrganizer" class="ms-1 me-1 d-none d-lg-inline-block organizer-menu-button--text"
        >{{currentOrganizer?.name}}</span
    >
    <mat-icon class="mat-icon_shadow arrow opacity-50" [class.d-none]="opened">keyboard_arrow_down</mat-icon>
    <mat-icon class="mat-icon_shadow arrow opacity-50" [class.d-none]="!opened">keyboard_arrow_up</mat-icon>
</button>
<mat-menu #mainMenu="matMenu" class="mymegamenu">
    <ng-container *ngFor="let navItem of menuItems">
        <button *ngIf="navItem.type === manuItemType.link" (click)="menuClick.emit(navItem)" mat-menu-item>
            <mat-icon>{{navItem.icon}}</mat-icon> {{navItem.name | translate}}
        </button>
        <button
            *ngIf="navItem.type === manuItemType.sub"
            [matMenuTriggerFor]="organizersMenu"
            mat-menu-item
            [disabled]="organizers.length < 2"
        >
            <mat-icon>{{navItem.icon}}</mat-icon> {{navItem.name | translate}}
        </button>
        <mat-divider *ngIf="navItem.type === manuItemType.separator"></mat-divider>
    </ng-container>
</mat-menu>

<mat-menu #organizersMenu="matMenu">
    <ng-container *ngFor="let organizer of organizers">
        <button *ngIf="organizer !== currentOrganizer" (click)="organizerMenuClick.emit(organizer)" mat-menu-item>
            {{organizer.name}}
        </button>
    </ng-container>
</mat-menu>
