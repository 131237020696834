import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatCardModule } from '@angular/material/card'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { CoreModule } from 'app/core/core.module'
import { OrganizersRoutingModule } from './organizers-routing.module'
import { OrganizerHomeComponent } from './components/organizer-home/organizer-home.component'
import { OrganizerBillingComponent } from './components/organizer-billing/organizer-billing.component'
import { OrganizerRegistrationsComponent } from './components/organizer-registrations/organizer-registrations.component'
import { OrganizerTermsComponent } from './components/organizer-terms/organizer-terms.component'
import { OrganizerSettingsComponent } from './components/organizer-settings/organizer-settings.component'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MaterialModule } from 'app/layouts/material.module'
import { OrganizerComponent } from './components/organizer/organizer.component'
import { OrganizerCreateComponent } from './components/organizer-create/organizer-create.component'
import { PipesModule } from 'app/core/pipes/pipes.module'
import { OrganizerMembersComponent } from './components/organizer-members/organizer-members.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ActionButtonModule } from 'app/core/components/action-button/action-button.module'
import { FileUploaderModule } from '../core/components/file-uploader/file-uploader.module';
import { OrganizerActivityResultsComponent } from './components/organizer-activity-results/organizer-activity-results.component';
import { OrganizerProfileComponent } from './components/organizer-profile/organizer-profile.component'
import { EventsModule } from '../events/events.module'

@NgModule({
    declarations: [
        OrganizerHomeComponent,
        OrganizerBillingComponent,
        OrganizerRegistrationsComponent,
        OrganizerTermsComponent,
        OrganizerSettingsComponent,
        OrganizerComponent,
        OrganizerCreateComponent,
        OrganizerMembersComponent,
        OrganizerActivityResultsComponent,
        OrganizerProfileComponent,
    ],
    exports: [],
  imports: [
    CoreModule,
    CommonModule,
    OrganizersRoutingModule,
    MaterialModule,
    MatCardModule,
    MatButtonToggleModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule,
    FlexLayoutModule,
    FormsModule,
    ActionButtonModule,
    FileUploaderModule,
    EventsModule,
  ],
})
export class OrganizersModule {}
