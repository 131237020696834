import { gql } from 'apollo-angular'
import { ORGANIZED_EVENT_FRAGMENT, REGISTRATION_FRAGMENT } from './events.fragments'

export const CREATE_ORGANIZED_ACTIVITY = gql`
    mutation createOrganizedActivity($command: CreateOrganizedActivityInput!) {
        createOrganizedActivity(command: $command) {
            id
        }
    }
`
export const FOLLOW_ORGANIZED_ACTIVITY = gql`
    mutation followOrganizedActivity($command: FollowOrganizedActivity!) {
        followOrganizedActivity(command: $command)
    }
`
export const UNFOLLOW_ORGANIZED_ACTIVITY = gql`
    mutation unfollowOrganizedActivity($command: UnfollowOrganizedActivity!) {
        unfollowOrganizedActivity(command: $command)
    }
`

export const OPEN_ORGANIZED_ACTIVITY = gql`
    mutation openOrganizedActivity($command: OpenOrganizedActivity!) {
        openOrganizedActivity(command: $command)
    }
`

export const UPDATE_ORGANIZED_ACTIVITY = gql`
    mutation updateOrganizedActivity($command: UpdateOrganizedActivityInput!) {
        updateOrganizedActivity(command: $command) {
            id
        }
    }
`
export const DELETE_ORGANIZED_ACTIVITY = gql`
    mutation deleteOrganizedActivity($command: DeleteOrganizedActivity!) {
        deleteOrganizedActivity(command: $command)
    }
`
export const COPY_ORGANIZED_ACTIVITY = gql`
    mutation copyOrganizedActivity($command: CopyOrganizedActivity!) {
        copyOrganizedActivity(command: $command) {
            id
        }
    }
`

export const UPDATE_ORGANIZED_ACTIVITY_SYNC_CONFIG = gql`
    mutation updateOrganizedActivitySyncConfig($command: UpdateOrganizedActivitySyncConfig!) {
        updateOrganizedActivitySyncConfig(command: $command)
    }
`

export const EXPORT_ORGANIZED_ACTIVITY_TO_CONNECTION = gql`
    mutation exportOrganizedActivityToConnection($command: ExportOrganizedActivityToConnection!) {
        exportOrganizedActivityToConnection(command: $command)
    }
`

export const CREATE_ORGANIZED_EVENT = gql`
    mutation createOrganizedEvent($command: CreateOrganizedEventInput!) {
        createOrganizedEvent(command: $command) {
            ...OrganizedEventFields
        }
    }
    ${ORGANIZED_EVENT_FRAGMENT}
`
export const UPDATE_ORGANIZED_EVENT = gql`
    mutation updateOrganizedEvent($command: UpdateOrganizedEventInput!) {
        updateOrganizedEvent(command: $command) {
            ...OrganizedEventFields
        }
    }
    ${ORGANIZED_EVENT_FRAGMENT}
`
export const ADD_ORGANIZED_EVENT_MEDIA = gql`
    mutation addOrganizedEventMedia($command: AddOrganizedEventMedia!) {
        addOrganizedEventMedia(command: $command)
    }
`
export const SER_ORGANIZED_EVENT_MEDIA_FAVORITE = gql`
    mutation setOrganizedEventMediaFavorite($command: SetOrganizedEventMediaFavorite!) {
        setOrganizedEventMediaFavorite(command: $command)
    }
`
export const REMOVE_ORGANIZED_EVENT_MEDIA = gql`
    mutation removeOrganizedEventMedia($command: RemoveOrganizedEventMedia!) {
        removeOrganizedEventMedia(command: $command)
    }
`
export const CREATE_REGISTRATION = gql`
    ${REGISTRATION_FRAGMENT}
    mutation createRegistration($command: CreateRegistration!) {
        createRegistration(command: $command) {
            ...RegistrationFields
        }
    }
`
export const CANCEL_REGISTRATION = gql`
    ${REGISTRATION_FRAGMENT}
    mutation cancelRegistration($command: CancelRegistration!) {
        cancelRegistration(command: $command) {
            ...RegistrationFields
        }
    }
`
