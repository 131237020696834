import { View } from 'app/events/components/organized-activities/organized-activities.model'
import { ActivityType, ButtonGroups, IOptions } from 'app/core/core.models'
import { DistanceRange, GetOrganizedActivities } from 'app/events/events.models'
import dayjs from 'dayjs'

export const distanceRangesOptions: IOptions<DistanceRange> = [
    {
        label: '0_5',
        value: { min: 0, max: 5 },
    },
    {
        label: '5_15',
        value: { min: 5, max: 15 },
    },
    {
        label: '10K',
        value: { min: 10, max: 10.1 },
    },
    {
        label: '15_25',
        value: { min: 15, max: 25 },
    },
    {
        label: 'HalfMarathon',
        value: { min: 21, max: 21.2 },
    },
    {
        label: '25_42',
        value: { min: 25, max: 42 },
    },
    {
        label: 'Marathon',
        value: { min: 42, max: 42.3 },
    },
    {
        label: 'More',
        value: { min: 42.3, max: 10000 },
    },
]

export const maxDistanceFromFromPositionOptions: IOptions<number> = [
    {
        label: 'From10',
        value: 10,
    },
    {
        label: 'From20',
        value: 20,
    },
    {
        label: 'From30',
        value: 30,
    },
    {
        label: 'From50',
        value: 50,
    },
    {
        label: 'From100',
        value: 100,
    },
    {
        label: 'From250',
        value: 250,
    },
    {
        label: 'From500',
        value: 500,
    },
]

export const buttonsGroups: ButtonGroups = [
    {
        isDefault: true,
        value: View.CARDS,
        icon: 'view_module',
        ariaLabel: 'cards',
    },
    {
        isDefault: false,
        value: View.LIST,
        icon: 'list',
        ariaLabel: 'list',
    },
    // {
    //     isDefault: false,
    //     value: View.CALENDAR,
    //     icon: 'event',
    //     ariaLabel: 'event',
    //     disabled: true,
    // },
    {
        isDefault: false,
        value: View.MAP,
        icon: 'map',
        ariaLabel: 'map',
    },
]

export const defaultFormValue: GetOrganizedActivities = {
    distanceRanges: [],
    limit: 50,
    activityTypes: [],
    labels: [],
    organizerIds: [],
    dateFrom: dayjs().startOf('day').toDate(),
    dateTo: dayjs().endOf('day').add(6, 'months').toDate(),
    myPosition: null,
    maxDistanceFromPosition: 250,
    seekId: null,
    ascending: true,
}
