import { ChangeDetectionStrategy, Component } from '@angular/core'
import { OrganizedActivitiesStateService } from 'app/events/components/organized-activities/organized-activities-state.service'
import { WindowService } from 'app/core/services/window.service'
import { OrganizedActivity } from 'app/events/events.models'

@Component({
    selector: 'app-organized-activities-map',
    templateUrl: './organized-activities-map.component.html',
    styleUrls: ['organized-activities-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedActivitiesMapComponent {
    markerDisabled = null
    constructor(
        public readonly organizedActivitiesStateService: OrganizedActivitiesStateService,
        public readonly windowService: WindowService,
    ) {}

    mapActivityDetail: OrganizedActivity = null

    detailActivity(organizedActivity: OrganizedActivity) {
        this.mapActivityDetail = null
        this.mapActivityDetail = organizedActivity
        this.markerDisabled = true
    }

    closeDetailActivity(e) {
        e.preventDefault()
        e.cancelBubble = true
        this.mapActivityDetail = null
        this.markerDisabled = false
    }
}
