import { Component, Input } from '@angular/core'
import { FileUploader } from 'lib/file-upload/file-uploader.class'
//https://valor-software.com/ng2-file-upload/
@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
    @Input()
    uploader: FileUploader

    @Input()
    disabled = false

    hasBaseDropZoneOver: boolean
    hasAnotherDropZoneOver: boolean

    constructor() {
        this.hasBaseDropZoneOver = false
        this.hasAnotherDropZoneOver = false
    }

    fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e
    }

    fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e
    }
}
