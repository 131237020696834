<!--groupId: {{wallPost.groupId}}-->
<mat-card
    class="wall-post-mat-card"
    [class.make-me-magic]="this.isLive"
    >
    <div fxLayout="row">
        <div  class="cursor-pointer" (click)="open(wallPost.ref ? wallPost.ref : '/')">
            <mat-card-content class="wall-post-holder">
                <div fxLayout="column" class="wall-post">
                    <div class="wall-post__header">
                        <div mat-card-avatar class="avatar-in-list wall-post__avatar">
                            <img [src]="icon(wallPost.icon)" class="img-fluid img-circle" style="width: 40px; height: 40px" />
                        </div>
                    </div>
                    <div fxFlex="grow" class="wall-post__content">
                        <h5 class="wall-post__name">{{wallPost.name}}</h5>
                        <div class="wall-post__text">
                            <div class="wall-post__text-bubble" [innerHTML]="(wallPost.text | marked) + (entity?.isCommercial ? ' (' + ('commercial' | translate | uppercase) + ')' : '')"></div>
                        </div>
                        <div class="wall-post__created">{{wallPost.created | localizeDate : 'd.MM.y HH:mm'}}</div>
                    </div>
                </div>
            </mat-card-content>
        </div>
        <div class="cursor-pointer" (click)="open(wallPost.ref ? wallPost.ref : '/')" fxFlex></div>
        <div>
            <button [matMenuTriggerFor]="wallPostMenu" mat-icon-button class="m-r-5">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #wallPostMenu="matMenu">
                <ng-container>
                    <button
                        mat-menu-item
                        [disabled]="wallPost.userId == null"
                        (click)="remove.emit(wallPost)"
                        >
                        <mat-icon>delete</mat-icon>
                        {{'remove' | translate}} {{'post' | translate | lowercase}}
                    </button>
                    <button mat-menu-item (click)="updateUserPostRule(true, 'Low')">
                        <mat-icon>notifications_off</mat-icon>
                        {{'do_not_notify' | translate}}
                    </button>
                    <button mat-menu-item (click)="updateUserPostRule(false, 'None')">
                        <mat-icon>do_not_disturb_on</mat-icon>
                        {{'do_not_show' | translate}}
                    </button>
                    <button mat-menu-item (click)="report()">
                        <mat-icon>report</mat-icon>
                        {{'report' | translate}} {{'post' | translate | lowercase}}
                    </button>
                </ng-container>
            </mat-menu>
        </div>
    </div>
<!--    <pre>{{wallPost | json}}</pre>-->
    <!-- entity -->
        <div class="wall-post-common"
             *ngIf="entity"
        >
        <!-- article -->
        <app-article-card
            *ngIf="wallPost.entityType == 'Article'"
            [article]="entity"
        ></app-article-card>

        <!-- message -->
        <mat-card-content
            *ngIf="wallPost.entityType == 'Message'"
        >
            <app-messages
                [entityId]="wallPost.entityId"
                [entityMessages]="true"
                [isShowingInput]="false"
                [isShowingPerson]="wallPost.type != 'WallPostCreated'"
            ></app-messages>
        </mat-card-content>

        <!-- person -->
        <mat-card-content
            *ngIf="wallPost.entityType == 'Person'"
            class="text-center person-component person-component_normal">
            <app-person
                [person]="entity"
                [asCard]="true"
                [withDescription]="true"
            ></app-person>
        </mat-card-content>

        <!-- organized activity -->
        <app-organized-activity-card
            class="wall-post-common__in"
            *ngIf="wallPost.entityType == 'OrganizedActivity'"
            [organizedActivity]="entity"
            [fixedAspectRatio]="false"
        >
        </app-organized-activity-card>

        <!-- user created -->
        <mat-card-content
            *ngIf="wallPost.type == 'UserCreated'"
            class="messages"
        >
            <app-messages
                entityType="Person"
                [entityId]="wallPost.entityId"
                [entityMessages]="true"
                [isShowingInput]="true"
                [isShowingPerson]="true"
            ></app-messages>
        </mat-card-content>

        <!-- user follow -->
        <div
            class="wall-post-common__in"
            style="padding-left: 18px"
            *ngIf="wallPost.entityType == 'UserFollow'">
            <mat-card-actions>
                <button
                    mat-raised-button
                    color="warn"
                    [disabled]="entity != 'Pending'"
                    (click)="approveUserFollow()"
                >
                    {{'approve' | translate}}
                </button>
            </mat-card-actions>
        </div>

        <!-- user activity -->
        <ng-container *ngIf="wallPost.entityType == 'UserActivity'">
            <div class="wall-post-common__in user-activity-holder" *ngIf="entity">
                <mat-card-content class="user-activity-content">
                    <app-user-activity-card [userActivity]="entity" [showMap]="false"> </app-user-activity-card>
                </mat-card-content>
                <!-- TODO in tablet/desktop map should be on right side (50%), not below !!! -->
                <div class="user-activity-map-holder" *ngIf="entity.activity?.track?.segments?.length && entity.activity.track.segments[0].points.length">
                    <app-map
                        class="user-activity-map"
                        mapHeight="100%"
                        gestureHandling="none"
                        [activity]="entity.activity"
                        [darkMode]="windowService.darkMode$ | async"
                    ></app-map>
                </div>
            </div>
<!--                <mat-card-content class="user-activity-discussion">-->
<!--                    <app-messages [entityType]="'UserActivity'" [entityId]="entity.id"></app-messages>-->
<!--                </mat-card-content>-->
            <div fxLayout="row wrap" class="text-center bg-light border-top rounded_bottom">
                <div fxFlex.gt-xs="50" fxFlex="50" class="p-t-10 p-b-10">
                    <a href="javascript:void(0)" class="link" (click)="like(wallPost.entityType, entity)">
                        <i class="fa fa-thumbs-up m-r-10 m-t-0 m-b-5"></i>
                        {{entity.likeCount}} {{'likes' | translate | lowercase}}
                    </a>
                </div>
                <div fxFlex.gt-xs="50" fxFlex="50" class="p-t-10 p-b-10">
                    <a href="javascript:void(0)" class="link" [routerLink]="['/users/activities', entity.id]">
                        <i class="fa fa-comment m-r-10 m-t-0 m-b-5"></i>
                        {{entity.messageCount}} {{'posts' | translate | lowercase}}
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
    <!--            <pre>{{wallPost | json}}</pre>-->
</mat-card>
