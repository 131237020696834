<div fxLayout="row wrap">
    <!-- Card column -->
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <p>TODO reviews</p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
