import { Component, Input, OnInit } from '@angular/core'
import { terms } from '../../data/terms'

@Component({
    selector: 'app-organizer-terms',
    templateUrl: './organizer-terms.component.html',
})
export class OrganizerTermsComponent implements OnInit {
    @Input()
    standalone: boolean = true

    get terms() {
        return terms.en //TODO provide terms based on language vs country
    }

    constructor() {}

    ngOnInit(): void {}
}
