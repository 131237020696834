import { Component, OnInit } from '@angular/core'
import { OrganizerService } from 'app/organizers/services/organizer.service'
import { BehaviorSubject, debounceTime, Observable, startWith } from 'rxjs'
import { Organizer, OrganizerMember, OrganizerRole } from 'app/organizers/organizers.models'
import { Person } from 'app/core/core.models'
import { TranslateService } from '@ngx-translate/core'
import { UserService } from 'app/users/services/user.service'
import { FormControl } from '@angular/forms'
import { switchMap } from 'rxjs'
import { filter } from 'rxjs/operators'

@Component({
    selector: 'app-organizer-members',
    templateUrl: './organizer-members.component.html',
})
export class OrganizerMembersComponent implements OnInit {
    organizerMembers$ = new BehaviorSubject<OrganizerMember[]>([])
    organizerRoles = Object.keys(OrganizerRole)
    personControl = new FormControl('')
    filteredPersons$: Observable<Person[]>
    currentOrganizer: Organizer
    memberRole: OrganizerRole = OrganizerRole.Reporter

    constructor(
        private userService: UserService,
        private organizerService: OrganizerService,
        private translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.organizerService.currentOrganizer$.subscribe((currentOrganizer) => {
            this.currentOrganizer = currentOrganizer
            if (currentOrganizer != null) {
                this.organizerService.getOrganizerMembers$(currentOrganizer.id).subscribe((organizerMembers) => {
                    this.organizerMembers$.next(organizerMembers)
                })
            } else {
                this.organizerMembers$.next([])
            }
        })
        this.filteredPersons$ = this.personControl.valueChanges.pipe(
            filter((value) => typeof value == 'string'),
            startWith(''),
            debounceTime(500),
            switchMap((value) => {
                return this.userService.getPersons$(value)
            }),
        )
    }

    stringify(person: Person): string {
        return person && person.name ? (person.name + (person.nickname ? ' (' + person.nickname + ')' : '')) : ''
    }

    addMember() {
        console.log('Adding organizer member')
        this.organizerService
            .addOrganizerMember(this.currentOrganizer.id, this.personControl.value.id, this.memberRole)
            .subscribe((organizerMember) => {
                console.log('Organizer member added', organizerMember)
                this.organizerMembers$.next(this.organizerMembers$.value.concat(organizerMember))
                this.memberRole = OrganizerRole.Reporter
            })
    }

    removeMember(person: Person) {
        if (confirm(this.translateService.instant('confirm_delete') + ` ${person.givenName} ${person.surname}?`)) {
            console.log('Removing organizer member', person)
            this.organizerService
                .removeOrganizerMember(this.currentOrganizer.id, person.id)
                .subscribe((result) => {
                    console.log('Organizer member removed', result)
                    this.organizerMembers$.next(
                        this.organizerMembers$.value.filter((item) => item.person.id != person.id),
                    )
                })
        }
    }
}
