import { Component, OnInit } from '@angular/core'
import { WindowService } from 'app/core/services/window.service'
import { LocationService } from 'app/core/services/location.service'
import { Position } from 'app/core/core.models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { VersionService } from 'app/core/services/version.service'
import { DeviceId, DeviceInfo } from '@capacitor/device'
import { LoginService } from 'app/users/services/login.service'
import { getPlatforms } from '@ionic/angular'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { logger } from 'app/core/utils/logger'
import { server } from 'app/core/core.server'

@Component({
    templateUrl: './dev-tools.component.html',
})
@UntilDestroy()
export class DevToolsComponent implements OnInit {
    authorizationHeader: String
    position: Position
    device: DeviceId & DeviceInfo
    orientation: number | null
    isNative = this.windowService.isNative
    platforms = getPlatforms()
    notificationToken$ = this.windowService.notificationToken$

    formGroup: FormGroup
    windowLocation = window.location
    server = server
    logItems = logger.items

    constructor(
        private windowService: WindowService,
        private locationService: LocationService,
        public loginService: LoginService,
        public versionService: VersionService,
        private router: Router,
        private fb: FormBuilder,
    ) {
        this.formGroup = this.fb.group({
            url: [null, [Validators.required]],
        })
        this.loginService.isLoggedIn$.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this.loginService.authorizationHeader$.subscribe((value) => {
                    this.authorizationHeader = value
                })
            } else {
                this.authorizationHeader = '-'
            }
        })
        this.windowService.device$.subscribe((device) => {
            this.device = device
        })
        this.windowService.screenOrientation$.subscribe((orientation) => {
            this.orientation = orientation
        })
        // this.locationService
        //     .getCurrentPosition$()
        //     .pipe(untilDestroyed(this))
        //     .subscribe((position) => {
        //         this.position = position
        //     })
        //
        this.locationService.currentPosition.then((position) => {
            this.position = position
        })
    }

    ngOnInit(): void {
    }

    browserTest() {
        this.windowService.open('https://ipecho.net')
    }

    setPosition(position: Position) {
        this.position = position
    }

    get serverUrl() {
        return server.apiUrl.slice(0, -4)
    }

    navigate(url: string) {
        console.log('URL', url)
        this.router.navigateByUrl(url)
    }
}
