import { Component, Inject } from '@angular/core'
import { LoginService } from 'app/users/services/login.service'
import { User, UserProfile } from 'app/users/users.models'
import { UserService } from '../../services/user.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-user-setup-modal',
  templateUrl: './user-setup-modal.component.html',
})
export class UserSetupModalComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public user: User,
        private dialogRef: MatDialogRef<UserSetupModalComponent>,
        private loginService: LoginService,
        private userService: UserService,
    ) {
    }

    updateProfile(profile: UserProfile) {
        this.userService.updateUserProfile(this.user.id, profile).subscribe((it) => {
            console.log('setup updated profile', it)
            this.loginService.currentUser = it
            this.dialogRef.close(true)
        })
    }
}
