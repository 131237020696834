import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { OrganizedEventModelTab } from 'app/events/components/organized-event/organized-event.model'
import { distinctUntilChanged } from 'rxjs'
import { organizedEventConfig } from 'app/events/components/organized-event/organized-event.config'
import { MatTabChangeEvent } from '@angular/material/tabs/tab-group'
import * as events from 'events'
import { OrganizedEventServiceState } from 'app/events/components/organized-event/organized-event-service.state'
import { map } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { error } from '@rxweb/reactive-form-validators'

@UntilDestroy()
@Component({
    selector: 'app-organized-event',
    templateUrl: './organized-event.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedEventComponent implements OnInit, OnDestroy {
    readonly OrganizedEventModelTab = OrganizedEventModelTab

    isNewEvent: boolean
    activeTabIndex: number

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly organizedEventServiceState: OrganizedEventServiceState,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(
                map((params) => params[organizedEventConfig.params.activeTab]),
                distinctUntilChanged(),
                untilDestroyed(this),
            )
            .subscribe((activeTab) => {
                const index = Number(OrganizedEventModelTab[activeTab])
                if (this.activeTabIndex == index) return

                this.activeTabIndex = index
                this.cd.markForCheck()

                const existsActiveTab = OrganizedEventModelTab[this.activeTabIndex]
                if (!existsActiveTab) {
                    throw 'wrong tab'
                }
            })

        this.route.params
            .pipe(
                map((params) => params[organizedEventConfig.params.id]),
                distinctUntilChanged(),
                untilDestroyed(this),
            )
            .subscribe((id: string) => {
                this.isNewEvent = id == organizedEventConfig?.paramForCreate
                if (!this.isNewEvent) {
                    this.organizedEventServiceState.loadOrganizedEvent(id)
                }
            })
    }

    ngOnDestroy(): void {
        this.organizedEventServiceState.resetOrganizedEvent()
    }

    selectedTabChange(event: MatTabChangeEvent) {
        this.activeTabIndex = event.index
        this.router.navigate([
            '/',
            'events',
            this.organizedEventServiceState.organizedEventId,
            OrganizedEventModelTab[event.index],
        ])
    }
}
