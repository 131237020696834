<button
    mat-raised-button
    color="warn"
    (click)="register()"
    *ngIf="(!(isLoggedIn$|async) && organizedActivity.currentPrice != null) || (canRegister$|async)"
>
    <ng-container *ngIf="organizedActivity.currentPrice.amount.value > 0">
        {{ 'register_for'| translate }} {{ organizedActivity.currentPrice.amount | amount}}
    </ng-container>
    <ng-container *ngIf="organizedActivity.currentPrice.amount.value == 0">
        {{ 'register_for_free'| translate }}
    </ng-container>
</button>

<button
    mat-raised-button
    (click)="openRegistration(registration)"
    *ngIf="(activeRegistration$|async) as registration"
>
    {{ 'my_registration' | translate }}
</button>
