import { Injectable } from '@angular/core'
import {
    AddOrganizedEventMedia,
    CreateOrganizedEventInput,
    EventsTotals,
    GetOrganizedEvent,
    GetOrganizedEvents,
    OrganizedEvent,
    RemoveOrganizedEventMedia,
    SetOrganizedEventMediaFavorite,
    UpdateOrganizedEventInput,
} from 'app/events/events.models'
import { HttpClient } from '@angular/common/http'
import { ApolloService } from 'app/core/services/apollo.service'
import { server } from 'app/core/core.server'
import { EVENT_TOTALS, ORGANIZED_EVENT, ORGANIZED_EVENTS } from '../events.queries'
import {
    CREATE_ORGANIZED_EVENT,
    UPDATE_ORGANIZED_EVENT,
    ADD_ORGANIZED_EVENT_MEDIA,
    SER_ORGANIZED_EVENT_MEDIA_FAVORITE,
    REMOVE_ORGANIZED_EVENT_MEDIA,
} from '../events.mutations'

@Injectable({
    providedIn: 'root',
})
export class OrganizedEventService {
    constructor(private apolloService: ApolloService, private readonly httpClient: HttpClient) {}

    getTotals$() {
        return this.apolloService.watchQuery<EventsTotals>({
            fetchPolicy: 'no-cache',
            query: EVENT_TOTALS,
        })
    }

    getOrganizedEvent$(query: GetOrganizedEvent) {
        return this.apolloService.query<OrganizedEvent>({
            query: ORGANIZED_EVENT,
            fetchPolicy: 'no-cache',
            variables: {
                query,
            },
        })
    }

    deleteMedia$(organizedEventId: string, ref: string) {
        return this.httpClient.delete(`${server.apiUrl}/events/${organizedEventId}/files/${ref}`)
    }

    getOrganizedEvents$(query: GetOrganizedEvents) {
        return this.apolloService.watchQuery<OrganizedEvent[]>({
            fetchPolicy: 'no-cache',
            query: ORGANIZED_EVENTS,
            variables: {
                query,
            },
        })
    }

    createOrganizedEventInput$(command: CreateOrganizedEventInput) {
        return this.apolloService.mutate<OrganizedEvent>({
            fetchPolicy: 'no-cache',
            mutation: CREATE_ORGANIZED_EVENT,
            variables: {
                command,
            },
        })
    }

    updateOrganizedEvent$(command: UpdateOrganizedEventInput) {
        return this.apolloService.mutate<OrganizedEvent>({
            fetchPolicy: 'no-cache',
            mutation: UPDATE_ORGANIZED_EVENT,
            variables: {
                command,
            },
        })
    }

    addOrganizedEventMedia$(command: AddOrganizedEventMedia) {
        return this.apolloService.mutate<boolean>({
            fetchPolicy: 'no-cache',
            mutation: ADD_ORGANIZED_EVENT_MEDIA,
            variables: {
                command,
            },
        })
    }

    setOrganizedEventMediaFavorite$(command: SetOrganizedEventMediaFavorite) {
        return this.apolloService.mutate<boolean>({
            fetchPolicy: 'no-cache',
            mutation: SER_ORGANIZED_EVENT_MEDIA_FAVORITE,
            variables: {
                command,
            },
        })
    }

    removeOrganizedEventMedia$(command: RemoveOrganizedEventMedia) {
        return this.apolloService.mutate<boolean>({
            fetchPolicy: 'no-cache',
            mutation: REMOVE_ORGANIZED_EVENT_MEDIA,
            variables: {
                command,
            },
        })
    }
}
