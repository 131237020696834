import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular'
import { BrowserTracing } from '@sentry/tracing'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
    Sentry.init({
        dsn: 'https://634d210925fa41c7a2d585877ace305c@o1367254.ingest.sentry.io/6667908',
        integrations: [
            // new BrowserTracing({
            //     tracingOrigins: [
            //         'localhost:4200',
            //         'https://preview.actigo.app',
            //         'https://actigo.app',
            //         'http://localhost',
            //         'capacitor://localhost',
            //     ],
            //     routingInstrumentation: Sentry.routingInstrumentation,
            // }),
        ],
        tracesSampleRate: 0.0,
    })
    enableProdMode()
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))
