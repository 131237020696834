<div fxLayout="row wrap">
    <div fxFlex.gt-sm="50%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <mat-form-field>
                    <mat-label>{{'person' | translate}}</mat-label>
                    <input
                        type="text"
                        matInput
                        [formControl]="personControl"
                        [matAutocomplete]="auto"
                    >
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="stringify">
                        <mat-option *ngFor="let person of filteredPersons$ | async" [value]="person">
                            {{stringify(person)}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'role' | translate}}</mat-label>
                    <mat-select [(ngModel)]="memberRole">
                        <mat-option *ngFor="let role of organizerRoles" [value]="role">{{role | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="form-element text-right">
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        class="button"
                        (click)="addMember()"
                        [disabled]="!personControl.value?.id"
                    >
                        {{'add' | translate}}
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="50%" fxFlex="100">
        <mat-card>
            <mat-card-content class="pt-2 pe-0 pb-0 ps-0">
                <div fxLayout="row wrap">
                    <app-person
                        *ngFor="let organizerMember of organizerMembers$ | async"
                        [person]="organizerMember.person"
                        [subtitle]="organizerMember.role | translate"
                        (remove)="removeMember(organizerMember.person)"
                        fxFlex.gt-sm="50"
                        fxFlex.gt-md="33.3"
                        fxFlex.gt-lg="33.3"
                        fxFlex.lt-sm="100"
                        fxFlex.lt-md="50"
                        fxFlex="33.3"
                        class="pb-3"
                    ></app-person>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
