import { concat, InMemoryCache } from '@apollo/client/core'
import { APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { authLink } from './auth.midddleware'
import { environment } from 'environments/environment'
import { Provider } from '@angular/core'
import { AccessTokenProvider } from './access-token-provider'
import { Auth0SpaService } from 'app/users/services/auth0-spa.service'
import { server } from 'app/core/core.server'

const apolloGraphQLFactory = (httpLink: HttpLink, auth: AccessTokenProvider) => {
    const cache = new InMemoryCache()

    const http = httpLink.create({
        uri: server.graphqlUrl,
    })

    const link = concat(authLink(auth), http)
    return {
        cache,
        link,
        connectToDevTools: !environment.production,
    }
}

export const ApolloGraphQLProvider: Provider = {
    provide: APOLLO_OPTIONS,
    useFactory: apolloGraphQLFactory,
    deps: [HttpLink, Auth0SpaService],
}
