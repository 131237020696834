import { LOCALE_ID, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ApolloGraphQLProvider } from './infrastructure/apollo-graphql-provider'
import { ApolloModule } from 'apollo-angular'
import { HttpClientModule } from '@angular/common/http'
import { PromptComponent } from './components/prompt/prompt.component'
import { TranslateModule } from '@ngx-translate/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MaterialModule } from 'app/layouts/material.module'
import { PersonComponent } from './components/person/person.component'
import { RouterModule } from '@angular/router'
import { PaceCalculatorComponent } from './components/pace-calculator/pace-calculator.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AddressComponent } from './components/address/address.component'
import { VerifiedContactComponent } from './components/verified-contact/verified-contact.component'
import { MessagesComponent } from './components/messages/messages.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { DirectivesModule } from 'app/core/directives/directives.module'
import { MessageModule } from 'app/core/components/message/message.module'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter'
import { IdentityComponent } from './components/identity/identity.component'
import { RegisterModalComponent } from './components/register-modal/register-modal.component'
import { MentionModule } from 'angular-mentions'
import { LinkListComponent } from './components/link-list/link-list.component'
import { PipesModule } from "./pipes/pipes.module";
import { MapModule } from './components/map/map.module'

@NgModule({
    declarations: [
        PromptComponent,
        PersonComponent,
        PaceCalculatorComponent,
        AddressComponent,
        VerifiedContactComponent,
        MessagesComponent,
        IdentityComponent,
        RegisterModalComponent,
        LinkListComponent,
    ],
  imports: [
    CommonModule,
    ApolloModule,
    HttpClientModule,
    TranslateModule,
    MatDialogModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    DirectivesModule,
    MessageModule,
    MentionModule,
    PipesModule,
    MapModule,
  ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'cs-CZ',
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
        { provide: MAT_DATE_LOCALE, useValue: 'cs-CZ' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        ApolloGraphQLProvider,
    ],
    exports: [
        PersonComponent,
        PaceCalculatorComponent,
        AddressComponent,
        VerifiedContactComponent,
        MessagesComponent,
        IdentityComponent,
        LinkListComponent,
    ],
})
export class CoreModule {}
