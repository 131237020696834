import { Component, OnInit } from '@angular/core'
import { Observable, tap, combineLatest, BehaviorSubject } from 'rxjs'
import { OrganizedActivity, RegisteredPerson } from 'app/events/events.models'
import { server } from 'app/core/core.server'
import * as R_ from 'ramda-extension'
import { AbstractSelectableOrganizedActivityComponent } from '../abstract-selectable-organized-activity.component'
import { filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Sort } from '@angular/material/sort'
import { compare } from '../../../core/core.functions'

@Component({
    selector: 'app-organizer-registrations',
    templateUrl: './organizer-registrations.component.html',
})
@UntilDestroy()
export class OrganizerRegistrationsComponent extends AbstractSelectableOrganizedActivityComponent implements OnInit {
    items$ = new BehaviorSubject<RegisteredPerson[]>([])
    apiUrl = `${server.apiUrl}/events/registrations`
    columns = ['num', 'person', 'state', 'startNumber', 'created', 'amount', 'id']

    override ngOnInit() {
        super.ngOnInit()
        combineLatest(
            this.organizedActivityId$.pipe(filter(R_.isNotNil)),
            this.registrationState$,
            this.dataFileFormat$,
        )
            .pipe(
                // tap((result) => {
                //     console.log('XX', result)
                // }),
                filter((r) => r[0] != null), untilDestroyed(this)
            )
            .subscribe((r) => {
                this.exportUrl = `${server.apiUrl}/events/activities/${r[0]}/registrations.csv?registrationState=${r[1]}&dataFileFormat=${r[2]}`
                this.registrationService.getRegisteredPersons$({
                    organizedActivityId: r[0],
                    registrationState: r[1]
                }).subscribe(this.items$)
            })
    }

    sort(sort: Sort) {
        const isAsc = sort.direction === 'asc'
        this.items$.next(this.items$.value.slice().sort((a, b) => {
            switch (sort.active) {
                case 'person':
                    return compare(a.person.surname + a.person.name, b.person.surname + b.person.name, isAsc)
                case 'state':
                    return compare(a.state, b.state, isAsc)
                case 'code':
                    return compare(a.code, b.code, isAsc)
                case 'amount':
                    return compare(a.amount.value, b.amount.value, isAsc)
                case 'startNumber':
                    return compare(a.startNumber, b.startNumber, isAsc)
                case 'created':
                    return compare(a.created, b.created, isAsc)
                default:
                    return 0
            }
        }))
    }
}
