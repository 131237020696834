import { Injectable } from '@angular/core'
import { ApolloService } from 'app/core/services/apollo.service'
import { CreateOrganizer, Organizer, OrganizerMember, OrganizerRole } from 'app/organizers/organizers.models'
import { BehaviorSubject, Observable, switchMap } from 'rxjs'
import { LoginService } from 'app/users/services/login.service'
import { Address } from 'app/core/core.models'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
    ORGANIZER_MEMBERS,
    ORGANIZER,
    USER_ORGANIZER_ROLE,
    MY_ORGANIZERS,
    IS_ROOT_ORGANIZER_MEMBER, CHECK_ORGANIZER, USER_ORGANIZERS, ORGANIZERS,
} from '../organizers.queries'
import {
    ADD_ORGANIZER_MEMBER,
    CREATE_ORGANIZER,
    REMOVE_ORGANIZER_MEMBER,
    UPDATE_ORGANIZER_ADDRESS,
    UPDATE_ORGANIZER,
} from '../oranizers.mutations'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class OrganizerService {
    currentOrganizer$ = new BehaviorSubject<Organizer | null>(null)
    myOrganizers$ = new BehaviorSubject<Organizer[]>([])
    currentOrganizerId: string = null

    constructor(private apolloService: ApolloService, private loginService: LoginService, private router: Router) {
        loginService.userLoggedIn$
            .pipe(
                switchMap(() => this.getMyOrganizers$()),
                untilDestroyed(this),
            )
            .subscribe((organizers) => {
                this.myOrganizers$.next(organizers)
                if (organizers?.length && this.currentOrganizer$.value == null) {
                    //this.currentOrganizerId = localStorage.getItem('currentOrganizerId')
                    console.log('currentOrganizerId from local storage', this.currentOrganizerId)
                    let organizer = this.currentOrganizerId
                        ? organizers.find((o) => o.id == this.currentOrganizerId)
                        : organizers[0]
                    this.switchOrganizer(organizer)
                } else {
                    this.switchOrganizer(null)
                }
            })
    }

    getMyOrganizers$(limit: number = 10000) {
        return this.apolloService.watchQuery<Organizer[]>({
            fetchPolicy: 'no-cache',
            query: MY_ORGANIZERS,
            variables: {
                query: {
                    limit: limit,
                },
            },
        })
    }

    getOrganizers$(name?: string, limit: number = 10000) {
        return this.apolloService.watchQuery<Organizer[]>({
            fetchPolicy: 'no-cache',
            query: ORGANIZERS,
            variables: {
                query: {
                    name: name,
                    limit: limit,
                },
            },
        })
    }

    getUserOrganizers$(userId: string, limit: number = 10) {
        return this.apolloService.watchQuery<Organizer[]>({
            fetchPolicy: 'no-cache',
            query: USER_ORGANIZERS,
            variables: {
                query: {
                    userId: userId,
                    limit: limit,
                },
            },
        })
    }

    getOrganizer$(organizerId: string) {
        return this.apolloService.watchQuery<Organizer>({
            query: ORGANIZER,
            variables: {
                query: {
                    id: organizerId,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }
    checkOrganizer$(name?: string, orgId?: string, vatId?: string) {
        return this.apolloService.watchQuery<Organizer>({
            query: CHECK_ORGANIZER,
            variables: {
                query: {
                    name: name,
                    orgId: orgId,
                    vatId: vatId
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getOrganizerMembers$(organizerId: string) {
        return this.apolloService.watchQuery<OrganizerMember[]>({
            query: ORGANIZER_MEMBERS,
            variables: {
                query: {
                    organizerId: organizerId,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getUserOrganizerRole$(userId: string, organizerId: string) {
        return this.apolloService.watchQuery<string>({
            query: USER_ORGANIZER_ROLE,
            variables: {
                query: {
                    userId: userId,
                    organizerId: organizerId,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    isRootOrganizerMember$() {
        return this.apolloService.watchQuery<boolean>({
            query: IS_ROOT_ORGANIZER_MEMBER,
        })
    }

    switchOrganizer(organizer?: Organizer) {
        this.currentOrganizer$.next(organizer)
        this.currentOrganizerId = organizer ? organizer.id : null
        //localStorage.setItem('currentOrganizerId', this.currentOrganizerId)
    }

    createOrganizer(input: CreateOrganizer) {
        this.apolloService
            .mutate<Organizer>({
                mutation: CREATE_ORGANIZER,
                variables: {
                    command: input,
                },
            })
            .subscribe((organizer) => {
                this.myOrganizers$.next(this.myOrganizers$.value.concat(organizer))
                this.currentOrganizer$.next(organizer)
                this.router.navigate(['/', 'organizers'])
            })
    }

    updateOrganizer(organizer: Organizer): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UPDATE_ORGANIZER,
            variables: {
                command: {
                    organizerId: organizer.id,
                    name: organizer.name,
                    description: organizer.description,
                    links: organizer.links,
                    email: organizer.email,
                    phoneNumber: organizer.phoneNumber,
                    orgId: organizer.orgId,
                    vatId: organizer.vatId,
                    bankAccount: organizer.bankAccount,
                    isActive: organizer.isActive
                },
            },
        })
    }

    updateOrganizerAddress(organizerId: string, address: Address): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UPDATE_ORGANIZER_ADDRESS,
            variables: {
                command: {
                    organizerId: organizerId,
                    address: address,
                },
            },
        })
    }

    addOrganizerMember(organizerId: string, memberRef: string, role: OrganizerRole): Observable<OrganizerMember> {
        return this.apolloService.mutate<OrganizerMember>({
            mutation: ADD_ORGANIZER_MEMBER,
            variables: {
                command: {
                    organizerId: organizerId,
                    memberRef: memberRef,
                    role: role,
                },
            },
        })
    }

    removeOrganizerMember(organizerId: string, memberId: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: REMOVE_ORGANIZER_MEMBER,
            variables: {
                command: {
                    organizerId: organizerId,
                    memberId: memberId,
                },
            },
        })
    }
}
