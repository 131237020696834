<div fxLayout="row" class="message">
    <div class="me-2 message-avatar avatar-40" fxShow.xs="false" *ngIf="isShowingPerson">
        <img
            [src]="apiUrl + '/users/' + message.sender.id + '.pic?time=' + time"
            class="img-circle"
            style="width: 40px; height: 40px"
            [attr.alt]="message.sender.givenName + ' ' + message.sender.surname"
        />
    </div>
    <div fxFlex="grow">
        <!-- person -->
        <div fxLayout.xs="column" fxLayout.gt-xs="row" *ngIf="isShowingPerson">
            <div fxFlex="grow" fxLayout="row">
                <div class="me-2 message-avatar avatar-40" fxShow.gt-xs="false">
                    <img
                        [src]="apiUrl + '/users/' + message.sender.id + '.pic?time=' + time"
                        class="img-circle"
                        style="width: 40px; height: 40px"
                        [attr.alt]="message.sender.givenName + ' ' + message.sender.surname"
                    />
                </div>
                <div fxFlex="grow" fxLayout="column">
                    <h3 class="message-user h6 mb-0">
                        <a [routerLink]="['/', message.sender.nickname ? message.sender.nickname : message.sender.id]">{{message.sender.name}}</a>
                    </h3>
                    <div class="message-info text-muted">
                        <ng-container *ngIf="message.sender.nickname">
                            @{{message.sender.nickname}}
                        </ng-container>
                    </div>
                    <div class="message-date mt-0">{{message.created | localizeDate: 'short'}}</div>
                </div>
            </div>
        </div>
        <div fxLayout="column">
            <!-- message -->
            <div fxLayout="column">
                <div fxFlex="grow" style="overflow: scroll">
                    <span class="message-text" [innerHTML]="message.text | marked"></span>
                </div>
                <!-- message actions -->
                <div *ngIf="!isShowingInput" class="message-buttons">
                    <button
                        mat-button
                        (click)="showCommentInput()"
                    >
                        <mat-icon>reply</mat-icon>
                        {{'reply' | translate}}
                    </button>
                    <button
                        mat-button
                        (click)="toggleChildren()"
                        [class.opacity-50]="!message.childCount"
                    >
                        <mat-icon>comment</mat-icon>
                        {{message.childCount}} {{'comments' | translate | lowercase}}
                    </button>
                    <button
                        mat-button
                        (click)="toggleLike()"
                        [disabled]="isTogglingLike"
                        [class.opacity-50]="!message.likeCount"
                    >
                        <mat-icon>thumb_up</mat-icon>
                        {{message.likeCount}} {{'likes' | translate | lowercase}}
                    </button>
                </div>
            </div>
            <div *ngIf="isShowingInput" fxFlex="100" class="text-right">
                <!-- text input -->
                <div fxLayout="row" class="message">
                    <mat-form-field class="mr-1 ml-1" fxFlex>
                        <textarea
                            matInput
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5"
                            [placeholder]="'comment' | translate"
                            [(ngModel)]="text"
                            [mentionConfig]="(loginService.mentionConfig$ | async) || { mentions: [] }"
                        ></textarea>
                    </mat-form-field>
                    <button mat-button color="accent" (click)="createChild()" [disabled]="!text">
                        <mat-icon>send</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="isShowingChildren">
            <app-message
                *ngFor="let comment of this.children$ | async"
                [message]="comment"
                [user]="user"
                class="mt-2"
            ></app-message>
        </ng-container>
    </div>
</div>
