import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'
import { RouterModule } from '@angular/router'
import { MatIconModule } from '@angular/material/icon'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatListModule } from '@angular/material/list'
import { MatBadgeModule } from '@angular/material/badge'
import { MatCardModule } from '@angular/material/card'
import { MatMenuModule } from '@angular/material/menu'
import { GoogleMapsModule } from '@angular/google-maps'
import { FullLayoutComponent } from 'app/layouts/full/full-layout.component'
import { VerticalAppHeaderComponent } from 'app/layouts/full/vertical-header/vertical-header.component'
import { SpinnerComponent } from 'app/layouts/shared/spinner.component'
import { BlankLayoutComponent } from 'app/layouts/blank/blank.component'
import { VerticalAppSidebarComponent } from 'app/layouts/full/vertical-sidebar/vertical-sidebar.component'
import { AppBreadcrumbComponent } from 'app/layouts/full/breadcrumb/breadcrumb.component'
import { HorizontalAppHeaderComponent } from 'app/layouts/full/horizontal-header/horizontal-header.component'
import { HorizontalAppSidebarComponent } from 'app/layouts/full/horizontal-sidebar/horizontal-sidebar.component'
import { SharedModule } from 'app/layouts/shared/shared.module'
import { CoreModule } from 'app/core/core.module'
import { TranslateModule } from '@ngx-translate/core'
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { FooterComponent } from 'app/layouts/full/footer/footer.component'
import { UserMenuComponent } from 'app/layouts/full/user-menu/user-menu.component'
import { PipesModule } from 'app/core/pipes/pipes.module'
import { LoaderModule } from 'app/core/components/loader/loader.module'
import { MatDividerModule } from '@angular/material/divider'
import { OrganizerMenuComponent } from 'app/layouts/full/organizer-menu/organizer-menu.component'
import { OrganizedActivitiesSearchModule } from 'app/events/components/organized-activities-search/organized-activities-search.module'
import { SearchComponent } from 'app/layouts/full/search/search.component'
import { DirectivesModule } from 'app/core/directives/directives.module'

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        MatSidenavModule,
        MatToolbarModule,
        RouterModule,
        MatIconModule,
        FlexLayoutModule,
        MatButtonModule,
        MatListModule,
        MatBadgeModule,
        MatCardModule,
        MatMenuModule,
        GoogleMapsModule,
        SharedModule,
        TranslateModule,
        PerfectScrollbarModule,
        PipesModule,
        LoaderModule,
        MatDividerModule,
        OrganizedActivitiesSearchModule,
        DirectivesModule,
    ],
    declarations: [
        FullLayoutComponent,
        VerticalAppHeaderComponent,
        BlankLayoutComponent,
        VerticalAppSidebarComponent,
        AppBreadcrumbComponent,
        HorizontalAppHeaderComponent,
        HorizontalAppSidebarComponent,
        FooterComponent,
        UserMenuComponent,
        SpinnerComponent,
        OrganizerMenuComponent,
        SearchComponent,
    ],
})
export class LayoutsModule {}
