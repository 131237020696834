import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'app/core/core.module';
import {PaymentResultComponent} from "./components/payment-result/payment-result.component"
import {MatCardModule} from "@angular/material/card"
import {PaymentsRoutingModule} from "./payments-routing.module"
import {LoaderModule} from "../core/components/loader/loader.module"
import { MaterialModule } from "../layouts/material.module"
import { TranslateModule } from "@ngx-translate/core"

@NgModule({
    declarations: [
        PaymentResultComponent,

    ],
    imports: [
        CommonModule,
        CoreModule,
        MatCardModule,
        PaymentsRoutingModule,
        LoaderModule,
        MaterialModule,
        TranslateModule,
    ],
    providers: [],
})
export class PaymentsModule {}
