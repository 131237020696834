<h1>{{'activity_copy'|translate}}</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>{{'Date'|translate}}</mat-label>
        <input
            matInput
            [matDatepicker]="startDate"
            [placeholder]='"Date" | translate'
            [(ngModel)]="data.startDate"
        />
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate [disabled]="false"></mat-datepicker>
    </mat-form-field>

        <mat-label>{{'startTime'|translate}}</mat-label>
        <ngx-mat-timepicker-field
            class="d-block"
            color="primary"
            [format]="24"
            [defaultTime]="'12:00'"
            [(ngModel)]="data.startTime"
        ></ngx-mat-timepicker-field>

</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data" tabindex="4">{{'copy'|translate}}</button>
    <button mat-button (click)="onNoClick()" tabindex="-1">{{'cancel'|translate}}</button>
</div>
