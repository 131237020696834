import { Injectable } from '@angular/core'
import { ActivityType, Position } from 'app/core/core.models'
import { TrackerState } from '../users.models'
import { interval, takeWhile } from 'rxjs'
import { Activity, Track, TrackSegment } from 'app/events/events.models'
import { registerPlugin, Capacitor } from '@capacitor/core'
import { BackgroundGeolocationPlugin } from '@capacitor-community/background-geolocation'
import { LocationService } from 'app/core/services/location.service'
import { distance } from 'app/core/utils/location-helpers'
import { TranslateService } from '@ngx-translate/core'
import { UserService } from './user.service'

// see https://github.com/capacitor-community/background-geolocation
const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>('BackgroundGeolocation')
const movingAveragePointCount = 3
const refreshPointCount = 10
const updatePointCount = 15

@Injectable({
    providedIn: 'root',
})
export class UserTrackerService {

    isLive: boolean = false
    elapsedTime: number = 0
    movingTime: number = 0
    started: Date
    lastTime: number
    watcherId: string
    userActivityId: string = null
    state: TrackerState = TrackerState.Stopped
    segment: TrackSegment = {
        points: []
    }
    activity: Activity = {
        type: ActivityType.Run,
        name: this.translateService.instant('tracker'),
        distance: 0,
        track: {
            segments: [ this.segment ]
        }
    }
    isWeb = Capacitor.getPlatform() == 'web'

    constructor(
        private locationService: LocationService,
        private translateService: TranslateService,
        private userService: UserService,
    ) {
    }

    get isRunning() {
        return this.state == TrackerState.Running
    }

    get isPaused() {
        return this.state == TrackerState.Paused
    }

    get isStopped() {
        return this.state == TrackerState.Stopped
    }

    get timeAsString() {
        const es = this.elapsedTime / 1000
        const hh = Math.floor(es / 3600)
        const hs = es % 3600
        const mm = Math.floor(hs / 60)
        const ss = Math.floor(hs % 60)
        return `${hh.toString()}:${mm.toString().padStart(2, '0')}:${ss.toString().padStart(2, '0')}`
    }

    /**
     * Perform create mutation and optionally reset tracker.
     * @param reset
     */
    create(reset: boolean) {
        this.userService.createUserActivity$(
            this.started,
            this.activity,
            this.elapsedTime,
            this.movingTime,
            this.isLive
        ).subscribe((userActivityId) => {
            console.log('User activity created, isLive =', this.isLive, ', id =', userActivityId)
            this.userActivityId = userActivityId
            if (reset)
                this.reset()
        })
    }

    refresh() {
        this.activity = { ...this.activity }
        console.log('User activity refreshed')
    }

    /**
     * Update activity object and perform update mutation if tracking is live.
     */
    update() {
        this.refresh()
        if (this.isLive) {
            if (this.userActivityId) {
                this.userService.updateUserActivity$(
                    this.userActivityId,
                    this.elapsedTime,
                    this.movingTime,
                    this.activity.distance,
                    this.isLive,
                    this.segment.points.slice(-updatePointCount)
                ).subscribe((result) => {
                    console.log('User activity updated, isLive =', this.isLive, ', id =', this.userActivityId, ', result =', result)
                })
            } else {
                console.warn('User activity cannot be updated, missing id')
            }
        }
    }

    /**
     * Reset tracker.
     */
    reset() {
        this.elapsedTime = 0
        this.movingTime = 0
        this.started = new Date()
        this.segment.points = []
        this.activity.distance = 0
        this.userActivityId = null
        this.refresh()
    }

    private process() {
        if (this.segment.points.length == 1) {
            this.refresh()
            if (this.isLive)
                this.create(false)
        }
        if (this.segment.points.length % refreshPointCount == 0) {
            this.refresh()
        }
        if (this.segment.points.length % updatePointCount == 0) {
            this.update()
        }
    }
    /**
     * Start/unpause tracking.
     */
    start() {
        this.lastTime = Date.now()
        if (this.isWeb) {
            // web app (which should not be used for tracking) - we put only current position to track
            this.locationService.currentPosition.then((position) => {
                if (position) {
                    this.segment.points.push({
                        latitude: position.latitude,
                        longitude: position.longitude,
                        time: new Date()
                    })
                    this.process()
                }
            })
        }

        if (this.state == TrackerState.Stopped) {
            this.state = TrackerState.Running
            this.reset()
            if (!this.isWeb) {
                // native app geolocation watcher
                BackgroundGeolocation.addWatcher({
                    backgroundMessage: this.translateService.instant('Tracker.backgroundMessage'),
                    backgroundTitle: this.translateService.instant('Tracker.backgroundTitle'),
                    requestPermissions: true,
                    stale: false,
                    distanceFilter: 1
                }, (location, error) => {
                    if (error) {
                        if (error.code === "NOT_AUTHORIZED") {
                            if (window.confirm(this.translateService.instant('Tracker.notAuthorized'))) {
                                BackgroundGeolocation.openSettings()
                            }
                        }
                        return console.error(error)
                    }
                    if (location && this.state == TrackerState.Running) {

                        // add location as new track point
                        this.segment.points.push({
                            latitude: location.latitude,
                            longitude: location.longitude,
                            altitude: location.altitude,
                            time: new Date(),
                        })

                        // update distance
                        if (this.segment.points.length > movingAveragePointCount) {
                            let ps1 = this.segment.points.slice(-movingAveragePointCount - 1, -1)
                            let ps2 = this.segment.points.slice(-movingAveragePointCount)
                            let ap1: Position = {
                                latitude: ps1.reduce((sum, p) => sum + p.latitude, 0) / movingAveragePointCount,
                                longitude: ps1.reduce((sum, p) => sum + p.longitude, 0) / movingAveragePointCount,
                            }
                            let ap2: Position = {
                                latitude: ps2.reduce((sum, p) => sum + p.latitude, 0) / movingAveragePointCount,
                                longitude: ps2.reduce((sum, p) => sum + p.longitude, 0) / movingAveragePointCount,
                            }
                            let rd = distance(ap1, ap2)
                            console.log('Distance update', ap1, ap2, rd)
                            this.activity.distance += rd
                        }

                        this.process()
                    }
                    return console.log('Background geolocation', location)
                }).then((watcherId) => {
                    this.watcherId = watcherId
                    console.log('Background geolocation watcher created', watcherId)
                })
            }

            interval(100)
                .pipe(takeWhile(() => !this.isStopped))
                .subscribe(() => {
                    if (this.isRunning) {
                        let currentTime = Date.now()
                        this.elapsedTime += currentTime - this.lastTime
                        this.movingTime += currentTime - this.lastTime
                        this.lastTime = currentTime
                    }
                })
        } else {
            this.state = TrackerState.Running
        }
    }

    /**
     * Stop tracking.
     */
    stop() {
        this.state = TrackerState.Stopped
        if (this.watcherId) {
            console.log('Removing background geolocation watcher')
            BackgroundGeolocation.removeWatcher({
                id: this.watcherId
            }).then(() => {
                console.log('Background geolocation watcher removed')
            })
        }
        this.update()
        this.isLive = false
    }

    /**
     * Pause tracking.
     */
    pause() {
        this.state = TrackerState.Paused
    }
}
