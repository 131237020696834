import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import { AbstractFormComponent } from 'app/core/components/abstract.form.component'
import { Form, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators'
import { EnumService } from 'app/core/services/enum.service'
import { findIndexOfId, localizeArrayForm, localizeArrayFormHint } from 'app/core/utils'
import dayjs from 'dayjs'
import {
    CreateEventForm,
    CreateOrganizedActivityInput,
    OrganizedActivity,
    OrganizedEvent,
    TrackInput,
    UpdateOrganizedActivityInput,
} from 'app/events/events.models'
import { FileUploader } from 'lib/file-upload/file-uploader.class'
import { OrganizedEventServiceState } from 'app/events/components/organized-event/organized-event-service.state'
import * as R from 'ramda'
import * as R_ from 'ramda-extension'
import { OrganizedEventActivityPriceListComponent } from 'app/events/components/organized-event-activity-price-list/organized-event-activity-price-list.component'
import { OrganizedEventActivityMerchandisePriceListComponent } from 'app/events/components/organized-event-activity-merchandise-price-list/organized-event-activity-merchandise-price-list.component'
import { MatTableDataSource } from '@angular/material/table'
import { WindowService } from 'app/core/services/window.service'
import { saveAs } from 'file-saver'
import { GeocoderResponse, MediaType, Nullish, Position } from 'app/core/core.models'
import { FileItem } from 'lib/file-upload/file-item.class'
import { Globals, Terms, TermsSummary } from 'app/app.consts'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'
import { OrganizedActivityService } from 'app/events/services/organized-activity.service'
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, switchMap } from 'rxjs'
import { LanguageCode } from 'app/core/constants/languages'
import { LanguageService } from 'app/core/services/language.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { DateAdapter } from '@angular/material/core'
import { Router } from '@angular/router'
import { CustomValidators } from 'app/core/validators/custom-validators.fnc'
import { Auth0SpaService } from 'app/users/services/auth0-spa.service'
import { LinkListComponent } from 'app/core/components/link-list/link-list.component'
import { server } from 'app/core/core.server'
import { MatDialog } from '@angular/material/dialog'
import { OrganizedActivityCopyComponent } from '../organized-activity-copy/organized-activity-copy.component'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { filter, map } from 'rxjs/operators'
import { GeocodingService } from '../../../core/services/geocoding.service'
import { UserFeedbackComponent } from '../../../users/components/user-feedback/user-feedback.component'
import {
    OrganizedActivityOpenModalComponent
} from '../organized-activity-open-modal/organized-activity-open-modal.component'

@UntilDestroy()
@Component({
    selector: 'app-organized-event-activity',
    templateUrl: './organized-event-activity.component.html',
    styleUrls: ['./organized-event-activity.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizedEventActivityComponent extends AbstractFormComponent implements OnInit {
    @Input()
    organizedActivity: Nullish<OrganizedActivity> = null
    @Input()
    organizedEvent: Nullish<OrganizedEvent> = null
    @ViewChild(OrganizedEventActivityPriceListComponent)
    priceListFormContainer!: OrganizedEventActivityPriceListComponent
    @ViewChild(OrganizedEventActivityMerchandisePriceListComponent)
    merchandisePriceListFormContainer!: OrganizedEventActivityMerchandisePriceListComponent
    @ViewChild(LinkListComponent)
    linkListFormContainer!: LinkListComponent
    @Output()
    finishAction: EventEmitter<any> = new EventEmitter<any>()
    readonly apiUrl = server.apiUrl
    readonly MediaType = MediaType
    minDate = new Date()
    activityTypes$ = this.enumService.activityTypes$
    activityLabels$ = this.enumService.activityLabels$
    workflowStates$ = this.enumService.workflowStates$

    readonly uploadingTrack$ = new BehaviorSubject<boolean>(false)
    readonly languageCode = LanguageCode.CS
    readonly form = new FormGroup({
        id: new FormControl(null),
        name: this.createLocalizeFormArray(),
        description: this.createLocalizeFormArray(false),
        externalId: new FormControl(),
        favoriteMediaRef: new FormControl(),
        labels: new FormControl([], Validators.required),
        position: new FormControl(null, Validators.required),
        place: this.createLocalizeFormArray(),
        startDate: new FormControl(null, [
            Validators.required,
            // RxwebValidators.minDate({ value: dayjs().toISOString() }),
        ]),
        startTime: new FormControl('12:00', Validators.required),
        timeLimit: new FormControl(null, [CustomValidators.isNumber(2)]),
        activity: new FormGroup({
            distance: new FormControl(null, [
                Validators.required,
                RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true }),
            ]),
            name: new FormControl(''),
            type: new FormControl(null, Validators.required),
            track: new FormControl(null),
        }),
        state: new FormControl(null, Validators.required),
        links: new FormArray([]),
        priceList: new FormArray([]),
        merchandisePriceList: new FormArray([]),
        address: new FormControl(null),
    })
    readonly addressForm = new FormGroup({
        address: new FormControl(null),
    })
    readonly initFormValue = this.form.getRawValue()
    readonly uploader = new FileUploader({
        url: `${server.apiUrl}/gpx2track`,
        method: 'POST',
        disableMultipart: true,
    })
    locationOptions$: Observable<google.maps.GeocoderResult[]>

    get formStartDate() {
        return this.form.controls['startDate']
    }

    get isInThePast() {
        return dayjs(this.formStartDate.value).isBefore(dayjs(new Date()))
    }

    get formActivity() {
        return this.form.controls['activity']
    }

    get formControlName() {
        return localizeArrayForm(this.form.controls['name'], this.windowService.currentLanguageCode)
    }

    get formControlNamePlaceholder() {
        return localizeArrayFormHint(this.form.controls['name'], this.windowService.currentLanguageCode)
    }

    get formControlDescription() {
        return localizeArrayForm(this.form.controls['description'], this.windowService.currentLanguageCode)
    }

    get formControlPlace() {
        return localizeArrayForm(this.form.controls['place'], this.windowService.currentLanguageCode)
    }

    get formControlDistancePlaceholder() {
        return localizeArrayFormHint(this.form.controls?.['place'], this.windowService.currentLanguageCode)
    }
    get stringifyOrganizedActivity() {
        let organizedActivity = R.clone(this.organizedActivity)
        organizedActivity.organizedEvent = null
        if (organizedActivity.activity.track)
            organizedActivity.activity.track.segments.forEach((segment) => {
                segment.points = '__REMOVED__'
                if (segment.profile) segment.profile.points = '__REMOVED__'
            })
        return JSON.stringify(organizedActivity, null, 2)
    }

    constructor(
        private readonly authService: Auth0SpaService,
        private readonly cd: ChangeDetectorRef,
        private readonly router: Router,
        private dialog: MatDialog,
        private geocodingService: GeocodingService,
        private readonly enumService: EnumService,
        public readonly organizedActivityService: OrganizedActivityService,
        public readonly organizedEventServiceState: OrganizedEventServiceState,
        private readonly snackBar: MatSnackBar,
        private readonly translateService: TranslateService,
        public override readonly windowService: WindowService,
        @Inject(LOCALE_ID) public _locale: string,
        _adapter: DateAdapter<any>,
        languageService: LanguageService,
    ) {
        super(windowService, languageService, _adapter)
    }

    ngOnInit(): void {
        this.organizedEventServiceState.loading$.pipe(untilDestroyed(this)).subscribe((loading: boolean) => {
            this.form.controls['startTime'].enable()
        })

        this.uploader.onAfterAddingFile = (fileItem) => {
            this.authService.getAccessToken$().subscribe((token) => {
                const fileItem = this.uploader?.queue?.[0]
                this.uploader.options.headers = [
                    {
                        name: 'Authorization',
                        value: 'Bearer ' + token,
                    },
                ]
                this.form.disable()
                this.uploadingTrack$.next(true)
                fileItem.upload()
            })
        }

        this.uploader.onSuccessItem = (fileItem, trackString) => {
            this.uploader.clearQueue()
            const track: TrackInput = JSON.parse(trackString)
            track.segments = R.map(({ points }) => {
                return { points }
            })(track.segments)
            delete track['description']
            this.form.get('activity').get('track').setValue(track)
            this.uploadingTrack$.next(false)
            this.form.enable()
            this.cd.detectChanges()
        }

        this.uploader.onErrorItem = (fileItem: FileItem, response, status) => {
            this.uploader.clearQueue()
            this.uploadingTrack$.next(false)
            this.form.enable()
            this.snackBar.open(response || this.translateService.instant('globalError'), null, {
                duration: Globals.durationSnackBarMessage,
                panelClass: 'snack-bar-error',
            })
        }

        this.uploader.onWhenAddingFileFailed = (item, filter) => {
            this.uploader.clearQueue()
            switch (filter.name) {
                case 'fileType':
                    this.snackBar.open(this.translateService.instant('badType'), null, {
                        duration: Globals.durationSnackBarMessage,
                        panelClass: 'snack-bar-error',
                    })
            }
        }

        if (this.organizedActivity) {
            const organizedActivity = {
                ...this.organizedActivity,
                startTime:
                    dayjs(this.organizedActivity?.startTime).hour() +
                    ':' +
                    dayjs(this.organizedActivity?.startTime).minute(),
                startDate: dayjs(this.organizedActivity?.startTime).toDate(),
                // startDate: this.organizedActivity?.startTime,
                timeLimit: R.isNil(this.organizedActivity?.timeLimit) ? null : this.organizedActivity?.timeLimit / 60,
            }
            this.prefillFormByKeys(organizedActivity, this.windowService.currentLanguageCode, ['activity', 'priceList', 'merchandisePriceList', 'links'])
            this.prefillFormByKeys(
                this.organizedActivity?.activity,
                this.windowService.currentLanguageCode,
                [],
                this.form.get('activity'),
            )
            setTimeout(() => {
                ;(<FormArray>this.form.get('priceList')).clear()
                ;(<FormArray>this.form.get('merchandisePriceList')).clear()
                organizedActivity.priceList.forEach(({ amount, validTo, validUntilRegistrationCount }) => {
                    const formArray = <FormArray>this.form.get('priceList')
                    formArray.push(
                        this.priceListFormContainer.create(amount, validTo, validUntilRegistrationCount),
                    )
                })
                organizedActivity.merchandisePriceList.forEach((price) => {
                    const formArray = <FormArray>this.form.get('merchandisePriceList')
                    formArray.push(this.merchandisePriceListFormContainer.create(price.sku, price.name, price.description, price.amount, price.validTo))
                })
                ;(<FormArray>this.form.get('links')).clear()
                organizedActivity.links.forEach(({ type, ref }) => {
                    const formArray =  <FormArray>this.form.get('links')
                    formArray.push(this.linkListFormContainer.createItem(type, ref))
                })

                if (this.priceListFormContainer) {
                    this.priceListFormContainer.setDataSource()
                }
                if (this.merchandisePriceListFormContainer) {
                    this.merchandisePriceListFormContainer.setDataSource()
                }
                if (this.linkListFormContainer) {
                    this.linkListFormContainer.setDataSource()
                }
                this.cd.detectChanges()
            })
            this.getAddress(`${organizedActivity.position.latitude},${organizedActivity.position.longitude}`).subscribe((address) => {
                this.addressForm.controls['address'].setValue(address, { emitEvent: false })
                this.form.controls['address'].setValue(address.formatted_address)
            })
        }
        if (this.organizedEvent) {
            this.prefillFormByKeys(
                {
                    name: this.organizedEvent.name,
                    labels: this.organizedEvent.labels
                },
                this.windowService.currentLanguageCode,
                [
                    'activity',
                    'priceList',
                    'merchandisePriceList',
                    'links'
                ]
            )
        }
        this.form.controls['position'].valueChanges
            .pipe(
                distinctUntilChanged(),
                switchMap(value => this.getAddress(`${value.latitude},${value.longitude}`)),
                untilDestroyed(this),
            )
            .subscribe((address) => {
                this.addressForm.controls['address'].setValue(address, { emitEvent: false })
                this.form.controls['address'].setValue(address.formatted_address)
            })
        this.locationOptions$ = this.addressForm.controls['address'].valueChanges.pipe(
            // startWith(''),
            filter(R_.isNotEmpty),
            debounceTime(Globals.valueChangeDebounce),
            distinctUntilChanged(),
            switchMap((val) => {
                return this.getLocation(val || '')
            }),
            untilDestroyed(this),
        )
    }

    delete() {
        if (confirm(this.translateService.instant('confirm_delete') + '?')) {
            console.log('Deleting organized activity', this.organizedActivity.id)
            this.organizedActivityService
                .deleteOrganizedActivity$(this.organizedActivity.id)
                .subscribe((result) => {
                    console.log('Organized activity deleted', result)
                    this.router.navigate([
                        '/',
                        'events',
                        //this.organizedEventServiceState.organizedEventId,
                        //'settings',
                    ])
                })
        }
    }

    private processFormArray(name: string): FormArray {
        const formArray = <FormArray>this.form.get(name)
        const values = (<FormArray>this.form.get(name)).getRawValue()
        const emptyValues = R.pipe(R.filter(this.isFull))(values)
        const idsToRemove = R.map(R.prop('id'))(emptyValues)
        idsToRemove.forEach((index) => {
            if (formArray.controls.length > 1) {
                const values = (<FormArray>this.form.get(name)).getRawValue()
                const indexToRemove = findIndexOfId(index, values)
                formArray.removeAt(indexToRemove)
            }
        })
        return formArray
    }

    override submitForm(appendData: any = undefined): void {
        this.priceListFormContainer.dataSource = new MatTableDataSource(this.processFormArray('priceList').controls)
        this.merchandisePriceListFormContainer.dataSource = new MatTableDataSource(this.processFormArray('merchandisePriceList').controls)
        super.submitForm(appendData)
    }

    onSubmitValidForm(values: CreateEventForm) {
        values.timeLimit = R.isNil(values.timeLimit)
            ? null
            : parseFloat(<any>values.timeLimit.toString().replace(',', '.')) * 60
        const [hours, minutes] = values.startTime.split(':')
        const startTime = dayjs(values.startDate)
            .set('hours', Number(hours))
            .set('minutes', Number(minutes))
            .toISOString()
        delete values['startDate']
        delete values['address']
        delete values['registrationLimit']
        values['priceList'].forEach((price) => {
            delete price['id']
        })
        values['merchandisePriceList'].forEach((price) => {
            delete price['id']
        })
        const organizedEventId = this.organizedEventServiceState.organizedEventId
        const inputData: CreateOrganizedActivityInput & UpdateOrganizedActivityInput = {
            ...values,
            startTime,
            organizedEventId,
        } as any

        const isUpdate = !!this.form.get('id').value
        inputData['priceList'] = R.map((price) => {
            return {
                ...price,
                amount: {
                    ...price.amount,
                    value: price.amount.value * 100,
                },
                validTo: dayjs(price.validTo).endOf('day').toDate(),
            }
        })(inputData['priceList'])
        inputData['merchandisePriceList'] = R.map((price) => {
            return {
                sku: price.sku,
                name: { language: "cs", text: price.name }, //FIXME should work with localized text
                description: { language: "cs", text: price.description }, //FIXME should work with localized text
                amount: {
                    ...price.amount,
                    value: price.amount.value * 100,
                },
                validTo: dayjs(price.validTo).endOf('day').toDate(),
            }
        })(inputData['merchandisePriceList'])
        if (isUpdate) {
            delete inputData['organizedEventId']
            delete inputData['externalId']
            delete inputData['position']?.['__typename']
            delete inputData['activity']?.['track']?.['distance']
            delete inputData['activity']?.['track']?.['description']
            delete inputData['activity']?.['track']?.['__typename']
            delete inputData['activity']?.['track']?.['segments']?.['__typename']
            delete inputData['activity']?.['track']?.['segments']?.[0]?.['__typename']
            if (inputData['activity']?.['track']?.['segments']?.[0]?.['points']) {
                R.forEach((points) => {
                    delete points['__typename']
                })(inputData['activity']?.['track']?.['segments']?.[0]?.['points'])
            }
            delete inputData['activity']?.['track']?.['segments']?.[0]?.['profile']
        } else {
            delete inputData['id']
        }

        const mutation = isUpdate
            ? this.organizedActivityService.updateOrganizedActivity$(inputData)
            : this.organizedActivityService.createOrganizedActivity$(inputData)

        mutation.subscribe(() => {
            this.organizedEventServiceState.loadOrganizedEvent(this.organizedEventServiceState.organizedEventId)
            this.organizedEventServiceState.organizedEvent$.pipe(untilDestroyed(this)).subscribe(() => {
                setTimeout(() => {
                    if (!isUpdate) {
                        this.finishAction.emit()
                    }
                    this.windowService.scrollTop()
                }, 100)
            })
        })
    }

    copy(): void {
        const values = this.removeLocalizedTextAfterSubmit(this.form.getRawValue())
        const dialogRef = this.dialog.open(OrganizedActivityCopyComponent, {
            width: '275px',
            data: {
                startDate: values.startDate,
                startTime: values.startTime,
            },
        })
        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed', result)
            if (typeof result == 'object') {
                const organizedActivityId = this.form.get('id').value
                const [hours, minutes] = result.startTime.split(':')
                const startTime = dayjs(result.startDate)
                    .set('hours', Number(hours))
                    .set('minutes', Number(minutes))
                    .toISOString()
                console.log('Copying organized activity', organizedActivityId, startTime)
                this.organizedActivityService.copyOrganizedActivity$(organizedActivityId, startTime).subscribe((id) => {
                    console.log('Organized activity copied, id = ', id)
                    window.location.reload()
                })
            }
        })
    }

    open() {
        const dialogRef = this.dialog.open(OrganizedActivityOpenModalComponent, {
            data: {
                organizedActivity: this.organizedActivity,
                terms: Terms[this.languageCode] ? Terms[this.languageCode] : Terms['cs'],
                summary: TermsSummary[this.languageCode] ? TermsSummary[this.languageCode] : TermsSummary['cs'],
            }
        })
        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.organizedActivityService.openOrganizedActivity$(this.organizedActivity.id, 'terms', 'summary').subscribe((result) => {
                    console.log('Organized activity open result =', result)
                    this.cd.markForCheck()
                    this.organizedActivity.isOpen = result
                })
            }
        })
    }

    downloadGpx(): void {
        this.organizedActivityService.downloadGpx$(this.form.get('id').value).subscribe((file: File) => {
            saveAs(file, 'track.gpx')
        })
    }

    isFull(data): boolean {
        return data.amount === null && data.validTo === null && data.validUntilRegistrationCount === null
    }

    updatePosition(position: Position) {
        this.form.controls['position'].setValue(position)
    }

    addressSelected(selection: MatAutocompleteSelectedEvent) {
        if (selection.option.value.geometry) {
            this.form.controls['position'].setValue(
                {
                    latitude: selection.option.value.geometry.location.lat,
                    longitude: selection.option.value.geometry.location.lng,
                },
                { emitEvent: false },
            )
            this.form.controls['address'].setValue(selection.option.value.formatted_address)
        }
    }

    displayAddressFn(options: google.maps.GeocoderResult) {
        return options?.formatted_address
    }

    getAddress(val: string): Observable<google.maps.GeocoderResult> {
        console.log('Getting address for', val)
        return this.geocodingService.getAddress(val).pipe(
            filter(R_.isNotNil),
            map((response: GeocoderResponse) => response?.results[0]),
        )
    }

    getLocation(val: string): Observable<google.maps.GeocoderResult[]> {
        console.log('getLocation', val)
        return this.geocodingService.getLocation(val).pipe(
            filter(R_.isNotNil),
            map((response: GeocoderResponse) => response?.results),
        )
    }
}
