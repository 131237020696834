import { Component, OnInit } from "@angular/core";
import { OrganizerService } from 'app/organizers/services/organizer.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CreateOrganizer } from 'app/organizers/organizers.models'
import { countries } from 'app/core/constants/countries'
import { OrganizerWelcome, Terms } from 'app/app.consts'
import { WindowService } from 'app/core/services/window.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'app-organizer-create',
    templateUrl: './organizer-create.component.html',
})
export class OrganizerCreateComponent implements OnInit {
    formGroup: FormGroup
    countries = countries
    text = ''
    terms = ''

    constructor(
        private organizerService: OrganizerService,
        private translateService: TranslateService,
        private windowService: WindowService,
        private formBuilder: FormBuilder
    ) {
        this.formGroup = this.formBuilder.group({
            orgId: '',
            vatId: '',
            name: [null, [Validators.required]],
            street: [null, [Validators.required]],
            city: [null, [Validators.required]],
            state: '',
            country: [null, [Validators.required]],
            postalCode: [null, [Validators.required]],
        })
    }

    ngOnInit() {
        this.windowService.selectedLanguage$.subscribe((language) => {
            this.text = OrganizerWelcome[language.code] ? OrganizerWelcome[language.code] : OrganizerWelcome['cs']
        })
    }

    submitForm(input: CreateOrganizer): void {
        this.organizerService.checkOrganizer$(input.name, input.orgId, input.vatId).subscribe((organizerExists) => {
            if (organizerExists) {
                alert(this.translateService.instant('organizer_exists'))
            } else {
                this.organizerService.createOrganizer(input)
            }
        })
    }
}
