import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { debounceTime, fromEvent } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter } from 'rxjs/operators'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { GetOrganizedActivities } from 'app/events/events.models'
import { OrganizedActivitiesStateService } from 'app/events/components/organized-activities/organized-activities-state.service'
import { LayoutService } from 'app/layouts/services/layout.service'

@UntilDestroy()
@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['search.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
    private readonly SEARCH_ROUTES = ['/events/activities/cards', '/events/activities/map', '/events/activities/list']
    @Input()
    filters: any

    @Output()
    searchActive = new EventEmitter<boolean>()

    _visibleSearch = false
    searchValues: GetOrganizedActivities = null
    searchValuesInit = false
    searchValuesFirstInit = false

    resizeEvent$ = fromEvent(window, 'resize').pipe(debounceTime(200))

    @ViewChild('searchForm', { static: true })
    searchForm: ElementRef

    get visibleSearch() {
        return this._visibleSearch
    }

    set visibleSearch(value: boolean) {
        window.setTimeout(
            () => {
                const height = value ? this.getSearchHeight() : 0
                this.setSearchHeight(height)
            },
            value ? 200 : 0,
        )
        this._visibleSearch = value

        this.layoutService.headerSearchFirstInit =
            this._visibleSearch === true || this.layoutService.headerSearchFirstInit
        this.searchValuesFirstInit = this.layoutService.headerSearchFirstInit
        this.searchActive.emit(this._visibleSearch)
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject(DOCUMENT) document: Document,
        public readonly organizedActivitiesStateService: OrganizedActivitiesStateService,
        private cd: ChangeDetectorRef,
        private layoutService: LayoutService,
    ) {
        this.searchValues = null
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setSearchHeight(0)
                if (this.SEARCH_ROUTES.indexOf(this.router.url.split('?')[0]) === -1) {
                    this.visibleSearch = false
                    this.cd.markForCheck()
                }
            }
        })
        this.resizeEvent$
            .pipe(
                filter(() => this.visibleSearch),
                untilDestroyed(this),
            )
            .subscribe((_) => {
                const height = this.getSearchHeight()
                this.setSearchHeight(height)
            })
    }

    ngOnInit() {
        this.searchValuesInit = true
        this.searchValuesFirstInit = this.layoutService.headerSearchFirstInit
    }

    getSearchHeight(): number {
        const { height } = this.searchForm.nativeElement.getBoundingClientRect()
        return height
    }

    setSearchHeight(height: number) {
        document.documentElement.style.setProperty('--searchHeight', height + 'px')
    }

    actualSearchParams(searchValues: GetOrganizedActivities) {
        this.searchValues = searchValues
    }

    closeSearch() {
        setTimeout(() => (this.visibleSearch = false), 100)
    }
}
