<form
    [formGroup]="formGroup"
    (ngSubmit)="submit(formGroup.value)"
    autocomplete="off"
>
    <mat-form-field class="form-element">
        <input matInput [placeholder]="(type.charAt(0).toLowerCase() + type.slice(1) | translate) + ' URL'" formControlName="endpointUrl" />
    </mat-form-field>

    <mat-form-field>
        <mat-select
            formControlName="dataFileFormat"
            [placeholder]="'format' | translate"
        >
            <mat-option value="Default">{{'app' | translate}}</mat-option>
            <mat-option value="Orgsu">ORGSU</mat-option>
            <mat-option value="IronTime">IronTime</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="mb-2">
        <mat-checkbox formControlName="runImmediately">{{'runImmediately' | translate}}</mat-checkbox>
    </div>

    <div class="mb-2">
        <mat-checkbox formControlName="dryRun">{{'dryRun' | translate}}</mat-checkbox>
    </div>

    <div class="form-element text-right">
        <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!formGroup.valid">
            {{ 'save' | translate }}
        </button>
    </div>

</form>
