import { Component, Input, OnInit } from '@angular/core'
import { Article } from 'app/articles/articles.models'
import { Location } from '@angular/common'
import { ArticleService } from 'app/articles/services/article.service'

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
})
export class ArticleComponent implements OnInit {
    @Input()
    article: Article = this.location.getState() as Article

    @Input()
    url: string

    @Input()
    link: boolean = false

    constructor(private location: Location, private articleService: ArticleService) {}

    ngOnInit(): void {}
}
