import { gql } from 'apollo-angular'

export const PAYMENT = gql`
    query payment($query: GetPayment!) {
        payment(query: $query) {
            id
            providerPaymentId
            amount {
                value
                currency
            }
            state
        }
    }
`
