import { IOptions, MediaType } from 'app/core/core.models'

export const mediaTypeOptions: IOptions = [
    {
        label: 'image',
        value: MediaType.image,
    },
    // {
    //     label: 'video',
    //     value: MediaType.video,
    // },
    {
        label: 'youtube',
        value: MediaType.youtube,
    },
    {
        label: 'iframe',
        value: MediaType.iframe,
    },
]

export const organizedEventMediaConfig = {
    maxMedia: 9,
    mediaTypesWithVideo: {
        [MediaType.video]: true,
        [MediaType.image]: true,
        [MediaType.youtube]: false,
        [MediaType.iframe]: false,
    },
    initMediaType: MediaType.image,
} as const
