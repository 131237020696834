import { Injectable } from '@angular/core'
import {
    BehaviorSubject,
    defer,
    from,
    Observable,
    ObservedValueOf,
    of,
    OperatorFunction,
    pipe,
    shareReplay,
    switchMap,
    tap,
    throwError,
    UnaryFunction,
} from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import {
    Auth0Client,
    GetTokenSilentlyOptions,
    RedirectLoginOptions,
    RedirectLoginResult,
    User as UserInfo,
} from '@auth0/auth0-spa-js'
import { Nullish } from 'app/core/core.models'
import { authConfig } from '../../app.module'
import { log } from '../../core/utils'
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'
import { AppState } from '../users.models'
import { AccessTokenProvider } from '../../core/infrastructure/access-token-provider'
import { LogoutOptions } from '@auth0/auth0-spa-js/src/global'

@Injectable({
    providedIn: 'root',
})
export class Auth0SpaService implements AccessTokenProvider {
    isAuthenticated$ = new BehaviorSubject<boolean>(false)

    state: AppState | null = null

    initAuthentication(url: string = null): Observable<boolean> {
        this.state = null
        return of(url ? url : window.location.href).pipe(this.authenticationPipe$)
    }

    private authenticationPipe$: UnaryFunction<Observable<string>, Observable<boolean>> = pipe(
        log('AUTH0-SPA: init authentication:'),
        switchMap((url: string) => {
            console.log('AUTH0-SPA: url = ', url)
            return this.shouldHandleCallback(url) ? this.handleRedirectCallback$(url) : this.checkSession$()
        }),
        switchMap(() => this.isAuthenticated$$()),
        log('AUTH0-SPA: authenticated:'),
        tap((it: boolean) => this.isAuthenticated$.next(it)),
        catchError((err, caught) => {
            return throwError(() => new Error('AUTH0-SPA: authentication failed ' + err))
        }),
    )

    private shouldHandleCallback(url: string): boolean {
        return url.includes('code=') && url.includes('state=') && !url.includes('/users/settings/connections/')
    }

    login(state: AppState): void {
        this.redirect(state, false)
    }

    signup(state: AppState): void {
        this.redirect(state, true)
    }

    logout(): void {
        const options: LogoutOptions = {
            client_id: authConfig.clientId,
            returnTo: authConfig.returnTo,
        }
        this.auth0Client$.subscribe((client: Auth0Client) => {
            client.logout({ ...options, localOnly: true })

            const url = client.buildLogoutUrl(options)
            console.log('Logout url:', url)
            if (Capacitor.isNativePlatform()) {
                Browser.open({ url, windowName: '_self' })
            } else {
                window.location['assign'](url)
            }
        })
    }

    private redirect(state: AppState, signUp: boolean) {
        const opt: RedirectLoginOptions = {
            prompt: 'login',
            screen_hint: signUp ? 'signup' : undefined,
            appState: state,
        }

        this.auth0Client$
            .pipe(switchMap((client: Auth0Client) => from(client.buildAuthorizeUrl(opt))))
            .subscribe((url) => {
                if (Capacitor.isNativePlatform) {
                    Browser.open({ url, windowName: '_self' })
                } else {
                    window.location['assign'](url)
                }
            })
    }

    getAccessToken$(): Observable<string> {
        const options: GetTokenSilentlyOptions = {
            // This is important!, redirect_uri is not actually used for redirect but has to match the browser origin (in mobile app the origin is different from redirect_uri)
            redirect_uri: window.location.origin,
        }

        return this.auth0Client$.pipe(
            log('AUTH0-SPA: getting access token'),
            switchMap((client: Auth0Client) => from(client.getTokenSilently(options))),
            log('AUTH0-SPA: got access token'),
            this.errorHandler('AUTH0-SPA: getAccessToken$() failed'),
        )
    }

    getAccessTokenOrNull$(): Observable<string> {
        return this.isAuthenticated$.pipe(
            switchMap((it) => {
                if (it) {
                    return this.getAccessToken$().pipe(
                        catchError((err, caught) =>
                            of(err).pipe(
                                log('AUTH0-SPA: token not available'),
                                map(() => null),
                            ),
                        ),
                    )
                } else {
                    return of(null)
                }
            }),
        )
    }

    private errorHandler(msg: string): OperatorFunction<any, ObservedValueOf<void> | any> {
        return catchError((err, s) => {
            throw msg + err
        })
    }

    private createClient() {
        console.log('AUTH0-SPA: creating SDK client')
        return new Auth0Client({
            ...authConfig,
        })
    }

    private readonly auth0Client$: Observable<Auth0Client> = defer(() => of(this.createClient())).pipe(
        this.errorHandler('AUTH0-SPA: create client failed '),
        shareReplay(1),
    )

    getUserInfo$(): Observable<Nullish<UserInfo>> {
        return this.auth0Client$.pipe(
            switchMap((client: Auth0Client) => from(client.getUser())),
            this.errorHandler('AUTH0-SPA: getUserInfo$() failed '),
        )
    }

    private handleRedirectCallback$(url: string): Observable<RedirectLoginResult> {
        return this.auth0Client$.pipe(
            log('AUTH0-SPA: handling redirect callback'),
            switchMap((client: Auth0Client) => from(client.handleRedirectCallback(url))),
            tap((it) => (this.state = it.appState)),
            this.errorHandler('AUTH0-SPA: handleRedirectCallback$() failed '),
        )
    }

    private checkSession$(): Observable<void> {
        return this.auth0Client$.pipe(
            log('AUTH0-SPA: checking existing session'),
            switchMap((client: Auth0Client) => from(client.checkSession())),
            this.errorHandler('AUTH0-SPA: checkSession$() failed '),
        )
    }

    private isAuthenticated$$(): Observable<boolean> {
        return this.auth0Client$.pipe(
            switchMap((client: Auth0Client) => from(client.isAuthenticated())),
            this.errorHandler('AUTH0-SPA: isAuthenticated$() failed '),
        )
    }
}
