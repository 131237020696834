import { Component, OnInit } from '@angular/core'
import { OrganizedEventService } from 'app/events/services/organized-event.service'

@Component({
    selector: 'app-events-totals',
    templateUrl: './events-totals.component.html',
})
export class EventsTotalsComponent implements OnInit {
    constructor(private organizedEventService: OrganizedEventService) {}

    eventsTotals$ = this.organizedEventService.getTotals$()

    ngOnInit(): void {}
}
