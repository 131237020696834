import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent {

    @Input()
    icon = 'add'

    @Input()
    disabled = false

    @Output()
    action: EventEmitter<any> = new EventEmitter<any>()
}
