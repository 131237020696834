import { Directive } from '@angular/core'
import { LoginService } from '../../users/services/login.service'
import { TranslateService } from '@ngx-translate/core'
import { OrganizerService } from '../services/organizer.service'
import { OrganizedActivityService } from '../../events/services/organized-activity.service'
import { RegistrationService } from '../../events/services/registration.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { WindowService } from '../../core/services/window.service'
import { Globals } from '../../app.consts'
import { FormControl, FormGroup } from '@angular/forms'
import { FileUploader } from '../../../lib/file-upload/file-uploader.class'
import { BehaviorSubject, Observable, startWith, switchMap } from 'rxjs'
import { OrganizedActivity } from '../../events/events.models'
import { ActivityResultService } from '../../events/services/activity-result.service'
import { filter, map } from 'rxjs/operators'
import { getLocalizedText } from '../../core/utils'
import { Person } from '../../core/core.models'
import dayjs from 'dayjs'


@Directive()
export abstract class AbstractSelectableOrganizedActivityComponent {

    organizedActivities$: Observable<OrganizedActivity[]>
    filteredOrganizedActivities$: Observable<OrganizedActivity[]>
    form: FormGroup = new FormGroup({
        organizedActivityId: new FormControl(null),
        registrationState: new FormControl(null),
        dataFileFormat: new FormControl(null),
    })

    readonly uploader = new FileUploader({
        url: null,
        method: 'put',
        disableMultipart: true,
    })
    readonly uploadingFile$ = new BehaviorSubject(false)
    protected organizedActivityId$ = new BehaviorSubject<string | null>(null)
    protected registrationState$ = new BehaviorSubject<string | null>(null)
    protected dataFileFormat$ = new BehaviorSubject<string | null>(null)
    protected authorizationHeader: string
    exportUrl = null

    constructor(
        private readonly loginService: LoginService,
        private readonly translateService: TranslateService,
        private readonly organizerService: OrganizerService,
        private readonly organizedActivityService: OrganizedActivityService,
        protected readonly registrationService: RegistrationService,
        protected readonly activityResultService: ActivityResultService,
        private readonly snackBar: MatSnackBar,
        public readonly windowService: WindowService,
    ) {
        this.uploader.onSuccessItem = (fileItem, response, status) => {
            this.uploader.clearQueue()
            this.snackBar.open(this.translateService.instant('successResult'), null, {
                duration: Globals.durationSnackBarMessage,
                panelClass: 'snack-bar-success',
            })
            this.uploadingFile$.next(false)
            this.organizedActivityId$.next(this.organizedActivityId$.value) // force reload
        }

        this.uploader.onErrorItem = (fileItem, response, status) => {
            this.uploader.clearQueue()
            this.snackBar.open(response || this.translateService.instant('globalError'), null, {
                duration: Globals.durationSnackBarMessage,
                panelClass: 'snack-bar-error',
            })
            this.uploadingFile$.next(false)
        }

        this.form.get('organizedActivityId').valueChanges.subscribe((value) => {
            console.log('organizedActivityId value changed to', value)
            if (typeof value == 'object')
                this.organizedActivityId$.next(value.id)
            if (typeof value == 'string')
                this.filteredOrganizedActivities$ = this.organizedActivities$.pipe(
                    map((items) => {
                        const value = this.form.controls['organizedActivityId'].value
                        //return typeof value == 'string' ? organizedActivity
                        return items.filter((item) =>
                            typeof value == 'string' ?
                                getLocalizedText(item.name, this.windowService.currentLanguageCode, false).toLowerCase().indexOf(value) > -1 :
                                value?.id == item.id
                        )
                    })
                )
        })
        this.form.get('registrationState').valueChanges.subscribe((value) => {
            console.log('registrationState value changed to', value)
            this.registrationState$.next(value)
        })
        this.form.get('dataFileFormat').valueChanges.subscribe((value) => {
            console.log('dataFileFormat value changed to', value)
            this.dataFileFormat$.next(value)
        })
    }

    ngOnInit(): void {
        this.loginService.authorizationHeader$.subscribe((header) => {
            this.authorizationHeader = header
        })
        this.organizerService.currentOrganizer$.subscribe((currentOrganizer) => {
            console.log('Current organizer', currentOrganizer)
            if (currentOrganizer) {
                this.organizedActivities$ = this.organizedActivityService.getOrganizerOrganizedActivities$(
                    currentOrganizer.id,
                    false,
                )
                this.organizedActivities$.subscribe((organizedActivities) => {
                    console.log('Got organized activities for ', currentOrganizer.id, organizedActivities)
                })
            }
        })
    }

    stringify(value: any): string {
        return value ? (typeof value == 'string' ? value :`${value.name[0].text} ${value.activity.distance} KM • ${dayjs(value.startTime).format('DD.MM.YYYY HH:mm')}`/*getLocalizedText(value.name, this.windowService.currentLanguageCode, false)*/) : ''
    }

    get canUpload() {
        return this.uploader.queue.length > 0
    }

    upload() {
        const fileItem = this.uploader.queue[this.uploader.queue.length - 1]
        this.uploader.setOptions({
            ...this.uploader.options,
            url: this.exportUrl,
            headers: [
                {
                    name: 'Authorization',
                    value: this.authorizationHeader,
                }
            ]
        })
        fileItem.upload()
        this.uploadingFile$.next(true)
    }
}
