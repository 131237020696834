<div fxLayout="row wrap">
    <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{'information' | translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <small>{{'client' | translate}}</small>
                <p>{{versionService.version$ | async}}</p>
                <small>{{'server' | translate}}</small>
                <p>{{server.apiUrl}}:{{versionService.apiVersion}}</p>
                <mat-form-field>
                    <mat-label>{{'environment' | translate}}</mat-label>
                    <mat-select [(ngModel)]="server.env">
                        <mat-option value="">{{'default' | translate}}</mat-option>
                        <mat-option value="preview">{{'preview' | translate}}</mat-option>
                        <mat-option value="develop">{{'develop' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <small>{{'authorization_header' | translate}}</small>
                <pre>{{authorizationHeader}}</pre>
                <ng-container *ngIf="loginService.userInfo$|async as info">
                    <small>{{'identity' | translate}}</small>
                    <p>{{info.email}}</p>
                </ng-container>
                <small>{{'orientation' | translate}}</small>
                <pre>{{orientation ? orientation : 0}}°</pre>
                <small>{{'position' | translate}}</small>
                <ng-container *ngIf="position">
                    <pre>{{position | json}}</pre>
                    <app-map
                        [inputMarker]="position"
                        [inputMarkerAllowed]="true"
                        mapHeight="100pt"
                        (inputMarkerAction)="setPosition($event)"
                    ></app-map>
                </ng-container>
                <small>{{'native_mode' | translate}}</small>
                <p>{{(isNative ? 'yes' : 'no' ) | translate | uppercase}}</p>
                <small>{{'platforms' | translate}}</small>
                <p>{{platforms | json}}</p>

                <small>{{'device_info' | translate}}</small>
                <pre>{{device | json}}</pre>
                <small>{{'notification_token' | translate}}</small>
                <pre>{{(notificationToken$ | async) || '-' }}</pre>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{'app' | translate}} API</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul>
                    <li><a [href]="serverUrl + '/graphql'" target="_blank">GraphQL</a></li>
                    <li><a [href]="serverUrl + '/webjars/swagger-ui/index.html'" target="_blank">Swagger UI</a></li>
                    <li><a [href]="serverUrl + '/openapi.json'" target="_blank">openapi.json</a></li>
                </ul>
            </mat-card-content>
        </mat-card>
        <app-user-access-keys *ngIf="loginService.isLoggedIn$ | async"></app-user-access-keys>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Router</mat-card-title>
                <mat-card-subtitle>Navigate without reloading page</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="formGroup" (ngSubmit)="navigate(formGroup.controls['url'].value)" class="form">
                    <mat-form-field class="form-element">
                        <input matInput placeholder="/" formControlName="url" />
                    </mat-form-field>
                    <div class="form-element text-right">
                        <button
                            mat-raised-button
                            color="primary"
                            type="submit"
                            class="button"
                            [disabled]="!formGroup.valid"
                        >
                            {{ 'navigate' | translate }}
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
<!--        <mat-card>-->
<!--            <mat-card-header>-->
<!--                <mat-card-title>{{'browser' | translate}}</mat-card-title>-->
<!--            </mat-card-header>-->
<!--            <mat-card-content>-->
<!--                <small>Location</small>-->
<!--                <p>{{windowLocation | json}}</p>-->
<!--            </mat-card-content>-->
<!--        </mat-card>-->
    </div>
</div>

<!--<div fxLayout="row wrap">-->
<!--    <div fxFlex="100">-->
<!--        <mat-card>-->
<!--            <mat-card-header>-->
<!--                <mat-card-title>{{'Log' | translate}}</mat-card-title>-->
<!--            </mat-card-header>-->
<!--            <mat-card-content>-->
<!--                <pre style="height: 200px; overflow: scroll">-->
<!--<ng-container *ngFor="let item of logItems; index as i;">{{item}}-->
<!--</ng-container>-->
<!--                </pre>-->
<!--            </mat-card-content>-->
<!--        </mat-card>-->
<!--    </div>-->
<!--</div>-->
