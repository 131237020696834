<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <!--<mat-icon *ngIf="!(user$ | async)" class="profile-pic mat-icon_shadow">account_circle</mat-icon>-->
    <img
        *ngIf="(picture$ | async) as picture"
        [src]="picture"
        class="profile-pic mat-icon_shadow"
        referrerpolicy="no-referrer"
    />
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <ng-container *ngFor="let navItem of menuItems">
        <button *ngIf="navItem.type === manuItemType.link" (click)="profileMenuClick.emit(navItem)" mat-menu-item>
            <mat-icon>{{navItem.icon}}</mat-icon> {{navItem.name | translate}}
        </button>
        <mat-divider *ngIf="navItem.type === manuItemType.separator"></mat-divider>
    </ng-container>
</mat-menu>
