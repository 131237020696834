import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { MenuItem } from 'app/layouts/layouts.models'
import { WindowService } from 'app/core/services/window.service'
import { User } from 'app/users/users.models'
import { LoginService } from 'app/users/services/login.service'
import { LanguageService } from 'app/core/services/language.service'
import { languages } from 'app/core/constants/languages'

@Component({
    selector: 'app-vertical-header',
    templateUrl: './vertical-header.component.html',
    styleUrls: [],
})
export class VerticalAppHeaderComponent {
    config: PerfectScrollbarConfigInterface = {}

    @Input()
    isLogged: boolean

    @Input()
    homeUrl: string

    @Input()
    userMenuItems: any

    @Output()
    userMenuClick = new EventEmitter<MenuItem<any>>()

    languages: any[] = languages

    constructor(
        public windowService: WindowService,
        public loginService: LoginService,
        public languageService: LanguageService,
    ) {}
}
