<mat-card>
    <mat-card-content>
        <mat-card-title>{{'nickname'|translate}}</mat-card-title>
        <mat-card-subtitle>{{'nickname_desc'|translate}}</mat-card-subtitle>
        <form
            [formGroup]="formGroup"
            (ngSubmit)="submitForm(formGroup.value)"
            class="form"
        >
            <mat-form-field class="form-element">
                <input
                    matInput
                    [placeholder]="loginService.currentUser.nickname"
                    formControlName="nickname"
                />
                <mat-error
                    *ngIf="!formGroup.controls['nickname'].valid && formGroup.controls['nickname'].touched"
                >
                    {{ 'missing' | translate }} {{ 'nickname' | translate }}
                </mat-error>
            </mat-form-field>
            <div class="form-element text-right">
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="button"
                    [disabled]="!formGroup.valid"
                >
                    {{ 'save' | translate }}
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
