import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Feedback } from '../../users.models'

@Component({
    selector: 'app-user-feedback',
    templateUrl: './user-feedback.component.html',
})
export class UserFeedbackComponent {
    constructor(
        public dialogRef: MatDialogRef<UserFeedbackComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Feedback,
    ) {}

    onNoClick(): void {
        this.dialogRef.close()
    }
}
