import { gql } from 'apollo-angular'
import { LINK_FRAGMENT, ADDRESS_FRAGMENT } from 'app/core/core.fragments'

export const ORGANIZER_FRAGMENT = gql`
    ${LINK_FRAGMENT}
    ${ADDRESS_FRAGMENT}
    fragment OrganizerFields on Organizer {
        id
        name
        description
        links {
            ...LinkFields
        }
        email
        phoneNumber
        address {
            ...AddressFields
        }
        orgId
        vatId
        bankAccount
        eventCount
        isActive
        contractType
    }
`
