<div class="activity-article-post">
    <div class="activity-article-post__picture-holder">
        <img
            [src]="article.picture"
            [attr.alt]="article.title"
            (click)="windowService.open(article.ref)"
            class="activity-article-post__picture"
        />
    </div>
    <div class="activity-article-post__content">
        <mat-card-header>
            <mat-card-title>{{article.title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content [innerHTML]="article.text | marked"></mat-card-content>
    </div>
</div>
