import {Component, OnInit} from '@angular/core'
import { UserTrackerService } from 'app/users/services/user-tracker.service'
import { RoutingHistoryService } from 'app/core/services/routing-history.service'
import { EnumService } from 'app/core/services/enum.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { WindowService } from 'app/core/services/window.service'
import { server } from 'app/core/core.server'

@Component({
    selector: 'app-user-tracker',
    templateUrl: './user-tracker.component.html',
})
export class UserTrackerComponent implements OnInit {

    formGroup: FormGroup
    appUrl = server.appUrl
    activityTypes$ = this.enumService.activityTypes$

    constructor(
        public routingHistoryService: RoutingHistoryService,
        public windowService: WindowService,
        public trackerService: UserTrackerService,
        private enumService: EnumService,
    ) {
        this.formGroup = new FormGroup({
            type: new FormControl(null, Validators.required),
            name: new FormControl(null, Validators.required),
            isLive: new FormControl(null, Validators.required)
        })
    }

    ngOnInit(): void {
    }

    reset() {
        //this.routingHistoryService.goBack()
        this.trackerService.reset()
    }

    save() {
        if (!this.trackerService.isLive)
            this.trackerService.create(true)
    }
}
