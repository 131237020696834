import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'environments/environment'
import { GeocoderResponse } from 'app/core/core.models';


@Injectable({
    providedIn: 'root',
})
export class GeocodingService {
    constructor(private http: HttpClient) {}

    getAddress(latlng: string): Observable<GeocoderResponse> {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&sensor=false&key=${environment.geocoding.apiKey}`
        return this.http.get<GeocoderResponse>(url)
    }

    getLocation(term: string): Observable<GeocoderResponse> {
        // 'https://nominatim.openstreetmap.org/search?format=json&q=' + val
        const url = `https://maps.google.com/maps/api/geocode/json?address=${term}&sensor=false&key=${environment.geocoding.apiKey}`
        return this.http.get<GeocoderResponse>(url)
    }
}
