import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MapComponent } from 'app/core/components/map/map.component'
import { GoogleMapsModule } from '@angular/google-maps'
import { FlexLayoutModule } from '@angular/flex-layout'
import { PipesModule } from 'app/core/pipes/pipes.module'
import { MatCardModule } from '@angular/material/card'
import { TranslateModule } from '@ngx-translate/core'
import { DirectivesModule } from 'app/core/directives/directives.module'
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http'

@NgModule({
    declarations: [MapComponent],
    imports: [
        CommonModule,
        GoogleMapsModule,
        FlexLayoutModule,
        PipesModule,
        MatCardModule,
        TranslateModule,
        DirectivesModule,
        HttpClientModule,
        HttpClientJsonpModule,
    ],
    providers: [],
    exports: [MapComponent],
})
export class MapModule {}
