import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Organizer } from 'app/organizers/organizers.models'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { LinkListComponent } from 'app/core/components/link-list/link-list.component'

@Component({
    selector: 'app-organizer',
    templateUrl: './organizer.component.html',
})
export class OrganizerComponent implements OnInit {

    @Input()
    organizer: Organizer
    @Output()
    updateOrganizer = new EventEmitter<Organizer>()
    @ViewChild(LinkListComponent)
    linkListFormContainer!: LinkListComponent
    form: FormGroup

    constructor() {
        this.form = new FormGroup({
            name: new FormControl(null, Validators.required),
            description: new FormControl(null),
            email: new FormControl(null),
            phoneNumber: new FormControl(null),
            links: new FormArray([]),
            orgId: new FormControl(null),
            vatId: new FormControl(null),
            bankAccount: new FormControl(null),
            isActive: new FormControl(null, Validators.required),
        })
    }

    ngOnInit(): void {
        this.form.patchValue(this.organizer)
        setTimeout(() => {
            ;(<FormArray>this.form.get('links')).clear()
            this.organizer.links.forEach(({ type, ref }) => {
                const formArray = <FormArray>this.form.get('links')
                formArray.push(this.linkListFormContainer.createItem(type, ref))
            })
            if (this.linkListFormContainer) {
                this.linkListFormContainer.setDataSource()
            }
        })
    }

    save(organizer: Organizer): void {
        this.updateOrganizer.emit(organizer)
    }

    submitForm(): void {
        if (this.form.valid) {
            this.save(this.form.getRawValue())
        } else {
            this.form.markAllAsTouched()
        }
    }
}
