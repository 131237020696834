import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export class CustomValidators {
    static isNumber = (maxDecimals: number = 0): ValidatorFn => {
        const expression = maxDecimals === 0 ? new RegExp(/^(0|-?[1-9]\d*)$/) : new RegExp(/^-?\d+([\.\,]?\d+)?$/)
        return (control: AbstractControl): ValidationErrors => {
            if (control.pristine) {
                return null
            }

            if (!control.value) {
                return null
            }

            let actualDecimals = 0
            if (expression.test(control.value)) {
                if (maxDecimals > 0) {
                    const decimalPart = control.value.toString().split(/[,.]/)[1]
                    actualDecimals = decimalPart ? decimalPart.length : 0
                }
                if (maxDecimals === 0 || actualDecimals <= maxDecimals) {
                    return null
                }
            }

            if (maxDecimals === 0) {
                return {
                    number: true,
                }
            } else {
                return {
                    decimal: actualDecimals === 0 ? true : { count: maxDecimals, actual: actualDecimals },
                }
            }
        }
    }
}
