import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'activeUrl',
})
export class ActiveUrlPipe implements PipeTransform {
    transform(
        activeRouteUrl: string,
        url: string[] = [],
        excludedUrls: string[][] = []
    ): boolean {
        if (url.length === 0) return false;
        const [_, ...tailUrl] = url;
        const [__, ...tailActiveUrl] = activeRouteUrl.split('/');
        const excluded = excludedUrls.map((excludeUrl) => {
            const [_, ...tailUrl] = excludeUrl;
            const [__, ...tailActiveUrl] = activeRouteUrl.split('/');
            return tailActiveUrl.join('/').indexOf(tailUrl.join('/')) === 0;
        });

        const isExcluded = excludedUrls.length
            ? excluded.some((val) => val === true)
            : false;

        return (
            tailActiveUrl.join('/').indexOf(tailUrl.join('/')) === 0 &&
            tailUrl.length >= 2 &&
            !isExcluded
        );
    }
}
