<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
    class="main-container"
    [class.minisidebar]="minimizedSidebar"
    [class.sidebarVisible]="sidebarOpened"
    [class.notch]=""
    [class.searchOpened]="searchVisible"
>
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" class="topbar relative toolbar--racebook d-flex" id="topbar">
        <div
            class="topbar-content mt-auto w-100 fix-width-for-horizontal align-items-center"
            (click)="searchVisible && searchActive(false)"
        >
            <!-- ============================================================== -->
            <!-- Logo - style you can find in header.scss -->
            <!-- ============================================================== -->
            <!--            <div class="navbar-header">-->
            <!--                <a class="navbar-brand" [routerLink]="'/'">-->
            <!--                    &lt;!&ndash; Logo icon &ndash;&gt;-->
            <!--                    <b>-->
            <!--                        &lt;!&ndash;You can put here icon as well // <i class="wi wi-sunset"></i> //&ndash;&gt;-->
            <!--                        &lt;!&ndash; Dark Logo icon &ndash;&gt;-->
            <!--                        &lt;!&ndash;                        <img&ndash;&gt;-->
            <!--                        &lt;!&ndash;                            src="assets/images/racebook-logo.png"&ndash;&gt;-->
            <!--                        &lt;!&ndash;                            alt="homepage"&ndash;&gt;-->
            <!--                        &lt;!&ndash;                            class="logo dark-logo"&ndash;&gt;-->
            <!--                        &lt;!&ndash;                        />&ndash;&gt;-->
            <!--                        &lt;!&ndash; Light Logo icon &ndash;&gt;-->
            <!--                        <img-->
            <!--                            src="assets/images/racebook-logo.png"-->
            <!--                            alt="homepage"-->
            <!--                            class="logo light-logo logo&#45;&#45;big mat-icon_shadow"-->
            <!--                        />-->
            <!--                        <img-->
            <!--                            src="assets/images/racebook-logo-small.png"-->
            <!--                            alt="homepage"-->
            <!--                            class="logo light-logo logo&#45;&#45;small mat-icon_shadow"-->
            <!--                        />-->
            <!--                    </b>-->
            <!--                    &lt;!&ndash;End Logo icon &ndash;&gt;-->
            <!--                    &lt;!&ndash; Logo text &ndash;&gt;-->
            <!--                    &lt;!&ndash;                    <span fxShow="false" fxShow.gt-xs>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        &lt;!&ndash; dark Logo text &ndash;&gt;&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <img&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            src="assets/images/logo-text.png"&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            alt="homepage"&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            class="dark-logo"&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        />&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        &lt;!&ndash; Light Logo text &ndash;&gt;&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <img&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            src="assets/images/logo-light-text.png"&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            class="light-logo"&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            alt="homepage"&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        />&ndash;&gt;-->
            <!--                    &lt;!&ndash;                    </span>&ndash;&gt;-->
            <!--                </a>-->
            <!--            </div>-->
            <!-- ============================================================== -->
            <!-- sidebar toggle -->
            <!-- ============================================================== -->
            <button
                *ngIf="!showBackButton"
                mat-icon-button
                (click)="toggleSideNavigation()"
                class="sidebar-toggle"
                value="sidebarclosed"
            >
                <mat-icon class="mat-icon_shadow">menu</mat-icon>
            </button>
            <button *ngIf="showBackButton" mat-icon-button (click)="goBack()">
                <mat-icon class="mat-icon_shadow">arrow_back</mat-icon>
            </button>

<!--            <img src="assets/images/actigo.png" style="height: 40pt" class="desktop-only" />-->
            <!--            <organizer-menu-->
            <!--                *ngIf="!!(loginService.isLoggedIn$ | async)"-->
            <!--                class="mt-1- d-block"-->
            <!--                [menuItems]="organizerMenuService.menuItemsSubject$ | async"-->
            <!--                (menuClick)="organizerMenuService.menuItemClick($event)"-->
            <!--                [organizers]="organizerService.myOrganizers$ | async"-->
            <!--                (organizerMenuClick)="organizerService.switchOrganizer($event)"-->
            <!--                [currentOrganizer]="organizerService.currentOrganizer$ | async"-->
            <!--            ></organizer-menu>-->

            <!-- ============================================================== -->
            <!-- Search - style you can find in header.scss -->
            <!-- ============================================================== -->
            <!--
            <button mat-icon-button class="srh-btn" (click)="showSearch = !showSearch">
                <mat-icon>search</mat-icon>
            </button>
            -->
            <form class="app-search" [ngClass]="{ 'show-search': showSearch }">
                <input type="text" class="form-control" placeholder="Search &amp; enter" />
                <a class="cl-srh-btn" (click)="showSearch = !showSearch">
                    <i class="ti-close"></i>
                </a>
            </form>
            <span fxFlex></span>
            <!-- ============================================================== -->
            <!-- app header component - style you can find in header.scss / header.component.ts-->
            <!-- ============================================================== -->
            <div *ngIf="!horizontal; else horizontalheader">
                <app-vertical-header
                    [isLogged]="!!(loginService.isLoggedIn$ | async)"
                    [userMenuItems]="userMenuService.menuItemsSubject$ | async"
                    (userMenuClick)="userMenuService.menuItemClick($event)"
                ></app-vertical-header>
            </div>

            <ng-template #horizontalheader>
                <app-horizontal-header></app-horizontal-header>
            </ng-template>

            <!-- ============================================================== -->
            <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
            <!-- ============================================================== -->
            <!--            <button-->
            <!--                (click)="end.toggle()"-->
            <!--                mat-icon-button-->
            <!--                class="ml-xs overflow-visible"-->
            <!--            >-->
            <!--                <mat-icon>settings</mat-icon>-->
            <!--            </button>-->
        </div>
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <app-search
        [class.search-logged]="!!(loginService.isLoggedIn$ | async)"
        (searchActive)="searchActive($event)"
        class="search"
    ></app-search>
    <mat-sidenav-container
        class="example-sidenav-container"
        [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
        [ngClass]="{ minisidebar: minimizedSidebar }"
    >
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav
            #snav
            id="snav"
            class="pl-xs"
            [mode]="mobileQuery.matches ? 'side' : 'over'"
            fixedTopGap="0"
            [opened]="mobileQuery.matches"
            [disableClose]="mobileQuery.matches"
            (open)="sidebarOpened = true"
            (close)="sidebarOpened = false"
        >
            <perfect-scrollbar *ngIf="!horizontal; else horizontalsidebar" class="scbar" [config]="config">
                <app-vertical-sidebar
                    [isLogged]="!!(loginService.isLoggedIn$ | async)"
                    [user]="loginService.currentUser$ | async"
                    [menuItems]="userMenuService.menuItemsSubject$ | async"
                    [mainMenuItems]="mainMenuItems | async"
                    (menuClick)="userMenuService.menuItemClick($event)"
                    class="app-sidebar"
                    [showClass]="status"
                    (notify)="snav.toggle()"
                >
                </app-vertical-sidebar>
                <div *ngIf="!isIos" class="copyright" style="width: 100%; text-align: right">
                    <img [src]="'assets/images/actigo-sidebar.png'" style="height: 55px">
                </div>
            </perfect-scrollbar>

            <ng-template #horizontalsidebar>
                <app-horizontal-sidebar></app-horizontal-sidebar>
            </ng-template>
        </mat-sidenav>

        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
        <!-- ============================================================== -->
        <!--        <mat-sidenav-->
        <!--            #end-->
        <!--            position="end"-->
        <!--            class="chat-panel"-->
        <!--            mode="over"-->
        <!--            opened="false"-->
        <!--        >-->
        <!--            <div class="scroll">-->
        <!--                <mat-nav-list>-->
        <!--                    <h3 mat-subheader class="text-uppercase font-weight-bold">-->
        <!--                        Settings-->
        <!--                    </h3>-->
        <!--                    <mat-list-item *ngIf="!minisidebar">-->
        <!--                        <mat-slide-toggle color="warn" [(ngModel)]="horizontal">-->
        <!--                            Horizontal Layout</mat-slide-toggle-->
        <!--                        >-->
        <!--                    </mat-list-item>-->
        <!--                    <mat-list-item>-->
        <!--                        <mat-slide-toggle-->
        <!--                            color="warn"-->
        <!--                            (change)="dir = dir == 'rtl' ? 'ltr' : 'rtl'"-->
        <!--                            >RTL-->
        <!--                        </mat-slide-toggle>-->
        <!--                    </mat-list-item>-->
        <!--                    <mat-list-item>-->
        <!--                        <mat-slide-toggle color="warn" [(ngModel)]="boxed"-->
        <!--                            >Boxed Layout</mat-slide-toggle-->
        <!--                        >-->
        <!--                    </mat-list-item>-->
        <!--                    <mat-list-item *ngIf="!horizontal">-->
        <!--                        <mat-slide-toggle [(ngModel)]="minisidebar"-->
        <!--                            >Mini Sidebar</mat-slide-toggle-->
        <!--                        >-->
        <!--                    </mat-list-item>-->
        <!--                    <mat-divider></mat-divider>-->

        <!--                    <h3 mat-subheader class="text-uppercase font-weight-bold">-->
        <!--                        Colors-->
        <!--                    </h3>-->

        <!--                    <mat-list-item>-->
        <!--                        <mat-checkbox-->
        <!--                            color="warn"-->
        <!--                            [(ngModel)]="danger"-->
        <!--                            (change)="green = blue = false"-->
        <!--                            class="text-danger"-->
        <!--                            >Red-->
        <!--                        </mat-checkbox>-->
        <!--                    </mat-list-item>-->

        <!--                    <mat-list-item>-->
        <!--                        <mat-checkbox-->
        <!--                            color="warn"-->
        <!--                            [(ngModel)]="green"-->
        <!--                            (change)="danger = blue = false"-->
        <!--                            class="text-megna"-->
        <!--                            >Teal Green</mat-checkbox-->
        <!--                        >-->
        <!--                    </mat-list-item>-->

        <!--                    <mat-list-item>-->
        <!--                        <mat-checkbox-->
        <!--                            color="warn"-->
        <!--                            [(ngModel)]="blue"-->
        <!--                            (change)="green = danger = false"-->
        <!--                            class="text-info"-->
        <!--                            >Blue-->
        <!--                        </mat-checkbox>-->
        <!--                    </mat-list-item>-->

        <!--                    <mat-list-item>-->
        <!--                        <mat-checkbox-->
        <!--                            color="warn"-->
        <!--                            [(ngModel)]="dark"-->
        <!--                            (change)="darkClick()"-->
        <!--                            >Dark-->
        <!--                        </mat-checkbox>-->

        <!--                        &lt;!&ndash; <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox> &ndash;&gt;-->
        <!--                    </mat-list-item>-->
        <!--                </mat-nav-list>-->
        <!--            </div>-->
        <!--        </mat-sidenav>-->

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''" id="content">
            <div class="content-holder">
                <app-breadcrumb class="page-container d-block pb-0"></app-breadcrumb>
                <div class="page-container">
                    <div class="page-content" style="padding-top: 5pt">
                        <router-outlet> <!--<app-spinner></app-spinner>--> </router-outlet>
                    </div>
                </div>
            </div>
            <app-footer
                [footerItems]="footerMenuItems"
                [copyright]="'Racebook s.r.o. © 2022' + (currentYear ? '-' + currentYear : '')"
            ></app-footer>
        </mat-sidenav-content>

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
    <!--    <app-loader *ngIf="(apolloStateService.loading$ | async)" class="app-loader absolute w-100"> </app-loader>-->
</div>

<button
    mat-fab
    [class]="userTrackerService.isStopped ? 'tracker-button' : 'tracker-active-button'"
    *ngIf="(windowService.isNative || !windowService.isProduction) && (loginService.isLoggedIn$ | async) && (routingHistoryService.currentUrl != '/users/tracker')"
    [routerLink]="['/users/tracker']"
>
    <mat-icon>timer</mat-icon>
</button>
