<button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <i class="mat-icon_shadow flag-icon flag-icon-{{ (languageService.selectedLanguage$ | async).icon }}"> </i>
</button>
<mat-menu #flags="matMenu" class="mymegamenu">
    <button
        mat-menu-item
        *ngFor="let language of languageService.availableLanguages"
        (click)="languageService.changeLanguage(language)"
    >
        <i class="flag-icon flag-icon-{{ language.icon }} m-r-10"></i>
        <span>{{ language.language }}</span>
    </button>
</mat-menu>

<ng-container *ngIf="!isLogged">
    <button mat-raised-button (click)="loginService.login()">
        <!--    <mat-icon>check</mat-icon>-->
        {{ 'login' | translate }}
    </button>
    &nbsp;
    <!--<button class="d-none d-md-inline" mat-raised-button color="warn" (click)="loginService.signup()">-->
    <!--&lt;!&ndash;    <mat-icon>check</mat-icon>&ndash;&gt;-->
    <!--    {{ 'signup' | translate }}-->
    <!--</button>-->
</ng-container>

<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->
<!--<button *ngIf="(postCount$ |async) > 0" mat-icon-button [routerLink]="['/']" class="me-3 mat-icon_shadow">-->
<!--    <mat-icon [matBadge]="postCount$ |async" matBadgeColor="warn">notifications</mat-icon>-->
<!--</button>-->
<user-menu
    *ngIf="isLogged"
    (profileMenuClick)="userMenuClick.emit($event)"
    [menuItems]="userMenuItems"
></user-menu>
