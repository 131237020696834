import { gql } from 'apollo-angular'
import {
    ACTIVITY_FRAGMENT,
    LINK_FRAGMENT,
    LOCALIZED_TEXT_FRAGMENT,
    MEDIA_FRAGMENT, MERCHANDISE_PRICE_FRAGMENT,
    PERSON_FRAGMENT,
    PRICE_FRAGMENT,
    WORKFLOW_FRAGMENT,
} from 'app/core/core.fragments'

export const ORGANIZED_EVENT_FRAGMENT = gql`
    fragment OrganizedEventFields on OrganizedEvent {
        id
        externalId
        organizerId
        name {
            ...LocalizedTextFields
        }
        description {
            ...LocalizedTextFields
        }
        labels
        media {
            ...MediaFields
        }
        workflow {
            ...WorkflowFields
        }
    }
    ${MEDIA_FRAGMENT}
    ${WORKFLOW_FRAGMENT}
    ${LOCALIZED_TEXT_FRAGMENT}
`

export const ORGANIZED_ACTIVITY_FRAGMENT = gql`
    fragment OrganizedActivityFields on OrganizedActivity {
        id
        code
        name {
            ...LocalizedTextFields
        }
        activity {
            ...ActivityFields
        }
        description {
            ...LocalizedTextFields
        }
        currentPrice {
            ...PriceFields
        }
        state
        #        workflow {
        #            ...WorkflowFields
        #        }
        organizedEvent {
            ...OrganizedEventFields
        }
        links {
            ...LinkFields
        }
        labels
        followCount
        messageCount
        isUserFollowing
        position {
            latitude
            longitude
        }
        place {
            text
            language
        }
        timeLimit
        favoriteMediaRef
        priceList {
            ...PriceFields
        }
        merchandisePriceList {
            ...MerchandisePriceFields
        }
        registrationSyncConfig {
            endpointUrl
            dataFileFormat
        }
        activityResultSyncConfig {
            endpointUrl
            dataFileFormat
        }
        startTime
        isOpen
        isCompleted
        isUserRegistered
        registrationCount
        registrationLimit
    }
    ${LINK_FRAGMENT}
    ${MEDIA_FRAGMENT}
    ${PRICE_FRAGMENT}
    ${MERCHANDISE_PRICE_FRAGMENT}
    ${WORKFLOW_FRAGMENT}
    ${ACTIVITY_FRAGMENT}
    ${LOCALIZED_TEXT_FRAGMENT}
    ${ORGANIZED_EVENT_FRAGMENT}
`

export const REGISTRATION_FRAGMENT = gql`
    fragment RegistrationFields on Registration {
        id
        code
        created
        state
        startNumber
        teamName
        organizedActivity {
            id
            name {
                ...LocalizedTextFields
            }
            startTime
            organizedEvent {
                ...OrganizedEventFields
            }
            merchandisePriceList {
                ...MerchandisePriceFields
            }
        }
        merchandise {
            sku
            quantity
        }
        paymentId
    }
    ${LOCALIZED_TEXT_FRAGMENT}
    ${ORGANIZED_EVENT_FRAGMENT}
    ${MERCHANDISE_PRICE_FRAGMENT}
`

export const ACTIVITY_RESULT_FRAGMENT = gql`
    fragment ActivityResultFields on ActivityResult {
        id
        startNumber
        time
        position
        category
        positionBySex
        positionByCategory
        userActivityId
        organizedActivity {
            ...OrganizedActivityFields
        }
        person {
            ...PersonFields
        }
        splitTimes {
            distance
            time
        }
    }
    ${PERSON_FRAGMENT}
    ${ORGANIZED_ACTIVITY_FRAGMENT}
`
