<div fxLayout="row wrap" class="user-activity-card">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <ng-container *ngIf="data$ | async as data">
            <ng-container *ngFor="let userActivity of data">
                <a
                    [href]="this.connectionUrl[userActivity.connectionType.toLowerCase()] + userActivity.connectionId"
                    target="_blank"
                    class="user-activity_logo"
                >
                    <img
                        [src]="'assets/images/logo-' + userActivity.connectionType.toLowerCase() + '.png'"
                        [alt]="userActivity.connectionType"
                    />
                </a>

                <h2 class="user-activity-card_title pb-2">
                    <a [routerLink]="['/users/activities', userActivity.id]">{{userActivity.activity.name}}</a>
                    <a
                        *ngIf="userActivity.organizedActivity"
                        [routerLink]="['/events/activities', userActivity.organizedActivity.id]"
                    ><br/>
                        {{userActivity.organizedActivity.name | localize}}
                        • {{'ActivityType.' + userActivity.organizedActivity.activity.type | translate}} {{userActivity.organizedActivity.activity.distance}} {{'Unit.KM' | translate}}
                        • {{userActivity.organizedActivity.place | localize}}
                    </a>
                </h2>
                <div fxLayout="row wrap">
                    <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex.gt-lg="33.3" fxFlex="33.3" class="pb-2">
                        <div class="user-activity_label pb-1">{{'type' | translate}}</div>
                        <div class="user-activity_value">{{'ActivityType.' + userActivity.activity.type | translate}}</div>
                    </div>
                    <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex.gt-lg="33.3" fxFlex="33.3" class="pb-2">
                        <div class="user-activity_label pb-1">{{'distance' | translate}}</div>
                        <div class="user-activity_value">
                            {{userActivity.distance | number : '1.0-2' }} {{'Unit.KM' | translate}}
                        </div>
                    </div>
                    <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex.gt-lg="33.3" fxFlex="33.3" class="pb-2">
                        <div class="user-activity_label pb-1">{{'startTime' | translate}}</div>
                        <div class="user-activity_value">
                            {{userActivity.started | localizeDate :'short'}}
                        </div>
                    </div>
                    <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex.gt-lg="33.3" fxFlex="33.3" class="pb-2">
                        <div class="user-activity_label pb-1">{{'elapsedTime' | translate}}</div>
                        <div class="user-activity_value">
                            {{userActivity.elapsedTime | time : {thousandths: false} }}
                        </div>
                    </div>
                    <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex.gt-lg="33.3" fxFlex="33.3" class="pb-2">
                        <div class="user-activity_label pb-1">{{'movingTime' | translate}}</div>
                        <div class="user-activity_value">
                            {{userActivity.movingTime | time : {thousandths: false} }}
                        </div>
                    </div>
                    <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex.gt-lg="33.3" fxFlex="33.3" class="pb-2">
                        <div class="user-activity_label pb-1">{{'pace' | translate}}</div>
                        <div class="user-activity_value">{{userActivity.pace | pace}}/{{'Unit.KM' | translate}}</div>
                    </div>
                </div>

                <div
                    fxLayout="row"
                    class="d-block"
                    fxflexfill
                    *ngIf="showMap && userActivity.activity?.track?.segments?.length"
                >
                    <app-map
                        mapHeight="200pt"
                        [activity]="userActivity.activity"
                        [darkMode]="windowService.darkMode$ | async"
                    ></app-map>
                </div>
            </ng-container>
        </ng-container>
        <mat-paginator
            [class.d-none]="dataSource?.data?.length <= 1"
            [pageSize]="1"
            hidePageSize="true"
            (page)="pageChanged($event)"
        ></mat-paginator>
    </div>
</div>
