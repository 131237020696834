<mat-card>
    <mat-card-title>
        <mat-card-content>
            <mat-card-title>{{'pace'|translate}} {{'calculator'|translate|lowercase}}</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
            <form [formGroup]="formGroup" class="form">
                <mat-autocomplete #distanceAutocomplete="matAutocomplete">
                    <mat-option *ngFor="let option of distanceOptions" [value]="option.distance">
                        {{option.label|translate}}
                    </mat-option>
                </mat-autocomplete>
                <mat-form-field>
                    <mat-label>{{'distance'|translate}}</mat-label>
                    <input matInput placeholder="10.0" formControlName="distance" [matAutocomplete]="distanceAutocomplete"/>
                    <span matSuffix>{{'Unit.KM' | translate}}</span>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'time'|translate}}</mat-label>
                    <input matInput placeholder="1:00:00" formControlName="time"/>
                    <span matSuffix>{{'Unit.HH' | translate}}:{{'Unit.MM' | translate}}:{{'Unit.SS' | translate}}</span>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'pace'|translate}}</mat-label>
                    <input matInput placeholder="6:00" formControlName="pace"/>
                    <span matSuffix>{{'Unit.MM' | translate}}:{{'Unit.SS' | translate}}/{{'Unit.KM' | translate}}</span>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'speed'|translate}}</mat-label>
                    <input matInput placeholder="10.0" formControlName="speed"/>
                    <span matSuffix>{{'Unit.KM' | translate}}/{{'Unit.HH' | translate}}</span>
                </mat-form-field>
            </form>
        </mat-card-content>
    </mat-card-title>
</mat-card>
