import { gql } from 'apollo-angular'
import { GROUP_FRAGMENT, PERSON_FRAGMENT } from '../core/core.fragments'
import { USER_ACTIVITY_FRAGMENT, USER_FRAGMENT } from './users.fragments'

export const USER = gql`
    ${USER_FRAGMENT}
    query user($query: GetUser!) {
        user(query: $query) {
            ...UserFields
        }
    }
`
export const USER_FOLLOW_STATUS = gql`
    query userFollowStatus($query: GetUserFollowStatus!) {
        userFollowStatus(query: $query)
    }
`
export const PERSON = gql`
    ${PERSON_FRAGMENT}
    query person($query: GetPerson!) {
        person(query: $query) {
            ...PersonFields
        }
    }
`

export const PERSONS = gql`
    ${PERSON_FRAGMENT}
    query persons($query: GetPersons!) {
        persons(query: $query) {
            ...PersonFields
        }
    }
`
export const TEAMS = gql`
    ${GROUP_FRAGMENT}
    query teams($query: GetTeams!) {
        teams(query: $query) {
            ...GroupFields
        }
    }
`
export const USER_FOLLOWER_PERSONS = gql`
    ${PERSON_FRAGMENT}
    query userFollowerPersons($query: GetUserFollowerPersons!) {
        userFollowerPersons(query: $query) {
            ...PersonFields
        }
    }
`
export const USER_FOLLOWING_PERSONS = gql`
    ${PERSON_FRAGMENT}
    query userFollowingPersons($query: GetUserFollowingPersons!) {
        userFollowingPersons(query: $query) {
            ...PersonFields
        }
    }
`
export const ME = gql`
    ${USER_FRAGMENT}
    query me {
        me {
            ...UserFields
        }
    }
`
export const USER_ACTIVITY = gql`
    ${USER_ACTIVITY_FRAGMENT}
    query userActivity($query: GetUserActivity!) {
        userActivity(query: $query) {
            ...UserActivityFields
        }
    }
`
export const USER_ACTIVITIES = gql`
    ${USER_ACTIVITY_FRAGMENT}
    query userActivities($query: GetUserActivities!) {
        userActivities(query: $query) {
            ...UserActivityFields
        }
    }
`

export const USER_ACTIVITY_LIKING_PERSONS = gql`
    ${PERSON_FRAGMENT}
    query userActivityLikingPersons($query: GetUserActivityLikingPersons!) {
        userActivityLikingPersons(query: $query) {
            ...PersonFields
        }
    }
`

export const USE = gql`
    ${USER_ACTIVITY_FRAGMENT}
    query userActivities($query: GetUserActivities!) {
        userActivities(query: $query) {
            ...UserActivityFields
        }
    }
`

export const OAUTH_REQUEST_TOKEN = gql`
    query oauthRequestToken($query: GetOAuthRequestToken!) {
        oauthRequestToken(query: $query) {
            token
            tokenSecret
        }
    }
`
