import { Component, OnInit } from '@angular/core'
import { WindowService } from 'app/core/services/window.service'
import { privacy } from './privacy.const'

@Component({
    templateUrl: './privacy.component.html',
})
export class PrivacyComponent implements OnInit {

    text: string = ''

    constructor(
        private windowService: WindowService,
    ) {}

    ngOnInit() {
        this.windowService.selectedLanguage$.subscribe((language) => {
            this.text = privacy[language.code]
        })
    }
}
