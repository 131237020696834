import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GoogleMapsModule } from '@angular/google-maps'
import { FlexLayoutModule } from '@angular/flex-layout'
import { PipesModule } from 'app/core/pipes/pipes.module'
import { MatCardModule } from '@angular/material/card'
import { TranslateModule } from '@ngx-translate/core'
import { DirectivesModule } from 'app/core/directives/directives.module'
import { MessageComponent } from 'app/core/components/message/message.component'
import { MaterialModule } from 'app/layouts/material.module'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MentionModule } from 'angular-mentions'

@NgModule({
    declarations: [MessageComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,
    FlexLayoutModule,
    PipesModule,
    MatCardModule,
    TranslateModule,
    DirectivesModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MentionModule
  ],
    providers: [],
    exports: [MessageComponent],
})
export class MessageModule {}
