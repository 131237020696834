import { Component } from '@angular/core'
import { AbstractFollowComponent } from '../abstract-follow.component'

@Component({
    selector: 'app-follow-button',
    templateUrl: './follow-button.component.html',
})
export class FollowButtonComponent extends AbstractFollowComponent {

}
