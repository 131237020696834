import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

@Injectable({
    providedIn: 'root',
})
export class RoutingHistoryService {
    private urls: string[] = []

    constructor(private router: Router) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let length = this.urls.length
                if (length == 0 || this.urls[length - 1] != event.url) {
                    if (!event.url.startsWith('/#') && !event.url.startsWith('/users/settings')) {
                        this.urls.push(event.url)
                        console.log('Adding URL to routing history', this.urls)
                    }
                }
            }
        })
    }

    get currentUrl(): string | null {
        return this.urls.length > 0 ? this.urls[this.urls.length - 1] : null
    }

    get canGoBack() {
        return this.urls.length > 1
    }

    goBack() {
        if (this.canGoBack) {
            this.urls.pop()
            this.router.navigateByUrl(this.currentUrl)
        }
    }
}
