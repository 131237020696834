<div fxLayout="row wrap" class="activities-overview">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <div
            fxLayout="row wrap"
            fxFlexAlign="center"
            class="row activities-overview-list"
            *ngIf="(organizedActivities$ | async) as organizedActivities"
        >
            <div
                *ngFor="let organizedActivity of organizedActivities"
                fxFlex="100"
                fxFlex.gt-xs="50"
                fxFlex.gt-md="33"
                class="activities-overview-list__item"
            >
                <mat-card>
                    <app-organized-activity-card [organizedActivity]="organizedActivity"></app-organized-activity-card>
                </mat-card>
            </div>
            <div
                class="h4 text-shadow text-center cursor-pointer"
            >
                {{'total' | translate}} {{organizedActivities.length}}
            </div>
        </div>
    </div>
</div>
