import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router'
import { filter, map, mergeMap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { LanguageService } from 'app/core/services/language.service'
import { defaultLanguage } from 'app/core/constants/languages'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: [],
})
export class AppBreadcrumbComponent {
    currentTitleKey: string = null
    pageTitle: string = null

    // @Input() layout;
    pageInfo: Data = Object.create(null)
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
        private languageService: LanguageService,
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(map(() => this.activatedRoute))
            .pipe(
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild
                    }
                    return route
                }),
            )
            .pipe(filter((route) => route.outlet === 'primary'))
            .pipe(mergeMap((route) => route.data))
            .pipe(untilDestroyed(this))
            // tslint:disable-next-line - Disables all
            .subscribe((event) => {
                this.pageInfo = event
                this.currentTitleKey = event['title']
                this.setPageTitle()
            })

        this.languageService.selectedLanguage$
            .pipe(untilDestroyed(this))
            .pipe(filter((language) => !!language && !!this.currentTitleKey))
            .subscribe(() => {
                this.setPageTitle()
            })
    }

    private setPageTitle() {
        // use is necessary because on load app there aren't any i18n files loaded. This method wait for it.
        this.translateService
            .use(this.languageService.selectedLanguageCode || defaultLanguage.code)
            .subscribe((res) => {
                if (res && this.currentTitleKey && res[this.currentTitleKey])
                    this.titleService.setTitle(`${res[this.currentTitleKey]} | ${res['app']}`)
            })
    }

    loadPageTitleInHtml(): string {
        let title = this.pageInfo?.title
        if (title == 'home') {
            let time = new Date()
            let dayTime =
                time.getHours() < 4
                    ? 'night'
                    : time.getHours() < 12
                    ? 'morning'
                    : time.getHours() < 18
                    ? 'afternoon'
                    : 'evening'
            return this.translateService.instant(`good_${dayTime}`)
        } else {
            return this.translateService.instant(title)
        }
    }
}
