import { Injectable } from '@angular/core'
import { BehaviorSubject, map } from 'rxjs'
import {
    ConsumerMenuItem,
    ConsumerMenuItems,
    notLoggedInConsumerMenuItems,
    loggedInConsumerMenuItems,
} from 'app/layouts/constants'
import { Router } from '@angular/router'
import { LoginService } from 'app/users/services/login.service'
import { LayoutService } from 'app/layouts/services/layout.service'

const getMenuItems = (isLogged: boolean): ConsumerMenuItems => {
    return isLogged ? loggedInConsumerMenuItems : notLoggedInConsumerMenuItems
}

@Injectable({
    providedIn: 'root',
})
export class UserMenuService {
    menuItemsSubject$ = this.loginService.isLoggedIn$.pipe(map(getMenuItems))

    menuItemClick = (menuItem: ConsumerMenuItem): void => {
        menuItem.action?.(this.router, this.loginService, this.layoutService)
    }

    minimizedSidebar$ = new BehaviorSubject<boolean>(false)

    constructor(private loginService: LoginService, private router: Router, private layoutService: LayoutService) {}
}
