import { gql } from 'apollo-angular'
import { WALLPOST_FRAGMENT } from '../users/users.fragments'
import { MESSAGE_FRAGMENT } from './core.fragments'

export const WALL_POSTS = gql`
    ${WALLPOST_FRAGMENT}
    query wallPosts($query: GetWallPosts!) {
        wallPosts(query: $query) {
            ...WallPostFields
        }
    }
`
export const WALL_POST_COUNT = gql`
    query wallPostCount($query: GetWallPostCount!) {
        wallPostCount(query: $query)
    }
`
export const ACTIVITY_TYPES = gql`
    query activityTypes {
        activityTypes
    }
`

export const POST_TYPES = gql`
    query postTypes {
        postTypes
    }
`

export const PRIORITIES = gql`
    query priorities {
        priorities
    }
`

export const MESSAGES = gql`
    ${MESSAGE_FRAGMENT}
    query messages($query: GetMessages!) {
        messages(query: $query) {
            ...MessageFields
        }
    }
`
export const ENTITY_MESSAGES = gql`
    ${MESSAGE_FRAGMENT}
    query entityMessages($query: GetEntityMessages!) {
        entityMessages(query: $query) {
            ...MessageFields
        }
    }
`
