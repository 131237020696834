import { Component, OnInit } from '@angular/core'
import { OrganizerService } from 'app/organizers/services/organizer.service'
import { Address } from 'app/core/core.models'
import { Organizer } from 'app/organizers/organizers.models'
import { switchMap } from 'rxjs'

@Component({
    selector: 'app-organizer-settings',
    templateUrl: './organizer-settings.component.html',
})
export class OrganizerSettingsComponent implements OnInit {
    organizer: Organizer = null

    constructor(private organizerService: OrganizerService) {}

    ngOnInit(): void {
        this.organizerService.currentOrganizer$
            .pipe(switchMap((it) => this.organizerService.getOrganizer$(it.id)))
            .subscribe((organizer) => {
                this.organizer = organizer
            })
    }

    updateOrganizer(organizer: Organizer) {
        console.log('update organizer', organizer)
        this.organizerService
            .updateOrganizer({ id: this.organizer.id, ...organizer })
            .subscribe((it) => {
                console.log('updated organizer', it)
            })
    }

    updateAddress(address: Address) {
        this.organizerService.updateOrganizerAddress(this.organizer.id, address).subscribe((it) => {
            console.log('updated organizer address', it)
        })
    }
}
