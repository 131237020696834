export function age(birthDateAsString: string) {
    const today = new Date()
    const birthDate = new Date(birthDateAsString)
    let age = today.getFullYear() - birthDate.getFullYear()
    const months = today.getMonth() - birthDate.getMonth()
    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}


export function uniqueId(length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}


export function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function str2sec(str: string): number {
    return str
        .split(':')
        .reverse()
        .reduce((prev, curr, i) => prev + Number(curr) * Math.pow(60, i), 0)
}

export function sec2pace(sec: number): string {
    return Math.floor(sec) + ':' + ((sec % 1) * 60).toFixed().toString().padStart(2, '0')
}

export function sec2time(sec: number): string {
    return (
        Math.floor(sec / 3600) +
        ':' +
        Math.floor((sec / 60) % 60)
            .toString()
            .padStart(2, '0') +
        ':' +
        Math.floor(sec % 60)
            .toString()
            .padStart(2, '0')
    )
}

export function escapeHtmlEntities(str: string): string {
    const tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
    }
    return str.replace(/[&<>]/g, function(tag) {
        return tagsToReplace[tag] || tag
    })
}
