import { FormControl } from '@angular/forms'
import { Component, Input } from '@angular/core'
import { IOptions } from 'app/core/core.models'
import { toggleItemInArray } from 'app/core/utils'

@Component({
    selector: 'app-chips-radio-input',
    templateUrl: './chips-radio-input.component.html',
    styleUrls: ['./chips-radio-input.component.scss'],
})
export class ChipsRadioInputComponent {
    @Input()
    options: IOptions

    @Input()
    control: FormControl

    @Input()
    errorMessage = 'isRequired'

    toggleOption(value) {
        const chipValues = this.control.value
        this.control.setValue(toggleItemInArray<string>(chipValues, value))
    }
}
