import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, Validators } from '@angular/forms'
import { LoginService } from '../../services/login.service'
import { UserService } from '../../services/user.service'
import { EnumService } from '../../../core/services/enum.service'

@Component({
    selector: 'app-user-post-rules',
    templateUrl: './user-post-rules.component.html',
})
export class UserPostRulesComponent implements OnInit {

    postTypes$ = this.enumService.postTypes$
    priorities$ = this.enumService.priorities$
    formGroup = this.fb.group({
        'items': this.fb.array([])
    })

    get items() {
        return this.formGroup.controls['items'] as FormArray
    }

    constructor(
        private loginService: LoginService,
        private enumService: EnumService,
        private userService: UserService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.loginService.currentUser.postRules.forEach((postRule) => {
            this.add(postRule.type, postRule.isEnabled, postRule.notificationPriority)
        })
    }

    add(type?: string, isEnabled: boolean = true, notificationPriority: string = 'Low') {
        const itemForm = this.fb.group({
            //id: [generateUUID()],
            type: [type, Validators.required],
            isEnabled: [isEnabled],
            notificationPriority: [notificationPriority],
        })
        this.items.push(itemForm)
    }

    delete(index: number) {
        this.items.removeAt(index)
    }

    save() {
        const postRules = this.formGroup.value.items
        this.userService.updateUserPostRules$(postRules).subscribe((postRules) => {
            this.loginService.currentUser.postRules = postRules
            console.log('User post rules updated', postRules)
        })
    }
}
