import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { OrganizedActivityContainer } from 'app/events/components/organized-activity/organized-activity.container'
import { OrganizedActivitiesComponent } from './components/organized-activities/organized-activities.component'
import { OrganizedEventsComponent } from './components/organized-events/organized-events.component'
import { OrganizedEventComponent } from './components/organized-event/organized-event.component'
import { RegistrationsComponent } from './components/registrations/registrations.component'
import { MessagesComponent } from 'app/core/components/messages/messages.component'
import { ReviewsComponent } from './components/reviews/reviews.component'
import { ActivityResultsComponent } from './components/activity-results/activity-results.component'
import { RegistrationComponent } from './components/registration/registration.component'
import { organizedEventConfig } from 'app/events/components/organized-event/organized-event.config'
import { OrganizedActivitiesMineComponent } from './components/organized-activities-mine/organized-activities-mine.component'
import { View } from 'app/events/components/organized-activities/organized-activities.model'

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'announcements',
                component: MessagesComponent,
                data: {
                    title: 'announcements',
                    urls: [{ title: 'home', url: '/' }, { title: 'announcements' }],
                },
            },
            {
                path: 'reviews',
                component: ReviewsComponent,
                data: {
                    title: 'reviews',
                    urls: [{ title: 'home', url: '/' }, { title: 'reviews' }],
                },
            },
            {
                path: 'registrations/:id',
                component: RegistrationComponent,
                data: {
                    title: 'registration_detail',
                    urls: [{ title: 'home', url: '/' }, { title: 'registration_detail' }],
                },
            },
            {
                path: 'r/:id',
                component: RegistrationComponent,
                data: {
                    title: 'registration_detail',
                    urls: [{ title: 'home', url: '/' }, { title: 'registration_detail' }],
                },
            },
            {
                path: 'registrations',
                component: RegistrationsComponent,
                data: {
                    title: 'registrations',
                    urls: [{ title: 'home', url: '/' }, { title: 'registrations' }],
                },
            },
            {
                path: 'activities',
                component: OrganizedActivitiesComponent,
                data: {
                    title: 'organized_activities',
                    urls: [{ title: 'home', url: '/' }, { title: 'events' }],
                },
            },
            {
                path: 'activities/cards',
                component: OrganizedActivitiesComponent,
                data: {
                    title: 'organized_activities',
                    urls: [{ title: 'home', url: '/' }, { title: 'events' }],
                    view: View.CARDS,
                },
            },
            {
                path: 'activities/list',
                component: OrganizedActivitiesComponent,
                data: {
                    title: 'organized_activities',
                    urls: [{ title: 'home', url: '/' }, { title: 'events' }],
                    view: View.LIST,
                },
            },
            {
                path: 'activities/map',
                component: OrganizedActivitiesComponent,
                data: {
                    title: 'organized_activities',
                    urls: [{ title: 'home', url: '/' }, { title: 'events' }],
                    view: View.MAP,
                },
            },
            {
                path: 'activities/mine',
                component: OrganizedActivitiesMineComponent,
                data: {
                    title: 'activities_mine',
                    urls: [{ title: 'home', url: '/' }, { title: 'events' }],
                },
            },
            {
                path: 'activities/results',
                component: ActivityResultsComponent,
                data: {
                    title: 'results',
                    urls: [{ title: 'home', url: '/' }, { title: 'results' }],
                },
            },
            {
                path: 'activities/:id',
                component: OrganizedActivityContainer,
                data: {
                    title: 'event',
                    urls: [{ title: 'home', url: '/' }, { title: 'event' }],
                },
            },
            {
                path: `:id/:${organizedEventConfig.params.activeTab}`,
                component: OrganizedEventComponent,
                data: {
                    title: 'event',
                    urls: [
                        { title: 'organizer', url: '/organizers' },
                        { title: 'events', url: '/events' },
                        { title: 'event' },
                    ],
                },
            },
            {
                path: '',
                component: OrganizedEventsComponent,
                data: {
                    title: 'events',
                    urls: [{ title: 'organizer', url: '/organizers' }, { title: 'events' }],
                },
            },
            {
                path: '**',
                redirectTo: '',
            },
        ],
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EventsRoutingModule {}
