import { Component, NgZone, OnInit } from '@angular/core'
import { WindowService } from 'app/core/services/window.service'
import { App } from '@capacitor/app'
import { ActivatedRoute, DefaultUrlSerializer, Params, Router } from '@angular/router'
import { authConfig } from '../app.module'
import { switchMap } from 'rxjs'
import { Browser } from '@capacitor/browser'
import { PushNotifications, Token } from '@capacitor/push-notifications'
import { MatSnackBar } from '@angular/material/snack-bar'
import config from '../../../capacitor.config'
import { Globals } from '../app.consts'
import { LoginService } from 'app/users/services/login.service'
import { UserService } from 'app/users/services/user.service'
import { UserNotificationType } from '../users/users.models'
import { PostService } from 'app/core/services/post.service'
import { Parameter, Post, Sex } from 'app/core/core.models'
import { isPlatform } from '@ionic/angular'
import { StatusBar, Style } from '@capacitor/status-bar'
import { TranslateService } from '@ngx-translate/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { filter } from 'rxjs/operators'
import { MatDialog } from '@angular/material/dialog'
import { UserSetupModalComponent } from '../users/components/user-setup-modal/user-setup-modal.component'
//import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'actigo'
    touched = false
    appUrl = 'https://actigo.app'
    appScheme = `${config.appId}://`
    urlSerializer = new DefaultUrlSerializer()

    constructor(
        private postService: PostService,
        private translateService: TranslateService,
        private windowService: WindowService,
        private loginService: LoginService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private ngZone: NgZone,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
    ) {
        SplashScreen.show()
        // if (windowService.isNative) {
        //     FirebaseAnalytics.setCollectionEnabled({ enabled: true }).then(() => {
        //         console.log('Firebase Analytics enabled - instance ID =' + FirebaseAnalytics.getAppInstanceId())
        //     })
        // }
        loginService.authenticate().subscribe({ complete: () => setTimeout(() => SplashScreen.hide(), 1000) })

        if (isPlatform('ios')) StatusBar.setStyle({ style: Style.Dark })
        App.addListener('appUrlOpen', ({ url }) => {
            ngZone.run(() => {
                console.log('App URL open', url)
                if (this.windowService.isNative && isPlatform('ios'))
                    Browser.close()
                if (url?.startsWith(authConfig.redirect_uri)) {
                    this.loginService.authenticate(url)
                } else if (url.startsWith(authConfig.returnTo)) {
                    App.removeAllListeners()
                    window.location.href = '/'
                } else if (url.startsWith(this.appScheme)) {
                    let path = url.substring(url.indexOf('/', this.appScheme.length))
                    this.router.navigateByUrl(path).then(() => {
                        console.log('App link routed to', path)
                    })
                    Browser.close()
                } else if (url.startsWith(this.appUrl)) {
                    // Example url: https://actigo.app/tabs/tab2 > slug = /tabs/tab2
                    const path = url.substring(this.appUrl.length)
                    if (path.startsWith('/?iss=')) {
                        this.loginService.login('/')
                    } else {
                        let url = path.startsWith('?') || path.startsWith('/?') ? '/' : path
                        let parameters = this.urlSerializer.parse(url).queryParams
                        this.processParameters(parameters)
                        console.log('Routing to', url)
                        this.router.navigateByUrl(url).then(() => {
                            let options = {
                                name: "app_route",
                                params: {
                                    url: url,
                                    parameters: parameters,
                                },
                            }
                            // if (this.windowService.isNative) {
                            //     FirebaseAnalytics.logEvent(options).then(() => {
                            //         console.log('Firebase Analytics event ' + JSON.stringify(options))
                            //     })
                            // }
                        })
                    }
                } else {
                    console.warn('No criteria for opening URL')
                    // If no match, do nothing - let regular routing logic take over
                }
            })
        })
    }

    private processParameters(params: Params): Parameter[] {
        let parameters = Object.entries(params).map((it) => <Parameter>{ name: it[0], value: it[1].toString() })
        if (parameters.length > 0 && !params['code'] && !params['state']) {
            let lastParameters = JSON.stringify(parameters)
            console.log('Last parameters', lastParameters)
            localStorage.setItem('lastParameters', lastParameters)
        }
        return parameters
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            let parameters = this.processParameters(params)
            this.loginService.isLoggedIn$.subscribe(() => {
                if (!this.touched) {
                    this.touched = true
                    this.userService.touchUser$(parameters).subscribe((result) => {
                        console.log('Touch user', parameters, result)
                    })
                }
            })
            this.loginService.isLoggedIn$.pipe(filter((it) => it)).subscribe(() => {
                let lastParameters = localStorage.getItem('lastParameters')
                if (lastParameters) {
                    parameters = JSON.parse(lastParameters)
                    localStorage.removeItem('lastParameters')
                }
                if (params['n_id']) {
                    let id = params['n_id']
                    console.log('Notification ID in URL', id)
                    this.receiveNotification(id)
                }
            })
        })

        //FIXME this should also work in browser as Firebase also supports web notifications
        if (this.windowService.isNative) {
            PushNotifications.requestPermissions().then((status) => {
                if (status.receive === 'granted') {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register()
                        .then(() => {
                            console.log('Push notifications registered')
                        })
                        .catch((reason) => {
                            console.log('Push notifications registration failed', reason)
                        })
                } else {
                    console.error('Push notification request permission unexpected status: ' + JSON.stringify(status))
                }
            })
            PushNotifications.removeAllListeners().then(() => {
                PushNotifications.addListener('registration', (token: Token) => {
                    console.log('Push notification registration success, token', token.value)
                    this.windowService.notificationToken$.next(token.value)
                    this.loginService.isLoggedIn$.subscribe((result) => {
                        if (result) {
                            this.windowService.device$.pipe(
                                switchMap((device) => {
                                    console.log('Setting user notification token for', device.uuid, device.platform, device.name)
                                    return this.userService.setUserNotificationToken$(
                                        UserNotificationType.Firebase,
                                        token.value,
                                        `${device.platform}:${device.name}`,
                                        device.uuid
                                    )
                                }),
                            )
                            .subscribe((result) => {
                                console.log('User notification token set', result)
                            })
                        }
                    })
                })

                PushNotifications.addListener('registrationError', (error: any) => {
                    console.error('Error on push notification registration', error)
                })

                PushNotifications.addListener('pushNotificationReceived', (it: any) => {
                    let message = 'Push notification received:' + JSON.stringify(it)
                    console.log(message)
                    //alert(message)
                    this.handleNotification(it.data as Post, true, false)
                })

                PushNotifications.addListener('pushNotificationActionPerformed', (it: any) => {
                    let message = 'Push notification action performed: ' + JSON.stringify(it)
                    console.log(message)
                    //alert(message)
                    this.handleNotification(it.notification.data as Post, false, true)
                })
            })
        }

        this.loginService.userLoggedIn$.subscribe((user) => {
            if (!user.profile.updated || user.profile.sex == Sex.None || !user.profile.givenName || !user.profile.surname || !user.profile.birthDate) {
                this.dialog.open(UserSetupModalComponent, {
                    width: '600px',
                    data: user,
                    disableClose: true,
                    closeOnNavigation: false
                })
            }
        })
    }

    private handleNotification(post: Post, showSnack = true, refNavigate = true) {
        this.ngZone.run(() => {
            console.log('Notification ' + JSON.stringify(post) + ' handling started', showSnack, refNavigate)
            if (showSnack) {
                this.snackBar
                    .open(`${post.name}: ${post.text}`, post.ref ? this.translateService.instant('open') : null, {
                        duration: Globals.durationSnackBarMessage,
                        panelClass: 'snack-bar-info',
                        verticalPosition: 'bottom',
                    })
                    .onAction()
                    .subscribe(() => {
                        this.handleRef(post.ref)
                    })
            }
            if (refNavigate && post.ref) {
                this.handleRef(post.ref)
            }
            if (this.loginService.isLoggedIn) {
                this.receiveNotification(post.id)
            }
            console.log('Notification handling done')
        })
    }

    private handleRef(ref: string) {
        if (ref.startsWith('http')) {
            console.log("Notification handling ref to external URL", ref)
            Browser.open({ url: ref }).then(() => {
                console.log(`Browser open ${ref} done`)
            }).catch(console.error)
        } else {
            console.log("Notification handling ref to route", ref)
            this.router.navigateByUrl(ref.startsWith('/') ? ref : '/' + ref).then((result) => {
                console.log('Notification ref route navigate', ref, result)
            })
        }
    }

    private receiveNotification(id: string) {
        this.postService.receivePost$(id).subscribe((result) => {
            console.log('Notification received', result)
        })
    }
}
