import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps'
import { darkStyle, defaultStyle, markerIcons, trackColors } from 'app/core/components/map/map.config'
import { MapActivity, MapTrack, MarkerType } from 'app/core/components/map/map.model'
import * as R from 'ramda'
import { Activity, OrganizedActivity, Track, TrackPoint, TrackSegment } from 'app/events/events.models'
import { Position } from 'app/core/core.models'
import { LocationService } from 'app/core/services/location.service'

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input()
    darkMode = false

    @Input()
    defaultZoom = 10

    @Input()
    pointLimit = 500

    @Input()
    activity: Activity

    @Input()
    activityTracking: boolean = false

    @Input()
    organizedActivity: OrganizedActivity[] = []

    @Input()
    showTrackForOrganizedActivity = false

    @Input()
    mapHeight = '800px'

    @Input()
    showLegendForTracks = false

    @Input()
    inputMarkerAllowed = false

    @Input()
    inputMarkerRemovingAllowed = true

    @Input()
    inputMarkerMovingAllowed = true

    @Input()
    inputMarker?: Position

    @Input()
    gestureHandling: string = 'auto'

    @Input()
    markerDisabled = false

    @Input()
    fullscreenControl = false

    @Output()
    markerClick: EventEmitter<OrganizedActivity> = new EventEmitter<OrganizedActivity>()

    @Output()
    inputMarkerAction: EventEmitter<Position> = new EventEmitter<Position>()

    apiLoaded$: Observable<boolean>
    display: google.maps.LatLngLiteral

    @ViewChild('map')
    map: GoogleMap

    @ViewChild('legend')
    legend: ElementRef

    @ViewChild(MapInfoWindow, { static: false })
    infoWindow: MapInfoWindow

    styles: google.maps.MapTypeStyle[]
    mapActivities: MapActivity[] = []
    mapRendered = false
    markerIcons = markerIcons
    markerType = MarkerType
    mapLegendSegments: MapTrack[] = []
    trackColors = trackColors

    constructor(public httpClient: HttpClient, private locationService: LocationService) {}

    markerInputPosition: google.maps.LatLngLiteral
    markerInputPrevPosition: google.maps.LatLngLiteral
    mapDblClicked = false
    selectedOrganizedActivity: OrganizedActivity
    lastMarker: MapMarker

    addMarker(event: google.maps.MapMouseEvent) {
        this.mapDblClicked = false
        this.markerInputPrevPosition = this.markerInputPosition
        setTimeout(() => {
            if (!this.mapDblClicked) {
                this.markerInputPosition = event.latLng.toJSON()
                this.inputMarkerAction.emit({
                    latitude: this.markerInputPosition.lat,
                    longitude: this.markerInputPosition.lng,
                })
            }
        }, 200)
    }

    keepMarkerOnSpot() {
        this.mapDblClicked = true
        this.markerInputPosition = this.markerInputPrevPosition
    }

    openInfoWindow(marker: MapMarker, organizedActivity: OrganizedActivity, showTooltip = false) {
        this.selectedOrganizedActivity = organizedActivity

        if (!this.markerDisabled) {
            this.infoWindow.open(marker)
            this.lastMarker = marker
        }
        if (!showTooltip) {
            this.markerClick.emit(organizedActivity)
            this.infoWindow.close()
        }
    }

    moveMap(event: google.maps.MapMouseEvent) {
        // this.map.googleMap.setCenter(event.latLng.toJSON())
    }

    mapClick(event: google.maps.MapMouseEvent) {}

    move(event: google.maps.MapMouseEvent) {
        this.display = event.latLng.toJSON()
    }

    getDraggedInputMarker(event: google.maps.MapMouseEvent) {
        this.inputMarkerAction.emit({ latitude: event.latLng.lat(), longitude: event.latLng.lng() })
    }

    inputMarkerClick() {
        if (this.inputMarkerRemovingAllowed) {
            this.markerInputPosition = null
            this.inputMarkerAction.emit(null)
        }
    }

    centralize(map: GoogleMap, points: google.maps.LatLngLiteral[], zoom: boolean = true) {
        points = R.uniq(points)
        const defaultMapCenter: google.maps.LatLngLiteral = {
            lat: 50.073658,
            lng: 14.41854,
        }
        if (points.length > 1) {
            const bounds = new google.maps.LatLngBounds()
            R.forEach((marker: google.maps.LatLngLiteral) => {
                bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
            })(points)
            map.fitBounds(bounds)
        } else {
            setTimeout(() => {
                map.googleMap.panTo(points[0] ? points[0] : defaultMapCenter)
                if (zoom) map.googleMap.setZoom(this.defaultZoom)
            })
        }
    }

    getAllPoints(): google.maps.LatLngLiteral[] {
        let points: google.maps.LatLngLiteral[] = []
        const getPoints = (prop1, prop2): google.maps.LatLngLiteral[] | google.maps.LatLngLiteral[][] =>
            R.pipe(R.map(R.prop(prop1)), R.flatten, R.map(R.prop(prop2)), R.values)
        const mapMarkerPoints: google.maps.LatLngLiteral[] = R.pipe(getPoints('markers', 'point'))(this.mapActivities)
        const mapTrackPoints: google.maps.LatLngLiteral[] = R.pipe(
            getPoints('tracks', 'points'),
            R.reduce(R.concat, []),
        )(this.mapActivities)
        points = [...mapMarkerPoints, ...mapTrackPoints]
        if (this.markerInputPosition) {
            points = [...points, this.markerInputPosition]
        }
        return points
    }

    setMapStyle(): void {
        if (this.map) {
            this.map.googleMap.setOptions({
                styles: this.darkMode ? darkStyle : defaultStyle,
            })
        }
    }

    ngAfterViewChecked() {
        if (this.map && !this.mapRendered) {
            if (this.legend) {
                this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.legend.nativeElement)
            }
            this.mapRendered = true
            const points = this.getAllPoints()
            this.setMapStyle()
            this.centralize(this.map, points)
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.darkMode) {
            this.setMapStyle()
        }
        if (
            changes.activity ||
            changes.organizedActivity ||
            (changes.inputMarker &&
                // changes.inputMarker.previousValue === null &&
                changes.inputMarker.currentValue !== null)
        ) {
            this.ngOnInit()
            if (
                changes.inputMarker && changes.inputMarker.currentValue &&
                !changes.inputMarker.firstChange &&
                changes.inputMarker.currentValue.latitude !== changes.inputMarker.previousValue?.latitude &&
                changes.inputMarker.currentValue.longitude !== changes.inputMarker.previousValue?.longitude
            ) {
                this.centralize(
                    this.map,
                    [
                        {
                            lat: changes.inputMarker.currentValue.latitude,
                            lng: changes.inputMarker.currentValue.longitude,
                        },
                    ],
                    false,
                )
            }
        }

        if (changes.markerDisabled && changes.markerDisabled.currentValue === false) {
            this.infoWindow.open(this.lastMarker)
        }
        if (this.map) {
            // this.centralize();
        }
    }

    ngOnInit() {
        this.mapActivities = this.organizedActivity?.length
            ? this.prepareOrganizedActivities(this.organizedActivity)
            : this.prepareMapTracks(this.activity && this.activity.track)
        this.markerInputPosition = this.inputMarker
            ? {
                  lat: this.inputMarker.latitude,
                  lng: this.inputMarker.longitude,
              }
            : null
        // this.mapLegendSegments = this.prepareMapLegendSegments(
        //     this.mapActivities
        // );
        // this.apiLoaded$ = this.httpClient
        //     .jsonp(
        //         `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&language=${this.language}`,
        //         'callback',
        //     )
        //     .pipe(
        //         map((v) => {
        //             return true
        //         }),
        //         catchError((e) => {
        //             return of(false)
        //         }),
        //     )
        if (this.activityTracking) {
            if (
                this.activity.track?.segments?.length > 0 &&
                this.activity.track.segments[this.activity.track.segments.length - 1].points.length > 0
            ) {
                // if there is a point in the current (last) segment
                let segment = this.activity.track.segments[0]
                let lastPoint = segment.points[segment.points.length - 1]
                this.centralize(
                    this.map,
                    [
                        {
                            lat: lastPoint.latitude,
                            lng: lastPoint.longitude,
                        },
                    ],
                    false,
                )
            } else {
                // otherwise use the current position
                this.locationService.currentPosition.then((position) => {
                    if (position) {
                        this.centralize(
                            this.map,
                            [
                                {
                                    lat: position.latitude,
                                    lng: position.longitude,
                                },
                            ],
                            false,
                        )
                    }
                })
            }
        }
    }

    private prepareOrganizedActivities(activities: OrganizedActivity[]): MapActivity[] {
        const activitiesWithPosition = R.filter(R.prop('position'))(activities)
        return R.map((activity: OrganizedActivity) => {
            const segments: Track = R.path(['activity', 'track'], activity)
            const tracks: MapTrack[] = this.showTrackForOrganizedActivity ? this.getMapTracks(segments) : []
            const trackMarkers: MapMarker[] = this.showTrackForOrganizedActivity ? this.getTrackMarkers(tracks) : []

            return {
                organizedActivity: activity,
                markers: R.flatten([
                    ...trackMarkers,
                    {
                        icon: this.markerIcons[MarkerType.default],
                        type: MarkerType.default,
                        point: {
                            lat: activity.position.latitude,
                            lng: activity.position.longitude,
                        },
                    },
                ]),
                tracks,
            }
        })(activitiesWithPosition)
    }

    private prepareMapTracks(track: Track): MapActivity[] {
        const tracks: MapTrack[] = this.getMapTracks(track)
        const trackMarkers: MapMarker[] = this.getTrackMarkers(tracks)

        return [
            {
                markers: R.flatten(trackMarkers),
                tracks,
            },
        ]
    }

    // private prepareMapLegendSegments(mapActivities: MapActivity[]): MapTrack[] {
    //     return R.pipe(
    //         R.map(R.prop('tracks')),
    //         R.mergeAll,
    //         R.map(R.pick(['strokeColor', 'name'])),
    //         R.values
    //     )(mapActivities);
    // }

    private getTrackMarkers(tracks: MapTrack[]): MapMarker[] {
        const markers = R.map((track: MapTrack) => {
            return [
                {
                    icon: this.markerIcons[MarkerType.start],
                    type: MarkerType.start,
                    point: R.head(track.points),
                },
                {
                    icon: this.markerIcons[MarkerType.finish],
                    type: MarkerType.finish,
                    point: R.last(track.points),
                },
            ]
        })(tracks)
        return markers
    }

    private getMapTracks(track: Track): MapTrack[] {
        return R.pipe(
            R.prop('segments'),
            R.mapObjIndexed((segments: TrackSegment[], index: string) =>
                R.pipe(
                    R.map((points: TrackPoint[]) => {
                        let mapPoints = R.map((point: TrackPoint) => ({
                            lat: point.latitude,
                            lng: point.longitude,
                        }))(points)
                        let ratio = points.length / this.pointLimit
                        if (ratio > 1) {
                            return R.times((i) => mapPoints[Math.floor(i * ratio)], this.pointLimit)
                        } else {
                            return mapPoints
                        }
                    }),
                    R.assoc('strokeColor', trackColors[index]),
                    R.assoc('name', `Segment${parseInt(index, 10) + 1}`),
                )(segments),
            ),
            R.values,
        )(track)
    }
}
