import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from 'app/core/pipes/pipes.module';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselComponent } from 'app/core/components/carousel/carousel.component';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

@NgModule({
    declarations: [CarouselComponent],
    imports: [
        CommonModule,
        FlexLayoutModule,
        PipesModule,
        MatCardModule,
        TranslateModule,
        GalleryModule,
        LightboxModule,
    ],
    providers: [],
    exports: [CarouselComponent],
})
export class CarouselModule {}
