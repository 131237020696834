<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{'delete_account' | translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>{{'delete_account_confirm' | translate}}</p>

                <div class="form-element text-right">
                    <button
                        (click)="delete()"
                        mat-raised-button
                        color="warn"
                        type="submit"
                        class="button"
                        >{{'yes' | translate}}, {{'delete_account' | translate | lowercase}}</button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
