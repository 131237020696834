<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div *ngIf="false" class="user-profile" style="background: url(assets/images/background/user-info.jpg) no-repeat">
    <!-- User profile image -->
    <ng-container *ngIf="isLogged">
        <div class="profile-img">
            <img [src]="user.picture" alt="user" (click)="handleNotify()" />
        </div>
        <!-- User profile text-->
        <!-- ============================================================== -->
        <!-- Profile - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="profile-text">
            <a [matMenuTriggerFor]="sdprofile" class="d-flex align-items-center">
                {{user.name}}
                <i class="ti-angle-down font-12 ml-auto"></i>
            </a>
        </div>
        <mat-menu #sdprofile="matMenu" class="mymegamenu">
            <button *ngFor="let navItem of menuItems" (click)="menuClick.emit(navItem)" mat-menu-item>
                <mat-icon>{{navItem.icon}}</mat-icon> {{navItem.name}}
            </button>
        </mat-menu>
    </ng-container>
</div>
<mat-nav-list appAccordion>
    <ng-container *ngFor="let menuItem of mainMenuItems; let i = index">
        <mat-list-item
            appAccordionLink
            *ngIf="menuItem.visible"
            [routerLinkActiveOptions]="{exact: true}"
            routerLinkActive="selected"
            [class.active]="router.url | activeUrl : menuItem.state : menuItem.excludedStates"
            group="{{ menuItem.state }}"
            (click)="scrollToTop()"
            class="menu-item"
            [disabled]="menuItem.disabled"
        >
            <a
                class=""
                appAccordionToggle
                [routerLink]="menuItem.state"
                *ngIf="menuItem.type === 'link'"
                (click)="handleNotify()"
            >
                <mat-icon>{{ menuItem.icon }}</mat-icon>
                <span>{{ menuItem.name | translate }}</span>
                <span fxFlex></span>
                <span class="label label-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
            </a>

            <a class="" appAccordionToggle href="{{ menuItem.state }}" *ngIf="menuItem.type === 'extLink'">
                <mat-icon>{{ menuItem.icon }}</mat-icon>
                <span>{{ menuItem.name | translate }}</span>
                <span fxFlex></span>
                <span class="label label-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
            </a>

            <a
                class=""
                appAccordionToggle
                href="{{ menuItem.state }}"
                target="_blank"
                *ngIf="menuItem.type === 'extTabLink'"
            >
                <mat-icon>{{ menuItem.icon }}</mat-icon>
                <span>{{ menuItem.name | translate }}</span>
                <span fxFlex></span>
                <span class="label label-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
            </a>

            <a class="" appAccordionToggle href="javascript:;" *ngIf="menuItem.type === 'sub'">
                <mat-icon>{{ menuItem.icon }}</mat-icon>
                <span>{{ menuItem.name | translate }}</span>
                <span fxFlex></span>
                <span class="label label-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
                <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
            </a>

            <mat-nav-list class="sub-item" *ngIf="menuItem.type === 'sub'">
                <mat-list-item *ngFor="let childItem of menuItem.children; let j = index" routerLinkActive="selected">
                    <a
                        [routerLink]="['/', menuItem.state, childItem.state]"
                        *ngIf="childItem.type === 'link'"
                        class="relative"
                        routerLinkActive="selected"
                        (click)="handleNotify()"
                        >{{ childItem.name | translate }}</a
                    >
                </mat-list-item>

                <mat-list-item *ngFor="let childItem of menuItem.children; let j = index">
                    <a
                        class=""
                        href="javascript: void(0);"
                        *ngIf="childItem.type === 'subchild'"
                        (click)="addExpandClass(childItem.name)"
                        [ngClass]="{ selected: showMenu === childItem.name }"
                    >
                        <span>{{ childItem.name | translate }}</span>
                        <span fxFlex></span>
                        <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
                    </a>

                    <mat-nav-list class="child-sub-item" *ngIf="childItem.type === 'subchild'">
                        <mat-list-item *ngFor="let child of childItem.children" routerLinkActive="selected">
                            <a
                                [routerLink]="['/', menuItem.state, childItem.state, child.state]"
                                routerLinkActive="selected"
                                >{{ child.name | translate }}</a
                            >
                        </mat-list-item>
                    </mat-nav-list>
                </mat-list-item>
            </mat-nav-list>

            <div class="separator text-muted" [class.separator--first]="i === 0" *ngIf="menuItem.type === 'separator'">
                <span>{{ menuItem.name | translate }}</span>
            </div>
        </mat-list-item>
    </ng-container>
</mat-nav-list>
