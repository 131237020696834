import { ChangeDetectorRef, Component, OnDestroy, Output, EventEmitter, Input } from '@angular/core'
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { MediaMatcher } from '@angular/cdk/layout'
import { LayoutService } from 'app/layouts/services/layout.service'
import { MenuItem, MenuMainItem } from 'app/layouts/layouts.models'
import { Router } from '@angular/router'

@Component({
    selector: 'app-vertical-sidebar',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: ['./vertical-sidebar.component.scss'],
})
export class VerticalAppSidebarComponent implements OnDestroy {
    config: PerfectScrollbarConfigInterface = {}
    mobileQuery: MediaQueryList
    @Input()
    mainMenuItems: MenuMainItem[]

    @Input()
    isLogged: boolean

    @Input()
    homeUrl: string

    @Input()
    menuItems: any

    @Input()
    user: any

    @Output()
    menuClick = new EventEmitter<MenuItem<any>>()

    @Input() showClass: boolean = false

    @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>()

    private readonly _mobileQueryListener: () => void
    status = true
    showMenu = ''
    itemSelect: number[] = []
    parentIndex = 0
    childIndex = 0

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0'
        } else {
            this.showMenu = element
        }
    }

    subclickEvent(): void {
        this.status = true
    }
    scrollToTop(): void {
        document.querySelector('.page-wrapper')?.scroll({
            top: 0,
            left: 0,
        })
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public layoutService: LayoutService,
        public router: Router,
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)')
        this._mobileQueryListener = () => changeDetectorRef.detectChanges()
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener)
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener)
    }

    handleNotify() {
        if (window.innerWidth < 1024) {
            this.notify.emit(!this.showClass)
        }
    }
}
