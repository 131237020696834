import { Injectable } from '@angular/core'
import {
    ConnectionType, PostRule,
    User,
    UserAccessKey,
    UserActivity,
    UserConnection,
    UserNotificationType,
    UserProfile,
} from 'app/users/users.models'
import { from, Observable, switchMap, zip } from 'rxjs'
import { Address, ImportResult, OAuthToken, Parameter, Person, Sex } from 'app/core/core.models'
import { ApolloService } from 'app/core/services/apollo.service'
import { Activity, TrackPoint } from 'app/events/events.models'
import { environment } from 'environments/environment'
import { LocationService } from 'app/core/services/location.service'
import {
    ME,
    USER,
    USER_FOLLOW_STATUS,
    PERSON,
    USER_FOLLOWER_PERSONS,
    USER_FOLLOWING_PERSONS,
    USER_ACTIVITY,
    USER_ACTIVITIES,
    PERSONS, OAUTH_REQUEST_TOKEN, USER_ACTIVITY_LIKING_PERSONS,
} from '../users.queries'
import {
    SIGNUP,
    TOUCH_USER,
    UPDATE_USER_ACTIVITY,
    FOLLOW_USER,
    UNFOLLOW_USER,
    APPROVE_USER_FOLLOW,
    DELETE_FOLLOW_USER,
    SET_USER_NOTIFICATION_TOKEN,
    REMOVE_USER_NOTIFICATION_TOKEN,
    SET_USER_CONNECTION,
    REMOVE_USER_CONNECTION,
    SET_USER_ACCESS_KEY,
    PURGE_USER,
    REMOVE_USER_ACCESS_KEY,
    IMPORT_USER_ACTIVITIES,
    IMPORT_USER_FRIENDS,
    UPDATE_USER_ADDRESS,
    UPDATE_USER_PROFILE,
    SYNC_PROFILE,
    UPDATE_USER_NICKNAME,
    UPDATE_USER_CONTACT_EMAIL,
    UPDATE_USER_PHONE_NUMBER,
    VERIFY_USER_CONTACT_EMAIL,
    VERIFY_USER_PHONE_NUMBER,
    CREATE_USER_ACTIVITY, CREATE_FEEDBACK, UPDATE_USER_POST_RULES, UPDATE_USER_ACTIVITY_NAME,
} from '../users.mutations'
import { Device } from '@capacitor/device'

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private readonly apolloService: ApolloService, private readonly locationService: LocationService) {}

    getUser$(userId: string) {
        return this.apolloService.watchQuery<User>({
            query: USER,
            variables: {
                query: {
                    userId,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getUserFollowStatus$(targetUserId: string, reverse: boolean = false): Observable<string> {
        return this.apolloService.watchQuery<string>({
            query: USER_FOLLOW_STATUS,
            variables: {
                query: {
                    targetUserId: targetUserId,
                    reverse: reverse,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getPerson$(ref: string): Observable<Person> {
        return this.apolloService.watchQuery<Person>({
            query: PERSON,
            variables: {
                query: {
                    ref,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getPersons$(name: string, limit: number = 20): Observable<Person[]> {
        return this.apolloService.watchQuery<Person[]>({
            query: PERSONS,
            variables: {
                query: {
                    name: name,
                    limit: limit
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getUserFollowerPerson$(userId: string): Observable<Person[]> {
        return this.apolloService.watchQuery<Person[]>({
            query: USER_FOLLOWER_PERSONS,
            variables: {
                query: {
                    userId: userId,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getUserFollowingPerson$(userId: string): Observable<Person[]> {
        return this.apolloService.watchQuery<Person[]>({
            query: USER_FOLLOWING_PERSONS,
            variables: {
                query: {
                    userId: userId,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    me$(): Observable<User | null> {
        return this.apolloService.query<User | null>({
            query: ME,
        })
    }

    getCurrentUser$(): Observable<User | null> {
        return this.apolloService.watchQuery<User | null>({
            query: ME,
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    signUp$(): Observable<User> {
        return this.apolloService.mutate<User>({
            mutation: SIGNUP,
        })
    }

    getOAuthRequestToken(connectionType: ConnectionType): Observable<OAuthToken> {
        return this.apolloService.watchQuery<OAuthToken>({
            query: OAUTH_REQUEST_TOKEN,
            variables: {
                query: {
                    connectionType: connectionType,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    touchUser$(parameters: Parameter[]): Observable<boolean> {
        return zip(Device.getInfo(), this.locationService.currentPosition).pipe(
            switchMap(([info, position]) =>
                this.apolloService.mutate<boolean>({
                    mutation: TOUCH_USER,
                    variables: {
                        command: {
                            position: position,
                            parameters: parameters,
                            device: info.platform
                        },
                    },
                }),
            ),
        )
    }

    purgeUser$(): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: PURGE_USER,
        })
    }

    syncProfile$(): Observable<User> {
        return this.apolloService.mutate<User>({
            mutation: SYNC_PROFILE,
        })
    }

    createFeedback$(entityId: string, entityType: string, text: String): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: CREATE_FEEDBACK,
            variables: {
                command: {
                    entityId: entityId,
                    entityType: entityType,
                    text: text
                },
            },
        })
    }

    updateUserNickname$(nickname: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UPDATE_USER_NICKNAME,
            variables: {
                command: {
                    nickname: nickname,
                },
            },
        })
    }

    updateUserContactEmail$(userId: string, newEmail: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UPDATE_USER_CONTACT_EMAIL,
            variables: {
                command: {
                    userId: userId,
                    newEmail: newEmail,
                },
            },
        }, false)
    }

    updateUserPhoneNumber$(userId: string, newPhoneNumber: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UPDATE_USER_PHONE_NUMBER,
            variables: {
                command: {
                    userId: userId,
                    newPhoneNumber: newPhoneNumber,
                },
            },
        }, false)
    }

    verifyUserContactEmail$(code: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: VERIFY_USER_CONTACT_EMAIL,
            variables: {
                command: {
                    code: code,
                },
            },
        })
    }

    verifyUserPhoneNumber$(code: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: VERIFY_USER_PHONE_NUMBER,
            variables: {
                command: {
                    code: code,
                },
            },
        })
    }

    createUserActivity$(
        started: Date,
        activity: Activity,
        elapsedTime: number,
        movingTime: number,
        isLive: boolean,
    ): Observable<string> {
        return this.apolloService.mutate<string>({
            mutation: CREATE_USER_ACTIVITY,
            variables: {
                command: {
                    started: started,
                    activity: activity,
                    elapsedTime: elapsedTime,
                    movingTime: movingTime,
                    isLive: isLive,
                },
            },
        })
    }

    updateUserActivity$(
        id: string,
        elapsedTime: number,
        movingTime: number,
        distance: number,
        isLive: boolean,
        trackPoints: TrackPoint[],
    ): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UPDATE_USER_ACTIVITY,
            variables: {
                command: {
                    userActivityId: id,
                    elapsedTime: elapsedTime,
                    movingTime: movingTime,
                    distance: distance,
                    isLive: isLive,
                    trackPoints: trackPoints,
                },
            },
        })
    }

    updateUserActivityName$(
        id: string,
        name:string
    ): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UPDATE_USER_ACTIVITY_NAME,
            variables: {
                command: {
                    userActivityId: id,
                    name: name,
                },
            },
        })
    }

    followUser$(userId: string): Observable<string> {
        return this.apolloService.mutate<string>({
            mutation: FOLLOW_USER,
            variables: {
                command: {
                    userId: userId,
                },
            },
        })
    }

    unfollowUser$(userId: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: UNFOLLOW_USER,
            variables: {
                command: {
                    userId: userId,
                },
            },
        })
    }

    approveUserFollow$(userId: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: APPROVE_USER_FOLLOW,
            variables: {
                command: {
                    userId: userId,
                },
            },
        })
    }

    deleteUserFollow$(userId: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: DELETE_FOLLOW_USER,
            variables: {
                command: {
                    userId: userId,
                },
            },
        })
    }

    setUserNotificationToken$(
        type: UserNotificationType,
        token: string,
        info: string,
        deviceId: string,
    ): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: SET_USER_NOTIFICATION_TOKEN,
            variables: {
                command: {
                    type: type,
                    token: token,
                    info: info,
                    deviceId: deviceId,
                },
            },
        })
    }

    removeUserNotificationToken$(token: string): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: REMOVE_USER_NOTIFICATION_TOKEN,
            variables: {
                command: {
                    token: token,
                },
            },
        })
    }

    setUserConnection$(type: ConnectionType, code: string, isNative: boolean, token?: string, tokenSecret?: string): Observable<UserConnection> {
        return this.apolloService.mutate<UserConnection>({
            mutation: SET_USER_CONNECTION,
            variables: {
                command: {
                    type: type,
                    code: code,
                    isNative: isNative,
                    token: token,
                    tokenSecret: tokenSecret
                },
            },
        })
    }

    removeUserConnection$(connection: UserConnection): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: REMOVE_USER_CONNECTION,
            variables: {
                command: {
                    type: connection.type,
                },
            },
        })
    }

    setUserAccessKey$(accessKey: UserAccessKey): Observable<UserAccessKey> {
        return this.apolloService.mutate<UserAccessKey>({
            mutation: SET_USER_ACCESS_KEY,
            variables: {
                command: {
                    name: accessKey.name,
                    secret: accessKey.secret,
                    rights: accessKey.rights,
                    info: accessKey.info,
                },
            },
        })
    }

    removeUserAccessKey$(accessKey: UserAccessKey): Observable<boolean> {
        return this.apolloService.mutate<boolean>({
            mutation: REMOVE_USER_ACCESS_KEY,
            variables: {
                command: {
                    name: accessKey.name,
                },
            },
        })
    }

    updateUserPostRules$(postRules: PostRule[]): Observable<PostRule[]> {
        return this.apolloService.mutate<PostRule[]>({
            mutation: UPDATE_USER_POST_RULES,
            variables: {
                command: {
                    postRules: postRules
                },
            },
        })
    }

    importUserActivities$(connectionType: ConnectionType): Observable<ImportResult> {
        return this.apolloService.mutate<ImportResult>({
            mutation: IMPORT_USER_ACTIVITIES,
            variables: {
                command: {
                    type: connectionType,
                },
            },
        })
    }

    importUserFriends$(connectionType: ConnectionType): Observable<ImportResult> {
        return this.apolloService.mutate<ImportResult>({
            mutation: IMPORT_USER_FRIENDS,
            variables: {
                command: {
                    type: connectionType,
                },
            },
        })
    }

    getUserActivity$(userActivityId: string) {
        return this.apolloService.watchQuery<UserActivity>({
            query: USER_ACTIVITY,
            variables: {
                query: {
                    userActivityId: userActivityId,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getLiveUserActivity$(userActivityId: string) {
        return this.apolloService.watchQuery<UserActivity>({
            query: USER_ACTIVITY,
            pollInterval: (<any>environment).pollInterval || 10000,
            variables: {
                query: {
                    userActivityId: userActivityId,
                },
            },
            nextFetchPolicy: 'no-cache',
            fetchPolicy: 'no-cache',
        })
    }

    getUserActivities$(userId: string, seekId: string | null = null, limit: number = 25): Observable<UserActivity[]> {
        return this.apolloService.watchQuery<UserActivity[]>({
            query: USER_ACTIVITIES,
            variables: {
                query: {
                    userId: userId,
                    seekId: seekId,
                    limit: limit,
                },
            },
        })
    }

    getUserActivityLikingPersons$(userActivityId: string) {
        return this.apolloService.watchQuery<Person[]>({
            fetchPolicy: 'no-cache',
            query: USER_ACTIVITY_LIKING_PERSONS,
            variables: {
                query: {
                    userActivityId,
                },
            },
        })
    }


    updateUserAddress(userId: String, address: Address): Observable<User> {
        return this.apolloService.mutate<User>({
            mutation: UPDATE_USER_ADDRESS,
            variables: {
                command: {
                    userId: userId,
                    address: address,
                },
            },
        })
    }

    updateUserProfile(userId: String, profile: UserProfile): Observable<User> {
        return this.apolloService.mutate<User>({
            mutation: UPDATE_USER_PROFILE,
            variables: {
                command: {
                    userId: userId,
                    profile: profile,
                },
            },
        })
    }

    hasCompletedProfile(user: User) {
        const profile = user.profile
        console.log(profile)
        return (
            profile.sex != Sex.None &&
            profile.birthDate != null &&
            profile.givenName.length > 0 &&
            profile.surname.length > 0
        )
    }
}
