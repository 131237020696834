<div fxLayout="row wrap" *ngIf="user != null">
    <div
        fxFlex.gt-xs="50%"
        fxFlex.gt-sm="50%"
        fxFlex="100"
    >
        <mat-card>
            <mat-card-content>
                <app-user-profile
                    [userProfile]="user.profile"
                    (saveProfile)="updateProfile($event)"
                ></app-user-profile>
            </mat-card-content>
        </mat-card>
        <app-address
            [title]="'address'|translate"
            [subtitle]="'address_desc'|translate"
            [address]="user.address"
            (updateAddress)="updateAddress($event)"
        ></app-address>
    </div>
    <div
        fxFlex.gt-xs="50%"
        fxFlex.gt-sm="50%"
        fxFlex="100"
    >
        <app-identity [user]="user"></app-identity>
        <app-verified-contact
            [title]="'contactEmail'|translate"
            [subtitle]="'contactEmail_desc'|translate"
            [placeholder]="user.contactEmail.value"
            [verifiedContact]="user.contactEmail"
            (updateVerifiedContact)="updateContactEmail($event)"
        ></app-verified-contact>
        <app-verified-contact
            [title]="'phoneNumber'|translate"
            [subtitle]="'phoneNumber_desc'|translate"
            [placeholder]="user.phoneNumber.value == '+0' ? '+XXX XXX XXX XXX' : user.phoneNumber.value"
            [verifiedContact]="user.phoneNumber"
            (updateVerifiedContact)="updatePhoneNumber($event)"
        ></app-verified-contact>
        <app-user-picture></app-user-picture>
        <app-user-nickname></app-user-nickname>
        <app-user-connections></app-user-connections>
        <app-user-post-rules></app-user-post-rules>
        <mat-card>
            <mat-card-content>
                <mat-card-title>{{'other' | translate}}</mat-card-title>
                <mat-card-actions class="form-element text-right">
                    <button
                        mat-raised-button
                        color="primary"
                        routerLink="/developers"
                    >
                        {{'developers' | translate}}
                    </button>
                    <button
                        mat-raised-button
                        color="warn"
                        routerLink="/users/delete"
                    >
                        {{'delete_account' | translate}}
                    </button>
                </mat-card-actions>
            </mat-card-content>
        </mat-card>
    </div>
</div>
