<div fxLayout="row wrap">
    <div *ngIf="isShowingInput" fxFlex.gt-sm="100%" fxFlex="100">
        <mat-form-field class="mr-1 ml-1" fxFlex>
            <textarea
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                [(ngModel)]="text"
                [placeholder]="type.toLowerCase() | translate"
                [mentionConfig]="(loginService.mentionConfig$ | async) || { mentions: [] }"
            ></textarea>
        </mat-form-field>
        <button mat-button color="accent" (click)="createMessage()" [disabled]="!text">
            <mat-icon>send</mat-icon>
        </button>
    </div>
    <div fxFlex.gt-sm="100%" fxFlex="100" *ngFor="let message of messages$ | async">
        <app-message
            [message]="message"
            [isShowingPerson]="isShowingPerson"
            [user]="loginService.currentUser$ | async"
        ></app-message>
    </div>
</div>
