import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject, switchMap } from 'rxjs'
import { PaymentService } from 'app/payments/services/payment.service'
import { Payment, PaymentStatus } from 'app/payments/payments.models'
import { LoginService } from 'app/users/services/login.service'

@Component({
    selector: 'app-reviews',
    templateUrl: './payment-result.component.html',
})
export class PaymentResultComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private paymentService: PaymentService,
        private loginService: LoginService,
    ) {}

    checkingPayment$ = new BehaviorSubject(true)
    payment$ = new BehaviorSubject<PaymentStatus>(null)

    ngOnInit(): void {
        let id = this.route.snapshot.queryParams['id']

        this.loginService.userLoggedIn$
            .pipe(switchMap((it) => this.paymentService.checkPayment$(id)))
            .subscribe((it) => {
                this.payment$.next(it)
                this.checkingPayment$.next(false)
            })
    }
}
