import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { IOptions } from 'app/core/core.models'
import { LanguageCode } from 'app/core/constants/languages'
import { LanguageService } from 'app/core/services/language.service'

@Pipe({
    name: 'optionsTranslate',
    pure: false,
})
export class OptionsTranslatePipe implements PipeTransform {
    private result: IOptions<any>
    private latestOptions: IOptions<any>
    private latestLanguageCode: LanguageCode
    private latestPrefix: string
    private checked = false

    constructor(private readonly translateService: TranslateService, private languageService: LanguageService) {}

    transform(
        options: IOptions<any>,
        languageCode: LanguageCode = null,
        prefix: string = '',
        stringifyValue = false,
    ): IOptions<any> {
        if (languageCode === null) {
            languageCode = this.languageService.selectedLanguageCode
        }
        if (
            this.latestOptions === options &&
            this.latestLanguageCode === languageCode &&
            this.latestPrefix === prefix &&
            this.checked
        ) {
            return this.result
        }

        this.latestOptions = options
        this.latestLanguageCode = languageCode
        this.latestPrefix = prefix
        this.translateService.get('_placeholder').subscribe(() => {
            this.checked = true
            this.result = options.map(({ value, label }) => ({
                value: stringifyValue ? JSON.stringify(value) : value,
                label: this.translateService.instant(`${prefix}${label}`),
            }))
        })
        return this.result
    }
}
